import {CustomComponent} from "../../../../../helpers/CustomComponent";
import {TrainingAnswerRequestItemDto} from "../../../model/TrainingAnswerRequestModel";
import {ChangeEvent} from "react";
import {AnsCellFocusController} from "../../AnsCellFocusController";
import {observer} from "mobx-react";

export interface AnsCellNumberProps {
    item: TrainingAnswerRequestItemDto,
    pos: number,
    value: string;
    deleteBlock: (pos: number) => void
    addBlock: (pos: number) => void
    changeName: (pos: string, text: string) => void
}
@observer
export class AnsCellName extends CustomComponent<AnsCellNumberProps, any> {
    public controller = new AnsCellFocusController();

    constructor(props: Readonly<AnsCellNumberProps>) {
        super(props);
        this.deleteBlock = this.deleteBlock.bind(this);
        this.addBlock = this.addBlock.bind(this);
        this.changeName = this.changeName.bind(this);
    }

    deleteBlock() {
        this.props.deleteBlock(this.props.pos);
    }

    addBlock() {
        this.props.addBlock(this.props.pos);
    }

    changeName(e: ChangeEvent<HTMLInputElement>) {
        this.props.changeName(this.props.item.id, e.currentTarget.value);
    }

    render() {
        let focusClass = this.controller.hasFocus ? " input-write-answer-focus" : "";
        return <div className={"names-rem3t " + focusClass}>
            <img alt="" src={'https://powermemory.io' + this.props.item.dictionaryImage}/>
            <input autoFocus={this.props.pos === 0}
                   onFocus={this.controller.onFocus}
                   onBlur={this.controller.onBLur}
                   ref={this.controller.setHTMLInputElement}
                   onChange={this.changeName}
                   value={this.props.value} className={"ans-text" + focusClass}/>
        </div>;
    }
}