import {CustomComponent} from "../../helpers/CustomComponent";
import './homeWorkComponent.scss'
import {BlockTextComponent} from "../../helpers/blockText/BlockTextComponent";
import {TitleComponent, TypePositionText, TypeTitle} from "../../helpers/title/TitleComponent";
import {BottomBlockComponent} from "../../helpers/bottomBlock/BottomBlockComponent";
import {NextPrevComponent} from "../../helpers/nextPrev/NextPrevComponent";
import React from "react";
import {observer} from "mobx-react";
import {HomeWorkStore} from "./store/HomeWorkStore";
import {UniversityTypeTask} from "./models/UniversityTypeTask";
import {MiddleTextComponent} from "../../helpers/middleText/MiddleTextComponent";
import {TextAreaComponent} from "../../helpers/textArea/TextAreaComponent";
import {CustomButton} from "../../helpers/CustomButton";
import KeyboardManager from "../../core/keyboardManager/adapters/KeyboardManager";


interface Props {
}

let question1 = [
    {title: "Мнемоника как драйвер", isSelected: false},
    {title: "Понятие", isSelected: false},
    {title: "Закон мнемоники", isSelected: false},
    {title: "Стимул -> Реакция", isSelected: false},
    {title: "Зачем?", isSelected: false},
    {title: "Почему?", isSelected: false},
    {title: "Цепочка", isSelected: false},
    {title: "Матрёшка", isSelected: false},
    {title: "Отличность", isSelected: false},
    {title: "Проникновение", isSelected: false},
]

@observer
export class HomeWorkComponent extends CustomComponent<Props, any> {
    id: number = 0;
    store: HomeWorkStore;

    constructor(props: Readonly<Props>) {
        super(props);
        let p = window.location.pathname.replace("/university/homeWork/", "");
        this.id = parseInt(p);
        this.store = new HomeWorkStore(this.id);
    }

    componentDidMount() {
        KeyboardManager.add('ArrowLeft', this.store.prev);
        KeyboardManager.add('ArrowRight', this.store.next);
    }

    componentWillUnmount() {
        KeyboardManager.remove('ArrowLeft', this.store.prev);
        KeyboardManager.remove('ArrowRight', this.store.next);
    }

    render() {
        return <div className="home-work-component">
            {
                this.store.isLoaded &&
                <>
                    {
                        <TitleComponent typeTitle={TypeTitle.homeWorkTitle}
                                        typePosition={TypePositionText.center}
                                        text={this.store.isFinish ? "" : this.store.task?.name}/>
                    }
                    <BlockTextComponent hasBackground={true} className={"block-main-component"}>
                        {
                            !this.store.isFinish &&
                            <>
                                {
                                    this.store.task?.type === UniversityTypeTask.selectAnswer &&
                                    <MiddleTextComponent>
                                        <TitleComponent typeTitle={TypeTitle.homeTaskTitle}
                                                        text={this.store.task?.title}/>

                                        <TitleComponent typeTitle={TypeTitle.homeTaskDescription}
                                                        text={this.store.task?.description}/>
                                    </MiddleTextComponent>
                                }
                                {
                                    this.store.task?.type === UniversityTypeTask.write &&
                                    <TextAreaComponent key={"text_area_home_work_" + this.store.task.id}
                                                       value={this.store.task.userAnswer}
                                                       onchange={this.store.onChange}/>
                                }
                            </>
                        }
                        {
                            this.store.isFinish &&
                            <MiddleTextComponent>
                                <TitleComponent typeTitle={TypeTitle.homeTaskTitle} text={"Ответы приняты!"}/>
                                <TitleComponent typeTitle={TypeTitle.homeTaskDescription}
                                                text={"Дополнительные разъяснения для тех ключевх слов которые вы не " +
                                                    "поняли будут даны тренером в обратной связи"}/>
                            </MiddleTextComponent>
                        }
                    </BlockTextComponent>
                    <BottomBlockComponent className={"home-work-component_middle-block"}>
                        {
                            !this.store.isFinish &&
                            <>
                                {
                                    this.store.task?.type === UniversityTypeTask.selectAnswer &&
                                    <NextPrevComponent next={this.store.next}
                                                       prev={this.store.prev}
                                                       textNumber={this.store.textNumber}
                                                       customNext={"Да"}
                                                       customPrev={"Нет"}
                                    />
                                }
                                {
                                    this.store.task?.type === UniversityTypeTask.write &&
                                    <NextPrevComponent next={this.store.next}
                                                       prev={this.store.prev}
                                                       textNumber={this.store.textNumber}
                                    />
                                }
                            </>
                        }
                        {
                            this.store.isFinish &&
                            <CustomButton text={"К ЗАДАНИЯМ"} className={""}/>
                        }
                    </BottomBlockComponent>
                </>
            }
        </div>;
    }
}