import React from 'react'
import './trainingResultPage.scss'
import {observer} from "mobx-react";
import TrainingResultStore from "../../components/trainingResult/TrainingResultStore";
import {CustomComponent} from "../../helpers/CustomComponent";
import {Loading} from "../../components/shared/Loading";
import {LoadingModel} from "../../components/shared/LoadingModel";
import {TrainingResultModel} from "../../components/trainingResult/model/TrainingResultModel";
import {useSearchParams} from "react-router-dom";
import TitleStore from "../../components/authorization/store/TitleStore";
import {CustomButton} from "../../helpers/CustomButton";
import {ResultTrainingComponent} from "../../helpers/result/ResultTrainingComponent";

interface RouteParams {
    id?: string;
    model?: LoadingModel<TrainingResultModel>;
}

@observer
export class TrainingResultPage extends CustomComponent<RouteParams, {}> {
    store: TrainingResultStore;

    constructor(props: Readonly<RouteParams>) {
        super(props);
        this.store = new TrainingResultStore(this.props.id, this.props.model);
    }

    componentDidMount() {
        TitleStore.setTitle("Результат тренировки");
        this.store.request();
    }

    render() {
        if (!this.store.model.hasModel) {
            return <Loading/>
        }
        return (
            <div className="training-result">
                <ResultTrainingComponent fullname={this.store.model.Model!.username}
                                         imgSrc={this.store.model.Model!.imgSrc}
                                         rang={this.store.model.Model!.rang}
                                         isPremium={this.store.model.Model!.isPremium}
                                         link={this.store.model.Model!.link}
                                         bestTraining={this.store.model.Model!.bestTraining}
                                         dis={this.store.model.Model!.dIS}
                                         date={this.store.model.Model!.date}
                                         id={this.store.model.Model!.id}
                                         speed={this.store.model.Model!.speed}
                                         timeAll={this.store.model.Model!.timeAll}
                                         textRight={this.store.textRight}
                                         coefficient={this.store.coefficient}
                                         countRightImages={this.store.model.Model!.countRightImages}
                                         error={this.store.model.Model!.error}
                                         index={this.store.model.Model!.index}
                                         items={this.store.model.Model!.items}
                />
                <div className="result-block">
                    {
                        this.store.getItems()
                    }
                    <CustomButton text={"Тренироваться"} className="repeat-tren"/>
                </div>
            </div>
        )
    }
}

export function TrainingResultSearchPage() {
    const [search] = useSearchParams();
    return (
        <TrainingResultPage id={search.get('id') ?? undefined}/>
    );
}