import React, {Component} from 'react'
import TrainingChangeBaseModel from "../store/TrainingChangeBaseModel";
import {observer} from "mobx-react";
import {TrainingDis} from "../../../helpers/trainingHelp/TrainingDis";


@observer
export class DisHelperView extends Component<{ changeModel: TrainingChangeBaseModel }, {}> {

    constructor(props: Readonly<{ changeModel: TrainingChangeBaseModel }>) {
        super(props);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.changeDis = this.changeDis.bind(this);
        this.getDisClassName = this.getDisClassName.bind(this);
        this.onChangeNumber = this.onChangeNumber.bind(this);
        this.onChangeCountElement = this.onChangeCountElement.bind(this);
    }

    changeDis(e: React.MouseEvent<HTMLLabelElement>) {
        if (this.props.changeModel.model.dis !== '0')
            this.props.changeModel.changeDis('0')
        else
            this.props.changeModel.changeDis(e.currentTarget.getAttribute("custom") as TrainingDis)
    }

    onMouseEnter() {
        this.props.changeModel.hoverDis(true);
    }

    onMouseLeave() {
        this.props.changeModel.hoverDis(false);
    }

    onChangeNumber(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.changeModel.changeNumber(parseInt(event.currentTarget
            ?.value
            ?.replace(/[^0-9.]/g, '')
            ?.replace(/(\..*?)\..*/g, '$1') ?? '0'));
    }

    onChangeCountElement(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.changeModel.changeCountElement(parseInt(event.currentTarget
            ?.value
            ?.replace(/[^0-9.]/g, '')
            ?.replace(/(\..*?)\..*/g, '$1') ?? '0'));
    }

    getDisClassName(dis: TrainingDis) {
        if (this.props.changeModel.model.dis === '0') {
            if (this.props.changeModel.model.dis === dis) {
                return "dis disActivity"
            }
            return "dis "
        }
        if (this.props.changeModel.model.dis === dis) {
            return "dis dis-elemnent-label disActivity"
        }
        return "dis dis-elemnent-label"
    }

    render() {
        return (
            <>
                <div id="amount-inst">
                    {
                        this.props.changeModel.model.dis === '0' &&
                        <input id="amount"
                               type="number"
                               value={this.props.changeModel.model.number}
                               onChange={this.onChangeNumber}
                            // ng-model="training.currentTraining.number"
                            // ng-change="changeTrainingNumber()"
                            // ng-class="{redColorText: training.colorRedNumber}"
                               name="amount"
                            // onInput="this.value = Number(this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'));"
                               autoComplete="off"/>
                    }
                    {
                        this.props.changeModel.model.dis === 'A' &&
                        <input id="amount-a"
                               type="number"
                               defaultValue={this.props.changeModel.model.numberA}
                               readOnly={true}
                               name="amount"
                               autoComplete="off"/>
                    }
                    {
                        this.props.changeModel.model.dis !== 'A' && this.props.changeModel.model.dis !== '0' &&
                        <input id="amount-div"
                               type="text"
                               defaultValue="∞"
                               readOnly={true}
                               name="amount"
                               autoComplete="off"/>
                    }


                    <div id="fixet-amount"
                         onMouseEnter={this.onMouseEnter}
                         onMouseLeave={this.onMouseLeave}>
                        {
                            (this.props.changeModel.model.dis === '0' && !this.props.changeModel.isHoverDis) &&
                            <>
                                <input id="dis-text" type="radio" name="dis"/>
                                <label className={this.getDisClassName('0')} id="dis-text-label"
                                       onClick={this.changeDis} custom="0"
                                       htmlFor="dis-text">DIS</label>
                            </>
                        }
                        {
                            (this.props.changeModel.model.dis === 'A' || (this.props.changeModel.isHoverDis &&
                                this.props.changeModel.model.dis === '0')) &&
                            <>
                                <input id="dis-a" type="radio" name="dis"/>
                                <label className={this.getDisClassName('A')} onClick={this.changeDis}
                                       id="dis-a-label" custom="A"
                                       htmlFor="dis-a">A</label>
                            </>
                        }
                        {
                            (this.props.changeModel.model.dis === 'B' || (this.props.changeModel.isHoverDis &&
                                this.props.changeModel.model.dis === '0')) &&
                            <>
                                <input id="dis-b" type="radio" name="dis"/>
                                <label className={this.getDisClassName('B')} custom="B" onClick={this.changeDis}
                                       id="dis-b-label"
                                       htmlFor="dis-b">B</label>
                            </>
                        }
                        {
                            (this.props.changeModel.model.dis === 'C' || (this.props.changeModel.isHoverDis &&
                                this.props.changeModel.model.dis === '0')) &&
                            <>
                                <input id="dis-c" type="radio" name="dis"/>
                                <label className={this.getDisClassName('C')} custom="C" onClick={this.changeDis}
                                       id="dis-c-label"
                                       htmlFor="dis-c">C</label>
                            </>
                        }
                        {
                            (this.props.changeModel.model.dis === 'D' || (this.props.changeModel.isHoverDis &&
                                this.props.changeModel.model.dis === '0')) &&
                            <>
                                <input id="dis-d" type="radio" name="dis"/>
                                <label className={this.getDisClassName('D')} custom="D" onClick={this.changeDis}
                                       id="dis-d-label"
                                       htmlFor="dis-d">D</label>
                            </>
                        }
                        {
                            this.props.changeModel.model.dis === 'A' &&
                            <span id="dis-a-text">≤1 мин</span>
                        }
                        {
                            this.props.changeModel.model.dis === 'B' &&
                            <span id="dis-b-text">5 мин</span>
                        }
                        {
                            this.props.changeModel.model.dis === 'C' &&
                            <span id="dis-c-text">10 мин</span>
                        }
                        {
                            this.props.changeModel.model.dis === 'D' &&
                            <span id="dis-d-text">20 мин</span>
                        }
                    </div>
                </div>

                <div style={{height: '25px'}}>
                    {
                        this.props.changeModel.type === 'number' &&
                        <div>
                            {
                                this.props.changeModel.model.dis === '0' &&
                                <div className="amount-number-training">
                                    <input id="amount2" type="number"
                                           onChange={this.onChangeCountElement}
                                           value={this.props.changeModel.getCountElement} name="amount2"/>
                                    <label id="amount-number-label"
                                           htmlFor="amount2">цифр</label>
                                </div>
                            }
                            {
                                this.props.changeModel.model.dis === 'A' &&
                                <div className="amount-number-training">
                                    <input id="amount2" type="number" defaultValue="100" readOnly={true} name="amount2"/>
                                    <label id="amount-number-label" htmlFor="amount2">цифр</label>
                                </div>
                            }

                        </div>
                    }
                    {
                        this.props.changeModel.type === 'cards' &&
                        <div>
                            {
                                this.props.changeModel.model.dis === '0' &&
                                <div className="amount-number-training" >
                                    <input id="amount3" type="number"
                                           onChange={this.onChangeCountElement}
                                           value={this.props.changeModel.getCountElement.toFixed(2)} name="amount3"/>
                                    <label id="amount-cards-label"
                                           htmlFor="amount3">колод</label>
                                </div>
                            }
                            {
                                this.props.changeModel.model.dis === 'A' &&
                                <div className="amount-number-training" >
                                    <input id="amount3" type="number" defaultValue="1" readOnly={true} name="amount3"/>
                                    <label id="amount-cards-label" htmlFor="amount3">колод</label>
                                </div>
                            }
                        </div>
                    }
                    {
                        this.props.changeModel.type === 'bins' &&
                        <div>
                            {
                                ['0', 'A'].includes(this.props.changeModel.model.dis) &&
                                <div className="amount-number-training">
                                    {
                                        this.props.changeModel.model.dis === 'A' &&
                                        <input id="amount4" type="number" value="300" readOnly={true} name="amount4"/>
                                    }
                                    {
                                        this.props.changeModel.model.dis === '0'  &&
                                        <input id="amount4" type="number"
                                               onChange={this.onChangeCountElement}
                                               value={this.props.changeModel.getCountElement}
                                               name="amount4"/>
                                    }
                                    <label id="amount-binary-label"
                                           htmlFor="amount4">знака(ов)</label>
                                </div>
                            }
                        </div>
                    }
                </div>
            </>
        )
    }
}