import {Component} from "react";
import {observer} from "mobx-react";
import './locationQuality.scss'
import {LocationQualityStore} from "./LocationQualityStore";
import CustomSelector from "../../../shared/selector/CustomSelector";
import {Chart} from "react-google-charts";


@observer
export class LocationQuality extends Component<{}, {}> {
    private store: LocationQualityStore = new LocationQualityStore();

    componentDidMount() {
        this.store.fetchRequest();
    }

    render() {
        return (
            <>
                <span className="sat-name">Качество локаций</span>

                <span className="sat-name sat-name2">Процент локаций по надежности</span>

                {
                    this.store.loadingStore.isLoaded &&
                    <div className="select-style statchosen">
                        <CustomSelector defaultValue={this.store.currentPos} values={this.store.getSelector}
                                        onChange={this.store.setSelector}/>
                        {/*<select className="chosen-select" id="select-routes"></select>*/}
                    </div>
                }

                <div className="circle-tabl-box">
                    {this.store.loadingStore.isLoaded &&
                        <Chart
                            chartType="PieChart"
                            data={this.store.getDataPie}
                            options={this.store.optionsPie}
                            width={"100%"}
                            height={"400px"}
                        />
                    }
                    {!this.store.loadingStore.isLoaded &&
                        <span className="empty-circle">
                        <span className="empty-stat3">Нет данных</span>
                    </span>
                    }
                    <table className="elpie-tabel">
                        <tbody>
                        <tr className="htr">
                            <td className="htr1td">Состояние</td>
                            <td>Ошибки</td>
                            <td>Кол-во</td>
                        </tr>
                        <tr>
                            <td className="htr-ref">Надёжные</td>
                            <td>≤10%</td>
                            <td id="good-stat">{this.store.getCurrent.good}</td>
                        </tr>
                        <tr>
                            <td className="htr-vm">Хорошие</td>
                            <td>20%</td>
                            <td id="normal-stat">{this.store.getCurrent.normal}</td>
                        </tr>
                        <tr>
                            <td className="htr-b">Плохие</td>
                            <td>≥30%</td>
                            <td id="bad-stat">{this.store.getCurrent.bad}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className="substrate sub1l"></div>


                <span className="sat-name sat-name3">Соотношения правильных ответов и ошибок на локациях по последним 10-ти результатам</span>

                <div className="newstackedbar" id="lists" data-highcharts-chart="1">
                    {this.store.getView}
                </div>
                <div className="del" id="del-tb-analway" title="Отчистить данные графика"></div>

                {/*<input className="info-t" id="info-analway" type="checkbox"/>*/}
                {/*    <label className="info-train info-stat" htmlFor="info-analway"></label>*/}
                {/*    <div className="info" id="about-analway">*/}
                {/*        Справка по качеству локаций*/}
                {/*        <div className="closer2w"></div>*/}
                {/*    </div>*/}
                {/*    <div className="parent_popup" onClick="blackout()"></div>*/}
            </>
        );
    }
}