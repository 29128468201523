import {Component} from "react";
import {observer} from "mobx-react";
import './trainingTime.scss'
import CustomSelector from "../../../shared/selector/CustomSelector";
import TrainingTimeStore from "./TrainingTimeStore";
import {reaction} from "mobx";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {Loading} from "../../../shared/Loading";


@observer
export class TrainingTime extends Component<{}, {}> {
    private store: TrainingTimeStore = new TrainingTimeStore();

    componentDidMount() {
        this.store.loadingStore.fetchRequest();
        reaction(
            () => [this.store.selector.value],
            this.store.loadingStore.fetchRequest
        );
    }

    render() {
        if (!this.store.loadingStore.isLoaded)
            return <Loading />
        return (<>
            <span className="sat-name">Время тренировки</span>
            <span className="sat-name sat-name2">Время запоминания и припоминания</span>
            {
                this.store.loadingStore.get!.list?.length === 0 &&
                <span className="empty-stat2">Нет данных о времени тренировки</span>
            }

            <div className="select-style statchosen">
                <CustomSelector groups={this.store.selectors}
                                defaultValue={this.store.selector.value}
                                onChange={this.store.setSelector}/>
            </div>

            {
                this.store.loadingStore.isLoaded &&
                <HighchartsReact
                    highcharts={Highcharts}
                    options={this.store.options} />
            }

            <div className="dop-chart1" id="rectend-dop">
                <div id="rectenddop1">
                    <span className="amount-actstl" id="memorization-time">{this.store.sumTimeAll()}</span>
                    <span className="txt-actstl">часов запоминания всего</span>
                </div>
                <div id="rectenddop2">
                    <span className="amount-actstl" id="recall-time">{this.store.sumTimeRecollection()}</span>
                    <span className="txt-actstl">часов припоминания всего</span>
                </div>
                <div id="rectenddop3">
                    <span className="amount-actstl" id="memo-recall-time">{this.store.sumTimes()}</span>
                    <span className="txt-actstl">часов тренировки всего</span>
                </div>
            </div>

            {/*// <!--Удалить данные о рекордах-->*/}
            <div className="del" id="del-tb-rectend" title="Отчистить данные графика"></div>
        </>);
    }
}