import React from "react";
import "./ReactModal.scss";
import {ReactModalStore} from "./ReactModalStore";
import {CustomComponent} from "../../helpers/CustomComponent";
import {observer} from "mobx-react";

type ModalProps = {
    classNameContent?: string;
    store: ReactModalStore;
    children: React.ReactNode;
    autoSub?: boolean;
};
@observer
export default class Modal extends CustomComponent<ModalProps, {}> {
    render() {
        if (!this.props.store.showModal)
            return null;
        return <ShowModal {...this.props} />;
    }
}

interface ModalShowProps extends ModalProps {
}

class ShowModal extends CustomComponent<ModalShowProps, {}> {
    constructor(props: Readonly<ModalShowProps>) {
        super(props);
        this.modalClick = this.modalClick.bind(this);
    }

    modalClick(e: React.MouseEvent<HTMLDivElement>) {
        e.stopPropagation()
    }

    componentDidMount() {
        document.body.style.overflow = 'hidden';
        if (this.props.autoSub === true) {
            this.props.store.init()
        }
    }

    componentWillUnmount() {
        document.body.style.overflow = 'unset';
        if (this.props.autoSub === true) {
            this.props.store.unInit()
        }
    }

    render() {
        return <div className={"modal-backdrop "} onClick={this.props.store.close}>
            <div className="parent_popup"></div>
            <div className={'modal-content ' + this.props.classNameContent} onClick={this.modalClick}>
                <div className="closer2w" onClick={this.props.store.close}></div>
                {this.props.children}
            </div>
        </div>;
    }
}