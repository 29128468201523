import {makeAutoObservable, observable} from "mobx";
import LoadingStore from "../../../../helpers/LoadingStore";
import {ArchiveResultsModel} from "./ArchiveResultsModel";


export class ArchiveResultsStore {

    @observable
    public typeTime: string = "allTime";
    @observable
    public dis: string = "0";
    @observable
    public mode: string = "number";
    @observable
    public loadingStore: LoadingStore<ArchiveResultsModel>;

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true});
        this.loadingStore = new LoadingStore<ArchiveResultsModel>(this.getUrl)
    }

    getClassMode(mode: string) : string{
        return mode === this.mode ? " activityModeResults": "";
    }
    getClassDis(dis: string) : string{
        return dis === this.dis ? " activityDisciplineResults": "";
    }
    getClassTime(typeTime: string) : string{
        return typeTime === this.typeTime ? " activityTimeBalls": "";
    }

    getUrl(): string {
        return '/api/Statistic/ArchiveResults?time=' + this.typeTime + '&mode=' + this.mode + '&dis=' + this.dis + '&countNow=0';
    }
}