import {SelectorGroupPros, SelectorPros} from "../components/shared/selector/CustomSelector";

export const sexSelectors: SelectorPros[] = [{
    title: "Не указ",
    value: "",
}, {
    title: "Мужской",
    value: "man",
}, {
    title: "Женский",
    value: "women",
}, {
    title: "Другой",
    value: "another",
}];

export const hoursSelectors: SelectorGroupPros[] = [
    {
        title: "Популярные пояса",
        selectors: [{
            title: "UTC+3 - московское время",
            value: "+3"
        }, {
            title: "UTC+4 - ОАЭ, Оман",
            value: "+4"
        }, {
            title: "UTC+8 - западноавстралийское время",
            value: "+8"
        },]
    },
    {
        title: "Все",
        selectors: [{
            title: "+13",
            value: "UTC+13 - тонганское время"
        },
            {
                title: "+12",
                value: "UTC+12 - новозеландское время"
            },
            {
                title: "+11",
                value: "UTC+11 - восточноавстралийское летнее время"
            },
            {
                title: "+10:30",
                value: "UTC+10:30 - лорд-Хау-Айлендское время"
            },
            {
                title: "+10",
                value: "UTC+10 - восточноавстралийское время"
            },
            {
                title: "+9:30",
                value: "UTC+9:30 - центральноавстралийское время"
            },
            {
                title: "+9",
                value: "UTC+9 - японское время"
            },
            {
                title: "+8",
                value: "UTC+8 - западноавстралийское время"
            },
            {
                title: "+7",
                value: "UTC+7 - индо-китайское время"
            },
            {
                title: "+6",
                value: "UTC+6 - западно-казахстанское время"
            },
            {
                title: "+5",
                value: "UTC+5 - пакистанское время"
            },
            {
                title: "+4",
                value: "UTC+4 - ОАЭ, Оман"
            },
            {
                title: "+3",
                value: "UTC+3 - московское время"
            },
            {
                title: "+2",
                value: "UTC+2 - центральноевропейское время"
            },
            {
                title: "+1",
                value: "UTC+1 - западноевропейское время"
            },
            {
                title: "0",
                value: "UTC - всемирное координированное время"
            },
            {
                title: "-1",
                value: "UTC−1 - азорское время"
            },
            {
                title: "-2",
                value: "UTC−2 - среднеатлантическое время"
            },
            {
                title: "-3",
                value: "UTC−3 - аргентинское время"
            },
            {
                title: "-4",
                value: "UTC−4 - атлантическое время"
            },
            {
                title: "-5",
                value: "UTC−5 - североамериканское восточное время"
            },
            {
                title: "-6",
                value: "UTC−6 - центральноамериканское время"
            },
            {
                title: "-7",
                value: "UTC−7 - горное время"
            },
            {
                title: "-8",
                value: "UTC−8 - североамериканское тихоокеанское время"
            },
            {
                title: "-9",
                value: "UTC−9 - аляскинское время"
            },
            {
                title: "-9:30",
                value: "UTC−9:30 - маркизские острова время"
            },
            {
                title: "-10",
                value: "UTC−10 - гавайско-алеутское время"
            },
            {
                title: "-11",
                value: "UTC−11 - самоанское время"
            },]
    },
];