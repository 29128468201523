import React, {Component} from 'react'
import './recordsboardBallsModel.scss'
import {Ball} from "./Ball";
import {observer} from "mobx-react";
import {observable} from 'mobx';
import BallResultStore, {TypeBallsResult, TypeBallsResultTime} from "./store/BallResultStore";
import {Link} from "react-router-dom";
import {TrainingMode} from "../../helpers/trainingHelp/TrainingMode";
import {TrainingDis} from "../../helpers/trainingHelp/TrainingDis";

interface Props {
    store: BallResultStore
}

@observer
export class RecordsboardBallsModel extends Component<Props, {}> {
    @observable
    private store: BallResultStore;

    constructor(props: Readonly<Props>) {
        super(props);
        this.store = this.props.store;
        this.activityTimeBallsClass = this.activityTimeBallsClass.bind(this);
        this.changeType = this.changeType.bind(this);
        this.changeTime = this.changeTime.bind(this);
        this.changeDiscipline = this.changeDiscipline.bind(this);
        this.activityTypeDiscipline = this.activityTypeDiscipline.bind(this);
        this.changeDis = this.changeDis.bind(this);
        this.activityTypeClass = this.activityTypeClass.bind(this);
    }

    componentDidMount() {
        this.store.fetchRequest();
    }

    activityTimeBallsClass(type: TypeBallsResultTime) {
        return this.store.typeTime === type ? " activityTimeBalls" : "";
    }

    activityTypeClass(type: TypeBallsResult) {
        return this.store.typeResult === type ? " activityResultsOrBalls" : "";
    }

    activityTypeDiscipline(className: string, discipline: TrainingMode) {
        if (discipline === this.store.discipline) {
            return className + " activityDisciplineResults"
        }
        return className;
    }

    changeType(event: React.MouseEvent<HTMLDivElement>) {
        this.store.changeType(event.currentTarget.id as TypeBallsResult);
    }

    changeTime(event: React.MouseEvent<HTMLDivElement>) {
        this.store.changeTime(event.currentTarget.id as TypeBallsResultTime);
    }

    changeDiscipline(event: React.MouseEvent<HTMLDivElement>) {
        this.store.setDiscipline(event.currentTarget.id as TrainingMode);
    }

    changeDis(event: React.ChangeEvent<HTMLSelectElement>) {
        this.store.setDis(event.target.value as TrainingDis);
    }

    render() {
        return (
            <div className="recordsboard-all">
                {/*@* ТАБЫ ВРЕМЯ МЕСЯЦ ДЕНЬ - БАЛЛЫ*@*/}
                <div className="records-tabs-1">
                    <div className={"r-tab" + this.activityTypeClass(TypeBallsResult.balls)}
                         id="balls"
                         onClick={this.changeType}>Баллы
                    </div>
                    <div className={"r-tab" + this.activityTypeClass(TypeBallsResult.results)}
                         id="results"
                         onClick={this.changeType}>Результаты
                    </div>
                </div>

                <div className="records-tabs-2">
                    <div className={"r-tab alltime-label " + this.activityTimeBallsClass(TypeBallsResultTime.all)}
                         id='all'
                         onClick={this.changeTime}>Все время
                    </div>
                    <div className={"r-tab month-label " + this.activityTimeBallsClass(TypeBallsResultTime.month)}
                         id='month'
                         onClick={this.changeTime}>Месяц
                    </div>
                    <div className={"r-tab day-label " + this.activityTimeBallsClass(TypeBallsResultTime.day)}
                         id='day'
                         onClick={this.changeTime}>День
                    </div>
                </div>
                {
                    this.store.typeResult === TypeBallsResult.balls &&
                    <aside className="recordsboard" id="recordsboard-balls">
                        {/*    /!*@*ТЕЛО ТАБЛИЦЫ БАЛЛОВ*@*!/*/}
                        <section className="records-tabs-balls">
                            {
                                // todo Добавить скелетонс
                                // !this.store.modelBalls.IsLoaded &&
                                // <Loading/>
                            }
                            {
                                this.store.modelBalls.Model && this.store.modelBalls.IsLoaded &&
                                this.store.modelBalls.Model!.ballsResult!.map((x, u) =>
                                    <Ball key={x.idAccount} ball={x} pos={u + 1}/>
                                )
                            }
                            {
                                this.store.modelBalls.Model && this.store.modelBalls.Model.prevBall &&
                                <div className="lider">
                                    <div className="last-records">Рекорд
                                        прошлого {this.store.modelBalls.Model.name}</div>
                                    <Ball ball={this.store.modelBalls.Model!.prevBall} pos={null}/>
                                </div>
                            }
                            {
                                this.store.typeTime === TypeBallsResultTime.all &&
                                <Link to={"/TopUsers"} className="more allhistory-user">Все пользователи</Link>
                            }
                        </section>
                    </aside>
                }
            </div>
        )
    }
}

