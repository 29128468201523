import {makeAutoObservable} from "mobx";


export class AccordionStore {
    show: boolean = false;
    constructor() {
        makeAutoObservable(this, {}, {autoBind: true})
    }

    clickShow(){
        this.show = !this.show;
    }
}