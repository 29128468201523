import {CustomComponent} from "../../../../helpers/CustomComponent";
import {PropsTrainingType} from "./PropsTrainingType";
import {TitleComponent, TypePositionText, TypeTitle} from "../../../../helpers/title/TitleComponent";
import {ImageComponent} from "../../../../helpers/img/ImageComponent";
import React from "react";
import {observer} from "mobx-react";
import {makeAutoObservable} from "mobx";
import HeaderTrainingLearnLanguageStore from "../HeaderTrainingLearnLanguageStore";
import {CheckTextStatus} from "../../../../helpers/checkText/CheckTextStatus";
import {NextAndPrevAdjectiveComponent} from "../../../../helpers/nextComponent/NextAndPrevAdjectiveComponent";
import {AudioComponent} from "../../../../helpers/audio/AudioComponent";


@observer
export class SpeakerAdjectiveComponent extends CustomComponent<PropsTrainingType, any> {
    private store: Store;

    constructor(props: Readonly<PropsTrainingType>) {
        super(props);
        this.store = new Store(this.props);
    }

    componentWillUnmount() {
        this.store.unInit();
    }

    render() {
        return <>
            {
                !this.store.showHint &&
                <ImageComponent key={this.props.currentItem.image}
                                src={this.props.currentItem.image}
                                alt={"Вспомните слово."}/>
            }

            {
                this.store.showHint &&
                <>
                    <TitleComponent key={this.props.currentItem.nameRus}
                                    text={this.props.currentItem.nameRus}
                                    typePosition={TypePositionText.center}
                                    typeTitle={TypeTitle.description}/>
                    {
                        this.props.currentItem.nuance &&
                        <TitleComponent text={this.props.currentItem.nuance} typePosition={TypePositionText.center}
                                        typeTitle={TypeTitle.bigTitle}/>
                    }
                    <ImageComponent key={this.props.currentItem.image}
                                    src={this.props.currentItem.image}
                                    alt={this.props.currentItem.nameRus}/>

                    <TitleComponent key={this.props.currentItem.nameEng}
                                    text={this.props.currentItem.nameEng}
                                    typePosition={TypePositionText.center}
                                    typeTitle={TypeTitle.bigTitle}/>
                    <TitleComponent key={this.props.currentItem.transcriptionAmerica}
                                    text={this.props.currentItem.transcriptionAmerica}
                                    typePosition={TypePositionText.center}
                                    typeTitle={TypeTitle.description}/>

                    <AudioComponent key={"audio_" + this.props.currentItem.audio}
                                    src={this.props.currentItem.audio}/>
                </>
            }
            <NextAndPrevAdjectiveComponent next={this.store.next} back={this.store.back}
                                           positionInfo={this.props.positionInfo} />
        </>
    }
}

class Store {
    props: PropsTrainingType;
    showHint: boolean = false;
    status: CheckTextStatus = CheckTextStatus.noCheck;

    constructor(props: PropsTrainingType) {
        this.props = props;
        makeAutoObservable(this, {}, {autoBind: true})
        HeaderTrainingLearnLanguageStore.setHintTraining(this.showHintClick);
    }

    showHintClick() {
        if (!this.showHint) {
            this.props.item.tryCount++;
        }
        this.showHint = !this.showHint;
    }

    next() {
        this.showHint = false;
        this.props.next(this.props.item)
    }

    back() {
        this.showHint = false;
        this.props.back(this.props.item)
    }

    unInit() {
    }
}