import React from "react";
import {AnsCellBins} from "./block/AnsCellBins";
import {AnswerTrainingBaseService} from "../AnswerTrainingBaseService";

export class BinsTrainingAnswerBaseService extends AnswerTrainingBaseService {
    get view(): JSX.Element {
        return <>
            {
                this.items.map((x) =>
                    <AnsCellBins key={x.id}
                                 ref={y => x.p = y}
                                 value={x.answer}
                                 complexity={this.model.complexity}
                                 pos={x.numberPosition}
                                 item={x}
                                 addBlock={this.addBlock}
                                 deleteBlock={this.deleteBlock}
                                 changeName={this.changeName}/>
                )
            }
        </>;
    }

}