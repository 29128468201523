import {makeAutoObservable, observable} from "mobx";
import LoadingStore from "../../../../helpers/LoadingStore";
import {SelectorPros} from "../../../shared/selector/CustomSelector";
import Highcharts from "highcharts";
import {MemorySpeedModel} from "./MemorySpeedModel";
import {getOpinion} from "../../../../helpers/HigchartsOptionsHelper";


export class MemorySpeedStore {

    public selectorDIS: SelectorPros;
    public selectorsDIS: SelectorPros[] = [
        {value: "A", title: "Дистанция A ≤1 мин"},
        {value: "B", title: "Дистанция B 5 мин"},
        {value: "C", title: "Дистанция C 10 мин"},
        {value: "D", title: "Дистанция D 20 мин"},
        {value: "0", title: "Дистанция ∞"},
    ];

    public selectorMode: SelectorPros;
    public selectorsMode: SelectorPros[] = [
        {value: "number", title: "Числа"},
        {value: "cards", title: "Карты"},
        {value: "word", title: "Слова"},
        {value: "name", title: "Имена"},
        {value: "bins", title: "Бинарные"},
        {value: "image", title: "Картинки"},
    ];

    public loadingStore: LoadingStore<MemorySpeedModel>;

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true});
        this.selectorMode = this.selectorsMode.filter((x) => x.value === "number")[0];
        this.selectorDIS = this.selectorsDIS.filter((x) => x.value === "0")[0];
        this.loadingStore = new LoadingStore<MemorySpeedModel>(this.getUrl)
    }


    getUrl(): string {
        return "/api/Statistic/MemorySpeed?discipline=" + this.selectorMode.value + "&dis=" + this.selectorDIS.value;
    }

    setSelectorMode(sel: SelectorPros) {
        this.selectorMode = sel;
    }

    setSelectorDis(sel: SelectorPros) {
        this.selectorDIS = sel;
    }

    get options(): Highcharts.Options {
        return getOpinion({
            date: this.loadingStore?.model?.Model?.list.map((x) => {
                    return {date: x.dateTime, value: x.speed}
                }) ?? [],
            nameSeries: 'Запоминание (мин)',
            valueDecimals: 3,
            chartType: 'column'
        })
    };
}