import {CustomComponent} from "../../../helpers/CustomComponent";
import React from "react";

interface Props{
    timeGet: string;
    dateGet: string;
    toShare: () => void;
    openCloseDiagram: () => void;
}
export class ResAndShareBox extends CustomComponent<Props, any>{

    constructor(props: Readonly<Props>) {
        super(props);
        this.shared = this.shared.bind(this);
    }
    shared(){
        this.props.toShare();
    }
    render() {
        return <div className="res-data-box">
            <div id="res-date">
                <span id="res-date-time">{this.props.timeGet}</span>
                <span id="res-date-date">{this.props.dateGet}</span>
            </div>
            <div id="res-repost" onClick={this.shared}
                 data-title="Поделиться результатом"></div>
            <div id="res-element-show" onClick={this.props.openCloseDiagram}
                 data-title="Диаграмма темпа запоминания"></div>
        </div>;
    }
}