import {SelectorPros} from "../shared/selector/CustomSelector";
import {coursesStore} from "./StoreHelper";
import {makeAutoObservable} from "mobx";


export class StoreStore {
    currentCourse: SelectorPros = coursesStore[0];

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true})
    }

    changeCourse(prop: SelectorPros | undefined) {
        if (prop) {
            this.currentCourse = prop;
        }
    }

    get currentCurrency(): string {
        if (this.currentCourse.value === 'usd') {
            return '$';
        }
        if (this.currentCourse.value === 'rub') {
            return 'RUB';
        }
        if (this.currentCourse.value === 'byn') {
            return 'BYN';
        }
        if (this.currentCourse.value === 'kzt') {
            return 'KZT';
        }
        if (this.currentCourse.value === 'uah') {
            return 'UAH';
        }
        if (this.currentCourse.value === 'eur') {
            return 'EUR';
        }
        if (this.currentCourse.value === 'aed') {
            return 'AED';
        }
        throw Error("not found value");
    }

    get currentValueCurrency(): number {
        if (this.currentCourse.value === 'usd') {
            return 1;
        }
        if (this.currentCourse.value === 'rub') {
            return 98;
        }
        if (this.currentCourse.value === 'byn') {
            return 3.28;
        }
        if (this.currentCourse.value === 'kzt') {
            return 462;
        }
        if (this.currentCourse.value === 'uah') {
            return 43;
        }
        if (this.currentCourse.value === 'eur') {
            return 0.929;
        }
        if (this.currentCourse.value === 'aed') {
            return 3.7;
        }
        throw Error("not found value");
    }
}