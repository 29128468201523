import {Component} from "react";
import {observer} from "mobx-react";
import './activity.scss';
import CustomSelector from "../../../shared/selector/CustomSelector";
import {ActivityStore} from "./ActivityStore";
import {reaction} from "mobx";
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";


@observer
export class Activity extends Component<{}, {}>{
    private store: ActivityStore = new ActivityStore();

    componentDidMount() {
        this.store.loadingStore.fetchRequest();
        reaction(
            () => [this.store.selector.value],
            this.store.loadingStore.fetchRequest
        );
    }

    render() {
        return (<>

            <span className="sat-name">Активность тренировок</span>
            {
                this.store.loadingStore.isLoaded && this.store.loadingStore.model.Model &&
                this.store.loadingStore.model.Model!.data.length === 0 &&
                <span className="empty-stat2" id="activity_empty">Начните тренироваться, чтобы увидеть график Вашей активности</span>
            }
            <span className="sat-name sat-name2">Количество набранных элементов</span>

            <div className="select-style statchosen">
                <CustomSelector values={this.store.selectors}  defaultValue={this.store.selector.value} onChange={this.store.setSelector}/>
            </div>

            <div className="charttxt" id="containerAct"></div>
            {
                this.store.loadingStore.isLoaded &&
                <HighchartsReact
                    // constructorType={'stockChart'}
                    highcharts={Highcharts}
                    options={this.store.options} />
            }
            <div id="timeseries"></div>

            <div className="dop-chart" id="activity-dop">
                <div id="actdop1">
                    <span className="amount-actstl" id="el-of-day">{!this.store.loadingStore.isLoaded ? "-" : this.store.loadingStore.model.Model!.temp10Trainings}</span>
                    <span className="txt-actstl">Среднее кол-во элементов за последние 10 тренировочных дней</span>
                </div>
                <div id="actdop2">
                    <span className="amount-actstl" id="el-of-month">{!this.store.loadingStore.isLoaded ? "-" : this.store.loadingStore.model.Model!.tempNowMonth}</span>
                    <span className="txt-actstl">Среднее кол-во элементов с начала календарного месяца с учетом пропусков</span>
                </div>
                <div id="actdop3">
                    <span className="amount-actstl" id="next-rang-el">{!this.store.loadingStore.isLoaded ? "-" : this.store.loadingStore.model.Model!.forecastMonth}</span>
                    <span className="txt-actstl">Прогноз к концу месяца если продолжите тренироваться каждый день с такой же интенсивностью</span>
                </div>
            </div>

            {/*// <!--Удаление "Активность"-->*/}
            <div className="del" id="del-activity"></div>

            {/*// <!--Справка по активности-->*/}
            {/*<input className="info-t" id="info-activity" type="checkbox" />*/}
            {/*<label className="info-train info-stat" htmlFor="info-activity"></label>*/}
            {/*<div className="info" id="about-activity">*/}
            {/*    Справка по активности*/}
            {/*    <div className="closer2w"></div>*/}
            {/*</div>*/}

        </>);
    }
}