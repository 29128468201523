import {Component} from "react";
import {observer} from "mobx-react";
import {GeneralStaminaStore} from "./GeneralStaminaStore";


@observer
export class GeneralStamina extends Component<{}, {}> {
    private store: GeneralStaminaStore = new GeneralStaminaStore();

    componentDidMount() {
        this.store.loadingStore.fetchRequest();
        // reaction(
        //     () => [this.store.selector.value],
        //     this.store.loadingStore.fetchRequest
        // );
    }

    render() {
        return (
            <>
                <span className="sat-name">Мнемоническая выносливость</span>
                <span className="sat-name sat-name2">На основе лучших результатов на каждом объёме</span>
                <span className="empty-stat2" id="error">
                    Для построения графика необходимо запомнить 20 чисел  <br/> и как минимум один из объёмов:
                    30, 40, 50, 60, 70, 80, 90, 100, <br/> 200, 300, 400, 500, 600, 700, 800, 900, 1000 чисел
                </span>

                {/*<div className="charttxt" id="endurance-chart"*/}
                {/*     style="top: 20px; max-width: 900px; min-height: 400px; margin: 0px auto; overflow: hidden;"*/}
                {/*     data-highcharts-chart="2"></div>*/}

                <div className="del" id="del-endurance"></div>
            </>
        );
    }
}