import {makeAutoObservable, observable} from "mobx";
import LoadingStore from "../../../../helpers/LoadingStore";
import {SelectorPros} from "../../../shared/selector/CustomSelector";
import {CodingSpeedModel} from "./CodingSpeedModel";
import Highcharts from "highcharts";
import {getOpinionColumn, getOpinionPie} from "../../../../helpers/HigchartsOptionsHelper";


export class CodingSpeedStore {

    public selectorMode: SelectorPros;
    public selectorModes: SelectorPros[] = [
        {value: "9", title: "0-9"},
        {value: "99", title: "00-99"},
        {value: "999", title: "000-999"},
        {value: "eng", title: "English"},
        {value: "figurativeСodes", title: "Образы значения"},
    ];

    public selectorType: SelectorPros;
    public selectorTypes: SelectorPros[] = [
        {value: "XClean", title: "Чистое кодирование"},
        {value: "imageClean", title: "Чистое декодирование"},
        {value: "X2", title: "Кодирование выбор из двух"},
        {value: "image2", title: "Декодирование выбор из двух"},
    ];

    public loadingStore: LoadingStore<CodingSpeedModel>;

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true});
        this.selectorMode = this.selectorModes.filter((x) => x.value === "99")[0];
        this.selectorType = this.selectorTypes.filter((x) => x.value === "XClean")[0];
        this.loadingStore = new LoadingStore<CodingSpeedModel>(this.getUrl)
    }


    getUrl(): string {
        return "/api/Statistic/CodingSpeed?discipline=" + this.selectorMode.value + "&type=" + this.selectorType.value;
    }

    setSelectorMode(sel: SelectorPros) {
        this.selectorMode = sel;
    }

    setSelectorType(sel: SelectorPros) {
        this.selectorType = sel;
    }

    get options(): Highcharts.Options {
        return getOpinionColumn({
            nameSeries: 'Запоминание (мин)',
            valueDecimals: 3,
            date: this.loadingStore?.model.Model?.days?.map((x, y) => {
                return {date: x.date, value: x.speedAverage / 1000}
            }) ?? []
        });
    }

    get optionsPie(): Highcharts.Options {
        return getOpinionPie(
            {
                colors: ['#4fb260', '#a5bd43', '#fcc826', '#f38067', '#d93243'],
                titleText: ((this.loadingStore.get?.averageSpeed ?? 0) / 1000).toFixed(3) + '</br><span class="txt-actstl">средний темп</br>кодирования</br>за последние</br>10 результатов</span>',
                date: [{
                    name: 'Рефлекс',
                    y: this.loadingStore.get?.speed05 ?? 0
                }, {
                    name: 'Очень сильная',
                    y: this.loadingStore.get?.speed10 ?? 0
                }, {
                    name: 'Сильная',
                    y: this.loadingStore.get?.speed15 ?? 0
                }, {
                    name: 'Средняя',
                    y: this.loadingStore.get?.speed25 ?? 0,
                }, {
                    name: 'Слабая',
                    y: this.loadingStore.get?.speed ?? 0,
                }]
            }
        );
    }
}