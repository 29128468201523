import {UniversityTaskFullModel} from "../models/UniversityTaskFullModel";
import {UniversityTypeTask} from "../models/UniversityTypeTask";
import {makeAutoObservable} from "mobx";


export class HomeWorkStore {
    id: number;
    currentPosition: number = 0;

    tasks: UniversityTaskFullModel[] = [{
        id: 1,
        name: "По какому закону работает память?",
        type: UniversityTypeTask.selectAnswer,
        title: "мысленный экран",
        description: "- ментальное пространство, в котором проявляются образы, " +
            "не наблюдамые в реальности, но припоминаемые.",
        userAnswer: ""
    }, {
        id: 2,
        name: "Как правильно представлять образы?",
        type: UniversityTypeTask.write,
        description: "",
        userAnswer: ""
    }, {
        id: 3,
        name: "Как правильно соединять образы?",
        type: UniversityTypeTask.write,
        description: "",
        userAnswer: ""
    }, {
        id: 4,
        name: "Какие основные этапы выделяют в процессе запоминания?",
        type: UniversityTypeTask.write,
        description: "",
        userAnswer: ""
    }, {
        id: 5,
        name: "Какие условия нужно соблюсти для гарантированного создания связи?",
        type: UniversityTypeTask.write,
        description: "",
        userAnswer: ""
    }, {
        id: 6,
        name: "Почему создавать связи между образами, используя эмоциональное нагнетание, истории, рационализацию не правильно?",
        type: UniversityTypeTask.write,
        description: "",
        userAnswer: ""
    }, {
        id: 7,
        name: "Чем отличаются приёмы запоминания последовательности «Цепочка» и «Матрёшка»?",
        type: UniversityTypeTask.write,
        description: "",
        userAnswer: ""
    }, {
        id: 8,
        name: "Какие виды связей по силе создания вы знаете?",
        type: UniversityTypeTask.write,
        description: "",
        userAnswer: ""
    }, {
        id: 9,
        name: "Почему важна высокая осознанность при изучении мнемотехники?",
        type: UniversityTypeTask.write,
        description: "",
        userAnswer: ""
    }, {
        id: 10,
        name: "Какие цели должны быть достигнуты для того, чтобы сказать \"навык эффективного запоминания\" встроен?",
        type: UniversityTypeTask.write,
        description: "",
        userAnswer: ""
    },]

    constructor(id: number) {
        makeAutoObservable(this, {}, {autoBind: true})
        this.id = id;
    }

    init() {

    }

    unInit() {

    }

    get textNumber(): string {
        return (this.currentPosition + 1) + " / " + this.tasks.length
    }

    onChange(val: string) {
        if (this.tasks[this.currentPosition]) {
            this.tasks[this.currentPosition].userAnswer = val;
        }
    }

    get isLoaded():boolean{
        return !!this.tasks;
    }

    get task(): UniversityTaskFullModel | undefined {
        if (this.isFinish)
            return undefined;
        return this.tasks[this.currentPosition];
    }

    get isFinish(): boolean {
        return this.tasks.length <= this.currentPosition;
    }

    next() {
        if (this.currentPosition < this.tasks.length) {
            this.currentPosition++;
        }
    }

    prev() {
        if (this.currentPosition > 0) {
            this.currentPosition--;
        }
    }

    finish() {

    }
}