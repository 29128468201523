import {CustomComponent} from "../CustomComponent";
import React from "react";

export class ButtonScrollToTop extends CustomComponent<any, any>{
    render() {
        return <div className="leftbar-wrap">
            <div className="left-controlbar">
                <span className="active-area"></span>
            </div>
        </div>;
    }
}