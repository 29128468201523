import {makeAutoObservable} from "mobx";
import {CheckScreen, TypeScreen} from "../../context/TypeScreen";


export class NavBurgerStore {
    constructor() {
        makeAutoObservable(this, {}, {autoBind: true})
    }

    private showBurger: boolean = false;

    set setBurger(burger: boolean) {
        this.showBurger = burger;
    }

    get burger() {
        return CheckScreen([TypeScreen.Mobile]) && this.showBurger;
    }

    get isMobile() {
        return CheckScreen([TypeScreen.Mobile]);
    }

    init() {

    }

    unInit() {

    }
}