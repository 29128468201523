import {Component} from "react";
import {observer} from "mobx-react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {MemorySpeedStore} from "./MemorySpeedStore";
import {reaction} from "mobx";
import CustomSelector from "../../../shared/selector/CustomSelector";
import {Loading} from "../../../shared/Loading";


@observer
export class MemorySpeed extends Component<{}, {}> {
    private store: MemorySpeedStore = new MemorySpeedStore();

    componentDidMount() {
        this.store.loadingStore.fetchRequest();
        reaction(
            () => [this.store.selectorDIS.value, this.store.selectorMode.value],
            this.store.loadingStore.fetchRequest
        );
    }

    render() {
        if (!this.store.loadingStore.isLoaded)
            return <Loading />
        return (
            <>
                <span className="sat-name">Темп запоминания</span>
                <span className="sat-name sat-name2">Тенденция темпа запоминания</span>

                <div className="statchosen-2v">
                    <div className="select-style statchosen2">
                        <CustomSelector defaultValue={this.store.selectorMode.value}
                                        values={this.store.selectorsMode}
                                        onChange={this.store.setSelectorMode} width="100%"/>
                    </div>

                    <div className="chdis" id="ch-remdis">
                        <div className="select-style-t2">
                            <CustomSelector defaultValue={this.store.selectorDIS.value}
                                            values={this.store.selectorsDIS}
                                            onChange={this.store.setSelectorDis} width="100%"/>
                        </div>
                    </div>
                </div>
                {
                    this.store.loadingStore.isLoaded && this.store.loadingStore.get!.list.length === 0 &&
                    <span className="empty-stat2">Нет результатов в выбранных дисциплине и дистанции</span>
                }

                {
                    this.store.loadingStore.isLoaded && this.store.loadingStore.get!.list.length !== 0 &&
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={this.store.options} />
                }
                <div className="dop-chart1" id="speedrem-dop">
                    <div id="speedrem1">
                        <span className="amount-actstl" id="speed-growth">{this.store.loadingStore.get!.speed ?? '-'}</span>
                        <span className="txt-actstl">средний прирост темпа</span>
                    </div>
                    <div id="speedrem2">
                        <span className="amount-actstl" id="days-left">{this.store.loadingStore.get!.days ?? '-'}</span>
                        <span className="txt-actstl">дней до достижения мировой темпа</span>
                    </div>
                </div>

                <div className="del" id="del-tb-speedrem" title="Отчистить данные графика"></div>
            </>
        );
    }
}