import React, {Component} from 'react'
import './answerTraining.scss'
import './ans-app-images.scss'
import './ans-app-cards.scss'
import {Loading} from "../../shared/Loading";
import {observer} from "mobx-react";
import {AnswerTrainingBaseStore} from "../store/AnswerTrainingBaseStore";
import StopwatchService from "../../../helpers/StopwatchService";
import KeyboardManager from "../../../core/keyboardManager/adapters/KeyboardManager";
import {CustomButton} from "../../../helpers/CustomButton";
import {AnswerTrainingStore} from "./AnswerTrainingStore";
import Modal from "../../modal/ReactModal";
import {ReactModalStore} from "../../modal/ReactModalStore";

interface Props {
    store: AnswerTrainingBaseStore | undefined
}

@observer
export default class AnswerTrainingComponent extends Component<Props, {}> {
    private readonly reactModalStore: ReactModalStore = new ReactModalStore();
    private store : AnswerTrainingStore;
    constructor(props: Readonly<Props>) {
        super(props);
        this.store = new AnswerTrainingStore(this.props.store);
    }

    componentDidMount() {
        KeyboardManager.add('ArrowLeft', this.props.store!.prevElement);
        KeyboardManager.add('ArrowRight', this.props.store!.nextElement);
        KeyboardManager.add('Escape', this.reactModalStore.open);
        StopwatchService.start()
        this.store.init();
    }

    componentWillUnmount() {
        KeyboardManager.remove('ArrowLeft', this.props.store!.prevElement);
        KeyboardManager.remove('ArrowRight', this.props.store!.nextElement);
        KeyboardManager.remove('Escape', this.reactModalStore.open);
        StopwatchService.clearTimer()
        this.store.unInit();
    }

    render() {
        if (!this.props.store)
            return <><Loading/></>
        return (
            <div className="answer-training">
                <Modal store={this.reactModalStore}>
                    <div id="dialog-form" title="Закрыть">
                        <p><span className="ui-icon ui-icon-alert"
                                 style={{float: 'left', margin: '12px 12px 20px 0'}}>
                        </span>Вы уверены что хотите завершить тренировку?</p>
                        <div className="dialog-form-block-buttons">
                            <CustomButton text="Нет" onclick={this.reactModalStore.close} />
                            {
                                this.store.currentTimer <= 0 &&
                                <CustomButton text="Закрыть тренировку" onclick={this.store.onFinish} />
                            }
                        </div>
                    </div>
                </Modal>
                <header id="header-training">
                    <div id="header-training-box">
                        <div id="show-me-the-code" style={{backgroundImage:"none"}}></div>
                        <div id="timer-abcd">{StopwatchService.currentTimeStr}</div>
                        <div id="f-finish" title="Закрыть тренировку" onClick={this.reactModalStore.open}>
                        </div>
                    </div>
                </header>
                <div id="contener-2">
                    <div id="ans-box" className="clearfix">
                        {this.props.store.service.view}
                    </div>
                    <div id="ans_display" className="ans-container">
                        {this.props.store.service.viewCustom}
                    </div>
                </div>
                <CustomButton className="watch-res" text={this.store.textWatchRes} onclick={this.store.onFinish}/>
            </div>
        )
    }
}