import {Callback, Key, Queue} from "./types";


type AddParams = {
    key: Key;
    callback: Callback;
};

export class CallbackKeyboardService{
    eventsService: EventsKeyboardService;

    constructor() {
        this.eventsService = new EventsKeyboardService(new QueueKeyboardService());
    }

    addCallback({ key, callback }: AddParams){
        const needAddEventListener = this.eventsService.queueService.allQueuesAreEmpty();

        const queue = this.eventsService.queueService.getOrCreateQueue(key);
        queue.push(callback);

        if (needAddEventListener) {
            this.eventsService.addEventListener();
        }
    }

    removeCallback = ({ key, callback }: AddParams) => {
        const queue = this.eventsService.queueService.getOrCreateQueue(key);
        const index = queue.findIndex(
            (queueCallback) => queueCallback === callback
        );

        if (index > -1) {
            queue.splice(index, 1);
        }

        if (this.eventsService.queueService.allQueuesAreEmpty()) {
            this.eventsService.removeEventListener();
        }
    };
}

class EventsKeyboardService {
    queueService: QueueKeyboardService;
    private keys: string[] = [];
    constructor(queueService: QueueKeyboardService) {
        this.queueService = queueService;
    }

    onKeyDown = (event: KeyboardEvent) => {
        const queue = this.queueService.getQueue(event.code);
        console.log('event.code', event.code)

        if (this.keys.filter(x => x === event.code).length > 0)
            return;

        this.keys.push(event.code)
        if (!queue || queue.length === 0) {
            return;
        }
        const wrappedCallback = queue[queue.length - 1];
        if (wrappedCallback)
            wrappedCallback(event)
    };

    onKeyUp = (event: KeyboardEvent) => {
        this.keys = this.keys.filter(x => x !== event.code);
    }

    addEventListener = (): void => {
        window.addEventListener('keydown', this.onKeyDown);
        window.addEventListener('keyup', this.onKeyUp);
    };

    removeEventListener = (): void => {
        window.removeEventListener('keydown', this.onKeyDown);
        window.addEventListener('keyup', this.onKeyUp);
    };
}

class QueueKeyboardService {
    keyToQueue: Record<Key, Queue> = {};

    allQueuesAreEmpty = (): boolean => {
        return Object.values(this.keyToQueue).every((queue) => queue.length === 0);
    };

    getQueue = (key: Key): Queue | undefined => {
        return this.keyToQueue[key];
    };

    getOrCreateQueue = (key: Key): Queue => {
        const queue = this.getQueue(key);

        if (queue) {
            return queue;
        }

        const newQueue: Queue = [];
        this.keyToQueue[key] = newQueue;

        return newQueue;
    };
}