import {TrainingMemberItemModel, TrainingMemberModel} from "../../model/TrainingMemberModel";
import {TrainingMemberRequestItemModel} from "../../model/TrainingMemberRequestModel";
import {IMemberTrainingBaseService} from "../IMemberTrainingBaseService";
import {makeAutoObservable} from "mobx";
import BinsMemberComplexityComponent from "./BinsMemberComplexityComponent";

export class BinsMemberTrainingBaseService implements IMemberTrainingBaseService {
    model: TrainingMemberModel;
    itemsRequest: TrainingMemberRequestItemModel[];
    countElementsOneTime: number;
    numberElNow: number = 0;
    statusBackgr: boolean = false;
    // mask: string;
    lastDate: Date = new Date();
    elementAtGo: number;
    text0 = '0';
    text1 = '1';
    // startAndEndTest: JSX.Element;
    trainFormat: number;
    answerRequest: (items: TrainingMemberRequestItemModel[]) => void;

    constructor(model: TrainingMemberModel, answerRequest: (items: TrainingMemberRequestItemModel[]) => void) {
        makeAutoObservable(this, {}, {autoBind: true})
        this.answerRequest = answerRequest;
        this.model = model;
        this.itemsRequest = [];
        this.elementAtGo = parseInt(model.elementAtGo);
        this.countElementsOneTime = parseInt(model.elementAtGo);
        this.trainFormat = parseInt(model.complexity) * 3;
        this.numberElNow = 0;
        // this.startAndEndTest = new BinsMemberComplexityComponent(this.countElementsOneTime, this.trainFormat)
        for (const modelElement of this.model.items) {
            this.itemsRequest.push({
                id: modelElement.id,
                date: null,
                timeElement: 0,
            })
        }

    }

    next() {
        if (this.numberElNow < 0) return;

        let firsStart = true;
        let elDate = this.numberElNow;
        let countElementNow = this.countElementsOneTime;
        for (; elDate % this.countElementsOneTime !== 0; elDate++) {
            countElementNow--;
        }
        for (; elDate < this.model.items.length && (elDate % this.countElementsOneTime !== 0 || firsStart === true); elDate++) {
            firsStart = false;
            this.itemsRequest[elDate].timeElement += (new Date().getTime() - this.lastDate.getTime()) / countElementNow;
            this.itemsRequest[elDate].date = new Date().getTime();
        }
        this.numberElNow += this.countElementsOneTime;
        this.lastDate = new Date();
        if (this.numberElNow >= this.model.items.length) {
            this.finish();
        }
    }

    back(): void {
        if (this.numberElNow <= 0) {
            return;
        }
        var firsStart = true;
        let elm1 = this.numberElNow;
        let countNow = 0;//Время
        for (; elm1 % this.countElementsOneTime !== 0 || firsStart === true; elm1--) {
            firsStart = false;
            countNow++;
        }
        firsStart = true;
        for (; elm1 < this.model.items.length && (elm1 % this.countElementsOneTime !== 0 || firsStart === true); elm1++) {
            firsStart = false;
            this.itemsRequest[elm1].timeElement += (new Date().getTime() - this.lastDate.getTime()) / countNow;
            this.itemsRequest[elm1].date = new Date().getTime();
        }
        //Показ все остальное
        this.numberElNow = this.numberElNow - countNow;
        this.lastDate = new Date();
    }

    finish(): void {
        this.answerRequest(this.itemsRequest);
    }

    repeat(): void {
        let firsStart = true;
        if (this.numberElNow > 0) {
            var elDate = this.numberElNow - this.countElementsOneTime;
            for (; elDate % this.countElementsOneTime !== 0; elDate++) {

            }
            for (; elDate < this.model.items.length && (elDate % this.countElementsOneTime !== 0 || firsStart === true); elDate++) {
                firsStart = false;
                this.itemsRequest[elDate].timeElement += (new Date().getTime() - this.lastDate.getTime()) / this.countElementsOneTime;
            }
        }
        this.numberElNow = 0;
        this.lastDate = new Date();
    }

    get view(): JSX.Element {
        let massive: TrainingMemberItemModel[] = [];
        let firsStart = true;
        let numberElNow = this.numberElNow;
        for (let i = 0; i < this.countElementsOneTime &&
        (numberElNow % this.countElementsOneTime !== 0 || firsStart === true) &&
        numberElNow < this.model.items.length; i++, numberElNow++) {
            firsStart = false;
            massive.push(this.model.items[numberElNow])
            if (numberElNow !== 0 && this.elementAtGo === 1 && this.countElementsOneTime <= 3) {
                this.statusBackgr = this.model.items[numberElNow].dictionary === this.model.items[numberElNow - 1].dictionary;
            }
        }

        let txt = "";
        let space = "";

        if (this.elementAtGo <= 3) {
            space = " ";
        }
        for (let i = 0; i < massive.length; i++) {
            // if (massive[i])
            txt += massive[i].dictionary + space;
        }
        return <div id="binary-rem"><BinsMemberComplexityComponent
            trainFormat={this.trainFormat}
            countElementsOneTime={this.countElementsOneTime}>{txt}</BinsMemberComplexityComponent></div>
    }

    get positionInfo(): string {
        if (this.model.dis === "0" || this.model.dis === "A") {
            return Math.ceil(this.numberElNow / this.countElementsOneTime) + 1 +
                " / " + Math.ceil(this.model.items.length / this.countElementsOneTime);
        }
        return Math.ceil((this.numberElNow / this.countElementsOneTime) + 1).toString();
    }
}