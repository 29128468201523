import {CustomComponent} from "../../helpers/CustomComponent";
import {CheckScreen, TypeScreen} from "../../context/TypeScreen";
import {Result} from "./Result";
import React from "react";
import {TrainingMode} from "../../helpers/trainingHelp/TrainingMode";
import {TrainingDis} from "../../helpers/trainingHelp/TrainingDis";
import BallResultStore, {TypeBallsResult, TypeBallsResultTime} from "./store/BallResultStore";
import {observable} from "mobx";
import './resultModel.scss'
import {observer} from "mobx-react";

interface Props{
    store: BallResultStore
}
@observer
export class RecordsboardResultModel extends CustomComponent<Props, { }>{
    @observable
    private store: BallResultStore;

    constructor(props: Readonly<{store: BallResultStore}>) {
        super(props);
        this.store = this.props.store;
        this.activityTimeBallsClass = this.activityTimeBallsClass.bind(this);
        this.changeType = this.changeType.bind(this);
        this.changeTime = this.changeTime.bind(this);
        this.changeDiscipline = this.changeDiscipline.bind(this);
        this.activityTypeDiscipline = this.activityTypeDiscipline.bind(this);
        this.changeDis = this.changeDis.bind(this);
    }

    componentDidMount() {
        this.store.fetchRequest();
    }

    activityTimeBallsClass(type: TypeBallsResultTime) {
        return this.store.typeTime === type ? " activityTimeBalls" : "";
    }

    activityTypeDiscipline(className: string, discipline: TrainingMode){
        if (discipline === this.store.discipline){
            return className + " activityDisciplineResults"
        }
        return className;
    }

    changeType(event: React.MouseEvent<HTMLDivElement>) {
        this.store.changeType(event.currentTarget.id as TypeBallsResult);
    }

    changeTime(event: React.MouseEvent<HTMLDivElement>) {
        this.store.changeTime(event.currentTarget.id as TypeBallsResultTime);
    }

    changeDiscipline(event: React.MouseEvent<HTMLDivElement>) {
        this.store.setDiscipline(event.currentTarget.id as TrainingMode);
    }

    changeDis(event: React.ChangeEvent<HTMLSelectElement>) {
        this.store.setDis(event.target.value as TrainingDis);
    }
    render() {
        return <aside className="recordsboard" id="recordsboard-result">
            <section className="records-tabs-result">
                {/*@*ТАБЫ ДИСЦИПЛИН*@*/}
                <div className="results-table-header" id="results-table-dis">
                    {
                        CheckScreen([TypeScreen.Desktop, TypeScreen.Tablet]) &&
                        <>
                            <div className={this.activityTypeDiscipline("r-tab results-number-label", "number")}
                                // type="number"
                                 onClick={this.changeDiscipline}
                                 id="number"
                                 title="Числа">
                                Числа
                            </div>
                            <div className={this.activityTypeDiscipline("r-tab results-cards-label", "cards")}
                                // type="cards"
                                 onClick={this.changeDiscipline}
                                 id="cards"
                                 title="Карты">
                                Карты
                            </div>
                            <div className={this.activityTypeDiscipline("r-tab results-words-label", "word")}
                                // type="word"
                                 onClick={this.changeDiscipline}
                                 id="word"
                                 title="Слова">
                                Слова
                            </div>
                            <div className={this.activityTypeDiscipline("r-tab results-names-label", "name")}
                                // type="name"
                                 onClick={this.changeDiscipline}
                                 id="name"
                                 title="Имена">
                                Имена
                            </div>
                            <div className={this.activityTypeDiscipline("r-tab results-binary-label", "bins")}
                                // type="bins"
                                 onClick={this.changeDiscipline}
                                 id="bins"
                                 title="Бинарные">
                                Бинарные
                            </div>
                            <div className={this.activityTypeDiscipline("r-tab results-images-label", "image")}
                                // type="image"
                                 onClick={this.changeDiscipline}
                                 id="image"
                                 title="Изображения">
                                Изображения
                            </div>
                        </>
                    }
                    {
                        CheckScreen([TypeScreen.Mobile]) &&
                        <>
                            <div
                                className={this.activityTypeDiscipline("r-tab results-number-label r-tab-sign", "number")}
                                // type="number"
                                onClick={this.changeDiscipline}
                                id="number"
                                title="Числа">
                                Ч
                            </div>
                            <div
                                className={this.activityTypeDiscipline("r-tab results-cards-label r-tab-sign", "cards")}
                                // type="cards"
                                onClick={this.changeDiscipline}
                                id="cards"
                                title="Карты">
                                К
                            </div>
                            <div className={this.activityTypeDiscipline("r-tab results-words-label r-tab-sign", "word")}
                                // type="word"
                                 onClick={this.changeDiscipline}
                                 id="word"
                                 title="Слова">
                                C
                            </div>
                            <div className={this.activityTypeDiscipline("r-tab results-names-label r-tab-sign", "name")}
                                // type="name"
                                 onClick={this.changeDiscipline}
                                 id="name"
                                 title="Имена">
                                И
                            </div>
                            {
                                CheckScreen([TypeScreen.Tablet]) &&
                                <>
                                    <div
                                        className={this.activityTypeDiscipline("r-tab results-binary-label-mob", "bins")}
                                        // type="bins"
                                        onClick={this.changeDiscipline}
                                        id="bins"
                                        title="Бинарные">
                                        Бинар.
                                    </div>
                                    <div
                                        className={this.activityTypeDiscipline("r-tab results-images-label-mob", "image")}
                                        // type="image"
                                        onClick={this.changeDiscipline}
                                        id="image"
                                        title="Изображения">
                                        Изобр.
                                    </div>
                                </>

                            }
                            {
                                CheckScreen([TypeScreen.Mobile]) &&
                                <>
                                    <div
                                        className={this.activityTypeDiscipline("r-tab results-binary-label r-tab-sign", "bins")}
                                        // type="bins"
                                        onClick={this.changeDiscipline}
                                        id="bins"
                                        title="Бинарные">
                                        Б
                                    </div>
                                    <div
                                        className={this.activityTypeDiscipline("r-tab results-images-label r-tab-sign", "image")}
                                        // type="image"
                                        onClick={this.changeDiscipline}
                                        id="image"
                                        title="Изображения">
                                        И
                                    </div>
                                </>
                            }
                        </>
                    }
                </div>
            </section>
            {/*@*ТЕЛО ТАБЛИЦЫ РЕЗУЛЬТАТОВ*@*/}
            <section id="results-table">
                <div className="results-table-header">
                    <div className="td-number">№</div>
                    <div className="td-name">Имя</div>
                    <select className="chosen-select td-index" onChange={this.changeDis}>
                        <option value="0">Индекс (BEST)</option>
                        <option value="A">"A" ≤1м</option>
                        <option value="B">"B" 5м</option>
                        <option value="C">"C" 10м</option>
                        <option value="D">"D" 20м</option>
                    </select>
                    {
                        CheckScreen([TypeScreen.Desktop, TypeScreen.Tablet]) &&
                        <div className="td-allelements td-allelements-comp">Элементов</div>
                    }
                    {
                        CheckScreen([TypeScreen.Mobile]) &&
                        <div className="td-allelements td-allelements-mob">Эл.</div>
                    }
                    <div className="td-error">Ошибки</div>
                    <div className="td-speed">Темп</div>
                    <div className="td-time">Время</div>
                    {
                        CheckScreen([TypeScreen.Desktop, TypeScreen.Tablet]) &&
                        <div className="td-more" id="td-more-desctop">Подробнее</div>
                    }
                    {
                        CheckScreen([TypeScreen.Mobile]) &&
                        <div className="td-more" id="td-more-mob">См.</div>
                    }
                </div>
                <section id="result-lines">
                    {
                        // todo Добавить скелетонс
                        // !this.store.modelResult.IsLoaded &&
                        // <Loading/>
                    }
                    {
                        this.store.modelResult.Model && this.store.modelResult.IsLoaded &&
                        this.store.modelResult.Model!.map((x, u) =>
                            <Result key={x.id} result={x} pos={u}/>
                        )
                    }
                </section>
            </section>
        </aside>;
    }
}