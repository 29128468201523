import React, {Component} from 'react'
import {observer} from "mobx-react";
import './customButtonView.scss';

interface Props {
    onClick: () => void;
    text?: string;
    style?: React.CSSProperties;
    readonly?: boolean
    disabled?: boolean
    children?: React.ReactNode;
    className?: string;
}

@observer
export class CustomButtonView extends Component<Props, {}> {

    constructor(props: Readonly<Props>) {
        super(props);
        this.click = this.click.bind(this);
    }

    click() {
        this.props.onClick();
    }

    get onlyRead(): boolean {
        return this.props.readonly === true;
    }

    get cssProperty(): React.CSSProperties {
        return {
            ...{
                border: this.onlyRead ? "2px solid #afafaf" : "2px solid #000",
                color: this.onlyRead ? "#afafaf" : "#000",
                pointerEvents: this.onlyRead ? "none" : "all",
            }, ...this.props.style
        };
    }

    render() {
        return (
            <div className={'custom-button ' + this.props.className} style={this.cssProperty}
                 onClick={this.click}>
                <div className="span-button">{this.props.text ?? "НАЧАТЬ"}</div>
                {this.props.children}
            </div>
        )
    }
}