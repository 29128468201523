import {CustomComponent} from "../../helpers/CustomComponent";
import React from "react";
import {TopUsersSorting} from "./TopUsersStore";


interface Props {
    title: string;
    text: string;
    className: string;
    onclick: (customAttr: string) => void;
    customAttr: string;
    status: boolean;
}

export class TopUserHead extends CustomComponent<Props, {}> {

    constructor(props: Readonly<Props>) {
        super(props);
        this.click = this.click.bind(this);
    }

    click(){
        this.props.onclick(this.props.customAttr)
    }

    render() {
        return <div className={this.props.className} title={this.props.title}>
            <div className={"sorting " + (this.props.status ? "select-sorting" : "")} onClick={this.click}>{this.props.text}</div>
        </div>;
    }
}