import {TrainingMode} from "../../../helpers/trainingHelp/TrainingMode";
import {makeAutoObservable, observable} from "mobx";
import TrainingChangeBaseModel from "./TrainingChangeBaseModel";
import RouteTrainingStore from "./RouteTrainingStore";
import {PackageTrainingViewModel, TrainingMenuSaveModel, TrainingModelCreateTraining} from "../model/TrainingModel";
import {GetRequest} from "../../../helpers/Request";
import AuthorizationStore from "../../authorization/store/AuthorizationStore";

export default class TrainingBaseStore {
    @observable
    mode: TrainingMode = 'number';
    @observable
    storeRoute: RouteTrainingStore;
    timeoutStartTraining: NodeJS.Timer | undefined = undefined;

    memberRequest: (model: TrainingModelCreateTraining) => void;

    @observable
    public baseStores: Map<TrainingMode, TrainingChangeBaseModel>
        = new Map<TrainingMode, TrainingChangeBaseModel>();

    init() {
        if (!AuthorizationStore.isAuthJwt)
            return;
        GetRequest<PackageTrainingViewModel>("/Training/TrainingMenuSaved")
            .then(x => {
                if (!x.Model)
                    return;
                this.setBaseStore('number', x.Model!.trainings.number);
                this.setBaseStore('word', x.Model!.trainings.word);
                this.setBaseStore('cards', x.Model!.trainings.cards);
                this.setBaseStore('image', x.Model!.trainings.images);
                this.setBaseStore('bins', x.Model!.trainings.bins);
                this.setBaseStore('name', x.Model!.trainings.name);
            })
    }

    setBaseStore(mode: TrainingMode, model: TrainingMenuSaveModel) {
        if (this.baseStores.has(mode)) {
            if (model.dis === 'DIS')
                model.dis = '0';
            this.baseStores.get(mode)!.model = {
                ...this.baseStores.get(mode)!.model,
                ...model
            };
        }
        if (model.isCurrentMode) {
            this.mode = mode;
        }
    }

    initBaseStores() {
        this.baseStores.set('number', new TrainingChangeBaseModel({
            dis: '0',
            numberA: 50,
            number: 40,
            complexity: '2',
            timeOnElement: undefined,
            deferredTime: undefined,
            elementAtGo: 1,
            elementOfTime: undefined
        }, 'number'));
        this.baseStores.set('word', new TrainingChangeBaseModel({
            dis: '0',
            numberA: 30,
            number: 30,
            complexity: '2',
            timeOnElement: undefined,
            deferredTime: undefined,
            elementAtGo: 1,
            elementOfTime: undefined
        }, 'word'));
        this.baseStores.set('cards', new TrainingChangeBaseModel({
            dis: '0',
            numberA: 52,
            number: 52,
            complexity: '2',
            timeOnElement: undefined,
            deferredTime: undefined,
            elementAtGo: 1,
            elementOfTime: undefined
        }, 'cards'));
        this.baseStores.set('name', new TrainingChangeBaseModel({
            dis: '0',
            numberA: 30,
            number: 30,
            complexity: '2',
            timeOnElement: undefined,
            deferredTime: undefined,
            elementAtGo: 1,
            sex: '1',
            elementOfTime: undefined
        }, 'name'));
        this.baseStores.set('bins', new TrainingChangeBaseModel({
            dis: '0',
            numberA: 50,
            number: 30,
            complexity: '2',
            timeOnElement: undefined,
            deferredTime: undefined,
            elementAtGo: 1,
            sex: '1',
            elementOfTime: undefined
        }, 'bins'));
        this.baseStores.set('image', new TrainingChangeBaseModel({
            dis: '0',
            numberA: 30,
            number: 30,
            complexity: '2',
            timeOnElement: undefined,
            deferredTime: undefined,
            elementAtGo: 1,
            sex: '1',
            elementOfTime: undefined
        }, 'image'));
    }


    constructor(memberRequest: (model: TrainingModelCreateTraining) => void) {
        this.memberRequest = memberRequest;
        makeAutoObservable(this, {}, {autoBind: true});
        this.storeRoute = new RouteTrainingStore(this)
        this.initBaseStores();
    }

    changeMode(mode: TrainingMode) {
        this.mode = mode;
    }

    get currentStorage(): TrainingChangeBaseModel {
        return this.baseStores.get(this.mode)!;
    }

    getSaveTraining(): TrainingMenuSaveModel[] {
        let res: TrainingMenuSaveModel[] = []
        let massive = Array.from(this.baseStores, ([name, value]) => ({name, value}));
        for (const item of massive) {
            let mode = item.name === 'image' ? 'images' : item.name;
            res.push({
                isCurrentMode: false,
                ...item.value.model,
                mode: mode
            })
        }

        return res;
    }

    startTraining() {
        if (!AuthorizationStore.checkAuthMenu) {
            AuthorizationStore.openModalAuth();
            return;
        }
        let storage = this.currentStorage;
        if (storage.cantStartTraining())
            return;
        let current = storage.getModelForCreate();
        if (current.deferredTime){
            alert('Отсчет пойдет как только вы подтвердите')
            this.timeoutStartTraining = setTimeout(this.memberRequestStart, parseInt(current.deferredTime) * 1000)
            return;
        }
        this.memberRequestStart();
    }

    private memberRequestStart() {
        let storage = this.currentStorage;
        if (storage.cantStartTraining())
            return;
        let current = storage.getModelForCreate();
        let countLocation = this.storeRoute.getCountPoints === ""
            ? undefined
            : parseInt(this.storeRoute.getCountPoints);
        if (this.timeoutStartTraining){
            clearInterval(this.timeoutStartTraining);
        }
        this.memberRequest({
            complexity: current.complexity,
            dis: current.dis,
            elementAtGo: current.elementAtGo,
            mode: this.mode,
            number: current.number,
            format: current.format,
            language: current.language,
            sex: current.sex,
            rangeFrom: current.rangeFrom,
            rangeTo: current.rangeTo,
            elementOfTime: current.timeOnElement,
            countLocation: countLocation,
            routeIds: this.storeRoute.routeIds(),
            allTrainings: this.getSaveTraining()
        })
    }
}
