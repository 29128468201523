import {CustomComponent} from "../../../helpers/CustomComponent";
import React from "react";
import TrainingSportBaseStore from "../store/TrainingSportBaseStore";
import {observer} from "mobx-react";
import {TypeTrainingCodingComponent} from "./TypeTrainingCodingComponent";

interface Props {
    store: TrainingSportBaseStore
}

@observer
export class MainCodingComponent extends CustomComponent<Props, {}> {

    constructor(props: Readonly<Props>) {
        super(props);
        this.getCodingClassName = this.getCodingClassName.bind(this);
        this.getCodingTextClassName = this.getCodingTextClassName.bind(this);
        this.clickCodingModel = this.clickCodingModel.bind(this);
        this.onChangeCount = this.onChangeCount.bind(this);
        this.clickCountPlus10 = this.clickCountPlus10.bind(this);
        this.clickCountMinus10 = this.clickCountMinus10.bind(this);
        this.clickCodingModel2 = this.clickCodingModel2.bind(this);
    }

    getCodingClassName(classname: string, coding: string): string {
        if (this.props.store.requestModel.coding === coding)
            return classname + " activityCoding";
        return classname;
    }

    getCodingTextClassName(classname: string, coding: string): string {
        if (this.props.store.requestModel.coding === coding)
            return classname + " activityCodingText";
        return classname;
    }

    clickCodingModel(ev: React.MouseEvent<HTMLDivElement>) {
        this.props.store.onChangeCoding(ev.currentTarget.id);
    }

    clickCodingModel2(ev: string) {
        this.props.store.onChangeCoding(ev);
    }

    onChangeCount(ev: React.ChangeEvent<HTMLInputElement>) {
        if (ev.currentTarget.value !== '') {
            this.props.store.onChangeCount(parseInt(ev.currentTarget.value))
            return
        }
        this.props.store.onChangeCount(10)
    }

    clickCountPlus10() {
        this.props.store.onChangeCount(this.props.store.requestModel.count + 10)
    }

    clickCountMinus10() {
        if (this.props.store.requestModel.count <= 10)
            return;
        this.props.store.onChangeCount(this.props.store.requestModel.count - 10)
    }

    render() {
        return <>
            <div id="t-coding" className="sim2">
                <div id="handler-coding">
                    <div id="handler-c">
                        <span className="handler-name">Кодирование</span>

                        <TypeTrainingCodingComponent type="X2" text="Выбор из двух"
                                                     click={this.clickCodingModel2}
                                                     left={{image: 'https://powermemory.io/images/general/ex1.png'}}
                                                     center={{text: 'X'}}
                                                     right={{image: 'https://powermemory.io/images/general/ex2.png'}}
                                                     currentSelect={this.props.store.requestModel.coding}/>

                        <TypeTrainingCodingComponent type="XClean" text="Чистое"
                                                     click={this.clickCodingModel2}
                                                     center={{text: 'X'}}
                                                     currentSelect={this.props.store.requestModel.coding}/>
                    </div>

                    <div id="handler-d">
                        <span className="handler-name">Декодирование</span>

                        <TypeTrainingCodingComponent type="image2" text="Выбор из двух"
                                                     click={this.clickCodingModel2}
                                                     left={{text: 'X'}}
                                                     center={{image: 'https://powermemory.io/images/general/ex1.png'}}
                                                     right={{text: 'Y'}}
                                                     currentSelect={this.props.store.requestModel.coding}/>

                        <TypeTrainingCodingComponent type="imageClean" text="Чистое"
                                                     click={this.clickCodingModel2}
                                                     center={{image: 'https://powermemory.io/images/general/ex1.png'}}
                                                     currentSelect={this.props.store.requestModel.coding}/>
                    </div>
                </div>

            </div>
            <div className="main-coding">
                <div className="container-count-elements">
                    <div className="amount-elements-number-training">
                        <label id="amount-coding-label-minus" htmlFor="amount-coding"
                               onClick={this.clickCountMinus10}></label>
                        <label id="amount-coding-text" htmlFor="amount-coding"
                               onClick={this.clickCountPlus10}>Количество
                            элементов</label>
                        <label id="amount-coding-label-plus" htmlFor="amount-coding"
                               onClick={this.clickCountPlus10}></label>
                    </div>
                    <input id="amount-coding" type="text" onChange={this.onChangeCount}
                           name="amount-coding" autoComplete="off" value={this.props.store.requestModel.count}/>
                </div>
            </div>
        </>;
    }
}