import {TrainingMemberModel} from "../model/TrainingMemberModel";
import {makeAutoObservable} from "mobx";
import {IMemberTrainingBaseService} from "../service/IMemberTrainingBaseService";
import {TrainingMemberRequestItemModel} from "../model/TrainingMemberRequestModel";
import {MemberMode} from "../../../helpers/trainingHelp/TrainingMode";
import AuthorizationStore from "../../authorization/store/AuthorizationStore";
import StopwatchService from "../../../helpers/StopwatchService";


export class MemberTrainingBaseStore {
    model: TrainingMemberModel;
    service: IMemberTrainingBaseService | undefined;
    answerRequest: (items: TrainingMemberRequestItemModel[]) => void;

    timer: NodeJS.Timer | undefined = undefined;
    timerDIS: NodeJS.Timer | undefined = undefined;

    constructor(model: TrainingMemberModel, answerRequest: (items: TrainingMemberRequestItemModel[], ) => void) {
        makeAutoObservable(this, {}, {autoBind: true})
        this.model = model;
        this.answerRequest = answerRequest;
        this.service = MemberMode(this.model.mode, this.model, this.answerRequest);
    }

    get memberModel(): TrainingMemberModel | null {
        return this.model;
    }

    next() {
        if (this.service)
            this.service.next()
    }

    finish() {
        if (this.service)
            this.service.finish()
    }

    back() {
        if (this.service)
            this.service.back()
    }

    repeat() {
        if (this.service)
            this.service.repeat()
    }

    get positionInfo(): string {
        if (!this.service)
            return "";
        return this.service!.positionInfo;
    }

    get view(): JSX.Element {
        if (!this.service)
            return <></>;
        return this.service!.view;
    }

    init() {
        AuthorizationStore.setShowLayoutHead(false);
        StopwatchService.start();
        if (this.model.timeElements)
            this.timer = setInterval(this.updateNextStep, this.model.timeElements * 1000);
        if (this.model.dis !== '0' && this.model.dis !== 'DIS'){
            if (this.model.dis === 'A'){
                this.timerDIS = setInterval(this.service!.finish, 60 * 1000);
            }
            if (this.model.dis === 'B'){
                this.timerDIS = setInterval(this.service!.finish, 5 * 60 * 1000);
            }
            if (this.model.dis === 'C'){
                this.timerDIS = setInterval(this.service!.finish, 10 * 60 * 1000);
            }
            if (this.model.dis === 'D'){
                this.timerDIS = setInterval(this.service!.finish, 20 * 60 * 1000);
            }
        }
    }

    updateNextStep(){
        this.next();
    }


    // закрыть
    unmount(){
        if (this.model.timeElements)
            clearInterval(this.timer);
        if (this.model.dis)
            clearInterval(this.timerDIS);
        StopwatchService.clearTimer()
    }
}