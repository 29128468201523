import {SubscriptionView} from "../model/ProductView";
import {LoadingModel} from "../../shared/LoadingModel";
import {IAdministrationStore} from "./IAdministrationStore";
import {AdministrationRepository} from "../repository/AdministrationRepository";
import {BaseAdministrationStore} from "./BaseAdministrationStore";

export class SubscriptionStore implements IAdministrationStore<SubscriptionView> {
    private repository = new AdministrationRepository();
    baseStore = new BaseAdministrationStore<SubscriptionView>(this);

    constructor() {
    }

    requestLocal(): Promise<LoadingModel<SubscriptionView[]>> {
        return this.repository.subscriptions();
    }
}