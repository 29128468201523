import {makeAutoObservable, observable} from "mobx";
import {SelectorPros} from "../../../shared/selector/CustomSelector";
import LoadingStore from "../../../../helpers/LoadingStore";
import {VolumeEnduranceModel} from "./VolumeEnduranceModel";
import Highcharts from "highcharts";


export class VolumeEnduranceStore {

    @observable
    public selector: SelectorPros;

    @observable
    public loadingStore: LoadingStore<VolumeEnduranceModel>;

    public selectors: SelectorPros[] = [
        {value: "20", title: "Объём 20"},
        {value: "30", title: "Объём 30"},
        {value: "40", title: "Объём 40"},
        {value: "50", title: "Объём 50"},
        {value: "60", title: "Объём 60"},
        {value: "70", title: "Объём 70"},
        {value: "80", title: "Объём 80"},
        {value: "90", title: "Объём 90"},
        {value: "100", title: "Объём 100"},
        {value: "200", title: "Объём 200"},
        {value: "300", title: "Объём 300"},
        {value: "400", title: "Объём 400"},
        {value: "500", title: "Объём 500"},
        {value: "600", title: "Объём 600"},
        {value: "700", title: "Объём 700"},
        {value: "800", title: "Объём 800"},
        {value: "900", title: "Объём 900"},
        {value: "1000", title: "Объём 1000"},
    ];

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true});
        this.selector = this.selectors.filter(x => x.value === '20')[0];
        this.loadingStore = new LoadingStore<VolumeEnduranceModel>(this.getUrl)
    }


    getUrl(): string {
        return '/api/Statistic/VolumeEndurance?discipline=' + this.selector.value;
    }


    get options(): Highcharts.Options {
        return {
            chart: {
                // width: 900,
                style: {
                    'float': 'right'
                }
            },
            rangeSelector: {
                selected: 0
            },
            xAxis: {
                type: 'datetime',
                title: {
                    text: ''
                },
                zoomEnabled: true
            },
            yAxis: {
                type: 'linear',
                title: {
                    text: ''
                },
            },
            title: {
                text: ''
            },
            series: [{
                name: 'Текущий темп',
                data: this.loadingStore?.model.Model?.list
                    .map((x) => [new Date(x.date).getTime(), x.speed]) ?? [],
                type: 'area',
                threshold: null,
                tooltip: {
                    valueDecimals: 0
                },
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, '#4572A7'],
                        [1, 'white']
                    ]
                }
            },
            {
                name: 'Лучший темп на объеме 20',
                data: this.loadingStore?.model.Model?.list
                    .map((x) => [new Date(x.date).getTime(), this.loadingStore?.model.Model!.minValue]) ?? [],
                type: 'area',
                threshold: null,
                tooltip: {
                    valueDecimals: 0
                },
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, '#4572A7'],
                        [1, 'white']
                    ]
                }
            }]
        };
    }

    setSelector(sel: SelectorPros) {
        this.selector = sel;
    }
}