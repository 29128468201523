import {TrainingMode} from "../../../helpers/trainingHelp/TrainingMode";

export interface TrainingResultModel {
    id: string;
    username: string;
    imgSrc: string;
    rang: string;
    link: string;
    search: boolean;
    isPremium: boolean;
    bestTraining: BestTrainingType;
    dIS: string;
    mode: TrainingMode;
    complexity: string;
    elementAtGo: string;
    timeElements: number;
    number: number;
    format: string;
    sex: string;
    language: string;
    styleColor: string;
    groupCard1: string;
    groupCard2: string;
    countLocation: number;
    counting: number;
    index: number;
    date: string;
    error: number;
    speed: number;
    timeAll: number;
    countRightImages: number;
    items: TrainingResultItemModel[];
}

export enum BestTrainingType {
    Fine = 1,
    BestUser = 2,
    BestIsTheBest = 3
}


export interface TrainingResultItemModel {
    date: string;
    dateTime: Date;
    dateLong?: BigInteger;
    answer: string;
    dictionary: string;
    answerImage: string;
    dictionaryImage: string;
    evenly: boolean;
    timeOnElement: number;
    numberPosition?: number;
    pointName?: string;
}