import {makeAutoObservable} from "mobx";
import {CodingViewModel} from "../model/PackageTrainingCodingViewModel";
import {PostRequest} from "../../../helpers/Request";
import {CodingMemberModel, CodingRequestMemberModel} from "../model/CodingMemberModel";
import {LoadingModel} from "../../shared/LoadingModel";
import {CodingResultResponseViewModel} from "../model/CodingResultMember";


export enum StateTrainingTrainSport {
    'base' = 0,
    'loadingImage' = 1,
    'write' = 2,
    'result' = 3,
}

export default class TrainingSportStore {
    state: StateTrainingTrainSport;
    modelWrite: LoadingModel<CodingMemberModel> = LoadingModel.createNotLoaded<CodingMemberModel>();
    modelResult: LoadingModel<CodingResultResponseViewModel> = LoadingModel.createNotLoaded<CodingResultResponseViewModel>();

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true});
        this.state = StateTrainingTrainSport.base;
    }

    startTrainingRequest(model: CodingViewModel): void {
        PostRequest<CodingMemberModel, CodingViewModel>("/TrainingsCoding/CodingTraining", model)
            .then(x => this.setModelMember(x));
    }

    setModelMember(model: LoadingModel<CodingMemberModel>) {
        this.modelWrite = model;
        this.state = StateTrainingTrainSport.write;
    }

    finishTrainingRequest(model: CodingRequestMemberModel): void {
        PostRequest<CodingResultResponseViewModel, CodingRequestMemberModel>("/TrainingsCoding/CodingResult", model)
            .then(x => this.setModelFinish(x));
    }

    setModelFinish(model: LoadingModel<CodingResultResponseViewModel>) {
        this.modelResult = model;
        this.state = StateTrainingTrainSport.result;
    }
}