import {CustomComponent} from "../../../../helpers/CustomComponent";
import {PropsTrainingType} from "./PropsTrainingType";
import {TitleComponent, TypePositionText, TypeTitle} from "../../../../helpers/title/TitleComponent";
import {ImageComponent} from "../../../../helpers/img/ImageComponent";
import React from "react";
import {observer} from "mobx-react";
import {makeAutoObservable} from "mobx";
import HeaderTrainingLearnLanguageStore from "../HeaderTrainingLearnLanguageStore";
import {AudioComponent} from "../../../../helpers/audio/AudioComponent";
import {NextAndPrevAdjectiveComponent} from "../../../../helpers/nextComponent/NextAndPrevAdjectiveComponent";


@observer
export class MemberEnglishAdjectiveComponent extends CustomComponent<PropsTrainingType, any> {
    private store: Store;

    constructor(props: Readonly<PropsTrainingType>) {
        super(props);
        this.store = new Store(this.props);
    }

    render() {
        return <>
            <TitleComponent key={this.props.currentItem.nameRus}
                            text={this.props.currentItem.nameRus}
                            typePosition={TypePositionText.center}
                            typeTitle={TypeTitle.bigTitle}/>
            {
                this.props.currentItem.nuance &&
                <TitleComponent text={this.props.currentItem.nuance} typePosition={TypePositionText.center}
                                typeTitle={TypeTitle.bigTitle}/>
            }

            <ImageComponent key={this.props.currentItem.image}
                            src={this.props.currentItem.image}
                            alt={this.props.currentItem.nameRus}/>

            <AudioComponent key={"audio_" + this.props.currentItem.audio} src={this.props.currentItem.audio}/>

            <TitleComponent key={this.props.currentItem.nameEng}
                            text={this.props.currentItem.nameEng}
                            typePosition={TypePositionText.center}
                            typeTitle={TypeTitle.description}/>

            <TitleComponent key={this.props.currentItem.transcriptionAmerica}
                            text={this.props.currentItem.transcriptionAmerica}
                            typePosition={TypePositionText.center}
                            typeTitle={TypeTitle.description}/>

            <NextAndPrevAdjectiveComponent next={this.store.next} back={this.store.back}
                                           positionInfo={this.props.positionInfo} />
        </>
    }
}

class Store {
    props: PropsTrainingType;
    constructor(props: PropsTrainingType) {
        this.props = props;
        makeAutoObservable(this, {}, {autoBind: true})
        HeaderTrainingLearnLanguageStore.setHintTraining(undefined);
    }

    next() {
        this.props.next(this.props.item)
    }

    back() {
        this.props.back(this.props.item)
    }
}