import {ProductStore} from "./ProductStore";
import {SubscriptionStore} from "./SubscriptionStore";
import {UserSubscriptionStore} from "./UserSubscriptionStore";
import {UserAdminStore} from "./UserAdminStore";
import {WordsStore} from "./WordsStore";
import {ImagesStore} from "./ImagesStore";


export class AdministrationStore {
    subsStore: SubscriptionStore = new SubscriptionStore();
    userSubsStore: UserSubscriptionStore = new UserSubscriptionStore();
    productsStore: ProductStore = new ProductStore();
    usersStore: UserAdminStore = new UserAdminStore();
    wordsStore: WordsStore = new WordsStore();
    imagesStore: ImagesStore = new ImagesStore();


    unInit() {
        this.subsStore.baseStore.unInit();
        this.productsStore.baseStore.unInit();
        this.userSubsStore.baseStore.unInit();
        this.usersStore.baseStore.unInit();
    }
}