import {CustomComponent} from "../../../helpers/CustomComponent";
import {TitleComponent, TypePositionText, TypeTitle} from "../../../helpers/title/TitleComponent";
import {CheckAdjectiveComponent} from "../../../helpers/nextComponent/CheckAdjectiveComponent";
import React from "react";
import {BlockTextComponent} from "../../../helpers/blockText/BlockTextComponent";
import {observer} from "mobx-react";
import {UniversityRecommendationsModel} from "../models/UniversityTrainingModel";
import {makeAutoObservable} from "mobx";


interface Props {
    models: UniversityRecommendationsModel[];
    finish: () => void;
}

@observer
export class RecommendationComponent extends CustomComponent<Props, any> {
    store: RecommendationStore;

    constructor(props: Readonly<Props>) {
        super(props);
        this.store = new RecommendationStore(this.props.models, this.props.finish);
    }

    render() {
        return <>
            <BlockTextComponent hasBackground={true} className={"block-main-component"}>
                <TitleComponent typeTitle={TypeTitle.bigTitle}
                                typePosition={TypePositionText.center}
                                text={this.store.itemRecommendations().title}/>
                <TitleComponent typeTitle={TypeTitle.homeTaskDescription}
                                text={this.store.itemRecommendations().description}/>
            </BlockTextComponent>

            <CheckAdjectiveComponent onClick={this.store.next}
                                     positionInfo={this.store.positionInfo}
                                     text={"Далее"}/>
        </>;
    }
}

export class RecommendationStore {
    currentPosition: number = 0;
    models: UniversityRecommendationsModel[];
    finish: () => void

    constructor(models: UniversityRecommendationsModel[], finish: () => void) {
        makeAutoObservable(this, {}, {autoBind: true})
        this.models = models;
        this.finish = finish;
    }


    get positionInfo() {
        return (this.currentPosition + 1) + ' / ' + this.models.length;
    }

    next() {
        if (this.currentPosition < 0) return;
        this.currentPosition++;
        if (this.currentPosition >= this.models.length) {
            this.finish();
        }
    }

    itemRecommendations(): UniversityRecommendationsModel {
        return this.models[this.currentPosition];
    }
}