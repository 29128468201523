import IResultService from "./IResultService";
import {TrainingResultItemModel, TrainingResultModel} from "../model/TrainingResultModel";
import {route} from "../../../helpers/trainingHelp/TrainingRoute";


export default class WordResultService implements IResultService{
    constructor() {
        this.getItem = this.getItem.bind(this);
        this.GetItems = this.GetItems.bind(this);
    }

    GetItems(training: TrainingResultModel): JSX.Element {
        return <>
            {training.items.map(this.getItem)}
        </>;
    }

    getItem(item: TrainingResultItemModel, position: number): JSX.Element{
        if (item.evenly){
            return <div className="ans-cell-word" key={position+"_"+item.dictionary}>
                <span className="num-cell" data-title={route(item)}>{position + 1}</span>
                <span className="ans-text">{item.dictionary}</span>
            </div>
        }
        return <div className="ans-cell-word err" key={position+"_"+item.dictionary}>
            <span className="num-cell num-err tooltip" data-title={item.pointName}>{position+1}</span>
            <span className="ans-text err" title="Слабая связь">{item.dictionary}</span>
            <span className="ans-text-err">{item.answer}</span>
            </div>
    }

}