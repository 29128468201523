export function getIndexResult(index: number) {
    return index.toFixed(2);
}

export function appendLeadingZeroes2(n: number): string {
    if (n <= 9) {
        return "0" + n;
    }
    return n.toString();
}

export function getTime(time: string) {
    let local = new Date(time);
    if (local.getUTCHours() !== 0) {
        return appendLeadingZeroes2(local.getUTCHours()) + ":" + appendLeadingZeroes2(local.getUTCMinutes()) + ":" + appendLeadingZeroes2(local.getUTCSeconds());
    } else {
        return appendLeadingZeroes2(local.getUTCMinutes()) + ":" + appendLeadingZeroes2(local.getUTCSeconds());
    }
}

export function getTimeRes(time: string) {
    let date = new Date(time);
    // date.setHours(date.getHours()); // + 3 - 12
    return appendLeadingZeroes2((date.getHours())) + ':' + appendLeadingZeroes2(date.getMinutes()) + ':' + appendLeadingZeroes2(date.getSeconds());
}

export function getDateRes(time: string) {
    let date = new Date(time);
    // date.setHours(date.getHours() + 3 - 12);
    return appendLeadingZeroes2(date.getDate()) + '.' + appendLeadingZeroes2(date.getMonth() + 1) + '.' + appendLeadingZeroes2(date.getFullYear());
}

export function getDate(dateStr: string) {
    //2020-09-28T05:00:15
    let date = new Date(dateStr);
    // date.setHours(date.getHours() + 3 - 12);
    //date.setMilliseconds(3 * 60 * 60 * 1000);
    return appendLeadingZeroes2(date.getFullYear()) + '.' + appendLeadingZeroes2(date.getMonth() + 1) + '.' + appendLeadingZeroes2(date.getDate()) + ' | ' +
        appendLeadingZeroes2((date.getHours())) + ':' + appendLeadingZeroes2(date.getMinutes()) + ':' + appendLeadingZeroes2(date.getSeconds());
}


export function msToTime(duration: number) {
    let milliseconds: number | string = Math.floor((duration % 1000)),
        seconds: number | string = Math.floor((duration / 1000) % 60)
        , minutes: number | string = Math.floor((duration / (1000 * 60)));

    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;
    milliseconds = (milliseconds < 10) ? "00" + milliseconds : ((milliseconds < 100) ? "0" + milliseconds : milliseconds);

    return minutes + ":" + seconds + ":" + milliseconds;
}

export function msToTimeSecond(duration: number) {
    let milliseconds: number | string = Math.floor((duration % 1000)),
        seconds: number | string = Math.floor((duration / 1000));

    if (milliseconds < 10) {
        milliseconds = (milliseconds < 10) ? "00" + milliseconds : milliseconds;
    } else if (milliseconds < 100) {
        milliseconds = (milliseconds < 100) ? "0" + milliseconds : milliseconds;
    }

    if (seconds < 10) {
        seconds = (seconds < 10) ? "0" + seconds : seconds;
    }

    return seconds + ":" + milliseconds;
}

export function getPercentError(countNumber: number, error: number): string {
    if (error === 0)
        return "0.00"
    return (error * 100 / countNumber).toFixed(2)
}

export function getPercentErrorFixed(countNumber: number, error: number, fractionDigits: number): string {
    if (error === 0)
        return error.toFixed(fractionDigits)
    return (error * 100 / countNumber).toFixed(fractionDigits)
}

export function getTimeResult(time: number): string {
    let minutes = Math.floor(time / 60000);
    let seconds = Math.floor((time - minutes * 60000) / 1000);

    return ((minutes < 10) ? "0" + minutes : minutes) + ":" + ((seconds < 10) ? "0" + seconds : seconds);
}

export function getSpeed(speed: number): string {
    return (speed < 10 ? "0" : "") + speed.toFixed(3).replace(",", ":")
}


export function chunk<T>(collection: T[], size: number): T[][] {
    let result = [];

    // add each chunk to the result
    for (let x = 0; x < Math.ceil(collection.length / size); x++) {
        let start = x * size;
        let end = start + size;

        result.push(collection.slice(start, end));
    }

    return result;
}

export function chunkSize<T>(collection: T[], size: number): T[][] {
    let result = [];

    // add each chunk to the result
    for (let x = 0; x < collection.length / size; x++) {
        let localCollection = [];
        for (let y = 0; y < size; y++) {
            localCollection.push(collection[y * size + x])
        }

        result.push(localCollection);
    }

    return result;
}

export function isNullOrEmpty(p: string | undefined): boolean {
    if (!p) return true;
    return p === '';

}

export function isNotNullOrEmpty(p: string | undefined): boolean {
    return !isNullOrEmpty(p);
}