import React from 'react';
import {observer} from "mobx-react";
import {CustomComponent} from "../../helpers/CustomComponent"
import TitleStore from "../../components/authorization/store/TitleStore";
import {PurchasesStore} from "../../components/purchases/PurchasesStore";
import './purchasesPage.scss'


@observer
export class PurchasesPage extends CustomComponent <{}, {}> {
    private store = new PurchasesStore();

    constructor(props: Readonly<{}>) {
        super(props);
    }

    componentDidMount() {
        TitleStore.setTitle("Платная подписки и покупки");
        // this.store.fetchRequest();
        this.store.init()
    }

    componentWillUnmount() {
        this.store.unInit()
    }

    render() {
        return <div id="contener-2" className="purchases">
            <div className="setup-block">
                <div className="setup-label">Подписка</div>
                <div className="setup-label">Покупки</div>
            </div>
            <section className="table-subs">
                <div className="results-table-header-subs">
                    <div className="td-number">
                        №
                    </div>
                    <div className="td_name-sub">
                        Вид подписки
                    </div>
                    <div className="td_status">
                        Статус
                    </div>
                    <div className="td_activated">
                        Дата активации
                    </div>
                    <div className="td_deactivated">
                        Действительна до
                    </div>
                    <div className="td_amount">
                        Стоимость
                    </div>
                </div>

                <div className="result-lines">

                    {/*@foreach (var sub in subs)*/}
                    {/*{*/}
                    {/*    <div className="subinfo-table @(sub.EndDate <= DateTime.Now ? "sub-off" : "")">*/}
                    {/*        <div className="results-table-number td-number">@number</div>*/}

                    {/*        <div className="td_name-sub">*/}
                    {/*            <span className="premium-info">@sub.SubscriptionObject.Name</span>*/}
                    {/*        </div>*/}

                    {/*        <div className="td_status">*/}
                    {/*            <input className="vip-on" id="bansub" type="checkbox" checked="">*/}
                    {/*                <label for="bansub">@(sub.Status == 1 ? "В проверке" : (sub.Status == 2 ? "Отключена" : "Активна"))</label>*/}
                    {/*                <div className="sb-comm">Блокировка</div>*/}
                    {/*                <input id="sb-tday1-1" type="radio" name="ban2-1">*/}
                    {/*                    <label className="sb-day" for="sb-tday1-1">1</label>*/}
                    {/*                    <input id="sb-tday7-1" type="radio" name="ban2-1">*/}
                    {/*                        <label className="sb-day" for="sb-tday7-1">7</label>*/}
                    {/*                        <input id="sb-tday30-1" type="radio" name="ban2-1">*/}
                    {/*                            <label className="sb-day" for="sb-tday30-1">30</label>*/}
                    {/*                            <input id="sb-tdayal-1" type="radio" name="ban2-1">*/}
                    {/*                                <label className="sb-day" for="sb-tdayal-1">Навсегда</label>*/}
                    {/*                                <input id="sb-tdaycl-1" type="radio" name="ban2-1" checked="">*/}
                    {/*                                    <label className="sb-day" for="sb-tdaycl-1">Нет</label>*/}
                    {/*                                    <label className="sb-daybk" for="bansub">Назад</label>*/}
                    {/*        </div>*/}

                    {/*        <div className="td_activated">*/}
                    {/*            <span className="time-reg">@sub.Date.ToString("dd.MM.yy | HH:mm")</span>*/}
                    {/*        </div>*/}

                    {/*        <div className="td_deactivated">*/}
                    {/*            <span className="time-reg">@sub.EndDate.ToString("dd.MM.yy | HH:mm")</span>*/}
                    {/*        </div>*/}

                    {/*        <div className="td_amount">*/}
                    {/*            <span>@sub.Cost</span>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    number++;*/}
                    {/*}*/}
                </div>
            </section>

            <section className="table-buy">
                <div className="results-table-header-buy">
                    <div className="td-number">
                        №
                    </div>
                    <div className="td_name_buy">
                        Название
                    </div>
                    <div className="td_date_buy">
                        Дата покупки
                    </div>
                    <div className="td_amount_buy">
                        Стоимость
                    </div>
                </div>

                {/*    <div className="result-lines">*/}
                {/*        <div className="subinfo-table">*/}
                {/*            <div className="td-number">*/}
                {/*                2*/}
                {/*            </div>*/}
                {/*            <div className="td_name_buy">*/}
                {/*                -*/}
                {/*            </div>*/}
                {/*            <div className="td_date_buy">*/}
                {/*                -*/}
                {/*            </div>*/}
                {/*            <div className="td_amount_buy">*/}
                {/*                -*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="subinfo-table">*/}
                {/*            <div className="td-number">*/}
                {/*                1*/}
                {/*            </div>*/}
                {/*            <div className="td_name_buy">*/}
                {/*                Полный курс мнемотехники (ГП)*/}
                {/*            </div>*/}
                {/*            <div className="td_date_buy">*/}
                {/*                -*/}
                {/*            </div>*/}
                {/*            <div className="td_amount_buy">*/}
                {/*                -*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}

            </section>

        </div>
    }
}