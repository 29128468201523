export interface MatryoshkaModel {
    items: MatryoshkaItemModel[];
    countRetries: number;
    id: bigint;
}

export interface MatryoshkaItemModel {
    id: bigint;
    word: string;
    hjighlight?: boolean;
    answerUser?: string;
    isHint?: boolean;
    isCorrect?: boolean;
}


export enum MatryoshkaState {
    remember = 0,
    writeAnswer = 1,
    checkAnswer = 2,
    result = 2,
}