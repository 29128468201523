import './accordion.scss'
import {AccordionStore} from "./AccordionStore";
import {CustomComponent} from "../CustomComponent";
import {observer} from "mobx-react";


interface Props {
    title?: string
    children?: React.ReactNode
}
@observer
export class Accordion extends CustomComponent<Props, {}> {
    store = new AccordionStore();

    render() {
        return <div className="accordion">
            <div className="accordion_title" onClick={this.store.clickShow}>{this.props.title}</div>
            {
                this.store.show &&
                <div className="accordion_content">
                    {this.props.children}
                </div>
            }
        </div>
    }
}