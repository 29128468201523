import {CustomComponent} from "../../../helpers/CustomComponent";
import {observer} from "mobx-react";
import React from "react";
import {UserAdminStore} from "../store/UserAdminStore";
import {CustomButton} from "../../../helpers/CustomButton";
import './usersAdminComponent.scss'
import {downloadFile} from "helpers/Request";
import {MantineReactCustomTableFunction} from "../../../helpers/table/MantineReactCustomTableFunction";

interface Props {
    store: UserAdminStore;
}

@observer
export class UsersAdminComponent extends CustomComponent<Props, {}> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.clickHead = this.clickHead.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
    }

    componentDidMount() {
        this.props.store.baseStore.init();
    }

    clickHead(customAttr: string) {

    }

    downloadFile() {
        downloadFile('/api/AdministrationsUser/GetEmailUsersCsv')
            .then(x => {
                if (x.ok) {
                    x.blob().then(blob => {
                        const url = window.URL.createObjectURL(new Blob([blob], {type: 'data:text/csv;'}));
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = 'user_emails.csv';
                        link.style.position = 'absolute';
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    });
                }
            })
            .catch(() => {
                alert('запрос в обработке. Подождите');
            });
    }

    render() {
        return <div className="users_admin_component">
            <div className={"wrapper_downloads"}>
                <CustomButton text={"Скачать email файлы"} onclick={this.downloadFile} className={"button download"}/>
            </div>
            <MantineReactCustomTableFunction url={"/api/AdministrationsUser/tableUsers"}
                                             type={"UserAdminViewModel"} />
        </div>;
    }
}