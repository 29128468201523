import {makeAutoObservable} from "mobx";
import {CheckScreen, TypeScreen} from "../../context/TypeScreen";

export type StatisticsType ='Activity' |'TrainingTime' |'VolumeRemembered' |'ArchiveResults' |'GetNewRank';


export default class StatisticsStore{
    private showBurger: boolean = false;

    set setBurger(burger: boolean){
        this.showBurger = burger;
    }

    get burger(){
        return CheckScreen([TypeScreen.Mobile]) && this.showBurger;
    }

    get isMobile(){
        return CheckScreen([TypeScreen.Mobile]);
    }


    constructor() {
        makeAutoObservable(this, {}, {autoBind: true})
    }
}