import React from 'react'
import TrainingChangeBaseModel from "../store/TrainingChangeBaseModel";
import {observer} from "mobx-react";
import CustomSelector, {SelectorPros} from "../../shared/selector/CustomSelector";
import {CountElementsView} from "./CountElementsView";
import {DisHelperView} from "./DisHelperView";
import {CustomComponent} from "../../../helpers/CustomComponent";

export interface Props {
    changeModel: TrainingChangeBaseModel;
}

@observer
export class WordView extends CustomComponent<Props, {}> {
    private selectGroups: SelectorPros[] = [
        {value: "4", title: "Всё столбцом"},
        {value: "5", title: "Всё текстом"},
    ]

    constructor(props: Readonly<Props>) {
        super(props);
        this.setComplexity = this.setComplexity.bind(this);
        this.setElementAtGo = this.setElementAtGo.bind(this);
        this.changeSelector = this.changeSelector.bind(this);
        this.changeGroupAndElements = this.changeGroupAndElements.bind(this);
    }

    setComplexity(e: React.MouseEvent<HTMLDivElement>) {
        this.props.changeModel.changeComplexity(e.currentTarget.getAttribute("custom") ?? "2");
    }

    setElementAtGo(e: React.MouseEvent<HTMLDivElement>) {
        this.props.changeModel.changeElementAtGo(e.currentTarget.getAttribute("custom") ?? "2");
    }

    changeGroupAndElements() {
        if (this.props.changeModel.hasGroupsElementsStart()) {
            this.props.changeModel.changeElementAtGo("4");
        } else {
            this.props.changeModel.changeElementAtGo("2");
        }
    }

    changeSelector(sel: SelectorPros | undefined) {
        this.props.changeModel.changeElementAtGo(sel?.value ?? "2");
    }

    getComplexityClassName(complexity: string): string {
        if (this.props.changeModel.model.complexity === complexity) {
            return "elements element1 point complexityModeActivity"
        }
        return "elements element1 point"
    }

    getElementAtGoClassName(elementOfTime: number): string {
        if (this.props.changeModel.model.elementAtGo === elementOfTime) {
            return "block-number point complexityModeActivity"
        }
        return "block-number point"
    }

    render() {
        return (
            <div className="number-training-default">
                <div className="complexity-training-default">
                    <div custom="1"
                         className={this.getComplexityClassName("1")}
                         onClick={this.setComplexity}>
                        <span>ПРЕДМЕТНЫЕ</span>
                    </div>
                    <div custom="2"
                         className={this.getComplexityClassName("2")}
                         onClick={this.setComplexity}>
                        <span>СПОРТ</span>
                    </div>
                    <div custom="3"
                         className={this.getComplexityClassName("3")}
                         onClick={this.setComplexity}>
                        <span>СЛОЖНЫЕ</span>
                    </div>
                </div>

                <div id="number-view">
                    <input id="atonce" type="radio" name="view-elements" value="2"/>
                    <div className="change-group-number" onClick={this.changeGroupAndElements}>
                        {
                            this.props.changeModel.hasGroupsElementsStart() &&
                            <label id="atonce-label" className="point">Показ
                                элементов за раз</label>
                        }
                        {
                            !this.props.changeModel.hasGroupsElementsStart() &&
                            <label id="grouping-number-label" className="point">Группировка
                                слов</label>
                        }
                        <label id="view-1-label"></label>
                    </div>
                    {
                        this.props.changeModel.hasGroupsElementsStart() &&
                        <div className="count-element-time">
                            <div id="number-view1-label"
                                 onClick={this.setElementAtGo}
                                 custom='1'
                                 className={this.getElementAtGoClassName(1)}>1
                            </div>
                            <div id="number-view2-label"
                                 onClick={this.setElementAtGo}
                                 custom='2'
                                 className={this.getElementAtGoClassName(2)}>2
                            </div>
                            <div id="number-view3-label"
                                 onClick={this.setElementAtGo}
                                 custom='3'
                                 className={this.getElementAtGoClassName(3)}>3
                            </div>
                        </div>
                    }
                    {
                        !this.props.changeModel.hasGroupsElementsStart() &&
                        <CustomSelector defaultValue={this.props.changeModel.model.elementAtGo?.toString() ?? '2'}
                                        values={this.selectGroups}
                                        width="190px"
                                        onChange={this.changeSelector}/>
                    }
                    <CountElementsView changeModel={this.props.changeModel}/>
                    <DisHelperView changeModel={this.props.changeModel}/>
                </div>
            </div>
        )
    }
}