import {TrainingModel} from "../model/TrainingModel";
import {makeAutoObservable} from "mobx";
import {TrainingDis} from "../../../helpers/trainingHelp/TrainingDis";
import {TrainingMode} from "../../../helpers/trainingHelp/TrainingMode";


export default class TrainingChangeBaseModel {
    groupsElementsStart = [1, 2, 3];
    isHoverDis: boolean = false;
    model: TrainingModel;
    type: TrainingMode;
    coefficient: number = 1;

    timeOnElementError: boolean = false;
    deferredTimeError: boolean = false;

    constructor(model: TrainingModel, type: TrainingMode) {
        makeAutoObservable(this, {}, {autoBind: true});
        this.model = model;
        this.type = type;

        this.changeCoefficient();
    }

    onChangeTimeOnElement(p: string | undefined) {
        this.model.timeOnElement = p;
    }

    onChangeLanguage(p: string | undefined) {
        this.model.language = p;
    }

    onChangeCards(_: string | undefined) {
        // this.model.language = p;
    }

    onChangeSex(p: string | undefined) {
        this.model.sex = p;
    }

    onChangeBin(_: string | undefined) {
        // this.model.language = p;
    }

    onChangeDeferredTime(p: string | undefined) {
        this.model.deferredTime = p;
    }

    onChangeRangeFrom(p: number | undefined) {
        this.model.rangeFrom = p;
    }

    onChangeRangeTo(p: number | undefined) {
        this.model.rangeTo = p;
    }

    changeCoefficient() {
        this.coefficient = 1;
        if (this.type === 'number') {
            if (this.groupsElementsStart.includes(this.model.elementAtGo!)) {
                this.coefficient = parseInt(this.model.complexity)
            } else {
                switch (this.model.elementAtGo) {
                    case 8:
                        this.coefficient = 8;
                        this.model.complexity = ('2');
                        break
                    case 9:
                        this.coefficient = 10;
                        this.model.complexity = ('2');
                        break;
                    case 10:
                        this.coefficient = 4;
                        this.model.complexity = ('2');
                        break;
                    case 11:
                        this.coefficient = 6;
                        this.model.complexity = ('2');
                        break;
                    case 12:
                        this.coefficient = 8;
                        this.model.complexity = ('2');
                        break;
                    case 13:
                        this.model.complexity = ('2');
                        break;
                    case 14:
                        this.coefficient = 6;
                        this.model.complexity = ('2');
                        break;
                    case 15:
                        this.coefficient = 6;
                        this.model.complexity = ('2');
                        break;
                    case 16:
                        this.coefficient = 6;
                        this.model.complexity = ('3');
                        break;
                    case 17:
                        this.coefficient = 9;
                        this.model.complexity = ('3');
                        break;
                    case 18:
                        this.coefficient = 39;
                        this.model.complexity = ('3');
                        break;
                    case 19:
                        this.coefficient = 39;
                        this.model.complexity = ('3');
                        break;
                    case 20:
                        this.coefficient = 40;
                        this.model.complexity = ('3');
                        break;
                    case 21:
                        this.coefficient = 40;
                        this.model.complexity = ('3');
                        break;
                    case 22:
                        this.coefficient = 40;
                        this.model.complexity = ('3');
                        break;
                }
            }
        }
        if (this.type === 'bins') {
            this.coefficient = parseInt(this.model.complexity) * 3;
        }
        if (this.type === 'cards') {
            this.coefficient = 1 / 52;
        }
    }


    changeComplexity(complexity: string) {
        this.model.complexity = complexity;
        this.changeCoefficient();
    }

    changeElementAtGo(elementOfTime: string) {
        this.model.elementAtGo = parseInt(elementOfTime);
        this.changeCoefficient();
    }

    hoverDis(isHover: boolean) {
        this.isHoverDis = isHover;
    }

    changeDis(dis: TrainingDis) {
        this.model.dis = dis;
    }

    changeNumber(number: number) {
        this.model.number = number;
    }

    changeCountElement(number: number) {
        this.model.number = number * this.coefficient;
    }

    get getCountElement() {
        return this.model.number * this.coefficient;
    }

    plusNumber(number: number) {
        if (this.model.number + number <= 0)
            return;
        this.model.number += number;
    }

    hasGroupsElementsStart(): boolean {
        return this.groupsElementsStart.includes(this.model.elementAtGo!)
    }

    getModelForCreate(): TrainingModel {
        return this.model
    }

    cantStartTraining(): boolean{
        if (this.timeOnElementError){
            alert('Введенное значение “времени на элемент” должно быть в диапазоне от 0.01 до 3600 секунд. Пожалуйста, введите корректное значение и попробуйте снова.')
        }
        if (this.deferredTimeError){
            alert('Введенное значение “отложенный старт” должно быть в диапазоне от 1 до 60 секунд. Пожалуйста, введите корректное значение и попробуйте снова.')
        }
        return this.deferredTimeError || this.timeOnElementError;
    }
}