import React, {Component} from 'react'
import './newsPage.css'
import {observer} from "mobx-react";
import {Posts} from "../../components/post/Posts";
import TitleStore from "../../components/authorization/store/TitleStore";
import {ButtonScrollToTop} from "../../helpers/scrollTop/ButtonScrollToTop";


@observer
export class NewsPage extends Component<{}, {}> {
    componentDidMount() {
        TitleStore.setTitle("Новости");
    }

    render() {
        return (
            <div id="contener-2">
                <Posts />
                <ButtonScrollToTop />
            </div>
        )
    }
}