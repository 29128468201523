import React, {Component} from 'react'
import {Link, NavLink} from 'react-router-dom'
import './navigator.scss'
import {Authorization} from "../authorization/Authorization";
import AuthorizationStore from "../authorization/store/AuthorizationStore";
import {observer} from "mobx-react";
import {TypeScreen, UseScreenType} from "../../context/TypeScreen";
import {reaction} from "mobx";


@observer
export class Navigation extends Component<{}, {}> {
    useScreen = UseScreenType();

    constructor(props: Readonly<{}>) {
        super(props);
        this.openModal = this.openModal.bind(this);
        this.updateUseScreenType = this.updateUseScreenType.bind(this);
    }

    updateUseScreenType() {
        this.useScreen = UseScreenType();
    }

    componentDidMount() {
        AuthorizationStore.setShowLayoutHead(true);
        reaction(
            () => [UseScreenType],
            this.updateUseScreenType
        );
    }

    openModal() {
        AuthorizationStore.openModalAuth();
    }

    render() {
        if (!AuthorizationStore.showLayoutHead)
            return <></>
        return (
            <div className="header">
                <div className="header-box">
                    <Link to="/">
                        <div className="header__logo"></div>
                    </Link>
                    {
                        this.useScreen === TypeScreen.Mobile &&
                        <div id="burger"></div>
                    }
                    {
                        this.useScreen !== TypeScreen.Mobile &&
                        <nav className="header__menu">
                            <ul className="header-menu-items">
                                <li className="menu-item">
                                    <NavLink to="/Training" className="item"><h1>Тренировка</h1></NavLink>
                                </li>
                                <li className="menu-item">
                                    <NavLink to="/" className="item"><h1>Обучение</h1></NavLink>
                                </li>
                                {
                                    AuthorizationStore.checkAuthMenu &&
                                    <li className="menu-item">
                                        <NavLink to="/statistics" className="item"><h1>Статистика</h1></NavLink>
                                    </li>
                                }
                                {
                                    !AuthorizationStore.checkAuthMenu &&
                                    <li className="menu-item">
                                        <div onClick={this.openModal} className="item"><h1>Статистика</h1></div>
                                    </li>
                                }
                                <li className="menu-item">
                                    <NavLink to="/News" className="item"><h1>Статьи</h1></NavLink>
                                </li>
                                <li className="menu-item">
                                    <NavLink to="/Store" className="item"><h1>Магазин</h1></NavLink>
                                </li>
                                <li className="menu-item">
                                    {/*<a className="item" asp-area="" asp-controller="Store" asp-action="Index">*/}
                                    {/*    <h1>@Localizer["Store"]</h1>*/}
                                    {/*</a>*/}
                                </li>
                            </ul>
                        </nav>
                    }
                    <Authorization/>
                </div>
            </div>
        )
    }
}