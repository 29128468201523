import {makeAutoObservable} from "mobx";
import {
    CodingModeNewCountViewModel,
    CodingViewModel,
    PackageTrainingCodingViewModel
} from "../model/PackageTrainingCodingViewModel";
import {LoadingModel} from "../../shared/LoadingModel";
import {GetRequest} from "../../../helpers/Request";
import {SelectorPros} from "../../shared/selector/CustomSelector";
import AuthorizationStore from "../../authorization/store/AuthorizationStore";
import KeyboardManager from "../../../core/keyboardManager/adapters/KeyboardManager";
import {range9, range99, range999, rangeEng, rangeEngIng, rangeFigurativeCodes} from "./TrainingCodingConstants";


export enum StateTrainingTrainSport {
    'base' = 0,
    'loadingImage' = 1,
    'write' = 2,
    'result' = 3,
}

export default class TrainingSportBaseStore {
    state: StateTrainingTrainSport;
    requestModel: CodingViewModel;
    responseModel: LoadingModel<PackageTrainingCodingViewModel> = LoadingModel.createLoaded();
    startTrainingCoding: (model: CodingViewModel) => void;

    constructor(startTraining: (model: CodingViewModel) => void) {
        makeAutoObservable(this, {}, {autoBind: true});
        this.state = StateTrainingTrainSport.base;
        this.startTrainingCoding = startTraining;
        this.requestModel = {
            antiStop: "",
            coding: "X2",
            count: 10,
            delayedStart: "",
            figurativeCodes: "99",
            generation: "random",
            defferTime: "",
            phonemesMorphemes: "",
            powerSinapsName: "",
            rate: "all",
            system: "PowerMemory",
            timeElement: "",
            middle: true,
            notUsed: true,
            power: true,
            reflecs: true,
            veryPower: true,
            weak: true
        }
    }

    startTraining() {
        if (!AuthorizationStore.checkAuthMenu) {
            AuthorizationStore.openModalAuth();
            return;
        }
        this.startTrainingCoding(this.requestModel);
    }

    getClassNameGeneration(val: string) {
        if (this.requestModel.generation === val)
            return "ch-radio radioActivity";
        return "ch-radio";
    }

    init() {
        this.request();
        KeyboardManager.add('Space', this.startTraining);
        KeyboardManager.add('Escape', this.back);
    }

    unInit() {
        KeyboardManager.remove('Space', this.startTraining);
        KeyboardManager.remove('Escape', this.back);
    }

    back() {
        window.location.pathname = '/Training';
    }

    request() {
        if (AuthorizationStore.isAuthJwt()) {
            GetRequest<PackageTrainingCodingViewModel>('/Training/GetCodingName')
                .then(x => {
                    if (x.Model === null)
                        return;
                    this.setModel(x);
                });
        }
    }

    setModel(model: LoadingModel<PackageTrainingCodingViewModel>) {
        this.responseModel = model;
    }

    onChangeAntiStop(val: string) {
        this.requestModel.antiStop = val;
    }

    onChangeTimeElement(val: string) {
        this.requestModel.timeElement = val;
    }

    onChangeDefferTime(val: string) {
        this.requestModel.defferTime = val;
    }

    onChangeDelayedStart(val: string) {
        this.requestModel.delayedStart = val;
    }

    onChangeFigurativeCodes(val: string) {
        this.requestModel.figurativeCodes = val;
    }

    onChangeRange(val: string) {
        this.requestModel.rate = val;
    }

    onChangeSystem(val: string) {
        this.requestModel.system = val;
    }

    onChangeGeneration(val: string) {
        this.requestModel.generation = val;
    }

    onChangeCoding(val: string) {
        this.requestModel.coding = val;
    }

    codingTrainingCodingName(): SelectorPros[] {
        if (!this.responseModel.IsLoadedModel())
            return [];
        return this.responseModel.Model?.codeNames?.map<SelectorPros>((x) => {
            return {
                value: x.titleId,
                title: x.name,
            }
        }) ?? [];
    }

    get codingTrainingCodingFigurative(): SelectorPros[] {
        if (!this.responseModel.IsLoadedModel())
            return [];
        let res = this.responseModel.Model?.codeNames.filter((x) => x.titleId === this.requestModel.system)[0];
        if (!res)
            return [];
        return res.modes.map<SelectorPros>((x) => {
            return {
                value: x.mode,
                title: x.modeShow
            }
        })
    }

    getRange(): SelectorPros[] {
        if (!this.responseModel.IsLoadedModel())
            return [];
        if (this.requestModel.figurativeCodes === '9') {
            return range9();
        }
        if (this.requestModel.figurativeCodes === '99') {
            return range99();
        }
        if (this.requestModel.figurativeCodes === '999') {
            return range999();
        }
        if (this.requestModel.figurativeCodes === 'eng') {
            return rangeEng();
        }
        if (this.requestModel.figurativeCodes === 'eng-incode') {
            return rangeEngIng();
        }
        if (this.requestModel.figurativeCodes === 'figurativeСodes') {
            return rangeFigurativeCodes();
        }
        return [];
    }

    get currentCoding(): CodingModeNewCountViewModel {
        if (!this.responseModel.hasModel)
            return {
                figurativeCodes: this.requestModel.figurativeCodes,
                middleSin: 0,
                notUsed: 0,
                powerSin: 0,
                reflecsSin: 0,
                styleCoding: this.requestModel.coding,
                verypowerSin: 0,
                weakSin: 0
            };
        let res = this.responseModel.Model!.codingMode
            .filter(x => x.figurativeCodes === this.requestModel.figurativeCodes)
            .filter(x => x.styleCoding === this.requestModel.coding)
        if (res.length !== 1)
            return {
                figurativeCodes: this.requestModel.figurativeCodes,
                middleSin: 0,
                notUsed: 0,
                powerSin: 0,
                reflecsSin: 0,
                styleCoding: this.requestModel.coding,
                verypowerSin: 0,
                weakSin: 0
            }
        return res[0];
    }

    onChangeCount(number: number) {
        this.requestModel.count = number;
    }
}