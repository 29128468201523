import {CustomComponent} from "../../helpers/CustomComponent";
import TimeSiteStore from "./TimeSiteStore";
import {observer} from "mobx-react";


@observer
export default class TimeSite extends CustomComponent<{}, {}>{
    constructor(props: Readonly<{}>) {
        super(props);
    }
    componentDidMount() {
        TimeSiteStore.start();
    }
    componentWillUnmount() {
        TimeSiteStore.finish();
    }

    render() {
        return <div id="timesite" title={TimeSiteStore.currentTimeStr}>{TimeSiteStore.currentTimeStr} (GMT+3)</div>;
    }
}