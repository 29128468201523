import React, {Component} from 'react'
import './mnemocardComponent.scss'
import {observer} from "mobx-react";
import CustomSelector, {SelectorPros} from "../shared/selector/CustomSelector";
import {MnemocardRecordStore} from "./store/MnemocardRecordStore";
import {ResultMax, TypeBestTraining} from "./models/MnemocardView";
import {nameMode, TrainingMode} from "../../helpers/trainingHelp/TrainingMode";
import {Link} from "react-router-dom";
import {msToTime} from "../../helpers/numberHelp/numberHelp";


@observer
export class MnemocardRecordsComponent extends Component<{ results: ResultMax[] }, {}> {

    private store: MnemocardRecordStore;

    constructor(props: Readonly<{ results: ResultMax[] }>) {
        super(props);
        this.store = new MnemocardRecordStore(this.props.results);
        this.changeDis = this.changeDis.bind(this);
        this.getViewMaxTraining = this.getViewMaxTraining.bind(this);
    }

    changeDis(selector: SelectorPros) {
        this.store.changeDis(selector.value);
    }

    trainingComplexity(training: ResultMax | undefined) {
        if (!training) return null;
        if (training.mode === "number") {
            if (training.complexity === "1")
                return "x";
            if (training.complexity === "2")
                return "xx";
            if (training.complexity === "3")
                return "xxx";
        }
        if (training.mode === 'number'){
            if (training.complexity === '1'){
                return 'X';
            }
            if (training.complexity === '2'){
                return 'XX';
            }
            if (training.complexity === '3'){
                return 'XXX';
            }
        }
        if (training.mode === 'cards'){
            if (training.complexity === '1'){
                return 'X';
            }
            if (training.complexity === '2'){
                return 'XX';
            }
            if (training.complexity === '3'){
                return 'X';
            }
        }
        if (training.mode === 'bins'){
            if (training.complexity === '1'){
                return '3X';
            }
            if (training.complexity === '2'){
                return '6X';
            }
            if (training.complexity === '3'){
                return '9X';
            }
        }
        if (training.mode === 'image'){
            if (training.complexity === '1'){
                return 'Ф';
            }
            if (training.complexity === '2'){
                return 'К';
            }
            if (training.complexity === '3'){
                return 'К-Ф';
            }
        }
        if (training.mode === 'word'){
            let languageText = '';
            if (training.language === '0'){
                languageText = 'RUS';
            }else if (training.language === '1'){
                languageText = 'ENG';
            }else{
                languageText = 'ХЗ';
            }
            if (training.complexity === '1'){
                return languageText + '-П';
            }
            if (training.complexity === '2'){
                return languageText + '-С';
            }
            if (training.complexity === '3'){
                return languageText + '-СЛ';
            }
        }
        if (training.mode === 'name'){
            if (training.complexity === '1'){
                return 'ENG';
            }
            if (training.complexity === '2'){
                return 'RUS';
            }
            if (training.complexity === '3'){
                return 'ADD';
            }
        }
        return training.complexity
    }

    getViewMaxTraining(mode: TrainingMode): JSX.Element {
        let training = this.store.training(mode);
        let complexity = this.trainingComplexity(training);
        return (<div className="tr-records" key={mode + "_" + this.store.dis}>
            <div className="el-name" id={"el-" + mode}>
                {
                    training?.typeBest === TypeBestTraining.Site &&
                    <div className="mk-record-star mk-record-star-pr"></div>
                }
                {
                    training?.typeBest === TypeBestTraining.User &&
                    <div className="mk-record-star mk-record-star-pr"></div>
                }
                {
                    !training?.typeBest &&
                    <div className="mk-record-star mk-record-star-none"></div>
                }
                <div>{nameMode(mode)}<span id="number-mkr-dis" className="mkr-dis">[{training?.dis ?? '-'}]</span></div>
            </div>
            <div className="el-amount" id="number-amount">{training?.countRightTraining ?? '-'}</div>
            <div className="el-time"
                 id="number-time">{training?.timeAllTraining ? msToTime(training?.timeAllTraining) : '-'}</div>
            <div className="el-temp" id="number-temp">{training?.speedTraining ?? '-'}
                {complexity &&
                    <sup className="mnemocardComplexity">{complexity}</sup>
                }
            </div>
            <div className="el-dop" id="number-descr">
                {!training && <span id="number-descr-line">-</span>}
                {training && <Link to={"/Training/Search?id=" + training.idTraining}
                                   className="mnemocard-tabs-training-link memocard-image-link"></Link>}
            </div>
        </div>)
    }

    render() {
        return (
            <div className="tab-mnemocard" id="m-recotds">
                <div id="td-header">
                    <div className="el-name">Дисциплина</div>
                    <div className="el-amount">Кол-во</div>
                    <div className="el-time">Время</div>
                    <div className="el-temp">Темп</div>
                    <div className="el-dop">См.</div>
                </div>

                {this.getViewMaxTraining('number')}
                {this.getViewMaxTraining('cards')}
                {this.getViewMaxTraining('word')}
                {this.getViewMaxTraining('name')}
                {this.getViewMaxTraining('bins')}
                {this.getViewMaxTraining('image')}

                <div id="mk-dis-record">
                    <div className="select-style-t2">
                        <CustomSelector defaultValue={this.store.dis} values={this.store.selectors}
                                        onChange={this.changeDis}/>
                    </div>
                </div>
            </div>
        )
    }
}