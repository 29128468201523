import {CustomComponent} from "../../../helpers/CustomComponent";
import {Accordion} from "../../../helpers/accordion/Accordion";
import './testLesson.scss'
import {WriteAnswer} from "../../../helpers/writeAnswer/WriteAnswer";
import {ManyAnswer} from "../../../helpers/manyAnswers/ManyAnswer";
import {BlockTextComponent} from "../../../helpers/blockText/BlockTextComponent";
import {SelectorPros} from "../../shared/selector/CustomSelector";
import {HorizontalLineComponent} from "../../../helpers/horizontalLine/HorizontalLineComponent";
import {TitleComponent, TypeTitle} from "../../../helpers/title/TitleComponent";
import {BlockTextWithLeftBoardComponent} from "../../../helpers/blockTextWithLeftBoard/BlockTextWithLeftBoardComponent";
import {PointsComponent} from "../../../helpers/points/PointsComponent";
import {ArtWorkComponent} from "../../../helpers/artWork/ArtWorkComponent";
import {TableComponent} from "../../../helpers/table/TableComponent";
import {NextPrevComponent} from "../../../helpers/nextPrev/NextPrevComponent";

let values: SelectorPros[] = [{
    title: "Ответ-1",
    value: "answer-1"
}, {
    title: "Ответ 2",
    value: "answer-2"
},]
let values2: SelectorPros[] = [{
    title: "Ответ-1",
    value: "answer-1"
}, {
    title: "Ответ 2",
    value: "answer-2"
}, {
    title: "Ответ 3",
    value: "answer-3"
}, {
    title: "Ответ 4",
    value: "answer-4"
},]

export class TestLesson extends CustomComponent<{}, {}> {
    render() {
        return <div className="test-lesson">
            <div>
                <div className="test-lesson-title">1. Блок текста</div>
                <BlockTextComponent hasImage={false} hasBackground={true}/>
            </div>
            <div>
                <div className="test-lesson-title">2. Title</div>
                <TitleComponent typeTitle={TypeTitle.h1}/>
            </div>
            <div>
                <div className="test-lesson-title">3. Title с порядковым номером</div>
                <TitleComponent typeTitle={TypeTitle.h1}/>
            </div>
            <div>
                <div className="test-lesson-title">4. Ввод ответа</div>
                <WriteAnswer/>
            </div>
            <div>
                <div className="test-lesson-title">5. Accordion - Аккордион</div>
                <Accordion/>
            </div>
            <div>
                <div className="test-lesson-title">6. Checkbox or manySelector</div>
                <ManyAnswer/>
            </div>
            <div>
                <div className="test-lesson-title">7. Блок текста с левым боардом</div>
                <BlockTextWithLeftBoardComponent/>
            </div>
            <div>
                <div className="test-lesson-title">8. Блок текста с картинкой</div>
                <BlockTextComponent hasImage={true}/>
            </div>
            <div>
                <div className="test-lesson-title">9. Иллюстрация</div>
                <ArtWorkComponent hasImage={true}/>
            </div>
            <div>
                <div className="test-lesson-title">10. Таблица</div>
                <TableComponent />
            </div>

            <div>
                <div className="test-lesson-title">11. Горизонтальная линия</div>
                <HorizontalLineComponent/>
            </div>

            <div>
                <div className="test-lesson-title">12. Пункт</div>
                <PointsComponent/>
            </div>

            <div>
                <div className="test-lesson-title">13. Заголовок текста</div>
                <TitleComponent typeTitle={TypeTitle.h3}/>
            </div>

            <div>
                <div className="test-lesson-title">13. Аудио файл</div>
                <HorizontalLineComponent/>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div className="test-lesson-title">14. Задачи</div>
                {/*<TaskComponent type={UniversityTypeTask.task} state={UniversityStateTask.wait} title="ТЕСТ НА ЗНАНИЕ ЗАНЯТИЯ №1" />*/}
                {/*<TaskComponent type={UniversityTypeTask.task} state={UniversityStateTask.success} title="ТЕСТ НА ЗНАНИЕ ЗАНЯТИЯ №1" />*/}
                {/*<TaskComponent type={UniversityTypeTask.read} state={UniversityStateTask.wait} title="ТЕСТ НА ЗНАНИЕ ЗАНЯТИЯ №1" />*/}
            </div>
            <div>
                <div className="test-lesson-title">15. Проверить слово</div>
                {/*<CheckTextComponent />*/}
            </div>
            <div className="test-lesson-title">16. кнопки предыдущие и следующие</div>
            <NextPrevComponent/>
            <div>
                <div className="test-lesson-title">Заглавный текст</div>
            </div>
        </div>;
    }
}