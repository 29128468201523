import {CustomComponent} from "../../helpers/CustomComponent";
import './universityModule.scss'
import {TaskComponent} from "../../helpers/task/TaskComponent";
import {observer} from "mobx-react";
import {TitleComponent, TypePositionText, TypeTitle} from "../../helpers/title/TitleComponent";
import React from "react";
import {BlockTextComponent} from "../../helpers/blockText/BlockTextComponent";
import {UniversityModuleStore} from "./store/UniversityModuleStore";
import {UniversityStateTask} from "./models/UniversityStateTask";
import {UniversityTypeTask} from "./models/UniversityTypeTask";

interface Props {

}

@observer
export class UniversityModuleComponent extends CustomComponent<Props, any> {
    private store: UniversityModuleStore = new UniversityModuleStore();

    componentDidMount() {
        this.store.init()
    }

    componentWillUnmount() {
        this.store.unInit()
    }

    render() {
        return <div className="university-module">
            {
                this.store.model.Model &&
                <div key={"block_id_1"}>
                    <TitleComponent typeTitle={TypeTitle.universityModuleTitle} text={this.store.model.Model.name}
                                    typePosition={TypePositionText.left}/>
                    <BlockTextComponent hasBackground={true}>
                        <div className="university-tasks">
                            {this.store.model.Model.models
                                .sort((x,y) => x.id - y.id)
                                .map(task =>
                                <TaskComponent key={task.id} task={{
                                    id: task.id,
                                    name: task.name,
                                    image: task.image,
                                    type: UniversityTypeTask.task,
                                    state: UniversityStateTask.wait,
                                }}/>)}
                        </div>
                    </BlockTextComponent>
                </div>
            }
        </div>;
    }
}
