import {LoadingModel} from "../../shared/LoadingModel";
import {makeAutoObservable} from "mobx";
import {IAdministrationStore} from "./IAdministrationStore";


export class BaseAdministrationStore<TModel> {
    private administrationStore: IAdministrationStore<TModel>;
    models: LoadingModel<TModel[]> = LoadingModel.createNotLoaded();
    name: string = "";
    orderName: string = "";

    constructor(administrationStore: IAdministrationStore<TModel>) {
        makeAutoObservable(this, {}, {autoBind: true});
        this.administrationStore = administrationStore;
    }

    request() {
        this.administrationStore.requestLocal()
            .then(x => this.setModels(x));
    }

    setModels(models: LoadingModel<TModel[]>) {
        this.models = models;
    }

    changeNameAndRequest(name: string) {
        this.name = name;
        this.request();
    }

    changeOrderNameAndRequest(name: string) {
        this.orderName = name;
        this.request();
    }

    init() {
        this.request();
    }

    unInit() {

    }
}