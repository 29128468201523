import React, {Component} from 'react'
import TrainingChangeBaseModel from "../store/TrainingChangeBaseModel";
import {observer} from "mobx-react";


@observer
export class CountElementsView extends Component<{changeModel: TrainingChangeBaseModel}, {}> {

    constructor(props: Readonly<{changeModel: TrainingChangeBaseModel}>) {
        super(props);
        this.plusNumber = this.plusNumber.bind(this);
        this.minusNumber = this.minusNumber.bind(this);
        this.clickCenter = this.clickCenter.bind(this);
    }

    plusNumber(){
        if (this.props.changeModel.type === 'cards'){
            if (this.props.changeModel.model.number >= 52){
                this.props.changeModel.plusNumber(52);
                return;
            }
            if (this.props.changeModel.model.number >= 40){
                this.props.changeModel.plusNumber(52 - this.props.changeModel.model.number);
                return;
            }
            if (this.props.changeModel.model.number <= 40){
                this.props.changeModel.plusNumber(10);
                return;
            }
            return;
        }
        this.props.changeModel.plusNumber(10);
    }
    minusNumber(){
        if (this.props.changeModel.type === 'cards'){
            if (this.props.changeModel.model.number <= 40){
                this.props.changeModel.plusNumber(-10);
                return;
            }
            if (this.props.changeModel.model.number > 40 && this.props.changeModel.model.number <= 52){
                this.props.changeModel.plusNumber(40 - this.props.changeModel.model.number);
                return;
            }
            if (this.props.changeModel.model.number > 52){
                this.props.changeModel.plusNumber(-52);
                return;
            }
            return;
        }
        this.props.changeModel.plusNumber(-10);
    }
    clickCenter(e : React.MouseEvent<HTMLLabelElement>){
        if (e.nativeEvent.button === 0){
            this.plusNumber();
        }
        if (e.nativeEvent.button === 2){
            this.minusNumber();
        }
    }
    render() {
        return (
            <div className="amount-elements-number-training">
                <label id="amount-label-minus" htmlFor="amount" onClick={this.minusNumber}></label>
                {
                    this.props.changeModel.hasGroupsElementsStart() &&
                    <label id="amount-label-text"
                           onClick={this.clickCenter}
                           onContextMenu={this.clickCenter}
                           htmlFor="amount">
                        Количество элементов
                    </label>
                }
                {
                    !this.props.changeModel.hasGroupsElementsStart() &&
                    <label id="amount-label-text2"
                           onClick={this.clickCenter}
                           onContextMenu={this.clickCenter}
                           htmlFor="amount">
                        Количество групп
                    </label>
                }
                <label id="amount-label-plus" htmlFor="amount" onClick={this.plusNumber}></label>
            </div>
        )
    }
}