import {CustomComponent} from "../../../helpers/CustomComponent";
import {observer} from "mobx-react";
import {TopUserHead} from "../../topUsers/TopUserHead";
import {TopUsersSorting} from "../../topUsers/TopUsersStore";
import React from "react";
import {Loading} from "../../shared/Loading";
import {InputSearchCustom} from "../../shared/input/InputSearchCustom";
import {ImagesStore} from "../store/ImagesStore";

interface Props {
    store: ImagesStore;
}

@observer
export class ImagesComponent extends CustomComponent<Props, {}> {

    componentDidMount() {
        this.props.store.baseStore.init();
    }

    componentWillUnmount() {
        this.props.store.baseStore.unInit();
    }

    clickHead(customAttr: string) {

    }

    render() {
        return <>
            <InputSearchCustom changeAndRequest={this.props.store.baseStore.changeNameAndRequest}/>

            <div className="results-table-header">
                <div className="td-number">№</div>
                <div className="td-name">Название</div>
                <TopUserHead title={"Цена"}
                             text={"Цена"}
                             className={"td_point_month"}
                             onclick={this.clickHead}
                             customAttr={TopUsersSorting.all}
                             status={false}/>
                <TopUserHead title={"Старая цена"}
                             text={"Старая цена"}
                             className={"td_point_month"}
                             onclick={this.clickHead}
                             customAttr={TopUsersSorting.all}
                             status={false}/>
                <TopUserHead title={"Лендинг"}
                             text={"Лендинг"}
                             className={"td_point_month"}
                             onclick={this.clickHead}
                             customAttr={TopUsersSorting.all}
                             status={false}/>
                <TopUserHead title={"Оплата"}
                             text={"Оплата"}
                             className={"td_point_month"}
                             onclick={this.clickHead}
                             customAttr={TopUsersSorting.all}
                             status={false}/>
            </div>

            <section id="result-lines">
                {
                    !this.props.store.baseStore.models?.IsLoadedModel() &&
                    <Loading/>
                }
                {
                    this.props.store.baseStore.models?.Model?.map((x, y) =>
                        <div className="results-table-userinfo" key={x.id}>
                            <div className="results-table-number td-number">{y + 1}</div>
                            <div className="td-name">{x.name}</div>
                            <div className="td_point_month">{x.imageType}</div>
                            <div className="td_point_month">{x.path}</div>
                        </div>
                    )
                }
            </section>
        </>;
    }
}