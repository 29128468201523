import React from 'react'
import './mnemocardComponent.scss'
import {observer} from "mobx-react";
import MnemocardStore from "./store/MnemocardStore";
import {Link} from "react-router-dom";
import {CustomComponent} from "../../helpers/CustomComponent";


@observer
export class MnemoCartInfoComponent extends CustomComponent<{}, {}> {

    // constructor(props: Readonly<{}>) {
    //     super(props);
    // }

    get sex(): string{
        if (MnemocardStore.mnemoCart.Model!.sex === "man"){
            return "мужской"
        }
        if (MnemocardStore.mnemoCart.Model!.sex === "women"){
            return "женский"
        }
        if (MnemocardStore.mnemoCart.Model!.sex === "another"){
            return "другой"
        }
        return "не выбран"
    }
    getYearTest(years : number){
        if (years === 0)
            return '';
        if (years === 1) {
            return '1 год';
        } else if (years <= 4) {
            return  years + ' года';
        } else {
            return years + ' лет';
        }
    }

    getMonthsText(months: number) {
        if (months === 0)
            return ''
        if (months === 1) {
            return  months + ' месяц';
        } else if (months <= 4) {
            return  months + ' месяца';
        } else if (months <= 12){
            return  months + ' месяцев';
        }
        return months.toString();
    }

    render() {
        return (
            <div className="tab-mnemocard" id="m-info">
                <div className="address minfo">{MnemocardStore.mnemoCart.Model!.city}</div>
                <div className="birthday minfo">
                    <span className="m-infon">Возраст:</span>
                    <span>{' ' + MnemocardStore.mnemoCart.Model!.year}</span>
                </div>

                <div className="sex-mc minfo">
                    <span className="m-infon">Пол:</span>
                    <span>{' ' + this.sex}</span>
                </div>

                <div className="stud minfo">
                    <span className="m-infon">На сайте:</span>
                    <span id="m-into-months"
                          data-title={'Регистрация с ' + MnemocardStore.mnemoCart.Model!.dateRegistration}>
                         {' ' + this.getYearTest(Math.floor(MnemocardStore.mnemoCart.Model!.months! / 12)) + ' ' + this.getMonthsText(Math.floor(MnemocardStore.mnemoCart.Model!.months! % 12))}
                    </span>
                </div>
                {
                    MnemocardStore.mnemoCart.Model!.socialNetwork &&
                    <div className="social minfo">
                        <span className="m-infon">Контакты:</span>
                        <span>{' ' + MnemocardStore.mnemoCart.Model!.socialNetwork}</span>
                    </div>
                }

                <div className="amount-loci minfo">
                    <span className="m-infon">Количество опорных образов:</span>
                    <span>{' ' + MnemocardStore.mnemoCart.Model!.supportingImages}</span>
                </div>
                {
                    MnemocardStore.mnemoCart.Model!.isAdmin &&
                    <>
                        <div className="id-user minfo-admin">
                            <span className="m-infon">Идентификатор:</span>
                            <span>{' ' + MnemocardStore.mnemoCart.Model!.id}</span>
                        </div>

                        <div className="id-user minfo-admin">
                            <span className="m-infon">Статистика:</span>
                            <div className="el-dop-admin">
                                <Link to={'/Statistics?userId=' + MnemocardStore.mnemoCart.Model!.id}
                                      className='mnemocard-tabs-training-link memocard-image-link'/>
                            </div>
                        </div>
                        <div className="id-user minfo-admin">
                            <span className="m-infon">История платежей:</span>
                            <div className="el-dop-admin">
                                <Link to={'/purchases?userId=' + MnemocardStore.mnemoCart.Model!.id}
                                      className='memocard-image-link'/>
                            </div>
                        </div>
                        <div className="id-user minfo-admin">
                            <span className="m-infon">Настройки:</span>
                            <div className="el-dop-admin">
                                <Link to={'/Settings?userId=' + MnemocardStore.mnemoCart.Model!.id}
                                      className='memocard-image-link'/>
                            </div>
                        </div>
                        <div className="id-user minfo-admin">
                            <span className="m-infon">Образные коды:</span>
                            <div className="el-dop-admin">
                                <Link to={'/settingscoding?userId=' + MnemocardStore.mnemoCart.Model!.id}
                                      className='memocard-image-link'/>
                            </div>
                        </div>
                        <div className="id-user minfo-admin">
                            <span className="m-infon">Маршруты:</span>
                            <div className="el-dop-admin">
                                <Link to={'/Route?userId=' + MnemocardStore.mnemoCart.Model!.id}
                                      className='memocard-image-link'/>
                            </div>
                        </div>
                    </>
                }
                <div className="go-message">
                    СООБЩЕНИЕ
                    <div className="messicon"></div>
                </div>
            </div>
        )
    }
}