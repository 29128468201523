import {CustomComponent} from "../CustomComponent";
import './tableComponent.scss'

interface Props {
}

export class TableComponent extends CustomComponent<Props, any> {
    render() {
        return <div className="table-component" >
            {/*<div className={}>*/}

            {/*</div>*/}
        </div>;
    }
}