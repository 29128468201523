import {GetRequest, PostRequest} from "../../../helpers/Request";
import {
    ImageAdminView,
    ProductView,
    SubscriptionView,
    UserAdminViewModel,
    UserProductsViewModel,
    UserSubscriptionView, WordAdminView
} from "../model/ProductView";
import {SmallPostModel} from "../../post/model/SmallPostModel";

export class AdministrationRepository {
    products() {
        return PostRequest<ProductView[], {}>('/api/Administrations/Products', {});
    }

    subscriptions() {
        return PostRequest<SubscriptionView[], {}>('/api/AdministrationsSubs/Subscriptions', {});
    }

    words() {
        return PostRequest<WordAdminView[], {}>('/api/Words/GetPost', {});
    }

    images() {
        return PostRequest<ImageAdminView[], {}>('/api/Images/GetPost', {});
    }

    userSubscriptions() {
        return GetRequest<UserSubscriptionView[]>('/api/AdministrationsSubs/GetUserSubscriptions');
    }

    users() {
        return GetRequest<UserAdminViewModel[]>('/api/AdministrationsUser/GetUsers');
    }

    userProducts() {
        return PostRequest<UserProductsViewModel[], {}>('/api/Administrations/GetUserProducts', {});
    }

    posts() {
        return PostRequest<SmallPostModel[], {}>('/api/Administrations/GetPosts', {});
    }
}