import {CustomComponent} from "../../../../helpers/CustomComponent";
import {SettingsCodingItemModel} from "../../../settingsCoding/model/SettingsCodingModel";
import {ChangeEvent} from "react";
import {observer} from "mobx-react";

interface Props {
    item: SettingsCodingItemModel,
    isChange: boolean,
    clickChange: (id: string) => void;
    clickRestore: (id: string) => void;
    changeNameSettingsCoding: (id: string, name: string) => void;
}

@observer
export class SettingsCodingItem extends CustomComponent<Props, {}> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.changeName = this.changeName.bind(this);

        this.clickChange = this.clickChange.bind(this);
        this.clickRestore = this.clickRestore.bind(this);
    }

    changeName(e: ChangeEvent<HTMLInputElement>) {
        this.props.changeNameSettingsCoding(this.props.item.id, e.currentTarget.value);
    }

    clickChange() {
        this.props.clickChange(this.props.item.id)
    }

    clickRestore() {
        this.props.clickRestore(this.props.item.id)
    }

    render() {
        return <div className="cods-images">
            <input type="text" className="cod-names" value={this.props.item.name}
                   onChange={this.changeName}/>
            <span className="info-names">{this.props.item.title}</span>
            <div className="codimage"
                 style={{backgroundImage: 'url(https://powermemory.io/' + this.props.item.image + ')'}}></div>
            {
                !this.props.isChange &&
                <div className="action-with-codimages">
                    <span className="replacement-cod" onClick={this.clickChange}
                          alt-name={this.props.item.name}>Заменить</span>
                    <span className="recovery-cod" onClick={this.clickRestore}>Восстановить</span>
                </div>
            }
        </div>;
    }
}