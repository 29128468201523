import {makeAutoObservable} from "mobx";
import {PostRequest} from "../../../helpers/Request";
import {MnemocardViewModal} from "../models/MnemocardView";
import {LoadingModel} from "../../shared/LoadingModel";
import KeyboardManager from "../../../core/keyboardManager/adapters/KeyboardManager";
import {ReactModalStore} from "../../modal/ReactModalStore";

type MnemoCartType = 'records' | 'awards' | 'information';

class MnemoCartStore {
    modalStore: ReactModalStore;
    link: string | undefined;
    mnemoCart: LoadingModel<MnemocardViewModal> =
        LoadingModel.createNotLoaded<MnemocardViewModal>();
    mnemoCartType: MnemoCartType = 'records';

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true})
        this.modalStore = new ReactModalStore(this.onClose);
    }

    init() {
        KeyboardManager.add('Escape', this.onClose);
        window.addEventListener("popstate", this.handlePopState);
    }

    unInit() {
        KeyboardManager.remove('Escape', this.onClose);
        window.removeEventListener("popstate", this.handlePopState);
    }

    handlePopState() {
        this.close(false);
    }

    setLink(link: string) {
        if (!window.location.pathname.startsWith("/@")) {
            window.history.pushState({}, "", "/@" + link)
        }
        this.mnemoCart = LoadingModel.createNotLoaded<MnemocardViewModal>();
        // this.showModal = true;
        this.modalStore.open()
        this.link = link;
        this.fetchRequest()
    }

    setType(mnemoCartType: MnemoCartType) {
        this.mnemoCartType = mnemoCartType;
    }

    onClose() {
        this.close(true);
    }

    close(isChangeHistory: boolean = true) {
        this.link = undefined;

        if (isChangeHistory) {
            if (window.history.length > 1) {
                window.history.go(-1);
            } else {
                window.history.pushState(null, "", "/");
            }
        }
    }

    setMnemoCard(mnemoCart: LoadingModel<MnemocardViewModal>) {
        this.mnemoCart = mnemoCart;
    }

    getActiveTabClass(mnemoCartType: MnemoCartType) {
        if (mnemoCartType === this.mnemoCartType) {
            return ' active-tab';
        }
        return '';
    }

    fetchRequest() {
        PostRequest<MnemocardViewModal, {}>('/Home/Mnemocard?id=' + this.link, {})
            .then(x => this.setMnemoCard(x))
    }
}

export default new MnemoCartStore();