import {Callback, Key} from "../types";
import {CallbackKeyboardService} from "../KeyboardService";

class KeyboardManager {
    private callbackService : CallbackKeyboardService = new CallbackKeyboardService();
    add(key: Key, callback: Callback, disabled = false) {
        if (disabled)
            return;
        this.callbackService.addCallback({
            key,
            callback
        });
    }

    remove(key: Key, callback: Callback) {
        this.callbackService.removeCallback({
            key,
            callback
        });
    }
}
export default new KeyboardManager();