export interface MnemocardViewModal{
    id: string;
    socialNetwork: string;
    city: string;
    dateBirth: string;
    dateTimeBirth: string;
    year: number| undefined;
    months: number| undefined;
    email: string;
    username: string;
    image: string;
    rank: string;
    supportingImages: string;
    codeOwnership: string;
    point: string;
    place: string;
    statusBuyStone: boolean;
    isPremium: boolean;
    isAdmin: boolean;
    dayBallsProfile: number;
    sex: string;
    dateRegistration: string;

    stone: StoneMnemocardViewModel
    results: ResultMax[]
    stoneUserProgresses2: StoneUserProgressesViewModel[]

    cimonidaStars2: CimonidaStarsViewModel[];
    mnemosinsPyramids2: MnemosinsPyramidsViewModel[];
    perfectLevel: PerfectLevelViewModel[];
}

export interface StoneMnemocardViewModel{
    name: string;
    countDaysCurrent: number;
    countDaysOnStone: number;
}

export interface RewardViewModel{
    name: string;
    image: string;
    countBalls: number;
}
export interface PerfectLevelDatabase{
    number: number;
    mode: string;
    countRightImages: number;
    groupById: number;
}
export interface PerfectLevelViewModel{
    countElements: number;
    mode: string;
    count: number;
}
export interface StoneUserProgressesViewModel{
    name: string;
    image: string;
    countDays: number;
    countBalls: number;
    stonesCount: number;
}
export interface CimonidaStarsViewModel{
    name: string;
    image: string;
    comonidaCount: number;
}
export interface MnemosinsPyramidsViewModel{
    name: string;
    image: string;
    count: number;
}

export interface ResultMax{
    mode: string;
    dis: string;
    complexity: string;
    idTraining: string;
    timeAllTraining: number;
    speedTraining: number;
    countRightTraining: number;
    language: string;
    index: number;
    typeBest: TypeBestTraining | null;
}


export enum TypeBestTraining{
    User= 1,
    Site= 2,
}