import {CustomComponent} from "../CustomComponent";
import './pointsComponent.scss'

interface Props {
    hasImage?: boolean;
    hasBackground?: boolean;
    title?: string;
    children?: React.ReactNode;
}

export class PointsComponent extends CustomComponent<Props, any> {
    render() {
        return <div className="points-component">
            <ol className="points-component_points">
                <div className="points-component_point">&bull; {this.props.title}</div>
                {
                    this.props.children &&
                    <div className="points-component_description">
                        {this.props.children}
                    </div>
                }
            </ol>
        </div>;
    }
}