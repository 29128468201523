import {CustomComponent} from "../../../helpers/CustomComponent";
import {observer} from "mobx-react";
import {TopUserHead} from "../../topUsers/TopUserHead";
import {TopUsersSorting} from "../../topUsers/TopUsersStore";
import React from "react";
import {Loading} from "../../shared/Loading";
import {InputSearchCustom} from "../../shared/input/InputSearchCustom";
import {UserSubscriptionStore} from "../store/UserSubscriptionStore";
import {AvatarWithMnemocard} from "../../user/AvatarWithMnemocard";

interface Props {
    store: UserSubscriptionStore;
}

@observer
export class UserSubscriptionComponent extends CustomComponent<Props, {}> {
    constructor(props: Readonly<Props>) {
        super(props);
    }

    componentDidMount() {
        this.props.store.baseStore.init();
    }

    componentWillUnmount() {
        this.props.store.baseStore.unInit();
    }

    clickHead(customAttr: string) {

    }

    render() {
        return <>
            <InputSearchCustom changeAndRequest={this.props.store.baseStore.changeNameAndRequest}/>

            <div className="results-table-header">
                <div className="td-number">№</div>
                <div className="td-name">Имя</div>
                <TopUserHead title={"Название подписки"}
                             text={"Название подписки"}
                             className={"td_point_month"}
                             onclick={this.clickHead}
                             customAttr={TopUsersSorting.all}
                             status={false}/>
                <TopUserHead title={"Статус"}
                             text={"Статус"}
                             className={"td_point_month"}
                             onclick={this.clickHead}
                             customAttr={TopUsersSorting.all}
                             status={false}/>
                <TopUserHead title={"Дата активации"}
                             text={"Дата активации"}
                             className={"td_point_month"}
                             onclick={this.clickHead}
                             customAttr={TopUsersSorting.all}
                             status={false}/>
                <TopUserHead title={"Действителен до"}
                             text={"Действителен до"}
                             className={"td_point_month"}
                             onclick={this.clickHead}
                             customAttr={TopUsersSorting.all}
                             status={false}/>
            </div>

            <section id="result-lines">
                {
                    !this.props.store.baseStore.models?.IsLoadedModel() &&
                    <Loading/>
                }
                {
                    this.props.store.baseStore.models?.Model?.map((x, y) =>
                        <div className="results-table-userinfo" key={x.applicationUserId}>
                            <div className="results-table-number td-number">{y + 1}</div>
                            <AvatarWithMnemocard
                                name={x.fullname}
                                image={x.imgSrc}
                                isPremium={x.isPremium}
                                link={x.link}
                                rank={x.rang}/>
                            <div className="td_point_month">{x.subName}</div>
                            <div className="td_point_month">{x.status}</div>
                            <div className="td_point_month">{x.date.toString()}</div>
                            <div className="td_point_month">{x.endDate.toString()}</div>
                        </div>
                    )
                }
            </section>
        </>;
    }
}