import {CustomComponent} from "../CustomComponent";
import './blockTextComponent.scss'

interface Props {
    hasImage?: boolean;
    hasBackground?: boolean;
    hasPadding?: boolean;
    children?: React.ReactNode;
    className?: string;
    withoutBlockContent?: boolean;
}

export class BlockTextComponent extends CustomComponent<Props, any> {
    render() {
        return <div className={"block-text-component " + this.props.className} style={{
            backgroundColor: this.props.hasBackground === true ? 'none' : 'transparent',
            padding: this.props.hasPadding === false ? '0' : 'none'
        }}>
            {
                this.props.hasImage === true &&
                <div className="block-text_image">
                </div>
            }
            {
                this.props.withoutBlockContent=== true &&
                this.props.children
            }
            {
                this.props.withoutBlockContent !== true &&
                <div className={"block-text_content"}>
                    {this.props.children}
                </div>
            }
        </div>;
    }
}