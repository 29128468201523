import {CustomComponent} from "../../helpers/CustomComponent";
import AuthorizationStore from "./store/AuthorizationStore";
import {Link} from "react-router-dom";
import React from "react";
import {UserName} from "../user/UserName";
import './hProfileUserMenu.scss'
import MnemocardStore from "../mnemocard/store/MnemocardStore";

interface Props{
    footer?: boolean;
}
export class HProfileUserMenu extends CustomComponent<Props, {}>
{

    render() {
        if (!AuthorizationStore.user.Model)
            return null;
        return <div className="h-profil">
            <UserName
                link={AuthorizationStore.user.Model.link}
                username={AuthorizationStore.user.Model.fullname}
                fullname={AuthorizationStore.user.Model.fullname}
                rank={AuthorizationStore.user.Model.rank}
                isPremium={AuthorizationStore.user.Model.isSub}/>
            <ul className="h-links">
                {
                    !AuthorizationStore.user.Model.isSub &&
                    <div className="h-profil-link">
                        <Link className="item" id="h-premium" to="/premiumbuy">Получить премиум</Link>
                    </div>
                }
                <div className="h-profil-link">
                    <div className="item left-padding-h-profile" onClick={AuthorizationStore.openMyMnemoCard} id="h-myprofil">Мой профиль</div>
                </div>
                <div className="h-profil-link">
                    <Link className="item" id="h-settings-coding" to="/SettingsCoding">Образные
                        коды</Link>
                </div>
                <div className="h-profil-link">
                    <Link className="item" id="h-routes" to="/Route">Маршруты</Link>
                </div>
                <div className="h-profil-link">
                    <Link className="item" id="h-buy" to="/Shop">Магазин</Link>
                </div>
                <div className="h-profil-link">
                    <Link className="item" id="h-plata" to="/Purchases">История платежей</Link>
                </div>
                <div className="h-profil-link">
                    <Link className="item" id="h-settings" to="/Settings">Настройки</Link>
                </div>
                <div className="h-profil-link">
                    <div className="item left-padding-h-profile" id="h-lingvo">Язык: Русский</div>
                </div>
                <div className="h-profil-link">
                    <Link className="item" id="h-help" to="/PremiumBuy">Справка</Link>
                </div>
                {
                    AuthorizationStore.user.Model.isAdmin &&
                    <div className="h-profil-link">
                        <Link className="item" id="administration" to="/Administration">Админка</Link>
                    </div>
                }
                <div className="h-profil-link">
                    <div className="item left-padding-h-profile" id="h-exit" onClick={AuthorizationStore.logOut}>Выход</div>
                </div>
            </ul>
        </div>;
    }
}