import {makeAutoObservable} from "mobx";
import {AnswerTrainingBaseStore} from "../store/AnswerTrainingBaseStore";


export class AnswerTrainingStore{
    timer: NodeJS.Timer | undefined = undefined;
    store: AnswerTrainingBaseStore | undefined;
    currentTimer: number = 3;
    constructor(store: AnswerTrainingBaseStore | undefined) {
        makeAutoObservable(this, {}, {autoBind: true});
        this.store = store;
    }

    init(){
        this.store?.init();
        this.timer = setInterval(this.updateCurrentTime,
            1000);
    }

    onFinish() {
        if (this.currentTimer <= 0)
            this.store!.onFinish()
    }

    updateCurrentTime(){
        this.currentTimer--;
        if (this.currentTimer <= 0){
            if (this.timer)
                clearInterval(this.timer);
        }
    }

    unInit(){
        this.store?.unInit();
        if (this.timer)
            clearInterval(this.timer);
    }

    get textWatchRes(): string{
        if (this.currentTimer <= 0){
            return "Проверить";
        }
        return this.currentTimer.toString();
    }
}