import {CustomComponent} from "./CustomComponent";
import './customButton.scss';

interface Props {
    text: string,
    onclick?: () => void;
    className?: string;
}

export class CustomButton extends CustomComponent<Props, {}> {
    render() {
        return <div className={"dialog-form-button " + this.props.className} onClick={this.props.onclick}>
            {this.props.text}
        </div>;
    }
}