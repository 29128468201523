import {observer} from "mobx-react";
import './universityTrainingPage.scss';
import {CustomComponent} from "../../helpers/CustomComponent";
import React from "react";
import TitleStore from "../../components/authorization/store/TitleStore";
import {
    UniversityTrainingState,
    UniversityTrainingStore
} from "../../components/universityTraining/UniversityTrainingStore";
import {MatryoshkaComponent} from "../../components/university/matryoshka/MatryoshkaComponent";
import {RecommendationComponent} from "../../components/universityTraining/recommendations/RecommendationComponent";
import {
    FinishUniversityTrainingComponent
} from "../../components/universityTraining/finish/FinishUniversityTrainingComponent";


interface Props {
}

@observer
export class UniversityTrainingPage extends CustomComponent<Props, {}> {
    store: UniversityTrainingStore;

    constructor(props: Readonly<Props>) {
        super(props);
        this.store = new UniversityTrainingStore();
    }

    componentDidMount() {
        TitleStore.setTitle("Университет тренировка");
        let trainingId = window.location.pathname.replace("/universityTraining/", '');
        this.store.init(trainingId);
    }

    componentWillUnmount() {
        this.store.unInit();
    }

    render() {
        return <div className="university-training-page">
            {
                this.store.model.Model &&
                this.store.state === UniversityTrainingState.recommendations &&
                <RecommendationComponent models={this.store.model.Model.recommendations}
                                         finish={this.store.finishRecommendation} />
            }
            {
                this.store.model.Model &&
                this.store.state === UniversityTrainingState.training &&
                <MatryoshkaComponent model={this.store.getOrCreateMatryoshka()}
                                     finish={this.store.finishTraining}/>
            }
            {
                this.store.finishModel.Model &&
                this.store.state === UniversityTrainingState.finish &&
                <FinishUniversityTrainingComponent model={this.store.finishModel.Model!} />
            }
        </div>;
    }
}