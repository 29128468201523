import React, {Component} from 'react'
import './trainingCodingWriteComponent.scss'
import {observer} from "mobx-react";
import TrainingSportCodingWriteStore from "./store/TrainingSportCodingWriteStore";
import {CodingMemberModel, CodingRequestMemberModel} from "./model/CodingMemberModel";
import StopwatchService from "../../helpers/StopwatchService";
import AuthorizationStore from "../authorization/store/AuthorizationStore";
import {CustomButton} from "../../helpers/CustomButton";
import Modal from "../modal/ReactModal";
import {ReactModalStore} from "../modal/ReactModalStore";

interface Props {
    finishTrainingRequest: (model: CodingRequestMemberModel) => void,
    model: CodingMemberModel
}

@observer
export class TrainingCodingWriteComponent extends Component<Props, {}> {
    private readonly reactModalStore: ReactModalStore = new ReactModalStore();
    store: TrainingSportCodingWriteStore;

    constructor(props: Readonly<Props>) {
        super(props);
        this.store = new TrainingSportCodingWriteStore(this.props.finishTrainingRequest, this.props.model);
        this.closeTraining = this.closeTraining.bind(this);
    }

    componentDidMount() {
        AuthorizationStore.setShowLayoutHead(false);
        StopwatchService.start();
        this.store.init();
        this.reactModalStore.init();
    }
    componentWillUnmount() {
        this.store.unmount();
        this.reactModalStore.unInit();
        StopwatchService.clearTimer()
        AuthorizationStore.setShowLayoutHead(true);
    }

    closeTraining(){
        window.location.reload();
    }

    render() {
        return (
            <div className="coding-body">
                <Modal store={this.reactModalStore}>
                    <div id="dialog-form" title="Закрыть">
                        <p><span className="ui-icon ui-icon-alert"
                                 style={{float: 'left', margin: '12px 12px 20px 0'}}>
                        </span>Вы уверены что хотите завершить тренировку?</p>
                        <div className="dialog-form-block-buttons">
                            <CustomButton text="Нет" onclick={this.reactModalStore.close} />
                            <CustomButton text="Закрыть тренировку" onclick={this.closeTraining} />
                            {
                                ((this.store?.numberElNow ?? 0) >= 9) &&
                                <CustomButton text="Показ результатов" onclick={this.store.fastFinishTrainingClick} />
                            }
                        </div>
                    </div>
                </Modal>

                <header id="header-training">
                    <div id="header-training-box">
                        <div id="show-me-the-code" onClick={this.store.clickHint} title="Посмотреть образ"></div>
                        <div id="timer-abcd">{StopwatchService.currentTimeStr}</div>
                        <div id="f-finish" onClick={this.reactModalStore.open} title="Закрыть тренировку"></div>
                    </div>
                </header>
                <div className="step1">
                    <div className="do-otr">
                        {this.store.view}

                        <div className="nextback">
                            {
                                (this.store.model.styleCoding === 'XClean' || this.store.model.styleCoding === 'imageClean') &&
                                <>
                                    <input id="back" type="button"/>
                                    <label className="back-label" htmlFor="back" onClick={this.store.leftOrBack}></label>
                                    <input id="next" type="button"/>
                                    <label className="next-label" htmlFor="next" onClick={this.store.rightOrNext}></label>
                                </>
                            }
                            {
                                (this.store.model.styleCoding === 'X2' || this.store.model.styleCoding === 'image2') &&
                                <>
                                    <input id="back" type="button"/>
                                    <label className="back-label back-label-l" style={{backgroundImage: 'none'}}
                                           onClick={this.store.leftOrBack}
                                           htmlFor="back">L</label>
                                    <input id="next" type="button"/>
                                    <label className="next-label next-label-r" style={{backgroundImage: 'none'}}
                                           onClick={this.store.rightOrNext}
                                           htmlFor="next">R</label>
                                </>
                            }
                            <span className="position-info coding-position-info">{this.store.positionInfo}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}