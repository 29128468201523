import {makeAutoObservable} from "mobx";
import React from "react";
import {Loading} from "../../shared/Loading";
import {
    AdjectiveTrainingItemType,
    TrainingMemoryRussianMemberItemRequestViewModel,
    TrainingMemoryRussianMemberItemViewModel,
    TrainingMemoryRussianMemberViewModel
} from "../model/LearnLanguageMemberModel";
import {LanguageMemberTransportFunctionModel} from "../model/LanguageMemberTransportFunctionModel";
import {MemberAdjectiveComponentProps} from "./trainingTypes/MemberAdjectiveComponent";
import {PropsTrainingType} from "./trainingTypes/PropsTrainingType";
import {SelectAnswerAdjectiveComponent} from "./trainingTypes/SelectAnswerAdjectiveComponent";
import {NameAdjectiveComponent} from "./trainingTypes/NameAdjectiveComponent";
import HeaderTrainingLearnLanguageStore from "./HeaderTrainingLearnLanguageStore";
import {ImageAdjectiveComponent} from "./trainingTypes/ImageAdjectiveComponent";
import {AudioAdjectiveComponent} from "./trainingTypes/AudioAdjectiveComponent";
import {MemberEnglishAdjectiveComponent} from "./trainingTypes/MemberEnglishAdjectiveComponent";
import {WriteEnglishAdjectiveComponent} from "./trainingTypes/WriteEnglishAdjectiveComponent";
import {
    WriteOnlyAudioWithoutImageAdjectiveComponent
} from "./trainingTypes/WriteOnlyAudioWithoutImageAdjectiveComponent";
import {ReadTitleAdjectiveComponent} from "./trainingTypes/ReadTitleAdjectiveComponent";
import {AudioRecognitionAdjectiveComponent} from "./trainingTypes/AudioRecognitionAdjectiveComponent";
import {SpeakerAdjectiveComponent} from "./trainingTypes/SpeakerAdjectiveComponent";


export default class TrainingLearnLanguageMemberStore {
    model: TrainingMemoryRussianMemberViewModel
    itemsRequest: TrainingMemoryRussianMemberItemRequestViewModel[] = [];
    countElementsOneTime: number = 1;
    numberElNow: number = 0;
    elementAtGo: number = 1;
    lastDate: Date;
    transportFunction: LanguageMemberTransportFunctionModel

    timer: NodeJS.Timer | undefined = undefined;

    timerAnswer: NodeJS.Timer | undefined = undefined;


    setModel(model: TrainingMemoryRussianMemberViewModel) {
        this.model = model;
    }

    constructor(transportFunction: LanguageMemberTransportFunctionModel,
                model: TrainingMemoryRussianMemberViewModel) {
        makeAutoObservable(this, {}, {autoBind: true});
        this.transportFunction = transportFunction;
        this.model = model;
        this.fillingItems();
        this.lastDate = new Date();
    }

    private fillingItems() {
        for (let i = this.itemsRequest.length; i < this.model.items.length; i++) {
            this.itemsRequest.push({
                id: this.model.items[i].id,
                timeElement: 0,
                answer: "",
                tryCount: 0
            })
        }
    }

    init() {
    }

    unmount() {
    }

    next(item: TrainingMemoryRussianMemberItemRequestViewModel) {
        if (this.timerAnswer)
            clearInterval(this.timerAnswer);
        if (this.numberElNow < 0) return;
        this.itemsRequest[this.numberElNow] = item;
        let firsStart = true;
        let elDate = this.numberElNow;
        let countElementNow = this.countElementsOneTime;
        for (; elDate % this.countElementsOneTime !== 0; elDate++) {
            countElementNow--;
        }
        for (; elDate < this.itemsRequest.length &&
               (elDate % this.countElementsOneTime !== 0 || firsStart === true); elDate++) {
            firsStart = false;
            if (!this.itemsRequest[elDate].timeElement)
                this.itemsRequest[elDate].timeElement = 0;
            this.itemsRequest[elDate].timeElement += (new Date().getTime() - this.lastDate.getTime()) / countElementNow;
        }
        this.numberElNow += this.countElementsOneTime;
        HeaderTrainingLearnLanguageStore.setNumberElNow(this.numberElNow);
        this.lastDate = new Date();

        if (this.numberElNow >= this.itemsRequest.length) {
            if (this.model.isAfterFinish) {
                this.resultTrainingClick();
            }
            this.continueTrainingClick();
        }
    }

    back(item: TrainingMemoryRussianMemberItemRequestViewModel): void {
        if (this.timerAnswer)
            clearInterval(this.timerAnswer);
        if (this.numberElNow <= 0) {
            return;
        }
        this.itemsRequest[this.numberElNow] = item;
        let firsStart = true;
        let elm1 = this.numberElNow;
        let countNow = 0;//Время
        for (; elm1 % this.countElementsOneTime !== 0 || firsStart === true; elm1--) {
            firsStart = false;
            countNow++;
        }
        firsStart = true;
        for (; elm1 < this.itemsRequest.length &&
               (elm1 % this.countElementsOneTime !== 0 || firsStart === true); elm1++) {
            firsStart = false;
            if (!this.itemsRequest[elm1].timeElement)
                this.itemsRequest[elm1].timeElement = 0;
            this.itemsRequest[elm1].timeElement += (new Date().getTime() - this.lastDate.getTime()) / countNow;
        }
        //Показ все остальное
        this.numberElNow = this.numberElNow - countNow;
        HeaderTrainingLearnLanguageStore.setNumberElNow(this.numberElNow);
        this.lastDate = new Date();
    }

    private currentItem(): TrainingMemoryRussianMemberItemViewModel {
        return this.model.items[this.numberElNow];
    }

    get view(): JSX.Element {
        if (this.itemsRequest.length <= this.numberElNow)
            return <Loading/>
        let model = this.currentItem();
        let props: PropsTrainingType = {
            currentItem: model,
            item: this.itemsRequest[this.numberElNow],
            next: this.next,
            back: this.back,
            positionInfo: this.positionInfo,
        }
        if (model.itemType === AdjectiveTrainingItemType.Member) {
            return <MemberAdjectiveComponentProps key={"member_answer" + model.id} {...props} />
        }
        if (model.itemType === AdjectiveTrainingItemType.SelectAnswer) {
            return <SelectAnswerAdjectiveComponent key={"select_answer_" + model.id} {...props} />
        }
        if (model.itemType === AdjectiveTrainingItemType.Name) {
            return <NameAdjectiveComponent key={"name_" + model.id} {...props} />
        }
        if (model.itemType === AdjectiveTrainingItemType.Image) {
            return <ImageAdjectiveComponent key={"image_" + model.id} {...props} />
        }
        if (model.itemType === AdjectiveTrainingItemType.Audio) {
            return <AudioAdjectiveComponent key={"image_" + model.id} {...props}/>
        }
        if (model.itemType === AdjectiveTrainingItemType.WriteTitleWithRussianWord ||
            model.itemType === AdjectiveTrainingItemType.WriteEnglishWithEnglishWord) {
            return <WriteEnglishAdjectiveComponent key={"write_english_" + model.id} {...props} />
        }
        if (model.itemType === AdjectiveTrainingItemType.MemberEnglishAndAudio) {
            return <MemberEnglishAdjectiveComponent key={"member_english_" + model.id} {...props}/>
        }
        if (model.itemType === AdjectiveTrainingItemType.WriteOnlyAudioWithoutImage) {
            return <WriteOnlyAudioWithoutImageAdjectiveComponent
                key={"write_only_audio_image" + model.id} {...props}/>
        }
        if (model.itemType === AdjectiveTrainingItemType.ReadEngToRus) {
            return <ReadTitleAdjectiveComponent
                key={"write_only_audio_image" + model.id} {...props}/>
        }
        if (model.itemType === AdjectiveTrainingItemType.AudioRecognition) {
            return <AudioRecognitionAdjectiveComponent
                key={"write_only_audio_image" + model.id} {...props}/>
        }
        if (model.itemType === AdjectiveTrainingItemType.Speak) {
            return <SpeakerAdjectiveComponent
                key={"write_only_audio_image" + model.id} {...props}/>
        }
        return <></>;
    }

    get positionInfo(): string {
        return Math.ceil(this.numberElNow / this.countElementsOneTime) + 1 +
            " / " + Math.ceil(this.itemsRequest.length / this.countElementsOneTime);
    }

    resultTrainingClick() {
        this.transportFunction.resultTraining({
            id: this.model.id,
            items: this.itemsRequest
        });
    }

    continueTrainingClick() {
        this.transportFunction.continueTraining({
            id: this.model.id,
            items: this.itemsRequest
        }).then(x => {
            let model = x.Model;
            if (x.Model !== null) {
                this.setModel(model!);
                this.fillingItems();
            }
        });
    }

    finishTrainingClick() {
        this.transportFunction.finishTraining({
            id: this.model.id,
        });
    }
}