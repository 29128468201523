import {CustomComponent} from "../../../helpers/CustomComponent";
import './matryoshkaComponent.scss'
import React from "react";
import {BlockTextComponent} from "../../../helpers/blockText/BlockTextComponent";
import {MatryoshkaStore} from "./MatryoshkaStore";
import {TitleComponent, TypePositionText, TypeTitle} from "../../../helpers/title/TitleComponent"
import {BottomBlockComponent} from "../../../helpers/bottomBlock/BottomBlockComponent";
import {MatryoshkaItemComponent} from "./MatryoshkaItemComponent";
import {observer} from "mobx-react";
import {MatryoshkaCheckItemComponent} from "./MatryoshkaCheckItemComponent";
import {MatryoshkaModel, MatryoshkaState} from "./MatryoshkaModel";
import {CheckAdjectiveComponent} from "../../../helpers/nextComponent/CheckAdjectiveComponent";

interface Props {
    model: MatryoshkaModel;
    finish: (model: MatryoshkaModel) => void;
}

@observer
export class MatryoshkaComponent extends CustomComponent<Props, any> {
    private store: MatryoshkaStore;

    constructor(props: Readonly<Props>) {
        super(props);
        this.store = new MatryoshkaStore(this.props.model, this.props.finish);
    }

    render() {
        return <div className={"matryoshka_component"}>
            {
                this.store.state === MatryoshkaState.remember &&
                <>
                    <BlockTextComponent hasBackground={true} className={"block-main-component"}>
                        <TitleComponent typeTitle={TypeTitle.homeTaskDescription}
                                        text={this.store.model.items.map(x => !x.hjighlight
                                            ? x.word// todo сделать подчеркивание
                                            : x.word).join(", ")}/>
                    </BlockTextComponent>
                    <BottomBlockComponent className={"component_middle-block"}>
                        <CheckAdjectiveComponent text={"Закончить"} onClick={this.store.nextStep} positionInfo={""}/>
                    </BottomBlockComponent>
                </>
            }
            {
                this.store.state === MatryoshkaState.writeAnswer &&
                <>
                    <TitleComponent typeTitle={TypeTitle.homeWorkTitle}
                                    typePosition={TypePositionText.center}
                                    text={"Вспомните слова по образам на мысленном экране соблюдая " +
                                        "главный энергосейв этап припоминания БП + У"}/>
                    <BlockTextComponent className={"block-write_answer-component"} withoutBlockContent={true}>
                        {
                            this.store.model.items.map((x, index) =>
                                <MatryoshkaItemComponent key={x.id}
                                                         model={x}
                                                         position={index}
                                                         onChange={this.store.onChange}
                                                         clickHint={this.store.clickHint}
                                />)
                        }
                    </BlockTextComponent>
                    <BottomBlockComponent className={"component_middle-block"}>
                        <CheckAdjectiveComponent text={"Проверить"} onClick={this.store.nextStep} positionInfo={""}/>
                    </BottomBlockComponent>
                </>
            }
            {
                this.store.state === MatryoshkaState.checkAnswer &&
                <>
                    <TitleComponent typeTitle={TypeTitle.homeWorkTitle}
                                    typePosition={TypePositionText.center}
                                    text={"Усильте слабые и не созданные связи"}/>
                    <BlockTextComponent className={"block-write_answer-component"}
                                        withoutBlockContent={true}>
                        {
                            this.store.model.items.map((x, index) =>
                                <MatryoshkaCheckItemComponent key={x.id}
                                                              model={x}
                                                              position={index}/>)
                        }
                    </BlockTextComponent>
                    <BottomBlockComponent className={"component_middle-block"}>
                        <CheckAdjectiveComponent text={"Далее"} onClick={this.store.nextStep} positionInfo={""}/>
                    </BottomBlockComponent>
                </>
            }
        </div>;
    }
}