import React, {Component, MouseEventHandler} from 'react'
import './trainingPage.scss'
import {observer} from "mobx-react";
import TitleStore from "../../components/authorization/store/TitleStore";
import {Link} from "react-router-dom";
import AuthorizationStore from "../../components/authorization/store/AuthorizationStore";

interface Props{

}

@observer
export class TrainingPage extends Component<Props, {}> {

    constructor(props: Readonly<Props>) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        AuthorizationStore.setShowModalBalls(false);
        TitleStore.setTitle("Выбор тренировки");
    }

    onClick(e: MouseEvent<HTMLAnchorElement, MouseEvent>){
        if (!AuthorizationStore.checkAuthMenu){
            e.preventDefault();
            AuthorizationStore.openModalAuth()
        }
    }

    render() {
        return (
            <div id="training-nav-blocks">

                {
                    AuthorizationStore.user.Model?.isAdmin === true &&
                    <Link className="nav-t-block" to={"/Training/Base"} onClick={this.onClick}>
                        <h2 className="h2-nt">Базовые</h2>
                        <div className="t-nav-image" id="t-nav-image1"></div>
                        <p className="nav-t-p">Начальная тренировка управления памятью с функцией доведения до безошибочного
                            припоминания</p>
                    </Link>
                }

                <Link className="nav-t-block" to={"/Training/Sport"} onClick={this.onClick}>
                    <h2 className="h2-nt">Спортивные</h2>
                    <div className="t-nav-image" id="t-nav-image2"></div>
                    <p className="nav-t-p">Тренировка скорости и выносливости запоминания, для формирования навыка и
                        подготовка к чемпионатам по запоминанию</p>
                </Link>
                <Link className="nav-t-block" to={"/Training/summingUp"} onClick={this.onClick}>
                    <h2 className="h2-nt">Подводящие</h2>
                    <div className="t-nav-image" id="t-nav-image3"></div>
                    <p className="nav-t-p">Тренировка кодирования различной информации, а также тренировка темпа
                        перехода по стимульным системам</p>
                </Link>
                {
                    AuthorizationStore.user.Model?.isAdmin === true &&
                    <Link className="nav-t-block" to={"/Training/LearnLanguage"} onClick={this.onClick}>
                        <h2 className="h2-nt">Иностранные слова</h2>
                        <div className="t-nav-image" id="t-nav-image4"></div>
                        <p className="nav-t-p">Тренажёр для запоминания от 400 иностранных слов в неделю по методу
                            Алекса
                            Грука</p>
                    </Link>
                }
            </div>
        )
    }
}
