import React, {ChangeEvent, useEffect, useState} from "react";
import './inputSearchCustom.scss'
import useThrottledValue from "../../../helpers/useThrottledValue";


interface Props {
    changeAndRequest: (val: string) => void;
}

export const InputSearchCustom = (props: Props) => {
    const [query, setQuery] = useState('');
    // const use
    const throttledValue = useThrottledValue({
        value: query,
        throttleMs: 1500
    });

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
    }

    useEffect(() => {
        props.changeAndRequest(throttledValue);
    }, [throttledValue]);

    return <div className="input-search-custom">
        {/*// onChange={this.changeName}*/}
        <input type="text" value={query} onChange={onChange} className="search-input" placeholder="Поиск"/>
        <label className="search-input-label" htmlFor="search-input"></label>
    </div>
}