import {observer} from "mobx-react";
import {CustomComponent} from "../../helpers/CustomComponent";
import {CheckScreen, TypeScreen} from "../../context/TypeScreen";
import Modal from "../modal/ReactModal";
import {AuthorizationMenu} from "./AuthorizationMenu";
import React from "react";
import AuthorizationStore from "./store/AuthorizationStore";


@observer
export class AuthorizationModal extends CustomComponent<{}, {}> {

    componentDidMount() {
        AuthorizationStore.reactModalStore.init()
    }
    componentWillUnmount() {
        AuthorizationStore.reactModalStore.unInit()
    }

    render() {
        return <div className="authorization-block">
            {
                CheckScreen([TypeScreen.Desktop, TypeScreen.Tablet]) &&
                <div className="authorize-label">
                    <h1 id="login" onClick={AuthorizationStore.reactModalStore.open}>Вход</h1>
                </div>
            }

            <Modal store={AuthorizationStore.reactModalStore}>
                <AuthorizationMenu/>
            </Modal>
        </div>;
    }
}