import React, {Component} from 'react'
import './trainingCodingPage.scss'
import {observer} from "mobx-react";
import {TrainingCodingComponent} from "../../components/trainingCoding/TrainingCodingComponent";
import TrainingSportStore, {StateTrainingTrainSport} from "../../components/trainingCoding/store/TrainingSportStore";
import {TrainingCodingWriteComponent} from "../../components/trainingCoding/TrainingCodingWriteComponent";
import {TrainingCodingResultComponent} from "../../components/trainingCoding/TrainingCodingResultComponent";
import TitleStore from "../../components/authorization/store/TitleStore";


@observer
export class TrainingCodingPage extends Component<{}, {}> {
    store: TrainingSportStore;

    constructor(props: Readonly<{}>) {
        super(props);
        this.store = new TrainingSportStore();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        TitleStore.setTitle("Тренировки кодирования");
        // this.store.memberRequest()
    }

    render() {
        // if (this.store.state === StateTrainingTrainBase.member && !this.store.storeMember)
        //     return <Loading/>
        // if (this.store.state === StateTrainingTrainBase.answer && !this.store.storeAnswer)
        //     return <Loading/>
        // if (this.store.state === StateTrainingTrainBase.result && !this.store.resultModel.IsLoadedModel())
        //     return <Loading/>
        return (
            <>
                {this.store.state === StateTrainingTrainSport.base &&
                    <TrainingCodingComponent startTraining={this.store.startTrainingRequest}/>
                }
                {this.store.state === StateTrainingTrainSport.write &&
                    <TrainingCodingWriteComponent model={this.store.modelWrite.Model!} finishTrainingRequest={this.store.finishTrainingRequest} />
                }
                {this.store.state === StateTrainingTrainSport.result &&
                    <TrainingCodingResultComponent model={this.store.modelResult.Model!} />
                }
            </>
        )
    }
}
