import {ResultMax} from "../models/MnemocardView";
import {makeAutoObservable, observable} from "mobx";
import {SelectorPros} from "../../shared/selector/CustomSelector";
import {TrainingMode} from "../../../helpers/trainingHelp/TrainingMode";


export class MnemocardRecordStore{
    @observable
    results: ResultMax[];
    @observable
    dis: string;

    public selectors: SelectorPros[] = [
        {value:"A",title:"Дистанция A ≤1 мин" },
        {value:"B",title:"Дистанция B 5 мин" },
        {value:"C",title:"Дистанция C 10 мин" },
        {value:"D",title:"Дистанция D 20 мин" },
        {value:"0",title:"Лучший результат" },
    ];

    constructor(result: ResultMax[]) {
        makeAutoObservable(this, {}, {autoBind: true})
        this.results = result;
        this.dis = '0';
    }

    training(mode: TrainingMode) : ResultMax | undefined{
        let res = this.results.filter(x => x.mode === mode &&
            (x.dis === this.dis || this.dis === '0')).sort((x,y) => y.index - x.index);
        if (res.length === 0)
            return undefined;
        return res[0];
    }


    changeDis(dis: string){
        this.dis = dis;
    }
}