import {observer} from "mobx-react";
import {CustomComponent} from "../../../../../helpers/CustomComponent";

export interface RouteRankProps{
    numberPosition: number;
    needCount: number | undefined;
    count: number
}
@observer
export class RouteRank extends CustomComponent<RouteRankProps, {}> {

    get percent(): string{
        if (!this.props.needCount)
            return '';
        return this.props.count > this.props.needCount ?
            '100' :
            (this.props.count/this.props.needCount*100).toFixed(2);
    }
    render() {
        return (<div className="exercise-box">
            <div className="ex-block-title">
                <div className="ex-name">{this.props.numberPosition}. Увеличьте стимульную систему</div>
                <div className="ex-info-question"></div>
            </div>
            <div className="ex-info-box">Расширьте маршрутную стимульную систему (метод Цицерона) до необходимого
                количества мест. Сделать это можно на странице "Маршруты".
            </div>
            {this.props.count >= this.props.needCount! &&
                <div className="exercise-complicate">Созданно {this.props.count} локаций из {this.props.needCount}</div>
            }
            {this.props.count < this.props.needCount! &&
                <div className="ex-line-box">
                    <div className="ex-greenline">
                        <div className="greenline-active" id="greenline-loci" style={{'width': this.percent+'%'}}></div>
                    </div>
                    <div className="exgl-quantity">
                        <div className="quantity-now">{this.props.count} локаций ({this.percent}%)</div>
                        <div className="quantity-need">{this.props.needCount}</div>
                    </div>
                </div>
            }
        </div>);
    }
}