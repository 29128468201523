import {CustomComponent} from "../../../helpers/CustomComponent";
import {SelectorPros} from "../../shared/selector/CustomSelector";
import {GetRequest} from "../../../helpers/Request";
import {LoadingModel} from "../../shared/LoadingModel";
import SelectorWithText from "../../shared/selector/SelectorWithText";
import React from "react";
import {makeAutoObservable} from "mobx";
import {observer} from "mobx-react";


interface Props {
    value: number
    onChange?(sel: SelectorPros | undefined): void;
}

@observer
export class LanguageSelectorLanguageComponent extends CustomComponent<Props, any>{
    store : LanguageSelectorStore = new LanguageSelectorStore();

    componentDidMount() {
        this.store.init()
    }

    componentWillUnmount() {

    }

    render() {
        return <SelectorWithText value={this.props.value?.toString() ?? ""}
                                 text="Выберите язык"
                                 values={this.store.getValues}
                                 width="225px"
                                 onChange={this.props.onChange}/>
    }
}

class LanguageSelectorStore{
    model : LoadingModel<LanguageModel[]> = LoadingModel.createNotLoaded();

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true})
    }

    get getValues(): SelectorPros[] {
        if (!this.model.Model)
            return [];
        return this.model.Model?.map(x => {
            return {
                value: x.id.toString(),
                title: x.name
            }
        }) ?? [];
    }

    init(){
        this.request();
    }

    setModel(model: LoadingModel<LanguageModel[]>){
        this.model = model;
    }

    request(){
        GetRequest<LanguageModel[]>('/api/LearnLanguage/GetAudio')
            .then(x => this.setModel(x))
    }

}

interface LanguageModel{
    id: bigint;
    name: string;
    languageType: LanguageType;
    languageTypeStr: string;
}
enum LanguageType{
    rus = 0,
    eng = 1,
}