import {CustomComponent} from "../CustomComponent";
import './middleTextComponent.scss'

interface Props {
    children?: React.ReactNode
}

export class MiddleTextComponent extends CustomComponent<Props, any> {

    render() {
        return <div className="middle-text-component">
            <div className="middle-text_text">
                {this.props.children}
            </div>
        </div>;
    }
}