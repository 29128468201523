import {makeAutoObservable} from "mobx";
import {TrainingMemberRequestItemModel, TrainingMemberRequestModel} from "../model/TrainingMemberRequestModel";
import {PostRequest} from "../../../helpers/Request";
import {MemberTrainingBaseStore} from "./MemberTrainingBaseStore";
import {TrainingAnswerModel} from "../model/TrainingAnswerModel";
import {AnswerTrainingBaseStore} from "./AnswerTrainingBaseStore";
import {LoadingModel} from "../../shared/LoadingModel";
import {TrainingResultModel} from "../../trainingResult/model/TrainingResultModel";
import {TrainingAnswerRequestItemModel, TrainingAnswerRequestModel} from "../model/TrainingAnswerRequestModel";
import {TrainingMemberModel} from "../model/TrainingMemberModel";
import {TrainingModelCreateTraining} from "../../training/model/TrainingModel";


export enum StateTrainingTrainBase {
    'base' = 0,
    'loadingImage' = 1,
    'member' = 2,
    'answer' = 3,
    'result' = 4,
}

export default class TrainingMainBaseStore {
    id: string | undefined;
    state: StateTrainingTrainBase;
    storeMember: MemberTrainingBaseStore | undefined;
    storeAnswer: AnswerTrainingBaseStore | undefined;
    resultModel: LoadingModel<TrainingResultModel> = LoadingModel.createNotLoaded();

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true});
        this.state = StateTrainingTrainBase.base;
    }

    memberRequest(model: TrainingModelCreateTraining): void {
        this.state = StateTrainingTrainBase.member;
        PostRequest<TrainingMemberModel, TrainingModelCreateTraining>('/TrainingsDefaultCustom/TrainingPostResponse', model)
            .then(x => this.setMemberModel(x))
    }

    setMemberModel(model: LoadingModel<TrainingMemberModel>) {
        this.id = model.Model!.id;
        this.storeMember = new MemberTrainingBaseStore(model.Model!, this.answerRequest)
    }

    answerRequest(items: TrainingMemberRequestItemModel[]): void {
        this.state = StateTrainingTrainBase.answer;
        PostRequest<TrainingAnswerModel, TrainingMemberRequestModel>('/TrainingsDefaultCustom/TrainingAbstrationMember',
            {
                id: this.id!,
                members: items
            })
            .then(x => this.setAnswerModel(x))
    }

    resultRequest(items: TrainingAnswerRequestItemModel[], timeRecollection: number): void {
        this.state = StateTrainingTrainBase.result;
        PostRequest<TrainingResultModel, TrainingAnswerRequestModel>('/TrainingsDefaultCustom/TrainingResult',
            //{"answers":[{"answer":"12","number":0},{"answer":"31","number":1},{"answer":"31","number":2},{"answer":"31","number":3},{"answer":"23","number":4},{"answer":"13","number":5},{"answer":"21","number":6},{"answer":"31","number":7},{"answer":"23","number":8},{"answer":"12","number":9},{"answer":"2","number":10},{"answer":"","number":11},{"answer":"","number":12},{"answer":"","number":13},{"answer":"","number":14},{"answer":"","number":15},{"answer":"","number":16},{"answer":"","number":17},{"answer":"","number":18},{"answer":"","number":19},{"answer":"","number":20},{"answer":"","number":21},{"answer":"","number":22},{"answer":"","number":23},{"answer":"","number":24},{"answer":"","number":25},{"answer":"","number":26},{"answer":"","number":27},{"answer":"","number":28},{"answer":"","number":29},{"answer":"","number":30},{"answer":"","number":31},{"answer":"","number":32},{"answer":"","number":33},{"answer":"","number":34},{"answer":"","number":35},{"answer":"","number":36},{"answer":"","number":37},{"answer":"","number":38},{"answer":"","number":39}],"timeRecollection":4943,"id":"9e15b0fe-eba3-4845-b3ce-4320ded28cbe"})
            {
                id: this.id!,
                answers: items,
                timeRecollection: timeRecollection
            })
            .then(x => this.setResultModel(x))
    }

    setAnswerModel(model: LoadingModel<TrainingAnswerModel>) {
        this.storeAnswer = new AnswerTrainingBaseStore(model.Model!, this.resultRequest);
    }

    setResultModel(model: LoadingModel<TrainingResultModel>) {
        this.resultModel = model;
    }
}