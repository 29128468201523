import {makeAutoObservable} from "mobx";
import LoadingStore from "../../../../helpers/LoadingStore";
import {VolumeEnduranceModel} from "../volumeEndurance/VolumeEnduranceModel";
import {SelectorPros} from "../../../shared/selector/CustomSelector";
import {EnglishWordsViewModel} from "./EnglishWordsViewModel";

export class EnglishWordsStore {

    public selector: SelectorPros;
    public loadingStore: LoadingStore<EnglishWordsViewModel[]>;
    public selectors: SelectorPros[] = [
        {value: "1", title: "Глаголы"},
    ];

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true});
        this.selector = this.selectors[0];
        this.loadingStore = new LoadingStore<EnglishWordsViewModel[]>(this.getUrl)
    }

    getUrl(): string {
        return '/api/Statistic/UsersTypeEng?type='+this.selector?.value;
    }
    setSelector(sel: SelectorPros) {
        this.selector = sel;
    }
}
