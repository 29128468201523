import {CustomComponent} from "../../../helpers/CustomComponent";
import React from "react";
import TrainingLearnLanguageBaseStore from "../store/TrainingLearnLanguageBaseStore";
import {observer} from "mobx-react";
import {TypeTrainingCodingComponent} from "../../trainingCoding/components/TypeTrainingCodingComponent";


interface Props {
    store: TrainingLearnLanguageBaseStore
}

@observer
export class MainLearnLanguageStepsComponent extends CustomComponent<Props, {}> {

    constructor(props: Readonly<Props>) {
        super(props);
        this.click = this.click.bind(this);
    }

    click(step: string) {
        this.props.store.setStep(step)
    }

    render() {
        return <div id="t-coding" className="learn-language-container">
            <div id="handler-coding" style={{maxWidth: '310px', flexWrap: 'wrap'}}>
                {
                    this.props.store.currentPackage?.steps.map((x, i) =>
                        <TypeTrainingCodingComponent
                            key={x.id}
                            text={x.title}
                            type={x.step?.toString() || ""}
                            center={{image: x.image, text: x.bigText}}
                            click={this.click}
                            currentSelect={this.props.store.requestModel.step.toString()}  />
                    )
                }
            </div>
        </div>;
    }
}

