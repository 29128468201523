import {observer} from "mobx-react";
import './homePage.scss';
import {CustomComponent} from "../../helpers/CustomComponent";
import {Route, Routes} from "react-router-dom";
import React, {Suspense} from "react";
import {HomeWorkStore} from "../../components/homeWork/store/HomeWorkStore";
import TitleStore from "../../components/authorization/store/TitleStore";
import {NavBurger} from "../../helpers/navburger/NavBurger";
import {NavBarCollectionModel} from "../../helpers/navburger/NavBarModel";
import {Loading} from "../../components/shared/Loading";
import {TestLesson} from "../../components/homeWork/component/TestLesson";
import {Goals1} from "../../components/homeWork/component/lesson1/Goals1";
import {LessonDay1} from "../../components/homeWork/component/lesson1/Lesson1";


interface Props {
}

@observer
export class HomeWorkPage extends CustomComponent<Props, {}> {
    private store: HomeWorkStore = new HomeWorkStore();
    navBugrers: NavBarCollectionModel[] = [
        {
            name: "Модуль 1",
            models: [
                {
                    name: "Цели",
                    toLink: "goal",
                },
                {
                    name: "День 1",
                    toLink: "day1",
                },
            ]
        },
    ]

    componentDidMount() {
        TitleStore.setTitle("Домашнаяя работа.");
        this.store.init();
    }

    componentWillUnmount() {
        this.store.unInit();
    }

    render() {
        return <div className="home-work-page">
            <NavBurger models={this.navBugrers}/>

            <div className="home-work-container">
                <Routes>
                    <Route path="test" element={<Suspense fallback={<Loading/>}>
                        <TestLesson/>
                    </Suspense>}/>
                    <Route path="goal" element={<Suspense fallback={<Loading/>}>
                        <Goals1/>
                    </Suspense>}/>
                    <Route path="day1" element={<Suspense fallback={<Loading/>}>
                        <LessonDay1/>
                    </Suspense>}/>
                    <Route path="*" element={<Suspense fallback={<Loading/>}>
                        <Goals1/>
                    </Suspense>}/>
                </Routes>
            </div>
        </div>;
    }
}