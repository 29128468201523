import {CustomComponent} from "../CustomComponent";
import KeyboardManager from "../../core/keyboardManager/adapters/KeyboardManager";
import React from "react";
import {observer} from "mobx-react";
import './checkAdjectiveComponent.scss';
import {CustomButtonView} from "../../components/training/components/CustomButtonView";
import {BottomBlockComponent} from "../bottomBlock/BottomBlockComponent";

interface Props {
    onClick: () => void
    positionInfo?: string
    text?: string
}

@observer
export class CheckAdjectiveComponent extends CustomComponent<Props, any> {

    componentDidMount() {
        KeyboardManager.add('Enter', this.props.onClick);
    }

    componentWillUnmount() {
        KeyboardManager.remove('Enter', this.props.onClick);
    }

    render() {
        return <BottomBlockComponent className={"check-adjective-component"}>
            <CustomButtonView onClick={this.props.onClick}
                              text={this.props.text ?? "Проверить"}>
                {
                    this.props.positionInfo &&
                    <span className="position-info not-select-element">{this.props.positionInfo}</span>
                }
            </CustomButtonView>
        </BottomBlockComponent>

    }
}