import {makeAutoObservable} from "mobx";
import {getDateRes, getTimeRes, msToTime, msToTimeSecond} from "../../../helpers/numberHelp/numberHelp";
import {TrainingMemoryRussianResultViewModel} from "../model/LearnLanguageMemberModel";
import Highcharts from "highcharts";


export default class TrainingLearnLanguageResultStore {
    model: TrainingMemoryRussianResultViewModel;
    optionsPie = {
        title: "",
        pieHole: 0.8,
        colors: ['#4fb260', '#d93243'],
        chartArea: {left: 0, top: 0, width: '100%', height: '100%'},
        legend: 'none',
        pieSliceText: "none",
    };

    constructor(model: TrainingMemoryRussianResultViewModel) {
        makeAutoObservable(this, {}, {autoBind: true});
        this.model = model;
    }

    get speedMiddle(): string {
        return msToTimeSecond(this.model.speed);
        // return msToTimeSecond(this.model.speed);
    }

    get speedMax(): string {
        return msToTimeSecond(Math.min.apply(Math, this.model.items
            .map(function (val) {
                return val.answerTimeElement;
            })));
    }

    get speedMin(): string {
        return msToTimeSecond(Math.max.apply(Math, this.model.items
            .map(function (val) {
                return val.answerTimeElement;
            })));
    }

    get getName(): string {
        return this.model.stageName;
        // if (this.model.styleCoding === "XClean")
        //     return "Кодирование (Чистое)"
        // if (this.model.styleCoding === "imageClean")
        //     return "Декодирование (Чистое)"
        // if (this.model.styleCoding === "X2")
        //     return "Кодирование (Выбор)"
        // return "Декодирование (Выбор)"
    }

    get timeGet(): string {
        return getTimeRes(this.model.createTime);
    }

    get dateGet(): string {
        return getDateRes(this.model.createTime);
    }

    get timeAll(): string {
        return msToTime(this.model.timeAll);
    }

    get dataPie() {
        return [
            ['Состояние', 'Кол-во'],
            ['Правильные', (this.model.items.length ?? 0) - (this.model.error ?? 0)],
            ['Ошибки', (this.model.error ?? 0)]
        ];
    }

    get opinionHighcharts(): Highcharts.Options {
        return {
            chart: {
                type: 'arearange',
                renderTo: 'element-diagram',
                zooming: {
                    type: 'x'
                }
            },
            rangeSelector: {
                allButtonsEnabled: true,
                selected: 2
            },
            tooltip: {
                formatter: function () {
                    let s = `<br />Номер: ${this.x}`;
                    // language=HTML
                    s += `<br />Время: ${this.y}c.`;
                    s += `<br />Значение: ${this.point.custom.dictionary}`;
                    s += `<br />Ответ: ${this.point.custom.answer}`;
                    return s;
                },
                shared: true,
            },
            title: {
                text: 'Темп запоминания'
            },
            xAxis: {
                allowDecimals: false,
                min: 1,
                minPadding: 0,
                maxPadding: 0,
                plotLines: [{
                    color: '#888',
                    value: 0,
                    width: 1,
                    label: {
                        rotation: 90
                    }
                }],
            },
            yAxis: [{
                lineWidth: 1,
                gridLineWidth: 1,
                title: {
                    text: ''
                },
                tickWidth: 1,
                tickLength: 5,
                labels: {
                    align: 'left',
                    x: 8
                }
            }],
            plotOptions: {
                series: {
                    stacking: 'normal'
                },
                area: {
                    fillOpacity: 1,
                    lineWidth: 1,
                    step: 'center'
                }
            },
            series: [{
                name: '',
                data: this.model.items.map((x, index) => {
                    return {
                        y: x.answerTimeElement / 1000,
                        x: index + 1,
                        color: x.correct ? '#50b162' : '#d93243',
                        custom: {
                            time: x.answerTimeElement,
                            dictionary: x.correctAnswer,
                            evenly: x.correct,
                            answer: x.userAnswer,
                        }
                    }
                }) ?? [],
                type: 'column',
                borderColor: "#ffffff",
                showInLegend: false,
                tooltip:
                    {
                        valueDecimals: 1
                    }
            }]
        };
    }
}