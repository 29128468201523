import {AnsCellNumber} from "./answerBlock/AnsCellNumber";
import {AnswerTrainingBaseService} from "../AnswerTrainingBaseService";


export class NumberTrainingAnswerBaseService extends AnswerTrainingBaseService{

    get view(): JSX.Element {
        return <>
            {
                this.items.map((x, pos) =>
                    <AnsCellNumber key={x.id}
                                   ref={(r) => x.p = r}
                                   value={x.answer}
                                   pos={x.numberPosition}
                                   item={x}
                                   addBlock={this.addBlock}
                                   deleteBlock={this.deleteBlock}
                                   changeName={this.changeName}/>
                )
            }
        </>;
    }

}