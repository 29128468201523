import {CustomComponent} from "../../../../helpers/CustomComponent";
import {PropsTrainingType} from "./PropsTrainingType";
import {TitleComponent, TypePositionText, TypeTitle} from "../../../../helpers/title/TitleComponent";
import {ImageComponent} from "../../../../helpers/img/ImageComponent";
import React from "react";
import {ChooseAnswerTrainingLearnLanguage} from "../../components/ChooseAnswerTrainingLearnLanguage";
import {observer} from "mobx-react";
import {TrainingMemoryRussianMemberItemRequestViewModel} from "../../model/LearnLanguageMemberModel";
import {makeAutoObservable} from "mobx";
import {makeClassComponentObserver} from "mobx-react/dist/observerClass";
import {NextElementTimeout} from "../TrainingLearnLanguageConstants";

@observer
export class SelectAnswerAdjectiveComponent extends CustomComponent<PropsTrainingType, any> {
    store: SelectAnswerAdjectiveStore;

    constructor(props: Readonly<PropsTrainingType>) {
        super(props);
        this.store = new SelectAnswerAdjectiveStore(this.props);
    }

    render() {
        return <>
            {
                <ImageComponent key={this.props.currentItem.image}
                                src={this.props.currentItem.image}
                                alt={this.props.currentItem.nameRus}/>
            }
            <div className="container-answers">
                {
                    this.props.currentItem.answers?.map(x =>
                        <ChooseAnswerTrainingLearnLanguage key={this.props.currentItem.id + "_" + x}
                                                           click={this.store.clickAnswer}
                                                           answer={x}
                                                           disabled={this.store.isClickAnswer}
                                                           checkAnswer={this.store.checkAnswer(x)}/>)
                }
            </div>
        </>;
    }
}

class SelectAnswerAdjectiveStore {
    props: PropsTrainingType;
    timerAnswer: NodeJS.Timer | undefined = undefined;
    isClickAnswer: boolean = false;

    constructor(props: PropsTrainingType) {
        this.props = props;
        makeAutoObservable(this, {}, {autoBind: true})
    }

    clickAnswer(answer: string): boolean | undefined {
        this.props.item.answer = answer;
        this.isClickAnswer = true;
        this.timerAnswer = setInterval(this.next, NextElementTimeout);
        return this.checkAnswer(answer);
    }

    next() {
        if (this.timerAnswer)
            clearInterval(this.timerAnswer);
        this.isClickAnswer = false;
        this.props.next(this.props.item);
    }

    checkAnswer(answer: string): boolean | undefined {
        if (!this.isClickAnswer)
            return undefined;
        if (this.props.currentItem.nameRus === answer)
            return true;
        if (this.props.item.answer !== answer)
            return undefined;

        return this.props.currentItem.nameRus === answer;
    }
}