import {CustomComponent} from "../CustomComponent";
import './blockTextWithLeftBoardComponent.scss'

interface Props {
    children?: React.ReactNode
}

export class BlockTextWithLeftBoardComponent extends CustomComponent<Props, any> {
    render() {
        return <div className="block-text-with-left-board-component">
            <div className="block-text-with-left-board-leftBoard"></div>
            <div className="block-text_content">
                {this.props.children}
            </div>
        </div>;
    }
}