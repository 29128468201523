import {makeAutoObservable} from "mobx";


export class WriteAnswerStore{
    private answer: string = "";
    constructor() {
        makeAutoObservable(this, {}, {autoBind: true})
    }
    changeAnswer(answer: string){
        this.answer = answer;
    }
}