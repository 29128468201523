import {makeAutoObservable} from "mobx";
import LoadingStore from "../../../../helpers/LoadingStore";
import VolumeRemembered from "./VolumeRememberedModel";


export default class VolumeRememberedStore{
    public loadingStore: LoadingStore<VolumeRemembered>;
    public options = {
        title: "",
        width: 373,
        height: 373,
        colors: ['#6386b0', '#f39164', '#fcc826', '#b873b2', '#50b162', '#d93243'],
        chartArea: { left: 0, top: 0, width: '100%', height: '80%', margin: '10px' },
        legend: 'none'
    };

    get data(){
        if (!this.loadingStore.model)
            return [];
        return [
            ['Вид дицеплины', 'Кол-во запомненых элементов'],
            ['Числа', this.loadingStore.model.Model!.number.el],
            ['Карты', this.loadingStore.model.Model!.cards.el],
            ['Слова', this.loadingStore.model.Model!.word.el],
            ['Имена', this.loadingStore.model.Model!.name.el],
            ['Бинарные', this.loadingStore.model.Model!.bins.el],
            ['Изображения', this.loadingStore.model.Model!.image.el]
        ]
    }

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true});
        this.loadingStore = new LoadingStore<VolumeRemembered>(this.getUrl)
    }

    getValueOrLoading(val: number | undefined){
        if (this.loadingStore.model.IsLoaded && val)
            return val.toString();
        return '-'
    }

    getUrl(): string {
        return '/api/Statistic/VolumeRemembered';
    }
}