import {makeAutoObservable} from "mobx";
import {StatusShowAllComment} from "../model/SmallPostModel";


export default class CommentStore{
    lengthMax = 175;
    status: StatusShowAllComment;
    constructor(text: string) {
        makeAutoObservable(this, {}, {autoBind: true})
        this.status = text.length > this.lengthMax ? StatusShowAllComment.hide : StatusShowAllComment.none;
    }

    clickShowAll() {
        this.status = StatusShowAllComment.all;
    }
}