import {CustomComponent} from "../../../../../helpers/CustomComponent";
import {observer} from "mobx-react";
import React from "react";


interface Props {
    index: number;
    linkCard: string | undefined;
    selected: boolean;
    click: (link: string) => void;
}

@observer
export class CardMemberView extends CustomComponent<Props, {}> {
    constructor(props: Readonly<Props>) {
        super(props)
        this.click = this.click.bind(this);
    }

    click() {
        if (this.props.selected)
            return;
        if (this.props.linkCard)
            this.props.click(this.props.linkCard);
    }

    render() {
        return <div key={this.props.linkCard + "_" + this.props.selected}
                    className="cell storage_cell"
                    data-index={this.props.index}>
            {
                !this.props.selected &&
                <div className="storage_cell__card cell__card">
                    <div className="card" data-ans="2h" onClick={this.click}
                         style={{backgroundImage: 'url(' + this.props.linkCard + ')'}}></div>
                </div>
            }
        </div>;
    }
}