import {LoadingModel} from "../shared/LoadingModel";
import {TopUserModel} from "./TopUserModel";
import {PostRequest} from "../../helpers/Request";
import {makeAutoObservable} from "mobx";


export enum TopUsersSorting {
    all = 'all',
    month = 'month',
    prevMonth = 'prevMonth',
    day = 'day',
    prevDay = 'prevDay',
}

interface DataRequest {
    searchFullname: string;
    order: string;
    countNow: number;
}

export class TopUsersStore{
    model: LoadingModel<TopUserModel[]> = LoadingModel.createNotLoaded();
    sort: TopUsersSorting = TopUsersSorting.all;
    name: string = "";

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true})
    }

    changeSorting(sort: TopUsersSorting){
        if (this.sort !== sort){
            this.sort = sort;
            this.request()
        }
    }

    changeNameAndRequest(name: string){
        this.name = name;
        this.request();
    }

    request() {
        this.model = LoadingModel.createLoaded();
        PostRequest<TopUserModel[], DataRequest>('/Home/GetTopUsers', {
            countNow: 0, order: this.sort, searchFullname: this.name
        }).then(x => this.setModel(x))
    }

    setModel(model: LoadingModel<TopUserModel[]>){
        this.model = model;
    }
}