import React from 'react'
import './resukt.scss'
import {CustomComponent} from "../../helpers/CustomComponent";
import {ResultModel} from "./models/ResultModel";
import {Link} from "react-router-dom";
import {getPercentError, getSpeed, getTimeResult} from "../../helpers/numberHelp/numberHelp";
import {CheckScreen, TypeScreen} from "../../context/TypeScreen";
import {AvatarWithMnemocard} from "../user/AvatarWithMnemocard";


export class Result extends CustomComponent<{ result: ResultModel, pos: number }, {}> {

    get elements(): JSX.Element {
        if (this.props.result.mode === 'image') {
            if (this.props.result.complexity === "1")
                return <div
                    className="results-table-allelements td-allelements">{this.props.result.elements} Фракталы</div>
            if (this.props.result.complexity === "2")
                return <div
                    className="results-table-allelements td-allelements">{this.props.result.elements} Картинки</div>
        }
        if (this.props.result.mode === 'word') {
            if (this.props.result.complexity === "1")
                return <div
                    className="results-table-allelements td-allelements">{this.props.result.elements} Простые</div>
            if (this.props.result.complexity === "2")
                return <div
                    className="results-table-allelements td-allelements">{this.props.result.elements} Спорт</div>
            return <div className="results-table-allelements td-allelements">{this.props.result.elements} Сложные</div>
        }
        return <div className="results-table-allelements td-allelements">{this.props.result.elements}</div>
    }

    render() {
        return (
            <>
                <div className="results-table-userinfo">
                    <div className="results-table-number td-number">{this.props.pos + 1}</div>
                    <AvatarWithMnemocard
                        name={this.props.result.name}
                        image={this.props.result.image}
                        isPremium={this.props.result.isPremium}
                        link={this.props.result.link}
                        rank={this.props.result.rank}
                        width={"50px"}
                        height={"50px"}
                    />
                    <div className="results-table-index td-index">{this.props.result.index?.toFixed(2)}</div>
                    {this.elements}
                    <div className="results-table-error td-error">
                        {
                            CheckScreen([TypeScreen.Tablet, TypeScreen.Desktop]) &&
                            <span
                                className="desktop">{this.props.result.error} ({getPercentError(this.props.result.elements, this.props.result.error)}%)</span>
                        }
                        {
                            CheckScreen([TypeScreen.Mobile]) &&
                            <span
                                className="mobile">{getPercentError(this.props.result.elements, this.props.result.error)}%</span>
                        }
                    </div>
                    <div className="results-table-speed td-speed" title="сек/элемент">
                        {getSpeed(this.props.result.speed)}
                    </div>
                    <div className="results-table-time td-time">{getTimeResult(this.props.result.time)}</div>
                    <div className="results-table-more td-more">
                        <Link id="moreref" to={"/Training/Search?id=" + this.props.result.id}/>
                    </div>
                </div>
            </>
        )
    }
}
