import {CustomComponent} from "../CustomComponent";
import './artWorkComponent.scss'

interface Props {
    hasImage?: boolean;
    hasBackground?: boolean;
}

export class ArtWorkComponent extends CustomComponent<Props, any> {
    render() {
        return <div className="art-work-component" >
            <ArtWorkBlockComponent />
            <ArtWorkBlockComponent />
        </div>;
    }
}
class ArtWorkBlockComponent extends CustomComponent<{}, {}>{
    render() {
        return <div className="art-work-block">
            <div className="art-work-title">Some-text</div>
            <img alt="image" className="art-work-image"
                 src="https://powermemory.io/images/users/f3590673-90df-4c3b-bfe8-40dbc37dea58.jpg"/>
            <div className="art-work-content">Some-text</div>
        </div>;
    }
}