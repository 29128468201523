import React from 'react'
import './recordsboardBallsModel.scss'
import {observer} from "mobx-react";
import {reaction} from 'mobx';
import BallResultStore from "./store/BallResultStore";
import {CheckScreen, TypeScreen} from "../../context/TypeScreen";
import {CustomComponent} from "../../helpers/CustomComponent";
import {RecordsboardBallsModel} from "./RecordsboardBallsModel";
import AuthorizationStore from "../authorization/store/AuthorizationStore";
import ReactModalRight from "../modal/ReactModalRight";

interface Props {
    isHomePage: boolean
    store?: BallResultStore;
}

@observer
export class BallsResults extends CustomComponent<Props, {}> {
    store: BallResultStore;

    constructor(props: Readonly<Props>) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
        this.store = this.props.store ?? new BallResultStore();
    }

    componentDidMount() {
        reaction(
            () => [this.store.typeTime, this.store.typeResult, this.store.discipline, this.store.dis],
            this.store.fetchRequest
        );
    }

    closeModal() {
        AuthorizationStore.setShowModalBalls(false);
    }

    render() {
        if (!this.props.isHomePage && !CheckScreen([TypeScreen.Mobile]))
            return <></>
        if (this.props.isHomePage && CheckScreen([TypeScreen.Mobile]))
            return <></>
        if (!this.props.isHomePage && CheckScreen([TypeScreen.Mobile]))
            return <ReactModalRight showModal={AuthorizationStore.showModalBalls} onClose={this.closeModal}>
                <RecordsboardBallsModel store={this.store}/>
            </ReactModalRight>
        return (<RecordsboardBallsModel store={this.store}/>)
    }
}

