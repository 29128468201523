import React, {Component, Suspense} from 'react'
import './statisticsPage.scss'
import {observer} from "mobx-react";
import StatisticsStore from "../../components/statistics/StatisticsStore";
import {ArchiveResults} from "../../components/statistics/component/archiveResults/ArchiveResults";
import {Outlet, Route, Routes} from "react-router-dom";
import {Loading} from "../../components/shared/Loading";
import {Activity} from "../../components/statistics/component/activity/Activity";
import {TrainingTime} from "../../components/statistics/component/trainingTime/TrainingTime";
import {VolumeRemembered} from "../../components/statistics/component/volumeRemembered/VolumeRemembered";
import {NewRank} from "../../components/statistics/component/newRank/NewRank";
import {CodingSpeed} from "../../components/statistics/component/codingSpeed/CodingSpeed";
import {TransitionRate} from "../../components/statistics/component/transitionRate/TransitionRate";
import {MemorySpeed} from "../../components/statistics/component/memorySpeed/MemorySpeed";
import {ConnectionReliability} from "../../components/statistics/component/connectionReliability/ConnectionReliability";
import {LocationQuality} from "../../components/statistics/component/locationQuality/LocationQuality";
import {GeneralStamina} from "../../components/statistics/component/generalStamina/GeneralStamina";
import {VolumeEndurance} from "../../components/statistics/component/volumeEndurance/VolumeEndurance";
import AuthorizationStore from "../../components/authorization/store/AuthorizationStore";
import {CodeReliability} from "../../components/statistics/component/codeReliability/CodeReliability";
import {NotFoundPage} from "../notFound/NotFoundPage";
import TitleStore from "../../components/authorization/store/TitleStore";
import {NavBurger} from "../../helpers/navburger/NavBurger";
import {NavBarCollectionModel} from "../../helpers/navburger/NavBarModel";
import {EnglishWords} from "../../components/statistics/component/englishWords/EnglishWords";
import {TempSpeedEnglish} from "../../components/statistics/component/tempSpeedEnglish/TempSpeedEnglish";


@observer
export class StatisticsPage extends Component<{}, {}> {
    store: StatisticsStore = new StatisticsStore();
    navBugrers: NavBarCollectionModel[] = [
        {
            name: "Общие данные",
            models: [
                {
                    name: "Активность",
                    toLink: "Activity",
                },
                {
                    name: "Время тренировки",
                    toLink: "TrainingTime",
                },
                {
                    name: "Объем запомненного",
                    toLink: "VolumeRemembered",
                },
                {
                    name: "Архив результатов",
                    toLink: "ArchiveResults",
                },
                {
                    name: "Новый ранг",
                    toLink: "NewRank",
                },
            ]
        },
        {
            name: "Темп",
            models: [
                {
                    name: "Темп кодирования",
                    toLink: "CodingSpeed",
                },
                {
                    name: "Темп перехода",
                    toLink: "TransitionRate",
                },
                {
                    name: "Темп запоминания",
                    toLink: "MemorySpeed",
                },
            ]
        },
        {
            name: "Надежность",
            models: [
                {
                    name: "Надёжность связей",
                    toLink: "ConnectionReliability",
                },
                {
                    name: "Качество кодов",
                    toLink: "CodeReliability",
                },
                {
                    name: "Качество локаций",
                    toLink: "LocationQuality",
                },
            ]
        },
        {
            name: "Легкость",
            models: [
                {
                    name: "Выносливость общая",
                    toLink: "GeneralStamina",
                },
                {
                    name: "Выносливость по объёму",
                    toLink: "VolumeEndurance",
                },
            ]
        },
        {
            name: "Английский",
            models: [
                {
                    name: "Все слова",
                    toLink: "EnglishWords",
                },
                {
                    name: "Темп кодирования словообразов",
                    toLink: "TempSpeedEnglish",
                },
            ]
        },
    ]

    constructor(props: Readonly<{}>) {
        super(props);
        this.changeBurger = this.changeBurger.bind(this);
    }

    componentDidMount() {
        AuthorizationStore.setShowModalBalls(false);
        TitleStore.setTitle("Статистика");
    }

    changeBurger() {
        this.store.setBurger = !this.store.burger;
    }

    render() {
        if (!AuthorizationStore.checkAuthMenu)
            return <NotFoundPage/>
        return (
            <div id="contener-2" className="contener-2-statistics">
                <NavBurger models={this.navBugrers}/>

                <div className="stat-page" id="recordsboard">
                    <Routes>
                        <Route path="ArchiveResults" element={<Suspense fallback={<Loading/>}>
                            <ArchiveResults/>
                        </Suspense>}/>

                        <Route path="TrainingTime" element={<Suspense fallback={<Loading/>}>
                            <TrainingTime/>
                        </Suspense>}/>

                        <Route path="VolumeRemembered" element={<Suspense fallback={<Loading/>}>
                            <VolumeRemembered/>
                        </Suspense>}/>

                        <Route path="NewRank" element={<Suspense fallback={<Loading/>}>
                            <NewRank/>
                        </Suspense>}/>

                        <Route path="activity" element={<Suspense fallback={<Loading/>}>
                            <Activity/>
                        </Suspense>}/>

                        <Route path="CodingSpeed" element={<Suspense fallback={<Loading/>}>
                            <CodingSpeed/>
                        </Suspense>}/>

                        <Route path="MemorySpeed" element={<Suspense fallback={<Loading/>}>
                            <MemorySpeed/>
                        </Suspense>}/>

                        <Route path="TransitionRate" element={<Suspense fallback={<Loading/>}>
                            <TransitionRate/>
                        </Suspense>}/>

                        <Route path="ConnectionReliability" element={<Suspense fallback={<Loading/>}>
                            <ConnectionReliability/>
                        </Suspense>}/>

                        <Route path="CodeReliability" element={<Suspense fallback={<Loading/>}>
                            <CodeReliability/>
                        </Suspense>}/>

                        <Route path="LocationQuality" element={<Suspense fallback={<Loading/>}>
                            <LocationQuality/>
                        </Suspense>}/>

                        <Route path="GeneralStamina" element={<Suspense fallback={<Loading/>}>
                            <GeneralStamina/>
                        </Suspense>}/>

                        <Route path="VolumeEndurance" element={<Suspense fallback={<Loading/>}>
                            <VolumeEndurance/>
                        </Suspense>}/>

                        <Route path="EnglishWords" element={<Suspense fallback={<Loading/>}>
                            <EnglishWords/>
                        </Suspense>}/>

                        <Route path="TempSpeedEnglish" element={<Suspense fallback={<Loading/>}>
                            <TempSpeedEnglish/>
                        </Suspense>}/>

                        <Route path="*" element={<Suspense fallback={<Loading/>}>
                            <Activity/>
                        </Suspense>}/>
                    </Routes>
                    <Outlet/>
                </div>
            </div>
        )
    }
}