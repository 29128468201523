import {observer} from "mobx-react";
import {CustomComponent} from "../../../../../helpers/CustomComponent";
import {RequiredRequiredTraining} from "../NewRankModel";
import {Link} from "react-router-dom";

export interface StandartsRequiredsViewRankProps{
    numberPosition: number;
    training: RequiredRequiredTraining;
}
@observer
export class StandartsRequiredsViewRank extends CustomComponent<StandartsRequiredsViewRankProps, {}> {

    constructor(props: Readonly<StandartsRequiredsViewRankProps>) {
        super(props);
        this.getView = this.getView.bind(this);
        this.getLink = this.getLink.bind(this);
        this.getLinkFoundTraining = this.getLinkFoundTraining.bind(this);
    }

    getLink():string{
        return '/Training?trainingMode='+this.props.training.mode + '&number='+this.props.training.number+'&trainingType=1';
    }
    getLinkFoundTraining():string{
        return '/Training/Search?id='+this.props.training.trainingId;
    }

    getView():JSX.Element{
        if (this.props.training.isFound){
            return (
                <div className="exercise-complicate">Выполнено! {this.props.training.text} <Link className="ex-comp-href"
                                                                                     to={this.getLinkFoundTraining()}></Link>
                </div>
            )
        }
        if (this.props.training.isBestFound){
            return <>
                <div className="ex-explanations">Лучший результат, в котором вы запомнили </div>
                <div className="res-speed-box">
                    <div id="res-time-now" data-title="Время запоминания [MM:СС:mmm]">{this.props.training.bestAllTime}</div>
                    <a className="ex-comp-href" href={this.getLinkFoundTraining()}></a>
                </div>
                <div className="ex-standard-main-box">
                    <div className="exsb-1">
                        <Link to={this.getLink()}>
                            <div className="ex-bottom">Запомнить</div>
                        </Link>
                    </div>
                </div>
            </>
        }
        return <>
            <div className="ex-standard-main-box">
                <div className="res-training-empty">{this.props.training.bestNotFoundEmpty}</div>
                <div className="exsb-1">
                    <Link to={this.getLink()}>
                        <div className="ex-bottom">Запомнить</div>
                    </Link>
                </div>
            </div>
        </>
    }

    render() {
        return (<>
            <div className="exercise-box">
                <div className="ex-name">{this.props.numberPosition}. {this.props.training.titleText}</div>
                {this.getView()}
            </div>
        </>);
    }
}