import {CustomComponent} from "../CustomComponent";
import {observer} from "mobx-react";
import './spaceComponent.scss'


interface Props {
    type: TypeSpaceComponent;
}

export enum TypeSpaceComponent {
    lvl1 = 0,
    lvl2 = 1,
}

@observer
export class SpaceComponent extends CustomComponent<Props, any> {
    get getClassName(): string {
        switch (this.props.type) {
            case TypeSpaceComponent.lvl1:
                return "space-height-lvl-1";
            case TypeSpaceComponent.lvl2:
                return "space-height-lvl-2";
        }
    }

    render() {
        return <div className="space-component">
            <div className={this.getClassName}></div>
        </div>;
    }
}