import {makeAutoObservable} from "mobx";
import KeyboardManager from "../../../core/keyboardManager/adapters/KeyboardManager";

class HeaderTrainingLearnLanguageStore {
    public numberElNow: number = 0;
    private finishTraining: (() => void) | undefined;
    private hint? : (() => void) | undefined

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true})
    }

    setNumberElNow(num: number) {
        this.numberElNow = num;
    }

    finishClick() {
        if (this.finishTraining)
            this.finishTraining();
    }

    setFinishTraining(finishClick: (() => void) | undefined) {
        if (finishClick){
            this.finishTraining = finishClick;
        }
    }

    hintClick() {
        if (this.hint)
            this.hint();
    }

    setHintTraining(hint: (() => void) | undefined) {
        this.clearHint();
        if (hint){
            this.hint = hint;
            KeyboardManager.add('ArrowUp', this.hint);
        }
    }

    hasHint():boolean{
        return !!this.hint;
    }

    clearHint(){
        if (this.hint){
            KeyboardManager.remove('ArrowUp', this.hint);
        }
    }

    clear() {
        this.clearHint();
        this.numberElNow = 0;
        this.finishTraining = undefined;
        this.hint = undefined;
    }
}

export default new HeaderTrainingLearnLanguageStore();