import React, {Component} from 'react'
import TrainingChangeBaseModel from "../store/TrainingChangeBaseModel";
import {observer} from "mobx-react";
import CustomSelector, {SelectorPros} from "../../shared/selector/CustomSelector";
import {CountElementsView} from "./CountElementsView";
import {DisHelperView} from "./DisHelperView";

export interface Props {
    changeModel: TrainingChangeBaseModel;
}

@observer
export class CardsView extends Component<Props, {}> {
    private selectGroups: SelectorPros[] = [
        {
            title: 'Рядом',
            value: '1',
        },
        {
            title: 'Наед 50%',
            value: '2',
        },
        {
            title: 'До индекса',
            value: '3',
        },
    ]
    private selectGroupsV1: SelectorPros[] = [
        {
            title: '2 карты',
            value: '2',
        },
        {
            title: '3 карты',
            value: '3',
        },
        {
            title: '4 карты',
            value: '4',
        },
        {
            title: '5 карт',
            value: '5',
        },
        {
            title: '6 карт',
            value: '6',
        },
        {
            title: '7 карт',
            value: '7',
        },
    ]
    private selectGroupsV2: SelectorPros[] = [
        {
            title: '2 карты',
            value: '2',
        },
        {
            title: '3 карты',
            value: '3',
        },
        {
            title: '4 карты',
            value: '4',
        },
        {
            title: '5 карт',
            value: '5',
        },
        {
            title: '6 карт',
            value: '6',
        },
        {
            title: '7 карт',
            value: '7',
        },
        {
            title: '8 карт',
            value: '8',
        },
        {
            title: '9 карт',
            value: '9',
        },
        {
            title: '10 карт',
            value: '10',
        },
        {
            title: '11 карт',
            value: '11',
        },
        {
            title: '12 карт',
            value: '12',
        },
        {
            title: '13 карт',
            value: '13',
        },
        {
            title: '14 карт',
            value: '14',
        },
    ]
    private selectGroupsV3: SelectorPros[] = [// todo дописать все до 52
        {
            title: '2 карты',
            value: '2',
        },
        {
            title: '3 карты',
            value: '3',
        },
        {
            title: '4 карты',
            value: '4',
        },
        {
            title: '5 карт',
            value: '5',
        },
        {
            title: '6 карт',
            value: '6',
        },
        {
            title: '7 карт',
            value: '7',
        },
        {
            title: '8 карт',
            value: '8',
        },
        {
            title: '9 карт',
            value: '9',
        },
        {
            title: '10 карт',
            value: '10',
        },
        {
            title: '11 карт',
            value: '11',
        },
        {
            title: '12 карт',
            value: '12',
        },
        {
            title: '13 карт',
            value: '13',
        },
        {
            title: '14 карт',
            value: '14',
        },
    ]
    constructor(props: Readonly<Props>) {
        super(props);
        this.setComplexity = this.setComplexity.bind(this);
        this.setElementAtGo = this.setElementAtGo.bind(this);
        this.changeSelector = this.changeSelector.bind(this);
        this.changeGroupAndElements = this.changeGroupAndElements.bind(this);
    }

    setComplexity(e: React.MouseEvent<HTMLDivElement>) {
        this.props.changeModel.changeComplexity(e.currentTarget.getAttribute("custom") ?? "2");
    }

    setElementAtGo(e: React.MouseEvent<HTMLDivElement>) {
        this.props.changeModel.changeElementAtGo(e.currentTarget.getAttribute("custom") ?? "2");
    }

    changeGroupAndElements(){
        if (this.props.changeModel.hasGroupsElementsStart()){
            this.props.changeModel.changeElementAtGo("4");
        }else{
            this.props.changeModel.changeElementAtGo("2");
        }
    }

    changeSelector(sel: SelectorPros | undefined) {
        this.props.changeModel.changeElementAtGo(sel?.value ?? "2");
    }

    getComplexityClassName(complexity: string): string {
        if (this.props.changeModel.model.complexity === complexity) {
            return "elements element1 point complexityModeActivity"
        }
        return "elements element1 point"
    }

    getElementAtGoClassName(elementOfTime: number): string {
        if (this.props.changeModel.model.elementAtGo === elementOfTime) {
            return "block-number point complexityModeActivity"
        }
        return "block-number point"
    }

    render() {
        return (
            <div className="number-training-default">
                <div className="complexity-training-default">
                    <div custom="1" className={this.getComplexityClassName("1")} onClick={this.setComplexity}>
                        <span>ЧПД</span>
                    </div>
                    <div custom="2" className={this.getComplexityClassName("2")} onClick={this.setComplexity}>
                        <span>1 КАРТА</span>
                    </div>
                    <div custom="3" className={this.getComplexityClassName("3")} onClick={this.setComplexity}>
                        <span>2704</span>
                    </div>
                </div>

                <div id="number-view">
                    <input id="atonce" type="radio" name="view-elements" value="2"/>
                    <div className="change-group-number" onClick={this.changeGroupAndElements}>
                        {
                            this.props.changeModel.hasGroupsElementsStart() &&
                            <label id="atonce-label" className="point">Показ
                                элементов за раз</label>
                        }
                        {
                            !this.props.changeModel.hasGroupsElementsStart() &&
                            <label id="grouping-number-label" className="point">Группировка
                                карт</label>
                        }
                        <label id="view-1-label"></label>
                    </div>
                    {
                        this.props.changeModel.hasGroupsElementsStart() &&
                        <div className="count-element-time">
                            <div id="number-view1-label"
                                 onClick={this.setElementAtGo}
                                 custom='1'
                                 className={this.getElementAtGoClassName(1)}>1
                            </div>
                            <div id="number-view2-label"
                                 onClick={this.setElementAtGo}
                                 custom='2'
                                 className={this.getElementAtGoClassName(2)}>2
                            </div>
                            <div id="number-view3-label"
                                 onClick={this.setElementAtGo}
                                 custom='3'
                                 className={this.getElementAtGoClassName(3)}>3
                            </div>
                        </div>
                    }
                    {
                        !this.props.changeModel.hasGroupsElementsStart() &&
                        <div style={{display: 'flex'}}>
                            <CustomSelector defaultValue={this.props.changeModel.model.elementAtGo?.toString() ?? '2'}
                                            values={this.selectGroups}
                                            onChange={this.changeSelector}
                                            width="55%"
                            />
                            {
                                // this.props.changeModel.model.elementAtGo?.toString() === "1"&&
                                <CustomSelector defaultValue={this.props.changeModel.model.elementAtGo?.toString() ?? '2'}
                                                values={this.selectGroupsV1}
                                                onChange={this.changeSelector}
                                                width="55%"
                                />
                            }
                            {
                                this.props.changeModel.model.elementAtGo?.toString() === "2"&&
                                <CustomSelector defaultValue={this.props.changeModel.model.elementAtGo?.toString() ?? '2'}
                                                values={this.selectGroupsV2}
                                                onChange={this.changeSelector}
                                                width="55%"
                                />
                            }
                            {
                                this.props.changeModel.model.elementAtGo?.toString() === "3"&&
                                <CustomSelector defaultValue={this.props.changeModel.model.elementAtGo?.toString() ?? '2'}
                                                values={this.selectGroupsV3}
                                                onChange={this.changeSelector}
                                                width="55%"
                                />
                            }
                        </div>
                    }
                    <CountElementsView changeModel={this.props.changeModel} />
                    <DisHelperView changeModel={this.props.changeModel} />
                </div>
            </div>
        )
    }
}