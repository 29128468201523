import {TrainingMemberItemModel, TrainingMemberModel} from "../../model/TrainingMemberModel";
import {TrainingMemberRequestItemModel} from "../../model/TrainingMemberRequestModel";
import {IMemberTrainingBaseService} from "../IMemberTrainingBaseService";
import {makeAutoObservable} from "mobx";

export class NumberMemberTrainingBaseService implements IMemberTrainingBaseService {
    model: TrainingMemberModel;
    itemsRequest: TrainingMemberRequestItemModel[];
    countElementsOneTime: number;
    numberElNow: number = 0;
    statusBackgr: boolean = false;
    mask: string;
    lastDate: Date = new Date();
    elementAtGo: number;
    answerRequest: (items: TrainingMemberRequestItemModel[]) => void;

    constructor(model: TrainingMemberModel, answerRequest: (items: TrainingMemberRequestItemModel[]) => void) {
        makeAutoObservable(this, {}, {autoBind: true})
        this.answerRequest = answerRequest;
        this.model = model;
        this.itemsRequest = [];
        this.elementAtGo = parseInt(model.elementAtGo);
        this.countElementsOneTime = getElement(this.elementAtGo)
        this.mask = getMask(this.elementAtGo);
        this.numberElNow = 0;
        for (const modelElement of this.model.items) {
            this.itemsRequest.push({
                id: modelElement.id,
                date: null,
                timeElement: 0,
            })
        }
    }

    next() {
        if (this.numberElNow < 0) return;

        let firsStart = true;
        let elDate = this.numberElNow;
        let countElementNow = this.countElementsOneTime;
        for (; elDate % this.countElementsOneTime !== 0; elDate++) {
            countElementNow--;
        }
        for (; elDate < this.model.items.length && (elDate % this.countElementsOneTime !== 0 || firsStart === true); elDate++) {
            firsStart = false;
            this.itemsRequest[elDate].timeElement += (new Date().getTime() - this.lastDate.getTime()) / countElementNow;
            this.itemsRequest[elDate].date = new Date().getTime();
        }
        this.numberElNow += this.countElementsOneTime;
        this.lastDate = new Date();
        if (this.numberElNow >= this.model.items.length) {
            this.finish();
        }
    }

    back(): void {
        if (this.numberElNow <= 0) {
            return;
        }
        var firsStart = true;
        let elm1 = this.numberElNow;
        let countNow = 0;//Время
        for (; elm1 % this.countElementsOneTime !== 0 || firsStart === true; elm1--) {
            firsStart = false;
            countNow++;
        }
        firsStart = true;
        for (; elm1 < this.model.items.length && (elm1 % this.countElementsOneTime !== 0 || firsStart === true); elm1++) {
            firsStart = false;
            this.itemsRequest[elm1].timeElement += (new Date().getTime() - this.lastDate.getTime()) / countNow;
            this.itemsRequest[elm1].date = new Date().getTime();
        }
        //Показ все остальное
        this.numberElNow = this.numberElNow - countNow;
        this.lastDate = new Date();
    }

    finish(): void {
        this.answerRequest(this.itemsRequest);
    }

    get currentElements(): number {
        return 0;
    }

    repeat(): void {
        let firsStart = true;
        if (this.numberElNow > 0) {
            var elDate = this.numberElNow - this.countElementsOneTime;
            for (; elDate % this.countElementsOneTime !== 0; elDate++) {

            }
            for (; elDate < this.model.items.length && (elDate % this.countElementsOneTime !== 0 || firsStart === true); elDate++) {
                firsStart = false;
                this.itemsRequest[elDate].timeElement += (new Date().getTime() - this.lastDate.getTime()) / this.countElementsOneTime;
            }
        }
        this.numberElNow = 0;
        this.lastDate = new Date();
    }

    get view(): JSX.Element {
        let massive: TrainingMemberItemModel[] = [];
        let firsStart = true;
        let numberElNow = this.numberElNow;
        for (let i = 0; i < this.countElementsOneTime &&
        (numberElNow % this.countElementsOneTime !== 0 || firsStart === true) &&
        numberElNow < this.model.items.length; i++, numberElNow++) {
            firsStart = false;
            massive.push(this.model.items[numberElNow])
            if (numberElNow !== 0 && this.elementAtGo === 1 && this.countElementsOneTime <= 3) {
                this.statusBackgr = this.model.items[numberElNow].dictionary === this.model.items[numberElNow - 1].dictionary;
            }
        }

        let txt = "";
        let space = "";

        if (this.elementAtGo <= 3) {
            space = " ";
        }
        for (let i = 0; i < massive.length; i++) {
            // if (massive[i])
            txt += massive[i].dictionary + space;
        }
        let bacgrColor = "white";
        // todo доделать
        // if (this.statusBackgr) {
        //     if ($("#number-rem").css("background-color").toString() === '#c2c2c2') {
        //         this.statusBackgr = false;
        //     }
        // }
        //
        // if  (this.statusBackgr) {
        //     bacgrColor = "#c2c2c2";
        // }
        let text = formatByMask(this.mask, txt);
        if (this.elementAtGo <= 3 && this.countElementsOneTime <= 3) {
            return <div id="number-rem" style={{backgroundColor: bacgrColor}}>
                <span id="num-g1">{txt}</span>
            </div>;
        } else if (this.elementAtGo <= 17) {
            return <div id="number-rem" style={{backgroundColor: bacgrColor}}>
                <span id="num-g1">{text}</span>
            </div>;
        } else {
            switch (this.elementAtGo) {
                case 18:
                    return <div id="number-rem" style={{backgroundColor: bacgrColor}}>
                        <span id="num-g39s">{text}</span>
                    </div>;
                case 19:
                    return <div id="number-rem" style={{backgroundColor: bacgrColor}}>
                        <span id="num-g39s">{text}</span>
                    </div>;
                case 20:
                    return <div id="number-rem" style={{backgroundColor: bacgrColor}}>
                        <span id="num-g40">{text}</span>
                    </div>;
                case 21:
                    return <div id="number-rem" style={{backgroundColor: bacgrColor}}>
                        <span id="num-g40s2">{text}</span>
                    </div>;
                case 22:
                    return <div id="number-rem" style={{backgroundColor: bacgrColor}}>
                        <span id="num-g40s4">{text}</span>
                    </div>;
                default:
                    return <></>
            }
        }

        // return txt;
    }

    get positionInfo(): string {
        if (this.model.dis === "0" || this.model.dis === "A") {
            return Math.ceil(this.numberElNow / this.countElementsOneTime) + 1 +
                " / " + Math.ceil(this.model.items.length / this.countElementsOneTime);
        }
        return Math.ceil((this.numberElNow / this.countElementsOneTime) + 1).toString();
    }
}


const formatByMask = (mask: string, value: string) => {
    let formattedValue = mask;
    const maskCharCount = [...mask.split('')].reduce((result, chr) => {
        if (chr === 'X') result++;
        return result
    }, 0);
    for (let i = 0; i < maskCharCount; i++) {
        if (value[i]) formattedValue = formattedValue.replace('X', value[i]);
    }
    return formattedValue;
};

function getMask(itemsAtTime: number) {
    switch (itemsAtTime) {
        case 4:
            return 'X XX';
        case 5:
            return 'XX X';
        case 6:
            return 'X XXX';
        case 7:
            return 'XXX X';
        case 8:
            return 'XX XX XX XX';
        case 9:
            return 'XX XX XX XX XX';
        case 10:
            return 'XXXX';
        case 11:
            return 'XXXXXX';
        case 12:
            return 'XXXXXXXX';
        case 13:
            return 'XXXXXXXXXX';
        case 14:
            return 'XXXX XX';
        case 15:
            return 'XX XXXX';
        case 16:
            return 'XXXXXX';
        case 17:
            return 'XXXXXXXXX';
        case 18:
            return 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX';
        case 19:
            return 'XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX';
        case 20:
            return 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX';
        case 21:
            return 'XX XX XX XX XX XX XX XX XX XX XX XX XX XX XX XX XX XX XX XX';
        case 22:
            return 'XXXX XXXX XXXX XXXX XXXX XXXX XXXX XXXX XXXX XXXX';
    }
    return '';
}

function getElement(elem: number): number {
    switch (elem) {
        case 1:
            return 1;
        case 2:
            return 2;
        case 3:
            return 3;
        case 4:
            return 2;
        case 5:
            return 2;
        case 6:
            return 2;
        case 7:
            return 2;
        case 8:
            return 4;
        case 9:
            return 5;
        case 10:
            return 2;
        case 11:
            return 3;
        case 12:
            return 4;
        case 13:
            return 5;
        case 14:
            return 3;
        case 15:
            return 3;
        case 16:
            return 2;
        case 17:
            return 3;
        case 18:
            return 13;
        case 19:
            return 13;
        default:
            return 20;
    }
}