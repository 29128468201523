import React from 'react'
import './storePage.scss'
import {observer} from "mobx-react";
import {CustomComponent} from "../../helpers/CustomComponent";
import CustomSelector from "../../components/shared/selector/CustomSelector";
import {StoreStore} from "../../components/store/StoreStore";
import {additionalCourse, coursesStore, improvingQuality, products} from "../../components/store/StoreHelper";
import {ProductItemComponent} from "../../components/store/components/ProductItemComponent";
import TitleStore from "../../components/authorization/store/TitleStore";

@observer
export class StorePage extends CustomComponent<{}, {}> {
    store: StoreStore;

    constructor(props: Readonly<{}>) {
        super(props);
        this.store = new StoreStore();
    }
    componentDidMount() {
        TitleStore.setTitle("Онлайн курсы развития");
    }

    render() {
        return (<div id="container-store">
                <div id="valuta">
                    <span className="line-name-prod">Онлайн курсы развития памяти от Алекса Грука</span>

                    <div className="select-style">
                        <CustomSelector value={this.store.currentCourse.value}
                                        values={coursesStore}
                                        onChange={this.store.changeCourse}/>
                    </div>
                </div>

                {/*// <!-- Онлайн курсы развития памяти /-->*/}
                <div className="line-product clear-fix">
                    {products.map((x, index) => (<ProductItemComponent key={x.id +'_' + this.store.currentCurrency} index={index}
                                                                       dto={x}
                                                                       currentValueCurrency={this.store.currentValueCurrency}
                                                                       currentCurrency={this.store.currentCurrency} />))}
                </div>
                <span className="line-name-prod">Повышение качества тренировок на сайте</span>

                <div className="line-product clear-fix">
                    {improvingQuality.map((x, index) => (<ProductItemComponent key={x.id +'_' + this.store.currentCurrency} index={index}
                                                                       dto={x}
                                                                       currentValueCurrency={this.store.currentValueCurrency}
                                                                       currentCurrency={this.store.currentCurrency} />))}
                </div>

                {/*// <!-- Онлайн курсы совершенствования /-->*/}
                <span className="line-name-prod">Дополнительные курсы </span>

                <div className="line-product clear-fix" style={{marginBottom: '200px'}}>
                    {additionalCourse.map((x, index) => (<ProductItemComponent key={x.id +'_' + this.store.currentCurrency} index={index}
                                                                               dto={x}
                                                                               currentValueCurrency={this.store.currentValueCurrency}
                                                                               currentCurrency={this.store.currentCurrency} />))}
                </div>
            </div>
        )
    }
}