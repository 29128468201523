import React from 'react'
import './notfound.scss'
import {observer} from "mobx-react";
import {CustomComponent} from "../../helpers/CustomComponent";
import TitleStore from "../../components/authorization/store/TitleStore";
import {HomePage} from "../home/HomePage";


@observer
export class NotFoundPage extends CustomComponent<{}, {}> {

    componentDidMount() {
        if (window.location.pathname.startsWith("/@")){
            TitleStore.setTitle("Мнемокарта");
            return ;
        }
        TitleStore.setTitle("Не нашли страницу");
    }

    render() {
        if (window.location.pathname.startsWith("/@")){
            return <HomePage />
        }
        return (
            <div className="not-found">
                {/*<section id="noaccess">*/}
                {/*    <span className="num-err">403</span>*/}
                {/*    <span className="err-name">Ошибка доступа</span>*/}
                {/*    <span className="text-err">Для получения доступа к этой странице, нажмите кнопку "Взломать" на своем квантовом суперкомпьютере.</span>*/}
                {/*    <img className="err-img" src="https://powermemory.io/images/general/403.png" alt="403"/>*/}
                {/*</section>*/}


                <section id="nofound">
                    <span className="num-err">404</span>
                    <span className="err-name">Страцина не найдена</span>
                    <span className="text-err">
            Вау! Эта страница сделала неожиданный скачок в параллельную реальность,
            где все запутано и переплетено. <br/><br/> Ваша задача - распутать этот клубок и вспомнить,
            что именно привело вас сюда. Удачи в тренировке памяти!
        </span>
                    <img className="err-img" src="https://powermemory.io/images/general/404.png" alt="404"/>
                </section>


                {/*<section id="developing">*/}
                {/*    <span className="err-name">Страница находится в разработке</span>*/}
                {/*    <span className="text-err">Следите за обновлениями <a href="https://t.me/joinchat/Q6Nye0gpCbU5sLIf"><u>на канале PowerMemory</u></a> в телеграм</span>*/}
                {/*    <img className="err-img" src="https://powermemory.io/images/general/dev-err.png"*/}
                {/*         alt="Страница не готова"/>*/}
                {/*</section>*/}
            </div>
        )
    }
}