import {makeAutoObservable, observable} from "mobx";
import {SelectorPros} from "../../../shared/selector/CustomSelector";
import LoadingStore from "../../../../helpers/LoadingStore";
import {ActivityModel} from "../activity/ActivityModel";
import {GeneralStaminaModel} from "./GeneralStaminaModel";
import Highcharts from "highcharts";


export class GeneralStaminaStore {

    // @observable
    // public selector: SelectorPros;

    @observable
    public loadingStore: LoadingStore<GeneralStaminaModel>;

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true});
        this.loadingStore = new LoadingStore<GeneralStaminaModel>(this.getUrl)
    }


    getUrl(): string {
        return "";
        // return '/api/Statistic/VolumeEndurance?discipline=' + this.selector.value;
    }


    get options(): Highcharts.Options {
        return {
            chart: {
                // width: 900,
                style: {
                    'float': 'right'
                }
            },
            rangeSelector: {
                selected: 0
            },
            title: {
                text: ''
            },
            series: [{
                name: 'Текущий темп',
                // data: this.loadingStore?.model.Model?.list
                //     .map((x) => [x.date, x.speed]) ?? [],
                type: 'area',
                threshold: null,
                tooltip: {
                    valueDecimals: 0
                },
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, '#4572A7'],
                        [1, 'white']
                    ]
                }
            },
            {
                name: 'Лучший темп на объеме 20',
                // data: this.loadingStore?.model.Model?.list
                //     .map((x) => [x.date, this.loadingStore?.model.Model!.minValue]) ?? [],
                type: 'area',
                threshold: null,
                tooltip: {
                    valueDecimals: 0
                },
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, '#4572A7'],
                        [1, 'white']
                    ]
                }
            }]
        };
    }
}