import {CustomComponent} from "../CustomComponent";
import {observer} from "mobx-react";
import './nextPrevComponent.scss';
import React from "react";

interface Props {
    next?: () => void
    prev?: () => void
    repeat?: () => void
    isRepeating?: boolean;
    textNumber?: string;
    customNext?: string;
    customPrev?: string;
}

@observer
export class NextPrevComponent extends CustomComponent<Props, any> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.prev = this.prev.bind(this);
        this.repeat = this.repeat.bind(this);
        this.next = this.next.bind(this);
    }

    prev() {
        if (this.props.prev)
            this.props.prev();
    }

    repeat() {
        if (this.props.repeat)
            this.props.repeat();
    }

    next() {
        if (this.props.next)
            this.props.next();
    }

    render() {
        return <div className="next-prev-component">
            <div
                className={(this.props.customPrev ? "next-prev-component_left_custom next-prev-component_text" : "next-prev-component_left") + " next-prev-component_block"}
                onClick={this.prev}>
                {this.props.customPrev}
            </div>
            {
                this.props.isRepeating === true &&
                <div className="next-prev-component_repeat" onClick={this.repeat}>
                </div>
            }
            <div
                className={(this.props.customNext ? "next-prev-component_text" : "next-prev-component_right") + " next-prev-component_block"}
                onClick={this.next}>
                {this.props.customNext}
            </div>
            <span className="next-prev-component_position_info">{this.props.textNumber ?? "10/100"}</span>
        </div>;
    }
}