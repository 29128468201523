import {AnsCellWordCell} from "./block/AnsCellWordCell";
import {AnswerTrainingBaseService} from "../AnswerTrainingBaseService";

export class WordTrainingAnswerBaseService extends AnswerTrainingBaseService {

    changeName(pos: number, text: string) {
        let item = this.items.filter(x =>
            x.numberPosition === pos)[0];
        item.answer = text;
    }

    get view(): JSX.Element {
        return <>
            {
                this.items.map((x) =>
                    <AnsCellWordCell key={x.id}
                                     ref={(r) => x.p = r}
                                     value={x.answer}
                                     pos={x.numberPosition}
                                     item={x}
                                     addBlock={this.addBlock}
                                     deleteBlock={this.deleteBlock}
                                     changeName={this.changeName}/>
                )
            }
        </>;
    }
}