import React, {ChangeEvent} from 'react'
import './routePage.scss'
import {observer} from "mobx-react";
import {CustomComponent} from "../../helpers/CustomComponent";
import {RouteStore} from "../../components/route/RouteStore";
import Modal from "../../components/modal/ReactModal";
import {RouteItem} from "../../components/route/RouteItem";
import {DragDropContext, Draggable, Droppable, DropResult, ResponderProvided} from "react-beautiful-dnd";
import {CustomButton} from "../../helpers/CustomButton";
import TitleStore from "../../components/authorization/store/TitleStore";


@observer
export class RoutePage extends CustomComponent<{}, {}> {
    store: RouteStore = new RouteStore();
    constructor(props: Readonly<{}>) {
        super(props);
        this.changeNamePoint = this.changeNamePoint.bind(this);
        this.changeValueLoadingFile = this.changeValueLoadingFile.bind(this);
        this.createPoint = this.createPoint.bind(this);
        this.deletePoint = this.deletePoint.bind(this);
        this.changeTitle = this.changeTitle.bind(this);

        this.onDragEnd = this.onDragEnd.bind(this);
        this.getClassArrow = this.getClassArrow.bind(this);
    }
    componentDidMount() {
        TitleStore.setTitle("Маршруты");
        this.store.fetchRequest();
        this.store.init()
    }
    componentWillUnmount() {
        this.store.uninit()
    }

    createPoint(pos: number) {
        this.store.createPoint(pos);
    }

    deletePoint(pos: number) {
        this.store.deletePoint(pos);
    }

    changeNamePoint(pos: number, val: string) {
        this.store.updatePoint(pos, val);
    }

    changeTitle(ex: ChangeEvent<HTMLInputElement>) {
        this.store.changeName(ex.target.value);
    }

    changeValueLoadingFile(ex: ChangeEvent<HTMLTextAreaElement>) {
        this.store.changeValueLoadingFile(ex.target.value);
    }

    onDragEnd(result2: DropResult, _: ResponderProvided) {
        if (!result2.destination) {
            return;
        }
        this.store.updatePoints(result2.source.index, result2.destination.index);
    }

    getClassArrow(isShow: boolean): string {
        if (isShow)
            return "";
        return "background-image-none";
    }

    render() {
        return (
            <div className="route-box">
                <div className="arrow-route">
                    <div className="del-i" id="deleteTable" title="Удалить" onClick={this.store.reactModalDeleteStore.open}></div>
                    <div className="add-loci" id="cut2" title="Добавить" onClick={this.store.createRouteRequest}></div>
                    <div className="add-loci" id="add-page-loci" title="Загрузить файл"
                         onClick={this.store.reactModalLoadingFileStore.open}></div>
                </div>
                <div className="arrows">
                    <div id="back-loci" className={this.getClassArrow(this.store.isPrevRoute)}
                         onClick={this.store.prevRoute}></div>
                    <input className="nwloci" type="text"
                           placeholder={this.store.getName}
                           onChange={this.changeTitle}
                           title={this.store.getName}/>
                    <div id="next-loci" className={this.getClassArrow(this.store.isNextRoute)}
                         onClick={this.store.nextRoute}></div>
                </div>

                {/*// <!-- Окно для не премиум аккаунтов-->*/}
                <div id="noaccess-routes" className="noaccess-win">
                    <div className="lock-i"></div>
                    <span className="del-txt">Лимит ограничен</span>
                    <span
                        className="del-info">Для добавления свыше 100 мест в маршруты подключите Premium-подписку</span>
                    <a href="/premiumbuy">
                        <div className="get-access">Оформить подписку</div>
                    </a>
                    <div className="closer"></div>
                </div>

                <div className="tablloci">
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, _) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {this.store.getPoints.map((x, index) =>
                                        <Draggable key={x.id + "_" + index} draggableId={x.id + "_" + index}
                                                   index={index}>
                                            {(provided, _) => (
                                                <RouteItem key={x.id + "_" + index}
                                                           pos={index}
                                                           point={x}
                                                           changeNamePoint={this.changeNamePoint}
                                                           createPoint={this.createPoint}
                                                           deletePoint={this.deletePoint}
                                                           provided={provided}
                                                />
                                            )}

                                        </Draggable>
                                    )}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>

                <CustomButton text={"СОХРАНИТЬ"} className="saveactive" onclick={this.store.updateRouteRequest}/>
                <div className="add-cut" id="cut1"></div>

                <Modal store={this.store.reactModalDeleteStore} autoSub={true}>
                    <div className="del-window">
                        <div className="del-i"></div>
                        <span className="del-txt">Удалить маршрут</span>
                        <span className="del-info">"{this.store.getName}"</span>
                        <div className="del-buttons">
                            <CustomButton text="Отменить" onclick={this.store.reactModalDeleteStore.close}/>
                            <CustomButton text="Подтвердить" onclick={this.store.onDeleteRoute}/>
                        </div>
                    </div>
                </Modal>

                <Modal store={this.store.reactModalLoadingFileStore} autoSub={true}>
                    <textarea id="comm-write" placeholder="Одна строчка или текст до запятой = один опорный образ"
                              rows={20} cols={60} style={{height: '100%', width: '100%'}}
                    onChange={this.changeValueLoadingFile}></textarea>
                    <div className="buttons-loading-file">
                        <CustomButton text="Добавить в конец"/>
                        <CustomButton text="Добавить в начало"/>
                        <CustomButton text="Создать новый маршрут" onclick={this.store.newCreateRoute}/>
                    </div>
                </Modal>
            </div>
        )
    }
}