import {CustomComponent} from "../../helpers/CustomComponent";
import './stoneComponent.scss';
import {StoneSelector, StoneStatus, StoneStore} from "./StoneStore";
import {observer} from "mobx-react";
import Modal from "../modal/ReactModal";
import {StoneSelectorTable} from "./components/StoneSelectorTable";
import {Loading} from "../shared/Loading";
import {ReactModalStore} from "../modal/ReactModalStore";


@observer
export class StoneComponent extends CustomComponent<{}, {}> {
    private readonly reactModalStore: ReactModalStore = new ReactModalStore();
    store: StoneStore;

    constructor(props: Readonly<{}>) {
        super(props);
        this.store = new StoneStore();

        this.changeStatus = this.changeStatus.bind(this);
    }

    componentDidMount() {
        this.store.request();
    }

    changeStatus() {
        this.store.changeStatus();
    }

    render() {
        return <div className="stone-component">
            <div id="status-seria" style={{
                borderColor: this.store.loadingStore.get?.stoneSelectedColor ?? '',
                color: this.store.loadingStore.get?.stoneSelectedColor ?? ''
            }} onClick={this.store.openShowModal}>{this.store.loadingStore.get?.daysOnlyLastStone ?? 0}</div>

            <Modal store={this.reactModalStore}>
                {
                    !this.store.loadingStore.isLoaded &&
                    <Loading/>
                }
                {
                    this.store.loadingStore.isLoaded &&
                    <section id="h-seria-windows">
                        {
                            this.store.stoneStatus === StoneStatus.choose &&
                            <div id="seria-ch">
                                <span className="s-main-name">Выберите ежедневную цель</span>

                                <div id="setup-seria-td">
                                    <div className="trs-h tds-f">
                                        <div className="tds-1">Мин. нагрузка <br/> баллов / день</div>
                                        <div className="tds-2">Количество дней серии</div>
                                        <div className="tds-2">Награда <br/> за серию</div>
                                    </div>
                                    <StoneSelectorTable
                                        key={StoneSelector.stone7.toString()}
                                        stoneSelector={StoneSelector.stone7}
                                        stone={"Аметист"}
                                        daysText={"7 дней"}
                                        countBalls={"50"}
                                        click={this.store.selectStone}
                                        isSelected={this.store.checkStone(StoneSelector.stone7)}
                                    />
                                    <StoneSelectorTable
                                        key={StoneSelector.stone14.toString()}
                                        stoneSelector={StoneSelector.stone14}
                                        stone={"Сапфир"}
                                        daysText={"14 дней"}
                                        countBalls={"100"}
                                        click={this.store.selectStone}
                                        isSelected={this.store.checkStone(StoneSelector.stone14)}
                                    />
                                    <StoneSelectorTable
                                        key={StoneSelector.stone21.toString()}
                                        stoneSelector={StoneSelector.stone21}
                                        stone={"Рубин"}
                                        daysText={"21 дней"}
                                        countBalls={"200"}
                                        click={this.store.selectStone}
                                        isSelected={this.store.checkStone(StoneSelector.stone21)}
                                    />
                                    <StoneSelectorTable
                                        key={StoneSelector.stone30.toString()}
                                        stoneSelector={StoneSelector.stone30}
                                        stone={"Изумруд"}
                                        daysText={"30 дней"}
                                        countBalls={"300"}
                                        click={this.store.selectStone}
                                        isSelected={this.store.checkStone(StoneSelector.stone30)}
                                    />
                                    <StoneSelectorTable
                                        key={StoneSelector.stone60.toString()}
                                        stoneSelector={StoneSelector.stone60}
                                        stone={"Бриллиант"}
                                        daysText={"60 дней"}
                                        countBalls={"500"}
                                        click={this.store.selectStone}
                                        isSelected={this.store.checkStone(StoneSelector.stone60)}
                                    />
                                </div>

                                <button id="start-seria" onClick={this.store.setStone}>Продолжить</button>
                                {
                                    this.store.loadingStore.get!.statusStone === 2 &&
                                    <div className="seria-stone-status status-stone2">
                                        <span
                                            id="rew-amount-day">Восстановление серии: {this.store.loadingStore.get!.daysOnlyLastStone} дня</span>

                                        <div className="rew-img" id="restore-seria">
                                            <img src="https://powermemory.io/images/rewards/restore-seria-off.png"
                                                 alt="restore"/>
                                        </div>

                                        <div id="restore-win-s">
                                            <span id="resname">Восстановление серии</span>
                                            <div id="resvar1">
                                                <span className="resvar">1 вариант</span>
                                                <p>Запомните 500 элементов в сумме за сегодня</p>
                                                <button id="ch-resvar1">Запомнить</button>
                                            </div>

                                            <div id="resvar2">
                                                <span className="resvar">2 вариант</span>
                                                <p>Платное восстановление 500 рублей</p>
                                                <a href="https://t.me/AlexGruk" id="ch-resvar2" target="_blank">Перейти
                                                    к оплате</a>
                                            </div>
                                            <div id="res-i"></div>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        {
                            this.store.stoneStatus === StoneStatus.main &&
                            <div id="seria-now" className="seria-stone-status status-stone-more2">
                                <span className="s-main-name" onClick={this.changeStatus}>
                                    {this.store.loadingStore.get!.stoneSelectedNameSeries}
                                </span>
                                <div id="show-reward"
                                     style={{backgroundImage: 'url(https://powermemory.io/' + this.store.loadingStore.get!.stoneSelectedImage + ')'}}></div>
                                <span id="min-load-n">Минимальная нагрузка</span>
                                <span
                                    id="min-load">{this.store.loadingStore.get!.stoneSelectedCountBalls} баллов / день</span>

                                {
                                    this.store.loadingStore.get!.statusStone === 2 &&
                                    <span className="seria-info seria-stone-status status-stone3" id="si-up">
                                        Для начала серии запомните сегодня {this.store.loadingStore.get!.stoneSelectedCountBalls} элементов
                                    </span>
                                }{
                                this.store.loadingStore.get!.statusStone === 3 &&
                                <span className="seria-info seria-stone-status status-stonstoneSelectedCountBallse3"
                                      id="si-up">
                                        Для начала серии запомните сегодня {this.store.loadingStore.get!.ballsNeed} элементов
                                    </span>
                            }
                                {
                                    this.store.loadingStore.get!.statusStone === 4 &&
                                    <span className="seria-info seria-stone-status status-stone4" id="si-up">
                                        Для продолжения серии осталось запомнить {this.store.loadingStore.get!.ballsNeed} элементов
                                    </span>
                                }
                                {
                                    this.store.loadingStore.get!.statusStone === 5 &&
                                    <span className="seria-info seria-stone-status status-stone5" id="si-up">
                                        {this.store.loadingStore.get!.daysOnlyLastStone} день серии!
                                    </span>
                                }
                                {
                                    this.store.loadingStore.get!.statusStone === 6 &&
                                    <span className="seria-info seria-stone-status status-stone6" id="si-up">
                                        Для продолжения серии осталось запомнить {this.store.loadingStore.get!.ballsNeed} элементов
                                    </span>
                                }
                                <div id="seria-calendar">
                                    <div id="sc-tr1">
                                        {this.store.calendar().map((x) => <span key={x.day + "_day"}>{x.day}</span>)}
                                    </div>
                                    <div id="sc-tr2">
                                        {this.store.calendar().map((x) => <div key={x.day + "_seria"} className="day-seria"></div>)}
                                    </div>
                                </div>
                                <span className="seria-info" id="si-down">
                                    До получения награды осталось {this.store.loadingStore.get!.stoneLeftDays} дней
                                </span>
                                {
                                    this.store.loadingStore.get!.statusStone === 2 &&
                                    <span className="seria-info seria-stone-status status-stone3" id="si-up">
                                        Для начала {this.store.loadingStore.get!.stoneSelectedNameSeries.toLowerCase()} запомните сегодня
                                        {this.store.loadingStore.get!.stoneSelectedCountBalls} элементов
                                    </span>
                                }
                                {
                                    this.store.loadingStore.get!.statusStone === 3 &&
                                    <span className="seria-info seria-stone-status status-stone3" id="si-up">
                                        Для начала {this.store.loadingStore.get!.stoneSelectedNameSeries.toLowerCase()} запомните сегодня
                                        {this.store.loadingStore.get!.ballsNeed} элементов
                                    </span>
                                }
                                {
                                    this.store.loadingStore.get!.statusStone === 4 &&
                                    <span className="seria-info seria-stone-status status-stone4" id="si-attention">
                                        Внимание! Если вы не наберёте {this.store.loadingStore.get!.ballsNeed} баллов за сегодня, серия будет обнулена.
                                    </span>
                                }
                                {
                                    this.store.loadingStore.get!.statusStone === 5 &&
                                    <span className="seria-info seria-stone-status status-stone5" id="si-attention">
                                        Внимание! Если вы не наберёте {this.store.loadingStore.get!.stoneSelectedCountBalls} баллов завтра, серия будет обнулена.
                                    </span>
                                }
                                {
                                    this.store.loadingStore.get!.statusStone === 6 &&
                                    <span className="seria-info seria-stone-status status-stone6" id="si-up">
                                        Для продолжения {this.store.loadingStore.get!.stoneSelectedNameSeries.toLowerCase()} запомните сегодня {this.store.loadingStore.get!.ballsNeed} элементов
                                    </span>
                                }
                            </div>
                        }
                    </section>
                }
            </Modal>
        </div>;
    }
}