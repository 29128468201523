import {IAnswerTrainingBaseService} from "../IMemberTrainingBaseService";
import {TrainingAnswerModel} from "../../model/TrainingAnswerModel";
import {TrainingAnswerRequestItemDto} from "../../model/TrainingAnswerRequestModel";
import {makeAutoObservable} from "mobx";
import React from "react";
import {CardPage, CardSelectedInPage, CardSelectedPages} from "./model/CardModel";
import {GetAllCards} from "./CardsConstants";
import {CardAnswerView} from "./view/CardAnswerView";
import {CardMemberView} from "./view/CardMemberView";


export class CardsTrainingAnswerBaseService implements IAnswerTrainingBaseService {
    model: TrainingAnswerModel;
    items: TrainingAnswerRequestItemDto[] = [];
    lastMouseItem: TrainingAnswerRequestItemDto;
    cards: CardPage[] = [];
    cardsSelected: CardSelectedPages[] = [];

    currentNumber: number = 0;
    currentPageNumber: number = 0;
    maxPageNumber: number = 0;
    countItemsPage: number = 52;

    constructor(model: TrainingAnswerModel, items: TrainingAnswerRequestItemDto[]) {
        makeAutoObservable(this, {}, {autoBind: true});

        this.model = model;
        this.items = items;
        this.lastMouseItem = items[0];
        this.currentPageNumber = 0;
        this.maxPageNumber = Math.ceil(items.length / this.countItemsPage);
        this.initPage();
    }

    get currentItemsPage() {
        return this.items.slice(this.currentPageNumber * this.countItemsPage, (this.currentPageNumber + 1) * this.countItemsPage);
    }

    get currentSelectedCardsPage() {
        if (!this.cardsSelected[this.currentPageNumber])
            return undefined;
        return this.cardsSelected[this.currentPageNumber].cards;
    }

    get current() {
        return this.currentNumber + this.currentPageNumber * this.countItemsPage;
    }

    initPage() {
        let cards = GetAllCards();
        for (let page = 0; page <= this.maxPageNumber; page++) {
            this.cards.push({
                cards: [],
            })
            for (let pos = 0; pos < this.countItemsPage; pos++) {
                this.cards[page].cards.push({
                    position: pos,
                    image: cards[pos]
                })
            }
            let cardsPage: CardSelectedInPage[] = GetAllCards().map(e => {
                return {
                    linkCard: e,
                    selected: false,
                }
            });
            this.cardsSelected.push({cards: cardsPage})
        }
        // this.nextCurrentNumber();
    }

    addBlock(pos: number) {
        // let x = 0;//pos: number
        // for (let x = pos; x < this.items.length; x++) {
        //
        // }
    }

    deleteBlock(pos: number) {
        //pos: number
        // for (let x = pos; x < this.items.length; x++) {
        //
        // }
    }

    next(){

    }
    prev(){

    }

    get view(): JSX.Element {
        return <></>;
    }

    clickAnswer(link: string) {
        this.currentItemsPage[this.currentNumber].answerImage = link;
        let item = this.currentSelectedCardsPage?.filter(x => x.linkCard === link)[0];
        if (item)
            item.selected = true;
        this.nextCurrentNumber();
    }

    returnAnswer(index: number) {

    }

    nextCurrentNumber() {
        if (this.currentItemsPage.filter(x => x.answerImage === undefined).length === 0)
            return;
        this.currentNumber++;
        if (this.currentNumber >= this.currentItemsPage.length) {
            this.currentNumber = -1;
            this.nextCurrentNumber();
        } else {
            if (this.currentItemsPage[this.currentNumber].answerImage)
                this.nextCurrentNumber();
        }
    }

    nextPage() {
        if (this.currentPageNumber < this.maxPageNumber) {
            this.currentPageNumber++;
            this.initPage();
        }
    }

    prevPage() {
        if (this.currentPageNumber >= 0) {
            this.currentPageNumber--;
            this.initPage();
        }
    }

    setCurrentNumber(setCurrentNumber: number) {
        this.currentNumber = setCurrentNumber;
    }

    get viewCustom(): JSX.Element | undefined {
        return <div id="ans_display-cards" className="ans-container" data-page-e="0">
            <div className="page_index__container">Колода {this.currentPageNumber + 1}</div>
            <div className="ans_area">
                <div className="ans_area__pages">
                    <div className="ans_area__page">
                        <div className="ans_area__answers">
                            {
                                this.currentItemsPage &&
                                this.currentItemsPage.map((x, index) => (
                                    <CardAnswerView key={x.id + "_" + x.answerImage}
                                                    returnCard={this.returnAnswer}
                                                    setCurrentNumber={this.setCurrentNumber}
                                                    selected={index === this.currentNumber}
                                                    answerImage={x.answerImage}
                                                    deleteBlock={this.deleteBlock}
                                                    addBlock={this.addBlock}
                                                    index={index}/>
                                ))
                            }
                        </div>
                        <div className="ans_area__controls">
                            {
                                this.maxPageNumber !== 1 &&
                                <div className="switch_page"><span
                                    className="switch_page__info">{this.currentPageNumber + 1} / {this.maxPageNumber}</span>
                                    <div className="switch_page__buttons">
                                        <div onClick={this.prevPage}
                                             className="switch_page__button switch_page__prev">Предыдущая страница
                                        </div>
                                        <div onClick={this.nextPage}
                                             className="switch_page__button switch_page__next">Следующая страница
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="ans_area__storage">
                            {
                                this.currentSelectedCardsPage &&
                                this.currentSelectedCardsPage.map((x, index) => (
                                    <CardMemberView key={x.linkCard + "_" + x.selected}
                                                    click={this.clickAnswer}
                                                    index={index}
                                                    linkCard={x.linkCard}
                                                    selected={x.selected}/>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}