import {CustomComponent} from "../../../helpers/CustomComponent";
import './customSelector.scss'
import {ChangeEvent} from "react";
import {observer} from "mobx-react";

export interface SelectorPros {
    value: string;
    title: string;
}

export interface SelectorGroupPros {
    selectors: SelectorPros[];
    title: string;
}

interface Props {
    defaultValue?: string;
    value?: string;
    values?: SelectorPros[];
    groups?: SelectorGroupPros[];

    onChange?(sel: SelectorPros | undefined): void;

    width?: string
    margin?: string
}
@observer
export default class CustomSelector extends CustomComponent<Props, {}> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.changeSelector = this.changeSelector.bind(this);
        this.getValue = this.getValue.bind(this);
        this.getGroupValue = this.getGroupValue.bind(this);
    }

    changeSelector(ev: ChangeEvent<HTMLSelectElement>) {
        if (this.props?.onChange)
            this.props?.onChange(this.getValue(ev.target.value, this.props.values) ?? this.getGroupValue(ev.target.value));
    }

    getValue(val: string, list?: SelectorPros[]): SelectorPros | undefined {
        if (list) {
            let current = list?.filter(x => x.value === val);
            if (current && current.length > 0)
                return current[0];
        }
        return undefined;
    }

    getGroupValue(val: string): SelectorPros | undefined {
        if (this.props.groups) {
            for (let group of this.props.groups) {
                let w = this.getValue(val, group.selectors)
                if (w)
                    return w
            }
        }
        return undefined;
    }

    render() {
        return (
            <div className="statchosen" style={{width: this.props.width ?? '225px', margin: this.props.margin ?? "0 auto"}}>
                <select className="chosen-select" value={this.props.value} defaultValue={this.props.defaultValue} onChange={this.changeSelector}>
                    {this.props.values?.map((x) =>
                        <option key={x.value} value={x.value}>{x.title}</option>
                    )}
                    {this.props.groups?.map((x) =>
                        <optgroup key={x.title} label={x.title}>
                            {x.selectors?.map((y) =>
                                <option key={y.value} value={y.value}>{y.title}</option>
                            )}
                        </optgroup>
                    )}
                </select>
            </div>);
    }
}