import {makeAutoObservable, observable} from "mobx";
import {SelectorGroupPros, SelectorPros} from "../../../shared/selector/CustomSelector";
import LoadingStore from "../../../../helpers/LoadingStore";
import Highcharts from "highcharts";
import {TrainingTimeAllModel} from "./TrainingTimeModel";


export default class TrainingTimeStore {
    @observable
    public selector: SelectorPros;

    @observable
    public loadingStore: LoadingStore<TrainingTimeAllModel>;

    public selectors: SelectorGroupPros[] = [{
        title: "Мнемоспорт",
        selectors: [
            {value: "all", title: "Все виды информации"},
            {value: "number", title: "Числа"},
            {value: "cards", title: "Карты"},
            {value: "word", title: "Слова"},
            {value: "name", title: "Имена"},
            {value: "bins", title: "Бинарные"},
            {value: "image", title: "Картинки"}]
    }, {
        title: "Мнемопрактика",
        selectors: [
            {value: "mnemopractics_all", title: "Вся мнемопрактика"},
            {value: "mnemopractics_english", title: "Английский язык"},]
    },
    ];

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true});
        this.selector = this.selectors[0].selectors.filter(x => x.value === 'all')[0];
        this.loadingStore = new LoadingStore<TrainingTimeAllModel>(this.getUrl)
    }

    getUrl(): string {
        return '/api/Statistic/TrainingTime?discipline=' + this.selector.value;
    }

    get options(): Highcharts.Options {
        return {
            chart: {
                type: 'area',
                style: {
                    'float': 'right'
                },
                zooming: {
                    type: 'x',
                },
            },
            rangeSelector: {
                selected: 0
            },
            title: {
                text: ''
            },
            xAxis: {
                type: 'datetime',
                title: {
                    text: ''
                },
                zoomEnabled: true
            },
            yAxis: {
                type: 'linear',
                title: {
                    text: ''
                },
            },
            series: [{
                name: 'Запоминание (мин)',
                data: this.loadingStore?.model.Model!.list
                    .map((x) => [new Date(x.date).getTime(), this.getTimeNumber(x.timeAll)]) ?? [],
                type: 'area',
                threshold: null,
                tooltip: {
                    valueDecimals: 3
                },
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, '#4572A7'],
                        [1, 'white']
                    ]
                }
            }, {
                name: 'Запоминание (мин)',
                data: this.loadingStore?.model.Model!.list
                    .map((x) => [new Date(x.date).getTime(), this.getTimeNumber(x.timeRecollection)]) ?? [],
                type: 'area',
                threshold: null,
                tooltip: {
                    valueDecimals: 3
                },
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, '#4572A7'],
                        [1, 'white']
                    ]
                }
            }],
        };
    }

    sumTimeAll() {
        return this.getValue(this.loadingStore.model?.Model?.sumTimeAll)
    }

    sumTimeRecollection() {
        return this.getValue(this.loadingStore.model?.Model?.sumTimeRecollection)
    }

    sumTimes() {
        return this.getValue(this.loadingStore.model?.Model?.sumTimes)
    }

    getValue(time?: number): string {
        if (!time)
            return "-"
        return (time / (60 * 60 * 1000)).toFixed(2)
    }

    getTimeNumber(time: number): number {
        return Math.ceil(((time / (60 * 1000))) * 1000) / 1000
    }

    setSelector(sel: SelectorPros) {
        this.selector = sel;
    }
}