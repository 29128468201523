import {CustomComponent} from "../../../../../helpers/CustomComponent";
import {TrainingAnswerRequestItemDto} from "../../../model/TrainingAnswerRequestModel";
import {ChangeEvent} from "react";
import {observer} from "mobx-react";
import {AnsCellFocusController} from "../../AnsCellFocusController";

interface Props {
    item: TrainingAnswerRequestItemDto,
    pos: number,
    value: string;
    deleteBlock: (pos: number) => void
    addBlock: (pos: number) => void
    changeName: (pos: number, text: string) => void
}
@observer
export class AnsCellNumber extends CustomComponent<Props, any> {
    public controller: AnsCellFocusController = new AnsCellFocusController();

    constructor(props: Readonly<Props>) {
        super(props);
        this.deleteBlock = this.deleteBlock.bind(this);
        this.addBlock = this.addBlock.bind(this);
        this.changeName = this.changeName.bind(this);
    }

    deleteBlock() {
        this.props.deleteBlock(this.props.pos);
    }

    addBlock() {
        this.props.addBlock(this.props.pos);
    }

    changeName(e: ChangeEvent<HTMLInputElement>) {
        this.props.changeName(this.props.pos, e.currentTarget.value);
    }

    render() {
        return <div className={"ans-cell-number" + (this.controller.hasFocus ? " input-write-answer-focus" : "")}>
            <span className="num-cell" data-title={this.props.item.routeName}>{this.props.pos + 1}</span>
            <div onClick={this.deleteBlock} className="add-cell" title="Удалить со сдвигом влево"></div>
            <div onClick={this.addBlock} className="del-cell" title="Добваить ячейку"></div>
            <input autoFocus={this.props.pos === 0}
                   type='tel'
                   onFocus={this.controller.onFocus}
                   onBlur={this.controller.onBLur}
                   ref={this.controller.setHTMLInputElement}
                   onChange={this.changeName}
                   maxLength={this.props.item.length ?? 100}
                   value={this.props.value}
                   className={"ans ans-focus ans-cell-number-width " + (this.controller.hasFocus ? " input-write-answer-focus" : "")}
                   max={this.props.item.length}/>
        </div>;
    }
}