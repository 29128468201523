import {LoadingModel} from "../../shared/LoadingModel";
import SettingsModel from "../model/SettingsModel";
import {makeAutoObservable} from "mobx";
import {GetRequest, PostRequestWithoutResponseBody} from "../../../helpers/Request";
import {LoadingUrlModel} from "../../../helpers/models/LoadingUrlModel";

export type SettingsType = 'main' | 'security';

export interface SettingsChangeEmail {
    email: string
    error?: string | undefined;
}

export interface SettingsChangePassword {
    oldPassword: string
    newPassword: string
    error?: string;
}

export default class SettingsStore {
    type: SettingsType = 'main'
    model: LoadingModel<SettingsModel> = LoadingModel.createLoaded();
    changeModel: SettingsModel | null = null;
    showModalUploadFile: boolean = false;

    changePassword: SettingsChangePassword = {
        oldPassword: "",
        newPassword: "",
        error: undefined
    }
    changeEmail: SettingsChangeEmail = {
        email: "",
        error: undefined
    }


    constructor() {
        makeAutoObservable(this, {}, {autoBind: true})
        this.fetchRequest()
    }

    setType(type: SettingsType) {
        this.type = type;
    }

    get sameEmail(): boolean {
        return this.changeEmail.email === this.model.Model?.email;
    }

    get savePassword(): boolean {
        return this.changePassword.newPassword === "" && this.changePassword.newPassword === this.changePassword.oldPassword;
    }

    setModel(model: LoadingModel<SettingsModel>) {
        this.model.IsLoaded = true;
        this.model = model;
        this.changeModel = model.Model!;
        this.changeEmail.email = model.Model?.email ?? "";
    }

    updateFirstnameAndSurname(val: string){
        return (val.charAt(0).toUpperCase() + val.slice(1).toLowerCase()).replaceAll(' ', '');
    }

    changeFirstname(val: string) {
        this.changeModel!.firsname = this.updateFirstnameAndSurname(val);
    }

    changeSurname(val: string) {
        this.changeModel!.surname = this.updateFirstnameAndSurname(val);
    }

    changeLink(val: string) {
        this.changeModel!.link = val;
    }

    changeTimeZone(val: number) {
        this.changeModel!.timeZone = val;
    }

    changeSocialNetworks(val: string) {
        this.changeModel!.socialNetworks = val;
    }

    changeSex(val: string) {
        this.changeModel!.sex = val;
    }

    changeCountry(val: string) {
        this.changeModel!.country = val;
    }

    changeCity(val: string) {
        this.changeModel!.city = val;
    }

    openModalUploadFile() {
        this.showModalUploadFile = true;
    }

    closedModalUploadFile() {
        this.showModalUploadFile = false;
    }

    deleteImage() {
        PostRequestWithoutResponseBody<object>('/api/Settings/deleteImage', new Object())
            .then(x => {
                this.fetchRequest();
            })
    }

    afterUpload(file: LoadingUrlModel | null) {
        if (!file) {
            console.log('ошибка загрузки файла')
        }

        PostRequestWithoutResponseBody<object>("/api/Settings/ChangeImage", {
            image: file!.files[0]!,
        }).then(x => {
            if (x.ok){
                alert('Успешно')
            }else{
                alert('Ошибка обновления картинки!')
            }
        })
    }

    fetchRequest() {
        this.model.IsLoaded = false;
        GetRequest<SettingsModel>('/api/Settings/get')
            .then(x => this.setModel(x))
    }

    vkAuth() {
        // var headers = new Headers();
        // headers.append('Accept', 'application/json');
        // headers.append('Access-Control-Allow-Origin', '*');
        // $http.get('/Settings/RedirectToVk', {headers: headers})
        //     .then(function (response){
        //         $scope.getAll();
        //     });
    }

    validName(name: string, type: string): string | undefined {
        if (name.length <= 1) {
            return 'При заполнения поля с ' + type + ', включите воображение и придумайте комбинацию из 2 букв и более.';
        }

        let regex = /^[a-zA-Zа-яА-Я]+$/;
        if (!regex.test(name)) {
            return 'Пожалуйста, используйте только буквы кириллицы или латиницы при заполнении поля с ' + type + '. Использование цифр или других символов не допускается.';
        }

        if (this.changeModel!.surname === this.changeModel!.firsname && this.changeModel!.surname === name){
            return 'Пожалуйста, укажите разные значения для полей Имя и Фамилия.';
        }

        return undefined;
    }

    changeEmailRequest() {
        PostRequestWithoutResponseBody<SettingsChangeEmail>('/api/Settings/ChangeEmail', this.changeEmail)
            .then(x => {
                if (x.ok) {
                    this.changeEmail.email = "";
                } else {
                    alert('Ошибка смены e-mail')
                }
            });
    }

    confirmEmail() {
        PostRequestWithoutResponseBody<object>('/api/Settings/ConfirmEmail', new Object())
            .then(x => {
                if (x.ok) {
                    alert('Сообщение отправленно, вам на e-mail')
                } else {
                    alert('Ошибка подтверждения e-mail')
                }
            });
    }

    changePasswordRequest() {
        PostRequestWithoutResponseBody<SettingsChangePassword>('/api/Settings/ChangePassword', this.changePassword)
            .then(x => {
                if (x.ok) {
                    alert('Пароль изменился')
                } else {
                    alert('Сменить пароль не получилось')
                }
            });
    }

    saveMainPage() {
        if (this.validName(this.changeModel!.firsname, '') !== undefined)
            return;
        if (this.validName(this.changeModel!.surname, '') !== undefined)
            return;
        if (this.changeModel!.surname === this.changeModel!.firsname)
            return;
        PostRequestWithoutResponseBody<SettingsModel>('/api/Settings/Save', this.changeModel!)
            .then(x => {
                if (x.ok){
                    console.log("Успешно обновилось")
                }else{
                    alert("Ошибка обновления")
                }
            })
    }
}