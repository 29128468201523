import {TrainingAnswerModel} from "../model/TrainingAnswerModel";
import {TrainingAnswerRequestItemDto} from "../model/TrainingAnswerRequestModel";
import {IAnswerTrainingBaseService} from "./IMemberTrainingBaseService";

export class AnswerTrainingBaseService implements IAnswerTrainingBaseService {
    model: TrainingAnswerModel;
    items: TrainingAnswerRequestItemDto[] = [];

    constructor(model: TrainingAnswerModel, items: TrainingAnswerRequestItemDto[]) {
        this.model = model;
        this.items = items;
        this.addBlock = this.addBlock.bind(this);
        this.deleteBlock = this.deleteBlock.bind(this);
        this.nextFocus = this.nextFocus.bind(this);
        this.changeName = this.changeName.bind(this);
    }

    get currentFocus() {
        let itemsHasFocus = this.items
            .filter(x => x?.p?.controller.hasFocus)
        if (itemsHasFocus.length > 0) {
            return itemsHasFocus[0]
        }
        return undefined;
    }

    next() {
        let itemFocus = this.currentFocus;
        if (!itemFocus)
            return;
        let index = this.items.findIndex(x => x.id === itemFocus!.id);
        this.nextFocusElement(index);
    };

    prev() {
        let itemFocus = this.currentFocus;
        if (!itemFocus)
            return;
        let index = this.items.findIndex(x => x.id === itemFocus!.id);
        this.prevFocusElement(index);
    };

    addBlock(pos: number) {
        for (let x = this.items.length - 1; x > pos ; x--) {
            if (x - 1 === pos) {
                this.items[x].answer = "";
                this.items[x].answerImage = "";
                continue;
            }
            this.items[x].answer = this.items[x - 1].answer;
            this.items[x].answerImage = this.items[x - 1].answerImage;
        }
    }

    deleteBlock(pos: number) {
        for (let x = pos; x < this.items.length; x++) {
            if (x === this.items.length - 1) {
                this.items[x].answer = "";
                this.items[x].answerImage = "";
                return;
            }
            this.items[x].answer = this.items[x + 1].answer;
            this.items[x].answerImage = this.items[x + 1].answerImage;
        }
    }

    nextFocus(pos: number) {
        let needItems = this.items
            .filter(x => x.numberPosition > pos)
            .filter(x => x.answer.length < x.length!)
        if (needItems.length < 1) {
            needItems = this.items
                .filter(x => x.answer.length < x.length!)
            if (needItems.length < 1)
                return;
        }
        needItems.sort((a, b) =>
            a.numberPosition - b.numberPosition);
        let nextItem = needItems[0];
        nextItem.p?.controller.onFocus()
    }

    nextFocusElement(pos: number) {
        let needItems = this.items
            .filter(x => x.numberPosition > pos)
        if (needItems.length < 1) {
            needItems = this.items
            if (needItems.length < 1)
                return;
        }
        needItems.sort((a, b) =>
            a.numberPosition - b.numberPosition);
        let nextItem = needItems[0];
        nextItem.p?.controller.onFocus()
    }

    prevFocusElement(pos: number) {
        let needItems = this.items
            .filter(x => x.numberPosition < pos);
        if (needItems.length < 1) {
            needItems = this.items;
            if (needItems.length < 1)
                return;
        }
        needItems.sort((a, b) =>
            a.numberPosition - b.numberPosition);
        let nextItem = needItems[needItems.length - 1];
        nextItem.p?.controller.onFocus()
    }

    changeName(pos: number, text: string) {
        let item = this.items.filter(x =>
            x.numberPosition === pos)[0];
        if (item.length && text.length >= item.length) {
            this.nextFocus(pos);
        }
        if (item.length && text.length <= item.length) {
            item.answer = text;
        }
    }

    get view(): JSX.Element {
        return <></>
    }

    get viewCustom(): JSX.Element | undefined {
        return undefined;
    }
}