import {CustomComponent} from "../../../helpers/CustomComponent";
import {observer} from "mobx-react";
import {UniversityFinishModel} from "../models/UniversityTrainingModel";

interface Props {
    model : UniversityFinishModel
}

@observer
export class FinishUniversityTrainingComponent extends CustomComponent<Props, any> {


    render() {
        return <div className="finish-university-training">
        </div>;
    }
}