import './motivationPage.scss'
import React from "react";
import TitleStore from "../../components/authorization/store/TitleStore";

export function MotivationPage() {
    TitleStore.setTitle("Спортивная тренировка");

    return <div id="contener-2" className="contener-2-help">

        <div className="parent_popup" id="parent_popup-stat"></div>

        <nav id="navsat">
            <div id="navburger"></div>
            <div className="nav-item" id="nav-stat">
                <span className="chc-span">Система мотивации</span>
                <input className="navsatin" id="hp-1" type="radio" name="help-op" value="system-balls" checked/>
                <label className="sattxt" id="hp-1label" htmlFor="hp-1">Система баллов</label>

                <input className="navsatin" id="hp-2" type="radio" value="system-ranks" name="help-op"/>
                <label className="sattxt" id="hp-2label" htmlFor="hp-2">Ранговая система</label>

                <input className="navsatin" id="hp-3" type="radio" value="series" name="help-op"/>
                <label className="sattxt" id="hp-3label" htmlFor="hp-3">Серия</label>

                <input className="navsatin" id="hp-4" type="radio" value="simons-star" name="help-op"/>
                <label className="sattxt" id="hp-4label" htmlFor="hp-4">Звезда Симонида</label>

                <input className="navsatin" id="hp-5" type="radio" value="pyramid-mnemosyne"
                       name="help-op"/>
                <label className="sattxt" id="hp-5label" htmlFor="hp-5">Пирамида Мнемозины</label>

                <input className="navsatin" id="hp-6" type="radio" value="lvl-perfection"
                       name="help-op"/>
                <label className="sattxt" id="hp-6label" htmlFor="hp-6">Уровень
                    совершенства</label>

                <input className="navsatin" id="hp-7" type="radio" value="medalists-month"
                       name="help-op"/>
                <label className="sattxt" id="hp-7label" htmlFor="hp-7">Медалисты
                    месяца</label>
            </div>
        </nav>
        {/*// <!--Cистема баллов-->*/}
        <aside className="info-help" id="system-balls-body">

            <span className="infohelpname" id="system-balls-header">Система баллов</span>

            <p>За каждый запомненный элемент информации вы получаете 1 балл.</p>

            <p><strong>Элемент</strong> – это информация, которая кодируется в один образ.</p>

            <p>Иллюстрация:</p>

            <div className="help-illustration">
                <div className="h-ill h-ill1">
                    <img alt="help-men" src="https://powermemory.io/images/help/men1.png"/>
                    <p className="p-first">
                        Квинтилиан запомнил 20 цифр: <br/>
                        45 77 83 05 96 <br/>
                        69 05 94 19 12 <br/>
                        использовалось 10 образных <br/>
                        кодов двузначных чисел
                    </p>
                </div>
                <div className="h-ill h-ill2">
                    <img alt="help-men" src="https://powermemory.io/images/help/men2.png"/>
                    <p className="p-first">
                        Цицерон запомнил 30 цифр: <br/>
                        451 773 835 057 960 <br/>
                        692 054 946 198 129 <br/>
                        использовалось 10 образных <br/>
                        кодов трёхзначных чисел
                    </p>
                </div>
            </div>

            <p>
                Цицерон, конечно, более эффективен, т.к. использовал образные коды
                на трёхзначные числа, однако, как и Квинтилиан, он тоже запомнил
                10 элементов (образов), а значит, они оба получают +10 баллов.
            </p>

            <p className="p-first">
                Так баллы отражают не количество запомненных единиц информации
                (999 – три цифры), а количество нейронных связей, которое удалось создать
                мнемонисту (999 – один элемент). Такой подход позволяет сразу понять сколько связей удалось создать
                мнемонисту.
            </p>

            <p>
                При этом запомненные вами элементы переведены в единицы информации здесь: <br/>
                <b><button title="Перейти на страницу статистики">Статистика -{">"} Объём запомненной информации</button></b>
            </p>

            <p className="p-first">Примеры элементов:</p>

            <table className="help-table" width="100%">
                <tr className="tr-color">
                    <td rowSpan={2} className="nbl"><b>Вид информации</b></td>
                    <td colSpan={3}><b>Один элемент (образ)</b></td>
                </tr>
                <tr className="tr-color tr-txt-color">
                    <td>1 ед. информации</td>
                    <td>2 ед. информации</td>
                    <td>3 ед. информации</td>
                </tr>
                <tr>
                    <td className="nbl">Цифры</td>
                    <td>9</td>
                    <td>99</td>
                    <td>999</td>
                </tr>
                <tr>
                    <td className="nbl">Карты</td>
                    <td>Туз Пик</td>
                    <td>Туз Пик, Дама Черви*</td>
                    <td>Туз Пик, Дама Черви, Три Крести**</td>
                </tr>
                <tr>
                    <td className="nbl">Слова</td>
                    <td>Стол</td>
                    <td>—</td>
                    <td>—</td>
                </tr>
                <tr>
                    <td className="nbl">Имена</td>
                    <td>Александр</td>
                    <td>—</td>
                    <td>—</td>
                </tr>
                <tr>
                    <td className="nbl">Бинарные</td>
                    <td>000</td>
                    <td>000 000</td>
                    <td>000 000 000</td>
                </tr>
                <tr>
                    <td className="nbl">Изображения</td>
                    <td>Одна картинка</td>
                    <td>—</td>
                    <td>—</td>
                </tr>
            </table>

            <p className="p-first">
                * — использование системы ЧДП (PAO), будет приносить +3 балла
                за 3 запомненные карты, поскольку три карты кодируются в два образа и одно
                действие, которое приравнивается к одному образу.
            </p>
            <p className="p-last">
                ** — использование системы теней или 2704, будет приносить +1 балл за 2
                запомненные карты, поскольку две карты кодируются в один образ.
            </p>
        </aside>

        {/*// <!--Ранговая система-->*/}

        <aside className="info-help" id="system-ranks-body">
            <span className="infohelpname" id="system-ranks-header">Ранговая система</span>

            <p>Для определения уровней мнемонистов на проекте PowerMemory используется ранговая система.</p>

            <p>
                Для получения нового ранга необходимо выполнить три условия:<br/>
                1. Набрать достаточное количество <b>баллов</b><br/>
                2. Выполнить контрольные <b>задания</b><br/>
                3. Сдать <b>нормативы</b> по указанным дисциплинам
            </p>

            <p>
                Если вы набрали достаточное количество баллов, но не сдали нормативы
                и/или не выполнили контрольное задание, ранг не повышается.
            </p>

            <p>
                Таким образом, ранг PowerMemory является не просто пустым текстом, а реальным,
                заслуженным достижением мнемониста, которое отражает не количественные, а качественные достижения.
            </p>

            <table className="help-table">
                <tbody>
                <tr className="tr-color">
                    <td rowSpan={2} className="nbl nbl-firs" style={{width: '232px'}}><b>Ранг</b></td>
                    <td colSpan={3}><b>Первое и второе условия получения ранга</b></td>
                </tr>
                <tr className="tr-color">
                    <td style={{width: '249px'}}><b>Баллы</b></td>
                    <td><b>Контрольные задания</b></td>
                </tr>
                <tr>
                    <td className="nbl"><span className="grad1"></span>lvl 15 Архитектор памяти</td>
                    <td>1 000 000 +</td>
                    <td>
                        <span className="hh-table hh-2tr"
                              data-title="Выучить +6 иностранных языков не ниже уровня intermediate и подтвердить владение языками выученных ранее">
                        + 5 иностранных языков<sup>?</sup>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td className="nbl"><span className="grad2"></span>lvl 14 Оракул</td>
                    <td>600 000 – 999 999</td>
                    <td>
                        <span className="hh-table" data-title="Выучить +1 иностранный язык не ниже уровня intermediate">
                        + 1 иностранный язык<sup>?</sup>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td className="nbl"><span className="grad3"></span>lvl 13 Легенда</td>
                    <td>500 000 – 599 999</td>
                    <td>
                        <span className="hh-table" data-title="Выучить +1 иностранный язык не ниже уровня intermediate">
                        + 1 иностранный язык<sup>?</sup>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td className="nbl"><span className="grad4"></span>lvl 12 Виртуоз</td>
                    <td>400 000 – 499 999</td>
                    <td>
                        <span className="hh-table hh-2tr"
                              data-title="Выучить +1 иностранный язык не ниже уровня intermediate; Достичь темпа кодирования 1 сек / две игральные карты">
                        + 1 иностранный язык; Темп 1 сек / 2 карты<sup>?</sup>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td className="nbl"><span className="grad5"></span>lvl 11 Гуру</td>
                    <td>300 000 – 399 999</td>
                    <td>
                        <span className="hh-table" data-title="Выучить +1 иностранный язык не ниже уровня intermediate">
                        + 1 иностранный язык<sup>?</sup>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td className="nbl"><span className="grad6"></span>lvl 10 Гроссмейстер</td>
                    <td>200 000 – 299 999</td>
                    <td>
                        <span className="hh-table hh-2tr"
                              data-title="Расширить маршрутную стимульную систему до 2000 мест; Достичь темпа кодирования в режиме &#34;выбор из двух&#34;">
                        Создать не менее 2000 loci <sup>?</sup><br/>
                        Темп 1 сек / число ХХХ<sup>?</sup>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td className="nbl"><span className="grad7"></span>lvl 9 Мастер памяти</td>
                    <td>100 000 – 199 999</td>
                    <td>
                        <span className="hh-table hh-2tr"
                              data-title="Расширить маршрутную стимульную систему до 1000 мест; Достичь темпа кодирования в режиме &#34;выбор из двух&#34;">
                        Создать не менее 1000 loci<sup>?</sup><br/>
                        Темп 1.5 сек / число ХХХ<sup>?</sup>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td className="nbl"><span className="grad8"></span>lvl 8 Подмастерье</td>
                    <td>50 000 – 99 999</td>
                    <td data-title="">
                        <span className="hh-table hh-1tr"
                              data-title="Расширить маршрутную стимульную систему до 500 мест">
                        Создать не менее 500 loci<sup>?</sup>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td className="nbl"><span className="grad9"></span>lvl 7 Профессионал</td>
                    <td>40 000 – 49 999</td>
                    <td data-title="">
                        <span className="hh-table hh-1tr"
                              data-title="Расширить маршрутную стимульную систему до 400 мест">
                        Создать не менее 400 loci<sup>?</sup>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td className="nbl"><span className="grad10"></span>lvl 6 Специалист</td>
                    <td>30 000 – 39 999</td>
                    <td data-title="">
                        <span className="hh-table hh-1tr"
                              data-title="Расширить маршрутную стимульную систему до 300 мест">
                        Создать не менее 300 loci<sup>?</sup>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td className="nbl"><span className="grad11"></span>lvl 5 Знаток</td>
                    <td>20 000 – 29 999</td>
                    <td>
                        <span className="hh-table"
                              data-title="Достичь темпа кодирования в режиме &#34;выбор из двух&#34;">
                        Темп 1 сек / число ХХ<sup>?</sup>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td className="nbl"><span className="grad12"></span>lvl 4 Опытный</td>
                    <td>10 000 – 19 999</td>
                    <td>
                        <span className="hh-table"
                              data-title="Достичь темпа кодирования в режиме &#34;выбор из двух&#34;">
                        Темп 1.5 сек / число ХХ<sup>?</sup>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td className="nbl"><span className="grad13"></span>lvl 3 Ученик</td>
                    <td>1 000 – 9 999</td>
                    <td>—</td>
                </tr>
                <tr>
                    <td className="nbl"><span className="grad14"></span>lvl 2 Новичок</td>
                    <td>100 – 999</td>
                    <td>—</td>
                </tr>
                <tr>
                    <td className="nbl"><span className="grad15"></span>lvl 1 Начинающий</td>
                    <td>50 – 99</td>
                    <td>—</td>
                </tr>
                <tr>
                    <td className="nbl"><span className="grad16"></span>lvl 0 Никто</td>
                    <td>0 – 49</td>
                    <td>—</td>
                </tr>
                </tbody>
            </table>

            <p className="p-first">Таблица с нормативами по всем дисциплинам:</p>

            {/*// <!--Импуты для управления таблицей ниже через чистый CSS-->*/}

            <input id="number-nor" type="radio" name="sel-nor" checked/>
            <input id="cards-nor" type="radio" name="sel-nor"/>
            <input id="words-nor" type="radio" name="sel-nor"/>
            <input id="bin-nor" type="radio" name="sel-nor"/>
            <input id="names-nor" type="radio" name="sel-nor"/>
            <input id="images-nor" type="radio" name="sel-nor" />

                <table className="help-table" style={{width: '900px', marginTop: '20px'}}>
                    <tbody>
                    <tr className="tr-color">
                        <td className="nbl nbl-firs" rowSpan={2} style={{width: '232px'}}><b>Ранг</b>
                        </td>
                        <td rowSpan={2} style={{width: '249px'}}><b>Дисциплины</b></td>
                        <td colSpan={6}><b>Третье условие – нормативы</b></td>
                    </tr>
                    <tr>
                        <td className="select-nor number-nor-lable"><label><b>Числа</b></label></td>
                        <td className="select-nor cards-nor-lable"><label
                            ><b>Карты</b></label></td>
                        <td className="select-nor words-nor-lable"><label
                            ><b>Слова</b></label></td>
                        <td className="select-nor bin-nor-lable"><label
                            ><b>Бинарные</b></label></td>
                        <td className="select-nor names-nor-lable"><label
                            ><b>Имена</b></label></td>
                        <td className="select-nor images-nor-lable"><label
                            ><b>Изобр.</b></label></td>
                    </tr>
                    <tr>
                        <td className="nbl"><span className="grad1"></span>lvl 15 Архитектор памяти
                        </td>
                        <td>Числа, Карты + <b style={{color: 'red'}}>2 на выбор</b></td>
                        <td colSpan={6} className="nor-off number-nor">
                        <span className="hh-table hh-nor" data-title="Предполагается использование трёхзначных кодов">
                        1000 цифр / 5 мин; 100 цифр / 25 сек<sup>?</sup>
                        </span>
                        </td>
                        <td colSpan={6} className="nor-off cards-nor">
                        <span className="hh-table hh-nor" data-title="Предполагается кодироване 2-х карт в 1 образ">
                        10 колод / 5 мин; 1 колода / 25 сек<sup>?</sup>
                        </span>
                        </td>
                        <td colSpan={6} className="nor-off words-nor">50 слов / 50 сек <span
                            className="wnor">[спорт]</span></td>
                        <td colSpan={6} className="nor-off bin-nor">
                        <span className="hh-table hh-nor" data-title="Предполагается кодироване 9-ти знаков в 1 образ">
                        300 знаков / 25 сек<sup>?</sup>
                        </span>
                        </td>
                        <td colSpan={6} className="nor-off names-nor">50 имён / 1 мин</td>
                        <td colSpan={6} className="nor-off images-nor">50 фракталов / 50 сек</td>
                    </tr>
                    <tr>
                        <td className="nbl"><span className="grad2"></span>lvl 14 Оракул</td>
                        <td>Числа, Карты + <b style={{color: 'red'}}>2 на выбор</b></td>
                        <td colSpan={6} className="nor-off number-nor">
                        <span className="hh-table hh-nor" data-title="Предполагается использование трёхзначных кодов">
                        1000 цифр / 7 мин; 100 цифр / 28 сек<sup>?</sup>
                        </span>
                        </td>
                        <td colSpan={6} className="nor-off cards-nor">
                        <span className="hh-table hh-nor" data-title="Предполагается кодироване 2-х карт в 1 образ">
                        10 колод / 8 мин; 1 колода / 30 сек<sup>?</sup>
                        </span>
                        </td>
                        <td colSpan={6} className="nor-off words-nor">50 спорт слов / 1 мин <span
                            className="wnor">[спорт]</span></td>
                        <td colSpan={6} className="nor-off bin-nor">
                        <span className="hh-table hh-nor" data-title="Предполагается кодироване 9-ти знаков в 1 образ">
                        300 знаков / 28 сек<sup>?</sup>
                        </span>
                        </td>
                        <td colSpan={6} className="nor-off names-nor">50 имён / 1 мин 30 сек</td>
                        <td colSpan={6} className="nor-off images-nor">50 фракталов / 1 мин 30 сек
                        </td>
                    </tr>
                    <tr>
                        <td className="nbl"><span className="grad3"></span>lvl 13 Легенда</td>
                        <td>Числа, Карты + <b style={{color: 'red'}}>2 на выбор</b></td>
                        <td colSpan={6} className="nor-off number-nor">
                        <span className="hh-table hh-nor" data-title="Предполагается использование трёхзначных кодов">
                        1000 цифр / 8 мин; 100 цифр / 30 сек<sup>?</sup>
                        </span>
                        </td>
                            <td colSpan={6} className="nor-off cards-nor">
                        <span className="hh-table hh-nor" data-title="Предполагается кодироване 2-х карт в 1 образ">
                        10 колод / 9 мин; 1 колода / 35 сек<sup>?</sup>
                        </span>
                            </td>
                            <td colSpan={6} className="nor-off words-nor">50 спорт слов / 2
                                мин <span className="wnor">[спорт]</span></td>
                            <td colSpan={6} className="nor-off bin-nor">
                        <span className="hh-table hh-nor" data-title="Предполагается кодироване 9-ти знаков в 1 образ">
                        300 знаков / 30 сек<sup>?</sup>
                        </span>
                            </td>
                            <td colSpan={6} className="nor-off names-nor">50 имён / 1 мин 40 сек
                            </td>
                            <td colSpan={6} className="nor-off images-nor">50 фракталов / 1 мин 40
                                сек
                            </td>
                    </tr>
                    <tr>
                        <td className="nbl"><span className="grad4"></span>lvl 12 Виртуоз</td>
                        <td>Числа, Карты + <b style={{color: 'red'}}>2 на выбор</b></td>
                        <td colSpan={6} className="nor-off number-nor">
                        <span className="hh-table hh-nor" data-title="Предполагается использование трёхзначных кодов">
                        1000 цифр / 9 мин; 100 цифр / 32 сек<sup>?</sup>
                        </span>
                        </td>
                        <td colSpan={6} className="nor-off cards-nor">
                        <span className="hh-table hh-nor" data-title="Предполагается кодироване 2-х карт в 1 образ">
                        10 колод / 10 мин; 1 колода / 40 сек<sup>?</sup>
                        </span>
                        </td>
                        <td colSpan={6} className="nor-off words-nor">50 спорт слов / 2.5 мин <span
                            className="wnor">[спорт]</span></td>
                        <td colSpan={6} className="nor-off bin-nor">
                        <span className="hh-table hh-nor" data-title="Предполагается кодироване 9-ти знаков в 1 образ">
                        300 знаков / 32 сек<sup>?</sup>
                        </span>
                        </td>
                        <td colSpan={6} className="nor-off names-nor">50 имён / 2 мин</td>
                        <td colSpan={6} className="nor-off images-nor">50 фракталов / 2 мин</td>
                    </tr>
                    <tr>
                        <td className="nbl"><span className="grad5"></span>lvl 11 Гуру</td>
                        <td>Числа, Карты + <b style={{color: 'red'}}>2 на выбор</b></td>
                        <td colSpan={6} className="nor-off number-nor">
                        <span className="hh-table hh-nor" data-title="Предполагается использование трёхзначных кодов">
                        1000 цифр / 10 мин; 100 цифр / 34 сек<sup>?</sup>
                        </span>
                        </td>
                        <td colSpan={6} className="nor-off cards-nor">
                        <span className="hh-table hh-nor" data-title="Предполагается кодироване 2-х карт в 1 образ">
                        10 колод / 15 мин; 1 колода / 45 сек<sup>?</sup>
                        </span>
                        </td>
                        <td colSpan={6} className="nor-off words-nor">50 спорт слов / 3 мин <span
                            className="wnor">[спорт]</span></td>
                        <td colSpan={6} className="nor-off bin-nor">
                        <span className="hh-table hh-nor" data-title="Предполагается кодироване 9-ти знаков в 1 образ">
                        300 знаков / 34 сек<sup>?</sup>
                        </span>
                        </td>
                        <td colSpan={6} className="nor-off names-nor">40 имён / 1.5 мин</td>
                        <td colSpan={6} className="nor-off images-nor">40 фракталов / 2 мин</td>
                    </tr>
                    <tr>
                        <td className="nbl"><span className="grad6"></span>lvl 10 Гроссмейстер</td>
                        <td><b style={{color: 'red'}}>Все нормативы</b></td>
                        <td colSpan={6} className="nor-off number-nor">
                        <span className="hh-table hh-nor" data-title="Предполагается использование трёхзначных кодов">
                        <b style={{color: 'red'}}>1000 цифр / 20 мин;</b> 100 цифр / 40 сек<sup>?</sup>
                        </span>
                        </td>
                        <td colSpan={6} className="nor-off cards-nor">
                            <b style={{color: 'red'}}>10 колод / 20 мин;</b> 1 колода / 52 сек
                        </td>
                        <td colSpan={6} className="nor-off words-nor">30 спорт слов / 1 мин <span
                            className="wnor">[спорт]</span></td>
                        <td colSpan={6} className="nor-off bin-nor">300 знаков / 50 сек</td>
                        <td colSpan={6} className="nor-off names-nor">30 имён / 1 мин</td>
                        <td colSpan={6} className="nor-off images-nor">30 фракталов / 30 сек</td>
                    </tr>
                    <tr>
                        <td className="nbl"><span className="grad7"></span>lvl 9 Мастер памяти</td>
                        <td><b style={{color: 'red'}}>Все нормативы</b></td>
                        <td colSpan={6} className="nor-off number-nor">1000 цифр / 30 мин; 100 цифр
                            / 1 мин
                        </td>
                        <td colSpan={6} className="nor-off cards-nor"><b style={{color: 'red'}}>10 колод
                            / 30 мин;</b> 1 колода / 1 мин
                        </td>
                        <td colSpan={6} className="nor-off words-nor">20 спорт слов / 1 мин <span
                            className="wnor">[спорт]</span></td>
                        <td colSpan={6} className="nor-off bin-nor">300 знаков / 1 мин</td>
                        <td colSpan={6} className="nor-off names-nor">20 имён / 1 мин</td>
                        <td colSpan={6} className="nor-off images-nor">30 картинок / 30 сек</td>
                    </tr>
                    <tr>
                        <td className="nbl"><span className="grad8"></span>lvl 8 Подмастерье</td>
                        <td>Числа + <b style={{color: 'red'}}>4 на выбор</b></td>
                        <td colSpan={6} className="nor-off number-nor">100 цифр / 2 мин</td>
                        <td colSpan={6} className="nor-off cards-nor">1 колода / 2 мин</td>
                        <td colSpan={6} className="nor-off words-nor">50 слов / 2 мин <span
                            className="wnor"> [предметные]</span></td>
                        <td colSpan={6} className="nor-off bin-nor">300 знаков / 2 мин</td>
                        <td colSpan={6} className="nor-off names-nor">20 имён / 1,5 мин</td>
                        <td colSpan={6} className="nor-off images-nor">30 картинок / 1 мин</td>
                    </tr>
                    <tr>
                        <td className="nbl"><span className="grad9"></span>lvl 7 Профессионал</td>
                        <td>Числа + <b style={{color: 'red'}}>4 на выбор</b></td>
                        <td colSpan={6} className="nor-off number-nor">100 цифр / 3 мин</td>
                        <td colSpan={6} className="nor-off cards-nor">1 колода / 3 мин</td>
                        <td colSpan={6} className="nor-off words-nor">50 слов / 3 мин <span
                            className="wnor">[предметные]</span></td>
                        <td colSpan={6} className="nor-off bin-nor">300 знаков / 3 мин</td>
                        <td colSpan={6} className="nor-off names-nor">20 имён / 2 мин</td>
                        <td colSpan={6} className="nor-off images-nor">30 картинок / 2 мин</td>
                    </tr>
                    <tr>
                        <td className="nbl"><span className="grad10"></span>lvl 6 Специалист</td>
                        <td>Числа + <b style={{color: 'red'}}>4 на выбор</b></td>
                        <td colSpan={6} className="nor-off number-nor">100 цифр / 4 мин</td>
                        <td colSpan={6} className="nor-off cards-nor">1 колода / 4 мин</td>
                        <td colSpan={6} className="nor-off words-nor">50 слов / 4 мин <span
                            className="wnor">[предметные]</span></td>
                        <td colSpan={6} className="nor-off bin-nor">300 знаков / 4 мин</td>
                        <td colSpan={6} className="nor-off names-nor">20 имён / 2 мин 30 сек</td>
                        <td colSpan={6} className="nor-off images-nor">30 картинок / 2 мин 30 сек
                        </td>
                    </tr>
                    <tr>
                        <td className="nbl"><span className="grad11"></span>lvl 5 Знаток</td>
                        <td>Числа + <b style={{color: 'red'}}>3 на выбор</b></td>
                        <td colSpan={6} className="nor-off number-nor">100 цифр / 4 мин 30 сек</td>
                        <td colSpan={6} className="nor-off cards-nor">1 колода / 4 мин 30 сек</td>
                        <td colSpan={6} className="nor-off words-nor">50 слов / 4 мин 30 сек <span
                            className="wnor">[предметные]</span></td>
                        <td colSpan={6} className="nor-off bin-nor">300 знаков / 4 мин 30 сек</td>
                        <td colSpan={6} className="nor-off names-nor">20 имён / 3 мин</td>
                        <td colSpan={6} className="nor-off images-nor">30 картинок / 3 мин</td>
                    </tr>
                    <tr>
                        <td className="nbl"><span className="grad12"></span>lvl 4 Опытный</td>
                        <td>Числа + <b style={{color: 'red'}}>2 на выбор</b></td>
                        <td colSpan={6} className="nor-off number-nor">100 цифр / 5 мин</td>
                        <td colSpan={6} className="nor-off cards-nor">1 колода / 5 мин</td>
                        <td colSpan={6} className="nor-off words-nor">50 слов / 5 мин <span
                            className="wnor">[предметные]</span></td>
                        <td colSpan={6} className="nor-off bin-nor">300 знаков / 5 мин</td>
                        <td colSpan={6} className="nor-off names-nor">20 имён / 3 мин 30 сек</td>
                        <td colSpan={6} className="nor-off images-nor">30 картинок / 3 мин 30 сек
                        </td>
                    </tr>
                    <tr>
                        <td className="nbl"><span className="grad13"></span>lvl 3 Ученик</td>
                        <td>1 на выбор</td>
                        <td colSpan={6} className="nor-off number-nor">40 цифр / 4 мин</td>
                        <td colSpan={6} className="nor-off cards-nor">40 карт / 4 мин</td>
                        <td colSpan={6} className="nor-off words-nor">40 слов / 4 мин <span
                            className="wnor">[предметные]</span></td>
                        <td colSpan={6} className="nor-off bin-nor">120 знаков / 4 мин</td>
                        <td colSpan={6} className="nor-off names-nor">20 имён / 4 мин</td>
                        <td colSpan={6} className="nor-off images-nor">30 картинок / 4 мин</td>
                    </tr>
                    <tr>
                        <td className="nbl"><span className="grad14"></span>lvl 2 Новичок</td>
                        <td>1 на выбор</td>
                        <td colSpan={6} className="nor-off number-nor">20 цифр / 4 мин</td>
                        <td colSpan={6} className="nor-off cards-nor">20 карт / 4 мин</td>
                        <td colSpan={6} className="nor-off words-nor">20 слов / 4 мин <span
                            className="wnor">[предметные]</span></td>
                        <td colSpan={6} className="nor-off bin-nor">60 знаков / 4 мин</td>
                        <td colSpan={6} className="nor-off names-nor">20 имён / 4 мин 30 сек</td>
                        <td colSpan={6} className="nor-off images-nor">20 картинок / 3 мин</td>
                    </tr>
                    <tr>
                        <td className="nbl"><span className="grad15"></span>lvl 1 Начинающий</td>
                        <td>1 на выбор</td>
                        <td colSpan={6} className="nor-off number-nor">10 цифр / 2 мин</td>
                        <td colSpan={6} className="nor-off cards-nor">10 карт / 2 мин</td>
                        <td colSpan={6} className="nor-off words-nor">10 слов / 2 мин <span
                            className="wnor">[предметные]</span></td>
                        <td colSpan={6} className="nor-off bin-nor">30 знаков / 2 мин</td>
                        <td colSpan={6} className="nor-off names-nor">10 имён / 2 мин</td>
                        <td colSpan={6} className="nor-off images-nor">10 картинок / 2 мин</td>
                    </tr>
                    <tr>
                        <td className="nbl"><span className="grad16"></span>lvl 0 Никто</td>
                        <td>—</td>
                        <td colSpan={6} className="nor-off number-nor">—</td>
                        <td colSpan={6} className="nor-off cards-nor">—</td>
                        <td colSpan={6} className="nor-off words-nor">—</td>
                        <td colSpan={6} className="nor-off bin-nor">—</td>
                        <td colSpan={6} className="nor-off names-nor">—</td>
                        <td colSpan={6} className="nor-off images-nor">—</td>
                    </tr>
                    </tbody>
                </table>

                <p className="p-first">
                    Для сдачи норматива можно запоминать больший объём данных, чем необходимо.
                    При этом норматив будет выполнен, если вы правильно вспомните весь установленный
                    в таблице объём данных. На
                    сам результат допускается не более 10% ошибок.
                </p>
                <p>Сдача норматива принимается в автоматическом режиме по факту.</p>

                <p className="p-last">Администрация сайта имеет право потребовать видео
                    подтверждение результата для проверки подлинности.</p>

        </aside>

        {/*// <!--Серия-->*/}

        <aside className="info-help" id="series-body">
            <span className="infohelpname" id="series-header">Серия</span>

            <p>
                <b>Серия</b> – это количество дней подряд, в течение которых вы тренировались на сайте.
            </p>

            <p>
                <b>Минимальная нагрузка</b> – это количество баллов, которое необходимо набрать в день
                для продолжении серии.
            </p>

            <p>
                <b>Награда</b> – один камень, который вы получаете за определенное количество дней серии с выбранной
                минимальной нагрузкой.
            </p>

                <div className="rewards-seria">
                    <div className="kamni" id="amet"><span>Аметист</span></div>
                    <div className="kamni" id="sapf"><span>Сапфир</span></div>
                    <div className="kamni" id="izum"><span>Изумруд</span></div>
                    <div className="kamni" id="rub"><span>Рубин</span></div>
                    <div className="kamni" id="bril"><span>Бриллиант</span></div>
                </div>

                <table className="help-table">
                    <tbody>
                    <tr className="tr-color">
                        <td className="nbl nbl-firs" style={{width: '220px'}}>Минимальная нагрузка</td>
                        <td style={{width: '220px'}}>Количество дней серии</td>
                        <td style={{width: '220px'}}>Награда</td>
                    </tr>
                    <tr>
                        <td className="nbl nbl-firs">50 баллов / день</td>
                        <td>7</td>
                        <td>Аметист</td>
                    </tr>
                    <tr>
                        <td className="nbl nbl-firs">100 баллов / день</td>
                        <td>14</td>
                        <td>Сапфир</td>
                    </tr>
                    <tr>
                        <td className="nbl nbl-firs">200 баллов / день</td>
                        <td>21</td>
                        <td>Изумруд</td>
                    </tr>
                    <tr>
                        <td className="nbl nbl-firs">300 баллов / день</td>
                        <td>30</td>
                        <td>Рубин</td>
                    </tr>
                    <tr>
                        <td className="nbl nbl-firs">500 баллов / день</td>
                        <td>60</td>
                        <td>Бриллиант</td>
                    </tr>
                    </tbody>
                </table>

                <p className="p-first">
                    <b>Серия прерывается</b> в случае 1 пропущенного дня тренировки.
                </p>

                <p className="p-last">
                    <b>Восстановить серию</b> можно не позднее следующего дня после пропущенного. Для этого необходимо
                    нажать на кнопку «Восстановить серию» и выбрать один из двух способов восстановления:
                    <br/>1 способ. Набрать 500 баллов за сегодня. Серия будет восстановлена автоматически.
                    <br/>2 способ. Оплатить штраф 500р. Серия будет восстановлена после проверки оплаты.
                </p>

        </aside>

        {/*// <!--Звезда Симонида-->*/}

        <aside className="info-help" id="simons-star-body">

            <span className="infohelpname" id="simons-star-header">Звезда Симонида</span>

            <p><b>Звезда Симонида</b> даётся тем, кто смог набрать определённое количество баллов за день.</p>

            <div className="rewards-seria2">
                <div className="starsim" id="starsim1"><span>1 уровень</span></div>
                <div className="starsim" id="starsim2"><span>2 уровень</span></div>
                <div className="starsim" id="starsim3"><span>3 уровень</span></div>
            </div>

            <table className="help-table">
                <tbody>
                <tr className="tr-color">
                    <td className="nbl nbl-firs" style={{width: '450px'}}>Звезда Симонида</td>
                    <td style={{width: '450px'}}>Кол-во баллов за день</td>
                </tr>
                <tr>
                    <td className="nbl nbl-firs">1 уровень</td>
                    <td>1000-1999</td>
                </tr>
                <tr>
                    <td className="nbl nbl-firs">2 уровень</td>
                    <td>2000-2999</td>
                </tr>
                <tr>
                    <td className="nbl nbl-firs">3 уровень</td>
                    <td>3000 и больше</td>
                </tr>
                </tbody>
            </table>


        </aside>

        {/*// <!--Пирамида Мнемозины -->*/}
        <aside className="info-help" id="pyramid-mnemosyne-body">

            <span className="infohelpname" id="pyramid-mnemosyne-header">Пирамида Мнемозины</span>

            <p><b>Пирамида Мнемозины</b> даётся тем, кто смог набрать определённое количество баллов за месяц.</p>

            <div className="rewards-seria">
                <div className="starsim" id="mnemosina1"><span>1 уровень</span></div>
                <div className="starsim" id="mnemosina2"><span>2 уровень</span></div>
                <div className="starsim" id="mnemosina3"><span>3 уровень</span></div>
                <div className="starsim" id="mnemosina4"><span>4 уровень</span></div>
                <div className="starsim" id="mnemosina5"><span>5 уровень</span></div>
                <br/>
                <div className="starsim mnemosina-top" id="mnemosina6"><span>6 уровень</span></div>
                <div className="starsim mnemosina-top" id="mnemosina7"><span>7 уровень</span></div>
                <div className="starsim mnemosina-top" id="mnemosina8"><span>8 уровень</span></div>
                <div className="starsim mnemosina-top" id="mnemosina9"><span>9 уровень</span></div>
                <div className="starsim mnemosina-top" id="mnemosina10"><span>10 уровень</span></div>
            </div>

            <table className="help-table" id="mnemosina-top">
                <tbody>
                <tr className="tr-color">
                    <td className="nbl nbl-firs" style={{width: '300px'}}>Пирамида Мнемозины</td>
                    <td style={{width: '300px'}}>Кол-во баллов за месяц</td>
                </tr>
                <tr>
                    <td className="nbl nbl-firs">1 уровень</td>
                    <td>10000-19999</td>
                </tr>
                <tr>
                    <td className="nbl nbl-firs">2 уровень</td>
                    <td>20000-29999</td>
                </tr>
                <tr>
                    <td className="nbl nbl-firs">3 уровень</td>
                    <td>30000-39999</td>
                </tr>
                <tr>
                    <td className="nbl nbl-firs">4 уровень</td>
                    <td>40000-49999</td>
                </tr>
                <tr>
                    <td className="nbl nbl-firs">5 уровень</td>
                    <td>50000-59999</td>
                </tr>
                <tr>
                    <td className="nbl nbl-firs">6 уровень</td>
                    <td>60000-69999</td>
                </tr>
                <tr>
                    <td className="nbl nbl-firs">7 уровень</td>
                    <td>70000-79999</td>
                </tr>
                <tr>
                    <td className="nbl nbl-firs">8 уровень</td>
                    <td>80000-89999</td>
                </tr>
                <tr>
                    <td className="nbl nbl-firs">9 уровень</td>
                    <td>90000-99999</td>
                </tr>
                <tr>
                    <td className="nbl nbl-firs">10 уровень</td>
                    <td>100000 и больше</td>
                </tr>
                </tbody>
            </table>

        </aside>

        {/*// <!-- Уровень совершенства -->*/}

        <aside className="info-help" id="lvl-perfection-body">

            <span className="infohelpname" id="lvl-perfection-header">Уровень совершенства</span>

            <p>
                <b>Уровень совершенства</b> присваивается за 10 безошибочных результатов в запоминании определённого
                количество цифровых элементов (цифры, двузначные или трёхзначные числа).
                Результаты должны быть получены подряд в течение 10 часов.
            </p>

            <div className="rewards-seria r-s-bottom">
                <div className="starsim" id="perfection"><span>Уровень совершенства</span></div>
            </div>


            <table className="help-table" style={{borderColor: '#fff', marginTop: 0, marginBottom: '200px'}}>
                <tbody>
                <tr className="tr-color">
                    <td className="nbl nbl-firs" rowSpan={2} style={{width: '170px'}}>Уровень</td>
                    <td colSpan={8}>Средний темп запоминания элементов в 10-ти результатах</td>
                    <td rowSpan={2}>Кол-во элементов для запоминания</td>
                </tr>
                <tr>
                    <td>2,0</td>
                    <td>1,5</td>
                    <td>1,0</td>
                    <td>0,9</td>
                    <td>0,8</td>
                    <td>0,7</td>
                    <td>0,6</td>
                    <td>0,5</td>
                </tr>
                <tr>
                    <td className="nbl nbl-firs">19</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>1000</td>
                </tr>
                <tr>
                    <td className="nbl nbl-firs">18</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>900</td>
                </tr>
                <tr>
                    <td className="nbl nbl-firs">17</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>800</td>
                </tr>
                <tr>
                    <td className="nbl nbl-firs">16</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>700</td>
                </tr>
                <tr>
                    <td className="nbl nbl-firs">15</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>600</td>
                </tr>
                <tr>
                    <td className="nbl nbl-firs">14</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>500</td>
                </tr>
                <tr>
                    <td className="nbl nbl-firs">13</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>400</td>
                </tr>
                <tr>
                    <td className="nbl nbl-firs">12</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>300</td>
                </tr>
                <tr>
                    <td className="nbl nbl-firs">11</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>200</td>
                </tr>
                <tr>
                    <td className="nbl nbl-firs">10</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>100</td>
                </tr>
                <tr>
                    <td className="nbl nbl-firs">9</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>90</td>
                </tr>
                <tr>
                    <td className="nbl nbl-firs">8</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>80</td>
                </tr>
                <tr>
                    <td className="nbl nbl-firs">7</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>70</td>
                </tr>
                <tr>
                    <td className="nbl nbl-firs">6</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>60</td>
                </tr>
                <tr>
                    <td className="nbl nbl-firs">5</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>50</td>
                </tr>
                <tr>
                    <td className="nbl nbl-firs">4</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>40</td>
                </tr>
                <tr>
                    <td className="nbl nbl-firs">3</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>30</td>
                </tr>
                <tr>
                    <td className="nbl nbl-firs">2</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>20</td>
                </tr>
                <tr>
                    <td className="nbl nbl-firs">1</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>10</td>
                </tr>
                <tr>
                    <td rowSpan={2} style={{borderLeft: 'none', borderBottom: 'none'}}></td>
                    <td>A</td>
                    <td>B</td>
                    <td>C</td>
                    <td>D</td>
                    <td>E</td>
                    <td>F</td>
                    <td>G</td>
                    <td>H</td>
                    <td rowSpan={2} style={{borderLeft: 'none', borderBottom: 'none'}}></td>
                </tr>
                <tr>
                    <td className="tr-color" colSpan={8}>Сектор</td>
                </tr>
                </tbody>
            </table>

        </aside>

        {/*// <!-- Медалисты месяца -->*/}

        <aside className="info-help" id="medalists-month-body">

            <span className="infohelpname" id="medalists-month-header">Медалисты месяца</span>

            <p><b>Медалисты месяца</b> определяются по набранным баллам за месяц. Существуют первое, второе и третье
                место.</p>

            <p>Топ всех мнемонистов за прошедший месяц и день по ссылке <a
                href="/Home/TopUsers">powermemory.io/home/TopUsers</a> или по кнопке "Все пользователи" на главной
                странице сайта внизу таблицы с баллами.</p>

        </aside>


        {/*// <!-- Рейтинг битв  -->*/}

        <aside className="info-help" id="rating-battle-body">

            <span className="infohelpname" id="rating-battle-header">Рейтинг битв</span>

            <p>Справка по рейтингу битв будет доступна с версией сайта 3.0</p>

        </aside>


        {/*// <!-- Мой профиль -->*/}

        <aside className="info-help" id="my-profil-body">

            <span className="infohelpname" id="my-profil-page-header">Мой профиль</span>

            <p>По нажатию на ваш аватар - любой пользователь сайта может увидеть ваши личные рекорды, награды нформацию
                о вас.</p>

        </aside>
    </div>
}