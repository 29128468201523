import {CustomComponent} from "../../../../helpers/CustomComponent";
import {PropsTrainingType} from "./PropsTrainingType";
import {TitleComponent, TypePositionText, TypeTitle} from "../../../../helpers/title/TitleComponent";
import {ImageComponent} from "../../../../helpers/img/ImageComponent";
import React from "react";
import {observer} from "mobx-react";
import {CheckAdjectiveComponent} from "../../../../helpers/nextComponent/CheckAdjectiveComponent";


@observer
export class MemberAdjectiveComponentProps extends CustomComponent<PropsTrainingType, any> {
    constructor(props: Readonly<PropsTrainingType>) {
        super(props);
        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
    }

    next() {
        this.props.next(this.props.item)
    }

    back() {
        this.props.back(this.props.item)
    }

    render() {
        return <>
            <TitleComponent text={this.props.currentItem.nameRus} typePosition={TypePositionText.center}
                            typeTitle={TypeTitle.bigTitle}/>
            {
                this.props.currentItem.nuance &&
                <TitleComponent text={this.props.currentItem.nuance} typePosition={TypePositionText.center}
                                typeTitle={TypeTitle.bigTitle}/>
            }
            {
                <ImageComponent key={this.props.currentItem.image} src={this.props.currentItem.image}
                                alt={this.props.currentItem.nameRus}/>
            }
            <TitleComponent text={this.props.currentItem.translationRus} typePosition={TypePositionText.center}
                            typeTitle={TypeTitle.description}/>
            <CheckAdjectiveComponent text={"Запомнил"}
                                     positionInfo={this.props.positionInfo}
                                     onClick={this.next} />
        </>;
    }
}