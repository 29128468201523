import {observer} from "mobx-react";
import {EnglishWordsStore} from "./EnglishWordsStore";
import {reaction} from "mobx";
import CustomSelector from "../../../shared/selector/CustomSelector";
import {CustomComponent} from "../../../../helpers/CustomComponent";
import React from "react";
import {Loading} from "../../../shared/Loading";


@observer
export class EnglishWords extends CustomComponent<{}, {}> {
    private store: EnglishWordsStore = new EnglishWordsStore();

    componentDidMount() {
        this.store.loadingStore.fetchRequest();
        reaction(
            () => [this.store.selector.value],
            this.store.loadingStore.fetchRequest
        );
    }

    render() {
        if (!this.store.loadingStore.isLoaded)
            return <Loading />
        return (
            <>
                <span className="sat-name">Английские слова</span>
                <span className="sat-name sat-name2">Тенденции выносливости</span>

                <CustomSelector
                    defaultValue={this.store.selector.value}
                    onChange={this.store.setSelector}
                    values={this.store.selectors}/>
                <div className="words-body">
                    <div className="words-rows words-header">
                        <div className="words-title words-columns">
                            Слово
                        </div>
                        <div className="words-attempts words-columns">
                            Кол-во успешных повторов/Кол-во повторов
                        </div>
                    </div>
                    {this.store.loadingStore?.model?.Model?.map(x =>
                        <div key={x.id} className="words-rows">
                            <div className="words-title words-columns">{x.nameRus}/{x.nameEng}</div>
                            <div className="words-attempts words-columns">{x.attemptsSuccessful}/{x.attempts}</div>
                        </div>)
                    }
                </div>
            </>
        );
    }
}