import Highcharts from "highcharts";

export interface HighOpinion {
    date: { date: string, value: number }[],
    nameSeries: string,
    valueDecimals: number
    chartType?: string
}

export function getOpinion(opinion: HighOpinion): Highcharts.Options {
    return {
        lang: {
            loading: 'Загрузка...',
            months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
            weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
            shortMonths: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'],
            // exportButtonTitle: "Экспорт",
            // printButtonTitle: "Печать",
            rangeSelectorFrom: "С",
            rangeSelectorTo: "По",
            rangeSelectorZoom: "Период",
            downloadPNG: 'Скачать PNG',
            downloadJPEG: 'Скачать JPEG',
            downloadPDF: 'Скачать PDF',
            downloadSVG: 'Скачать SVG',
            printChart: 'Напечатать график'
        },
        chart: {
            type: opinion.chartType ?? 'column',
            style: {
                'float': 'right'
            },
            zooming: {
                type: 'x',
            },
        },
        title: {
            text: ''
        },
        xAxis: {
            type: 'datetime',
            title: {
                text: ''
            },
            zoomEnabled: true
        },
        yAxis: {
            type: 'linear',
            title: {
                text: ''
            },
        },
        rangeSelector: {
            buttons: [{
                count: 1,
                type: 'month',
                text: '1M'
            }, {
                count: 5,
                type: 'month',
                text: '5M'
            }, {
                type: 'all',
                text: 'Alles'
            }],
            inputEnabled: false,
            selected: 0,
            // buttons: [{
            //     type: 'month',
            //     text: '1M'
            // }, {
            //     type: 'month',
            //     text: '5M'
            // }, {
            //     type: 'all',
            //     text: 'All'
            // }],
            // inputEnabled: true,
            // selected: 0
        },
        series: [{
            name: opinion.nameSeries,
            data: opinion?.date
                .sort((a, b) =>
                    new Date(a.date).getTime() - new Date(b.date).getTime())
                .map((x, y) => [new Date(x.date).getTime(), x.value]) ?? [],
            type: 'area',
            threshold: null,
            tooltip: {
                valueDecimals: opinion.valueDecimals
            },
            fillColor: {
                linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1
                },
                stops: [
                    [0, '#4572A7'],
                    [1, 'white']
                ]
            }
        }]
    };
}

export function getOpinionColumn(opinion: HighOpinion): Highcharts.Options {
    return {
        chart: {
            type: 'column',
            style: {
                'float': 'right'
            }
        },
        rangeSelector: {
            selected: 0
        },
        title: {
            text: ''
        },
        xAxis: {
            type: 'datetime',
            title: {
                text: ''
            },
            zoomEnabled: true
        },
        yAxis: {
            type: 'linear',
            title: {
                text: ''
            },
        },
        plotOptions: {
            series: {
                stacking: 'normal'
            },
            area: {
                fillOpacity: 1,
                lineWidth: 1,
                step: 'center'
            }
        },
        legend: {
            borderWidth: 1,
            shadow: false
        },
        series: [{
            name: opinion.nameSeries,
            data: opinion?.date
                .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
                .map((x, y) => [new Date(x.date).getTime(), x.value]) ?? [],
            type: 'column',
            threshold: null,
            maxPointWidth: 45,
            tooltip: {
                valueDecimals: opinion.valueDecimals
            },
        }]
    }
}

export interface HighOpinionPie {
    date: { name: string, y: number }[],
    titleText: string,
    colors?: string[],
}
export function getOpinionPie(pie: HighOpinionPie): Highcharts.Options {
    return {
        colors: pie?.colors ?? [],
        chart: {
            type: 'pie'
        },
        title: {//'0,0</br><span class="txt-actstl">средний темп</br>кодирования</br>за последние</br>10 результатов</span>',
            text: pie?.titleText,
            align: 'center',
            verticalAlign: 'middle',
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                borderWidth: 2,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b><br>{point.percentage:.1f}%',
                    distance: 3
                }
            }
        },
        series: [{
            animation: {
                duration: 2000
            },
            innerSize: '65%',
            enableMouseTracking: false,
            colorByPoint: true,
            type: 'pie',
            data: pie.date
        }],
    }
}