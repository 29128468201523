import {CustomComponent} from "../../../helpers/CustomComponent";
import React from "react";
import TrainingSportBaseStore from "../store/TrainingSportBaseStore";
import CustomSelector, {SelectorPros} from "../../shared/selector/CustomSelector";
import {observer} from "mobx-react";

@observer
export class RouteMemberCoding extends CustomComponent<{ store: TrainingSportBaseStore }, {}> {

    constructor(props: Readonly<{ store: TrainingSportBaseStore }>) {
        super(props);
        this.onChangeSystem = this.onChangeSystem.bind(this);
        this.clickRandom = this.clickRandom.bind(this);
        this.clickRandomMin = this.clickRandomMin.bind(this);
        this.clickRandomOrder = this.clickRandomOrder.bind(this);
        this.onChangeFigurativeCodes = this.onChangeFigurativeCodes.bind(this);
        this.onChangeRange = this.onChangeRange.bind(this);
    }

    onChangeSystem(selector: SelectorPros) {
        this.props.store.onChangeSystem(selector.value);
    }

    onChangeFigurativeCodes(selector: SelectorPros) {
        this.props.store.onChangeFigurativeCodes(selector.value);
    }
    onChangeRange(selector: SelectorPros) {
        this.props.store.onChangeRange(selector.value);
    }
    clickRandom(){
        this.props.store.onChangeGeneration('random')
    }
    clickRandomMin(){
        this.props.store.onChangeGeneration('randomMin')
    }
    clickRandomOrder(){
        this.props.store.onChangeGeneration('order')
    }

    render() {
        return <div>
            {/*// <!--1. Выбор системы кодов-->*/}
            <label className="way-text way-text1-1" id="name-SystemCod" htmlFor="nsc">Версия образов</label>
            <div className="select-style-training" id="cod-select1">
                <CustomSelector defaultValue={this.props.store.requestModel.system}
                                width="285px"
                                onChange={this.onChangeSystem}
                                values={this.props.store.codingTrainingCodingName()}/>
            </div>

            {/*// <!--2. Выбор образных кодов-->*/}
            <label className="way-text way-text1-2" id="name-Cod" htmlFor="nsc2">Вид информации</label>
            <div className="select-style-training loci" id="cod-select2">
                <CustomSelector defaultValue={this.props.store.requestModel.figurativeCodes}
                                width="285px"
                                onChange={this.onChangeFigurativeCodes}
                                values={this.props.store.codingTrainingCodingFigurative}/>
            </div>

            {/*// <!--3. Диапазон-->*/}
            <label className="way-text way-text1-2 podcat" id="name-Cod2" htmlFor="nsc2">Диапазон</label>
            <div className="select-style-training podcat">
                <CustomSelector defaultValue={this.props.store.requestModel.rate}
                                width="285px"
                                onChange={this.onChangeRange}
                                values={this.props.store.getRange()}/>
            </div>

            {/*// <!--4. Генерация-->*/}
            <div id="codgen-div">
                <span id="name-codgen">Генерация</span>

                <input id="random-gen" type="radio" name="chwGeneration"
                       value="random"/>
                <label className={this.props.store.getClassNameGeneration('random')}
                       onClick={this.clickRandom}
                       id="reflecs-label" htmlFor="random-gen">Случайная</label>

                <input id="random-min-gen" type="radio" name="chwGeneration"
                       value="randomMin"/>
                <label className={this.props.store.getClassNameGeneration('randomMin')}
                       onClick={this.clickRandomMin}
                       id="random-min-label" htmlFor="random-min-gen">Случайная MIN</label>

                <input id="az-gen" type="radio" name="chwGeneration"
                       value="order"/>
                <label className={this.props.store.getClassNameGeneration('order')}
                       onClick={this.clickRandomOrder}
                       id="az-gen-label" htmlFor="az-gen">Последовательная</label>
            </div>
        </div>;
    }
}