import {observer} from "mobx-react";
import {CustomComponent} from "../../../../../helpers/CustomComponent";

export interface BallsRankProps{
    numberPosition: number;
    ballsNow: number;
    ballsRequired: number
}
@observer
export class BallsRank extends CustomComponent<BallsRankProps, {}> {

    constructor(props: Readonly<BallsRankProps>) {
        super(props);
    }

    get percent(): string{
        return this.props.ballsNow > this.props.ballsRequired ?
            '100' :
            (this.props.ballsNow/this.props.ballsRequired*100).toFixed(2);
    }
    render() {
        return (<div className="balls_rank">
            {
                this.props.ballsNow > this.props.ballsRequired &&
                <div className="exercise-box">
                    <div className="ex-block-title">
                        <div className="ex-name">{this.props.numberPosition}. Получите достаточный опыт</div>
                        <div className="ex-info-question"></div>
                    </div>
                    <div className="ex-info-box">Один балл = 1 связь = 1 запомненный элемент. Подробнее о системе баллов по
                        ссылке: "Система баллов"
                    </div>
                    <div className="exercise-complicate">Заработано баллов: {this.props.ballsNow} из {this.props.ballsRequired}</div>
                </div>
            }
            {
                this.props.ballsNow <= this.props.ballsRequired &&
                <div className="exercise-box">
                    <div className="ex-block-title">
                        <div className="ex-name">{this.props.numberPosition}. Получите достаточный опыт</div>
                        <div className="ex-info-question"></div>
                    </div>
                    <div className="ex-info-box">Один балл = 1 связь = 1 запомненный элемент. Подробнее о системе баллов по
                        ссылке: "Система баллов"</div>
                    <div className="ex-line-box">
                        <div className="ex-greenline">
                            <div className="greenline-active" id="greenline-balls" style={{'width': this.percent+'%'}}></div>
                        </div>
                        <div className="exgl-quantity">
                            <div className="quantity-now">{this.props.ballsNow} баллов ({this.percent}%)</div>
                            <div className="quantity-need">{this.props.ballsRequired}</div>
                        </div>
                    </div>
                </div>
            }
            </div>);
    }
}