import {CustomComponent} from "../../../../../helpers/CustomComponent";
import {TrainingAnswerRequestItemDto} from "../../../model/TrainingAnswerRequestModel";
import {ChangeEvent} from "react";
import {AnsCellFocusController} from "../../AnsCellFocusController";
import {observer} from "mobx-react";

export interface AnsCellNumberProps {
    item: TrainingAnswerRequestItemDto,
    pos: number,
    value: string;
    deleteBlock: (pos: number) => void
    addBlock: (pos: number) => void
    changeName: (pos: number, text: string) => void
}

@observer
export class AnsCellWordCell extends CustomComponent<AnsCellNumberProps, any> {
    public controller: AnsCellFocusController = new AnsCellFocusController();

    constructor(props: Readonly<AnsCellNumberProps>) {
        super(props);
        this.deleteBlock = this.deleteBlock.bind(this);
        this.addBlock = this.addBlock.bind(this);
        this.changeName = this.changeName.bind(this);
        this.onFocus = this.onFocus.bind(this);
    }

    deleteBlock() {
        this.props.deleteBlock(this.props.pos);
    }

    addBlock() {
        this.props.addBlock(this.props.pos);
    }

    changeName(e: ChangeEvent<HTMLInputElement>) {
        this.props.changeName(this.props.pos, e.currentTarget.value);
    }

    onFocus() {
        this.controller.onFocus();
    }

    render() {
        let focusClass = this.controller.hasFocus ? " input-write-answer-focus" : "";
        return <div className={"ans-cell-word " + focusClass}>
            <span className="num-cell" data-title={this.props.item.routeName}>{this.props.pos + 1}</span>
            <div onClick={this.deleteBlock} className="add-cell" title="Удалить со сдвигом влево"></div>
            <div onClick={this.addBlock} className="del-cell" title="Добваить ячейку"></div>
            <input autoFocus={this.props.pos === 0}
                   onFocus={this.controller.onFocus}
                   onBlur={this.controller.onBLur}
                   ref={this.controller.setHTMLInputElement}
                   onChange={this.changeName}
                   value={this.props.value}
                   className={"ans-text ans-focus " + focusClass}
            />
        </div>;
    }
}