import {CustomComponent} from "../../../../helpers/CustomComponent";
import {PropsTrainingType} from "./PropsTrainingType";
import {TitleComponent, TypePositionText, TypeTitle} from "../../../../helpers/title/TitleComponent";
import {ImageComponent} from "../../../../helpers/img/ImageComponent";
import React from "react";
import {observer} from "mobx-react";
import {makeAutoObservable} from "mobx";
import HeaderTrainingLearnLanguageStore from "../HeaderTrainingLearnLanguageStore";
import {NextAndPrevAdjectiveComponent} from "../../../../helpers/nextComponent/NextAndPrevAdjectiveComponent";
import {AudioComponent} from "../../../../helpers/audio/AudioComponent";


@observer
export class AudioAdjectiveComponent extends CustomComponent<PropsTrainingType, any> {
    private store: AudioAdjectiveStore;

    constructor(props: Readonly<PropsTrainingType>) {
        super(props);
        this.store = new AudioAdjectiveStore(this.props);
    }

    render() {
        return <>
            <AudioComponent key={"audio_" + this.props.currentItem.audio}
                            src={this.props.currentItem.audio}/>
            {
                this.store.showHint &&
                <>
                    <TitleComponent key={this.props.currentItem.nameRus}
                                    text={this.props.currentItem.nameRus}
                                    typePosition={TypePositionText.center}
                                    typeTitle={TypeTitle.bigTitle}/>
                    {
                        this.props.currentItem.nuance &&
                        <TitleComponent text={this.props.currentItem.nuance} typePosition={TypePositionText.center}
                                        typeTitle={TypeTitle.bigTitle}/>
                    }

                    <ImageComponent key={this.props.currentItem.image}
                                    src={this.props.currentItem.image}
                                    alt={this.props.currentItem.nameRus}/>

                    <TitleComponent key={this.props.currentItem.translationRus}
                                    text={this.props.currentItem.translationRus}
                                    typePosition={TypePositionText.center}
                                    typeTitle={TypeTitle.description}/>
                </>
            }
            <NextAndPrevAdjectiveComponent next={this.store.next}
                                           back={this.store.back}
                                           positionInfo={this.props.positionInfo} />
        </>
    }
}

class AudioAdjectiveStore {
    props: PropsTrainingType;
    showHint: boolean = false;

    constructor(props: PropsTrainingType) {
        this.props = props;
        makeAutoObservable(this, {}, {autoBind: true})
        HeaderTrainingLearnLanguageStore.setHintTraining(this.showHintClick);
    }

    showHintClick() {
        if (!this.showHint) {
            this.props.item.tryCount++;
        }
        this.showHint = !this.showHint;
    }

    next() {
        this.showHint = false;
        this.props.next(this.props.item)
    }

    back() {
        this.showHint = false;
        this.props.back(this.props.item)
    }
}