import {CustomComponent} from "../CustomComponent";
import './manyAnswers.scss'

interface Props {
    models?: ManyAnswerModel[];
}

export type ManyAnswerModel = {
    title: string;
    isSelected?: boolean;
}

export class ManyAnswer extends CustomComponent<Props, any> {
    render() {
        return <div className="many-answers">
            {this.props.models?.map(x =>
                <div className="many-answer-block" key={x.title}>
                    <input
                        type="checkbox"
                        // checked={x.isSelected}
                        // onChange={handleChange}
                    />
                    <div className="many-answers-title">{x.title}</div>
                </div>)}
        </div>;
    }
}