import {observer} from "mobx-react";
import {CustomComponent} from "../../../../helpers/CustomComponent";
import {ArchiveResultsModel} from "./ArchiveResultsModel";
import {CustomDateTime} from "../../../../helpers/CustomDate";
import {CheckScreen, TypeScreen} from "../../../../context/TypeScreen";
import {getPercentErrorFixed, getSpeed, getTimeResult} from "../../../../helpers/numberHelp/numberHelp";
import React from "react";
import {Link} from "react-router-dom";


@observer
export class ArchiveResultColumns extends CustomComponent<{model: ArchiveResultsModel}, {}>{

    render() {
        return (<>
            {
                this.props.model.trainingObjects.length === 0 &&
                <span className="empty-stat2"
                      id="activity_empty">Начните тренироваться, чтобы увидеть Ваши результаты</span>
            }
            {
                this.props.model.trainingObjects.map((x, index) =>
                    <div className="results-table-userinfo" key={x.id}>
                        <div className="results-table-number td-number">
                            {this.props.model.count - index}
                        </div>
                        <div className="td-del">
                            {
                                x.activity && <div className="del-i" title="Удалить"></div>
                            }
                            {
                                !x.activity && <div className="restore" title="Восстановить"></div>
                            }
                        </div>
                        <div className="results-table-name td-name">
                            <span className="results-time">{CustomDateTime(x.date)}</span>
                        </div>
                        <div className="results-table-index td-index">{x.index.toFixed(2)}</div>
                        <div className="results-table-allelements td-allelements">{x.itemsCount}</div>
                        <div className="results-table-error td-error">
                            {
                                CheckScreen([TypeScreen.Tablet, TypeScreen.Desktop]) &&
                                <span className="desktop">{x.error} ({getPercentErrorFixed(x.itemsCount, x.error, 0)}%)</span>
                            }
                            {
                                CheckScreen([TypeScreen.Mobile]) &&
                                <span className="mobile">{getPercentErrorFixed(x.itemsCount, x.error, 0)}%</span>
                            }
                        </div>
                        <div className="results-table-speed td-speed">{getSpeed(x.speed)}</div>
                        <div className="results-table-time td-time">{getTimeResult(x.timeAll)}</div>
                        <div className="results-table-more td-more">
                            <Link id="moreref" to={"/Training/Search?id=" + x.id}></Link>
                        </div>
                    </div>
                )
            }
        </>);
    }
}