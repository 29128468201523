import {CustomComponent} from "../../helpers/CustomComponent";
import React from "react";
import {SmallPostModel} from "./model/SmallPostModel";
import './smallPosts.css'
import {Link} from "react-router-dom";

export interface Props {
    post: SmallPostModel
}

export class SmallPosts extends CustomComponent<Props, {}> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.getLink = this.getLink.bind(this);
    }

    getLink(): string {
        return '/news/' + this.props.post.link;
    }

    render() {
        return (
            <section className="news-box news1">
                <div className="news-picture">
                    <Link to={this.getLink()}>
                        <img src={this.props.post.image} alt="PowerMemory | Новости" title="Новости"/>
                    </Link>
                </div>

                <div className="news-cell">
                    <div className="news-ts">
                        <a className="news-title" href={this.getLink()}>{this.props.post.title}</a>
                        <a className="news-summary" href={this.getLink()}>{this.props.post.summary}</a>
                    </div>
                    <div className="news-complementary">
                        {
                            this.props.post?.rubrics?.map((x) =>
                                <Link key={x} className="tags" to={"/News?rubric=" + x}>{x}</Link>
                            )
                        }
                        <div className="page-view">{this.props.post.views}</div>
                        <a href={this.getLink() + "#comments"}>
                            <div className="page-comm">{this.props.post.comm}</div>
                        </a>
                    </div>
                </div>
            </section>
        )
    }
}