import React from 'react'
import './mnemocardComponent.scss'
import {observer} from "mobx-react";
import {Avatar} from "../user/Avatar";
import MnemocardStore from "./store/MnemocardStore";
import {CustomComponent} from "../../helpers/CustomComponent";


@observer
export class MnemocardUserInfoComponent extends CustomComponent<{}, {}> {

    render() {
        if (!MnemocardStore.mnemoCart.IsLoaded && !MnemocardStore.mnemoCart.Model)
            return null;
        return (
            <div id="user-info">
                <div className="user-info-div">
                    <Avatar fullname={MnemocardStore.mnemoCart.Model!.username}
                            img={MnemocardStore.mnemoCart.Model!.image}
                            link={MnemocardStore.mnemoCart.Model!.username}
                            width='140px' height='140px'/>
                </div>
                <div className="user-info-div">

                    <div className="user-name-card">
                        {MnemocardStore.mnemoCart.Model!.username}
                        {MnemocardStore.mnemoCart.Model!.isPremium &&
                            <div className="premium-user"></div>
                        }
                    </div>
                    <div className="rank">
                        <span className="uid-p">Ранг: </span>{MnemocardStore.mnemoCart.Model!.rank}
                    </div>
                    <div className="point">
                        <span className="uid-p">Баллы: </span>{MnemocardStore.mnemoCart.Model!.point}
                    </div>
                    <div className="place">
                        <span className="uid-p">Место на сайте: </span>{MnemocardStore.mnemoCart.Model!.place}
                    </div>
                    <div className="seria-status">
                    <span className="uid-p">{MnemocardStore.mnemoCart.Model!.stone.name}овая серия: </span>
                        {MnemocardStore.mnemoCart.Model!.stone.countDaysCurrent % MnemocardStore.mnemoCart.Model!.stone.countDaysOnStone} день из {MnemocardStore.mnemoCart.Model!.stone.countDaysOnStone}
                    </div>
                </div>
            </div>
        )
    }
}