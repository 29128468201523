import {CustomComponent} from "../../../../../helpers/CustomComponent";
import {observer} from "mobx-react";
import React from "react";


interface Props {
    index: number;
    selected: boolean;
    answerImage: string | undefined;
    deleteBlock: (index: number) => void;
    returnCard: (index: number) => void;
    addBlock: (index: number) => void;
    setCurrentNumber: (index: number) => void;
}

@observer
export class CardAnswerView extends CustomComponent<Props, {}> {
    constructor(props: Readonly<Props>) {
        super(props)
        this.addBlock = this.addBlock.bind(this);
        this.deleteBlock = this.deleteBlock.bind(this);
        this.setCurrentNumber = this.setCurrentNumber.bind(this);
    }

    addBlock() {
        this.props.addBlock(this.props.index);
    }

    deleteBlock() {
        this.props.deleteBlock(this.props.index);
    }

    setCurrentNumber(){
        this.props.setCurrentNumber(this.props.index);
    }

    get selectedClass(){
        if (this.props.selected)
            return " ans_cell-select";
        return "";
    }

    render() {
        return <div className={"cell ans_cell"} onClick={this.setCurrentNumber}>
            <div className="ans_cell__title"><span className="ans_cell__index"
                                                   data-title="Нет данных о локации">{this.props.index + 1}</span>
            </div>
            <div className={"ans_cell__card cell__card" + this.selectedClass}>
                {
                    this.props.answerImage &&
                    <div className="card" data-ans="ac"
                         style={{
                             backgroundImage: 'url(' + this.props.answerImage + ')',
                             left: '76.8438px',
                             top: '24px'
                         }}></div>
                }
            </div>
            <div className="ans_cell__controls">
                <div onClick={this.deleteBlock}
                     className="ans_cell__del_cell"
                     title="Удалить со сдвигом влево"></div>
                <div onClick={this.addBlock}
                     className="ans_cell__add_cell"
                     title="Добваить ячейку"></div>
            </div>
        </div>;
    }
}