import '../news/newsPostPage.scss'
import TitleStore from "../../components/authorization/store/TitleStore";

export function RulesPage(){
    TitleStore.setTitle("Пользовательское соглашение");
    return <div className="news-post-page">
        <div id="news-post">
            <hr className="news-page"/>
                <div className="news-theme"></div>

                <div className="news-stat">
                    <div className="author-date author-date2">Версия 2.0 от 13.10.2023</div>
                </div>

                <h1 id="page-name">Пользовательское соглашение</h1>
                <div className="news-theme"></div>
                <div className="news-theme">Правила сайта</div>


                <p style={{paddingTop: '10px'}}>
                    Настоящее Пользовательское соглашение является договором между Администрацией и Пользователем, в
                    котором определены условия пользования Сайтом, а также взаимные права и обязанности сторон.

                    В соответствии с п. 1 ст. 398 Гражданского кодекса Республики Беларусь настоящее Пользовательское
                    соглашение является договором присоединения.

                    В соответствии с п. 2 ст. 407 Гражданского кодекса Республики Беларусь настоящее Пользовательское
                    соглашение является публичной офертой. Пользовательское соглашение считается заключенным с момента
                    получения Администрацией согласия Пользователя с условиями настоящего Пользовательского соглашения
                    (акцепт оферты). Акцепт оферты производится путем осуществления Пользователем действий по
                    регистрации Учетной записи.

                    Пользовательское соглашение определяет условия использования Сайта, а также взаимные права и
                    обязанности сторон.

                </p>

                <p><b style={{fontSize: '160%'}}>Основные положения</b></p>

                <p><b style={{fontSize: '120%'}}>1. Общие положения</b></p>

                <p>1.1. Сайт PowerMemory, размещенный по адресу www.powermemory.io (далее — Сайт) — коммерческий ресурс
                    Общества с ограниченной ответственностью «ПауэрМемори», на котором размещаются статьи, фото- и
                    видеоинформация, рекламная и другая информация от имени Администрации, а также предоставляется
                    гражданам возможность тренировать память посредствам специальных тренажеров, читать статьи, писать
                    комментарии, проходить обучение.</p>
                <p>1.2. Термины и определения, применяемые в настоящих Пользовательском соглашении, имеют следующее
                    значение:</p>
                <p style={{paddingLeft: '30px'}}>1.2.1. Пользователь — пользователь сети Интернет, изъявивший желание
                    воспользоваться услугами Сайта, заключивший с Владельцем Интернет-ресурса (Администрацией)
                    Пользовательское соглашение.</p>
                <p style={{paddingLeft: '30px'}}>1.2.2. Имя и фамилия — реальное или вымышленное имя и фамилия
                    пользователя, под которым он зарегистрирован, отображаемое на экране данного пользователя Сайта.</p>
                <p style={{paddingLeft: '30px'}}>1.2.3. Юзерпик — картинка, отображаемая рядом с именем и фамилией
                    пользователя и сопровождающая все сообщения пользователя.</p>
                <p style={{paddingLeft: '30px'}}>1.2.4. Администрация — Общество с ограниченной ответственностью
                    «ПауэрМемори».</p>
                <p style={{paddingLeft: '30px'}}>1.2.5. Модератор — уполномоченное Администрацией лицо, занимающееся
                    Модерацией.</p>
                <p style={{paddingLeft: '30px'}}>1.2.6. Модерация — действия Модератора, направленные на соблюдение
                    пользователями Пользовательского соглашения.</p>
                <p style={{paddingLeft: '30px'}}>1.2.7. Информация — любые данные, тексты, графические изображения,
                    фотоизображения, программное обеспечение, базы данных или данные из баз данных в любом объеме,
                    аудио- и видеоинформация.</p>
                <p style={{paddingLeft: '30px'}}>
                    1.2.8. ID пользователя — это уникальный текст и/или комбинация цифр, которая включается в ссылку
                    ведущую на профиль пользователя, например, <a style={{color:'blue'}}
                                                                  href="https://powermemory.io/&#64;AlexGruk">powermemory.io/&#64;AlexGruk</a>.
                </p>

                <p style={{paddingLeft: '10px'}}>1.3. Согласие с настоящим Пользовательским соглашением может выражаться в
                    том, что:</p>
                <p style={{paddingLeft: '30px'}}>1.3.1. Вы создаете учетную запись на Сайте.</p>
                <p style={{paddingLeft: '30px'}}>1.3.2. Вы размещаете комментарии на Сайте.</p>
                <p style={{paddingLeft: '30px'}}>1.3.3. Вы посещаете Сайт. В этом случае Вы понимаете и соглашаетесь с
                    тем, что Администрация будет расценивать факт пользования Вами Сайтом как согласие с
                    Пользовательским соглашением с соответствующего момента времени.</p>
                <p style={{paddingLeft: '30px'}}>1.3.4. Запрещается регистрация пользователя на Сайте более одного раза
                    (повторная регистрация). Запрещается использование одним пользователем нескольких аккаунтов.</p>
                <p style={{paddingLeft: '30px'}}>1.3.5. Незнание Пользовательского соглашения не только не освобождает вас
                    от ответственности за их нарушение, но и само по себе является нарушением и отягчающим вину
                    обстоятельством.</p>
                <p>1.4. Осуществлять просмотр Сайта вправе любые посетители сети Интернет. Услугами Сайта вправе
                    пользоваться только Зарегистрированные Пользователи.</p>
                <p>1.5. Идентификация Пользователя.</p>
                <p style={{paddingLeft: '30px'}}>1.5.1. Пользование Услугами Сайта осуществляется Пользователем после его
                    Идентификации, которая производится при регистрации Учетной записи.</p>
                <p style={{paddingLeft: '30px'}}>1.5.2. Для регистрации на сайте Пользователь указывает свой адрес
                    электронной почты или профиль одного из следующих сервисов:</p>
                <p style={{paddingLeft: '30px'}}>— «ВКонтакте»;</p>
                <p style={{paddingLeft: '30px'}}>— Google;</p>
                <p style={{paddingLeft: '30px'}}>— Apple ID.</p>
                <p style={{paddingLeft: '30px'}}>Пользователь вправе заполнить дополнительные поля в своем профиле,
                    указать контактные данные, пол, возраст, страну и город.</p>

                <p><b style={{fontSize: '120%'}}>2. Права и обязанности:</b></p>

                <p>2.1. Права и обязанности Пользователя:</p>
                <p style={{paddingLeft: '30px'}}>2.1.1. Пользователь имеет право после активации своей Учетной записи
                    тренировать память посредствам специальных тренажеров, размещать на Сайте комментарии к статьям, при
                    условии авторизации на Сайте (введении при входе на Сайт своего логина и пароля, указанных при
                    регистрации), а также пользоваться любыми иными сервисами Сайта на платной и безвозмездной основе.
                    Пользователь имеет право на свободное высказывание собственного мнения по любой теме при условии
                    соблюдения ограничений, установленных действующим законодательством Республики Беларусь, настоящим
                    Пользовательским соглашением, прав третьих лиц.</p>
                <p style={{paddingLeft: '30px'}}>2.1.2. Пользователь обязуется соблюдать настоящее Пользовательское
                    соглашение.</p>
                <p style={{paddingLeft: '30px'}}>2.1.3. В случае несогласия с Пользовательским соглашением Пользователь
                    обязуется отказаться от дальнейшего использования Сайта.</p>
                <p style={{paddingLeft: '30px'}}>2.1.4. Пользователь обязуется предоставить полную, точную и достоверную
                    информацию при осуществлении процедуры регистрации в соответствии с вопросами регистрационной
                    формы.</p>
                <p style={{paddingLeft: '30px'}}>2.1.5. Пользователь обязуется не предпринимать действий, направленных на
                    подрыв сетевой безопасности либо нарушение работы Сайта, программно-технических средств
                    Администрации.</p>
                <p style={{paddingLeft: '30px'}}>2.1.6. Пользователь при размещении комментария, использовании юзерпика,
                    имени и фамилии и указании контактов обязан соблюдать международное законодательство, действовать
                    добросовестно, разумно и осмотрительно. Пользователь обязан учитывать тот факт, что в силу
                    возможности из любой точки мира просматривать Сайт Пользователь своими комментариями, юзерпиком,
                    именем и фамилией, контактами может нарушить законодательство какого-либо государства и может быть
                    признан в связи с этим ответственным по законодательству такого государства.</p>
                <p style={{paddingLeft: '30px'}}>2.1.7. Размещая на Сайте комментарии, используя юзерпик, имя и фамилию,
                    контакты, Пользователь тем самым подтверждает, что имеет на это все необходимые права, не нарушает
                    этим права третьих лиц, а также какие-либо законы и иные акты обязательного характера.</p>
                <p style={{paddingLeft: '30px'}}>2.1.8. Пользователь обязуется тренироваться честно и без использования
                    каких-либо читерских или недобросовестных методов, включая, но не ограничиваясь, использование
                    вспомогательных программ, мошеннических средств, либо иных способов, нарушающих правила и нормы
                    тренировки на нашем сайте.</p>

                <p style={{paddingLeft: '30px'}}>2.1.9. Пользователь обязуется тренироваться с учетом особенностей <a
                    style={{color:'blue'}} href="https://powermemory.io/help/motivation#system-balls">системы
                    баллов</a> принятой на сайте. Баллы начисляются в зависимости от количества связей между образами,
                    созданных в результате запоминания информации, а не по количеству запомненных единиц информации.</p>
                <p style={{paddingLeft: '30px'}}>2.1.10. Пользователь не имеет права копировать (воспроизводить),
                    перерабатывать, распространять, публиковать, скачивать, передавать, отчуждать или иным способом
                    использовать целиком или по частям Информацию на Сайте, размещенную другим пользователем либо
                    Администрацией, без предварительного разрешения такого другого пользователя (правообладателя, не
                    являющегося пользователем) либо Администрации. Для того чтобы использовать (копировать) такую
                    информацию, Пользователю необходимо связаться с таким пользователем, выяснить, кто является
                    правообладателем в отношении указанной выше Информации, и получить разрешение на использование такой
                    Информации.</p>
                <p style={{paddingLeft: '30px'}}>2.1.11. Пользователь обязуется не использовать Сайт для незаконных
                    целей.</p>
                <p style={{paddingLeft: '30px'}}>2.1.12. Пользователь обязуется не использовать Сайт, в частности,
                    для:</p>
                <p style={{paddingLeft: '30px'}}>• нарушения прав на интеллектуальную собственность;</p>
                <p style={{paddingLeft: '30px'}}>• оскорблений (диффамации), клеветы, умаления чести, достоинства, деловой
                    репутации физических лиц, умаления деловой репутации организаций и т. п.;</p>
                <p style={{paddingLeft: '30px'}}>• размещения порнографических материалов;</p>
                <p style={{paddingLeft: '30px'}}>• разжигания межнациональной розни;</p>
                <p style={{paddingLeft: '30px'}}>• размещения призывов изменить власть неконституционным путем;</p>
                <p style={{paddingLeft: '30px'}}>• размещения информации о том, как изготавливать взрывчатые вещества,
                    организовывать террористические акты;</p>
                <p style={{paddingLeft: '30px'}}>• размещения информации о способах совершения самоубийств;</p>
                <p style={{paddingLeft: '30px'}}>• размещения государственных секретов и коммерческих секретов (know-how и
                    т. п.);</p>
                <p style={{paddingLeft: '30px'}}>• размещения информации о лицах, не достигших совершеннолетия без
                    согласия их опекунов или родителей;</p>
                <p style={{paddingLeft: '30px'}}>• размещения иной любой информации, которая очевидно или случайно
                    способна причинить вред любым лицам, в том числе размещения множества одинаковых или не несущих
                    смысловой нагрузки комментариев;</p>
                <p style={{paddingLeft: '30px'}}>• публикации персональных данных третьих лиц (в частности, телефонных
                    номеров, адреса проживания) без их согласия;</p>
                <p style={{paddingLeft: '30px'}}>продажи или информирования о наркотических или психотропных средствах и
                    их прекурсорах;</p>
                <p style={{paddingLeft: '30px'}}>• размещения сообщений и (или) материалов, содержащих информацию,
                    распространение которой запрещено законодательством Республики Беларусь.</p>
                <p style={{paddingLeft: '30px'}}>2.1.13. Пользователь обязан контролировать логин и пароль, которые
                    необходимы для размещения сообщений и иных действий под Именем и Фамилией Пользователя и любые
                    действия на Сайте, сделанные с использованием Имени и Фамилии Пользователя, расцениваются
                    Администрацией исключительно как действия Пользователя, а не третьих лиц, которые могли каким-либо
                    образом перехватить контроль над Ником Пользователя. Таким образом, Пользователь несет полную
                    ответственность за действия, которые совершены при использовании его Имени и Фамилии.</p>
                <p style={{paddingLeft: '30px'}}>2.1.14. Пользователь также обязуется:</p>
                <p style={{paddingLeft: '30px'}}>• не изменять каким-либо образом программное обеспечение Сайта или
                    какую-либо его часть;</p>
                <p style={{paddingLeft: '30px'}}>• не получать доступ к Сайту с правами, которые позволяют контролировать
                    Сайт или любую его часть;</p>
                <p style={{paddingLeft: '30px'}}>• не подвергать Сайт воздействию вредоносных программ, хакерским атакам и
                    т. п.</p>
                <p>2.1.15. Пользователь имеет право в любой момент расторгнуть в одностороннем порядке настоящее
                    Пользовательское соглашение.</p>
                <p>2.2. Права и обязанности Администрации:</p>
                <p style={{paddingLeft: '30px'}}>2.2.1. Администрация вправе в течение срока действия Пользовательского
                    соглашения, а также в течение 1 года с даты его расторжения хранить, обрабатывать и представлять в
                    соответствии с действующим законодательством данные, указанные Пользователем в регистрационной
                    форме, а также сведения о размещении и (или) изменении Пользователем на Сайте информационных
                    сообщений и (или) материалов, дате и времени их размещения и (или) изменения; сведения о сетевом
                    (IP) адресе устройства Пользователя, присвоенном при регистрации Пользователя на Сайте, внесении
                    изменений в регистрационные данные Пользователя.</p>
                <p style={{paddingLeft: '30px'}}>2.2.2. Администрация вправе в любой момент расторгнуть в одностороннем
                    порядке настоящее Пользовательское соглашение и аннулировать учетную запись без уведомления об этом.
                    *</p>
                <p style={{paddingLeft: '30px'}}>2.2.3. Администрация при нарушении Пользователем настоящего
                    Пользовательского соглашения (в том числе если посчитает, что информация, предоставленная
                    Пользователем в регистрационной форме, не соответствует действительности) имеет право приостановить
                    или прекратить права данного Пользователя по использованию всех или некоторых возможностей
                    Сайта.</p>
                <p style={{paddingLeft: '30px'}}>2.2.4. Администрация имеет право расширять функциональность Сайта,
                    изменять объем, характер и способы пользования Сайтом, в том числе устанавливать ограничения
                    технического характера: количество комментариев, максимальная длина комментариев на Сайте,
                    периодичность сбора информации поисковой машиной, перечень поддерживаемых протоколов, номера
                    используемых портов и другие технические характеристики и параметры.</p>
                <p style={{paddingLeft: '30px'}}>2.2.5. Администрация имеет право планово либо внепланово изменять
                    технические характеристики и параметры программно-технических средств, задействованных на Сайте, в
                    том числе с временным приостановлением работы Сайта.</p>
                <p style={{paddingLeft: '30px'}}>2.2.6. Администрация оставляет за собой право хранить (архивировать)
                    любые комментарии и результаты о тренировках Пользователя на Сайте, использованные юзерпики с целью
                    сохранения (обеспечения) доказательств по любому гражданскому, уголовному, административному
                    делу.</p>
                <p style={{paddingLeft: '30px'}}>2.2.7. Администрация имеет право посылать через личный кабинет
                    Пользователям сервисные, информационные и рекламные сообщения, в том числе касающиеся
                    функционирования Сайта, изменений функционала Сайта и настоящего Соглашения, предупреждений,
                    предостережений, действий других пользователей Сайта и иных вопросов. Указанные сообщения
                    загружаются автоматически, возможности отключить получение сообщений нет.</p>
                <p style={{paddingLeft: '30px'}}>2.2.8. Администрация вправе высылать Пользователю информационные письма о
                    работе сайта на адрес, оставленный Пользователем при регистрации.</p>
                <p style={{paddingLeft: '30px'}}>2.2.9. Администрация вправе удалять и изменять результаты тренировок,
                    комментарии и (или) материалы Пользователя без объяснения причин.</p>
                <p style={{paddingLeft: '30px'}}>2.2.10. Администрация обязана оказывать Услуги Пользователю в
                    соответствии с настоящим Пользовательским соглашением.</p>
                <p style={{paddingLeft: '30px'}}>2.2.11. Администрация обязана принимать меры по защите от утраты, а также
                    разглашения без согласия Пользователя сведений, указанных в п. 1.5 настоящего Пользовательского
                    соглашения (исключение составляют ситуации, описанные в пункте 5.5 данного Пользовательского
                    соглашения).</p>
                <p>2.3. Права и обязанности Модератора:</p>
                <p style={{paddingLeft: '30px'}}>2.3.1. Модератор имеет право без предварительного уведомления удалять и
                    изменять любую информацию, размещенную Пользователем на Сайте, в том числе результаты
                    тренировок.</p>
                <p style={{paddingLeft: '30px'}}>2.3.2. Модератор имеет право в случае возникновения вопросов/спорных
                    ситуаций запросить у Пользователя необходимую для решения вопроса дополнительную информацию.</p>
                <p>2.4. Пользователь вправе в любое время расторгнуть заключенное с Администрацией Сайта
                    Пользовательское соглашение. Для расторжения Пользовательского соглашения Пользователь обращается к
                    Администрации Сайта посредством отправки запроса через поддержку пользователей или отправки
                    электронного письма на почту powermemory.io@gmail.com с указанного при регистрации электронного
                    ящика. В случае расторжения Пользовательского соглашения Администрация Сайта подтверждает
                    принадлежность профиля заявителю и в течение последующих 15 дней с момента подтверждения
                    осуществляет действия, направленные на обезличивание профиля Пользователя, о чем уведомляет
                    Пользователя по электронной почте.</p>
                <p>Обезличивание профиля Пользователя представляет собой действия, в результате которых становится
                    невозможным без использования дополнительной информации определить принадлежность учетной записи
                    (профиля) конкретному субъекту персональных и иных данных, и включает в себя удаление страницы
                    учетной записи (профиля) Пользователя (https://powermemory.io/) и содержащихся в учетной записи
                    (профиле) обязательных и дополнительных персональных данных и иных сведений, указанных Пользователем
                    при регистрации на Сайте, а также замену Ника и Юзерпика Пользователя на иные по усмотрению
                    Администрации Сайта; удаление всех результатов тренировок Пользователя.</p>
                <p>Исключение составляют: комментарии Пользователя на сайте.</p>
                <p>В случае расторжения Пользовательского соглашения Пользователь теряет возможность тренировать память
                    и размещать комментарии на Сайте.</p>
                <p>Администрация Сайта обеспечивает хранение данных Пользователя в течение одного года с даты
                    обезличивания профиля (пункт 4 Постановления Совета Министров Республики Беларусь 23.11.2018 №850).
                    В указанный период Пользователь вправе восстановить свой профиль путем обращения к Администрации
                    Сайта посредством отправки запроса в поддержку пользователей или отправки электронного письма на
                    почту powermemory.io@gmail.com с указанного при регистрации электронного ящика. В течение года с
                    момента расторжения Пользовательского соглашения Пользователь не имеет права регистрировать новый
                    профиль с использованием номера мобильного телефона и адреса электронной почты, которые
                    использовались им ранее при регистрации обезличенной учетной записи (профиля).</p>
                <p>Пользователь имеет право обжаловать в судебном порядке решение о расторжении в одностороннем порядке
                    Пользовательского соглашения Администрацией Сайта в месячный срок со дня получения такого
                    уведомления.</p>

                <p><b style={{fontSize: '120%'}}>3. Требования при использовании Сайта</b></p>

                <p>3.1. Имя и фамилия пользователя:</p>
                <p style={{paddingLeft: '30px'}}>3.1.1. Имя и фамилия пользователя должны состоять из двух и более
                    символов на латинице или кириллице и не должны превышать 30 символов суммарно.</p>
                <p style={{paddingLeft: '30px'}}>3.1.2. Запрещается использовать в Имени и фамилии (так же как и в
                    служебных полях — город, контакты и др.) адреса веб-сайтов, доменные имена, а также грубые,
                    оскорбительные и нецензурные выражения.</p>
                <p style={{paddingLeft: '30px'}}>3.1.3. Запрещается выбор Пользователем Имени и фамилии, идентичного или
                    сходного до степени смешения с ником другого зарегистрированного пользователя, и размещение
                    сообщений от имени этого пользователя.</p>
                <p style={{paddingLeft: '30px'}}>3.1.4. Запрещается использовать в Имени и фамилии рекламу, в том числе
                    рекламные ссылки и скрытую рекламу, товарные знаки, иные средства индивидуализации участников
                    гражданского оборота, товаров, работ и услуг.</p>
                <p style={{paddingLeft: '30px'}}>3.1.5. Имени и фамилия пользователя не должны нарушать требования
                    международного законодательства и законодательства Республики Беларусь.</p>
                <p style={{paddingLeft: '30px'}}>3.1.6. Имя и фамилия пользователя не должны быть случайными комбинациями
                    букв или явно вымышленными.</p>
                <p>3.2. Юзерпик не должен нарушать все нижеуказанные правила:</p>
                <p style={{paddingLeft: '30px'}}>3.2.1. При выборе изображения для юзерпика соблюдайте авторские права.
                    Запрещается использование юзерпика с нарушением авторских прав.</p>
                <p style={{paddingLeft: '30px'}}>3.2.2. Юзерпик должен быть шириной не более 80 пикселей, высотой не более
                    80 пикселей.</p>
                <p style={{paddingLeft: '30px'}}>3.2.3. Запрещается использовать в качестве юзерпика изображения реальных
                    личностей без их согласия.</p>
                <p style={{paddingLeft: '30px'}}>3.2.4. Запрещается использовать в качестве юзерпика фотографии либо
                    изображения известных личностей или политиков.</p>
                <p style={{paddingLeft: '30px'}}>3.2.5. Запрещается использовать в юзерпике рекламу, в том числе рекламные
                    ссылки и скрытую рекламу, товарные знаки, иные средства индивидуализации участников гражданского
                    оборота, товаров, работ и услуг.</p>
                <p style={{paddingLeft: '30px'}}>3.2.6. Запрещается повторное использование юзерпика, удаленного
                    модератором.</p>
                <p style={{paddingLeft: '30px'}}>3.2.7. Запрещается использование в качестве юзерпика часто мигающих или
                    изменяющих цвета картинок.</p>

                <p>3.3. Правила написания комментариев</p>
                <p style={{paddingLeft: '30px'}}>3.3.1. При написании комментариев пользуйтесь русским, украинским,
                    белорусским или английским языком. Запрещается размещение сообщений, содержащих чрезмерное
                    количество грамматических ошибок, жаргонных слов, современных сетевых «диалектов».</p>
                <p style={{paddingLeft: '30px'}}>3.3.2. При написании комментариев соблюдайте требования междунарожного
                    законодательства и законодательства Республики Беларусь.</p>
                <p style={{paddingLeft: '30px'}}>3.3.3. Запрещается размещение комментариев, содержащих призывы к
                    нарушению действующего законодательства.</p>
                <p style={{paddingLeft: '30px'}}>3.3.4. Запрещается размещение комментариев, содержащих высказывания
                    расистского характера, разжигание межнациональной розни.</p>
                <p style={{paddingLeft: '30px'}}>3.3.5. Запрещаются правки или удаления собственных комментариев с целью
                    исказить или скрыть первоначальный смысл комментария.</p>
                <p style={{paddingLeft: '30px'}}>3.3.6. При написании комментариев соблюдайте авторские права на
                    произведения науки, литературы, искусства. При цитировании обязательно указывайте источник и
                    автора.</p>
                <p style={{paddingLeft: '30px'}}>3.3.7. При написании комментариев соблюдайте коммерческую и
                    государственную тайну. Запрещается размещение сообщений, содержащих информацию, ограниченную к
                    распространению в соответствии с законодательством, договором или иным соглашением.</p>
                <p style={{paddingLeft: '30px'}}>3.3.8. При написании комментариев соблюдайте право на личную жизнь.
                    Запрещается размещение комментариев, содержащих информацию, касающуюся личной жизни других
                    пользователей, без их разрешения, в том числе разглашение содержания корреспонденции, телефонных и
                    иных сообщений личной переписки, реальных имен и иных личных данных (адресов, телефонов, мест работы
                    и т. п.) других пользователей.</p>
                <p style={{paddingLeft: '30px'}}>3.3.9. Запрещается использование Сайта для личного общения в режиме чата
                    под статьями.</p>
                <p style={{paddingLeft: '30px'}}>3.3.10. Запрещается размещение комментариев, посвященных обсуждению
                    действий или личностей Модераторов либо Администрации.</p>
                <p style={{paddingLeft: '30px'}}>3.3.11. Запрещается размещение комментариев, содержащих политические
                    дискуссии.</p>
                <p style={{paddingLeft: '30px'}}>3.3.12. Запрещается размещение комментариев, содержащих не подтвержденную
                    доказательствами информацию, способную нанести вред чести, достоинству, деловой репутации
                    физического лица или деловой репутации юридического лица, заведомо ложную информацию, клевету.</p>
                <p style={{paddingLeft: '30px'}}>3.3.13. Запрещается размещение комментариев, содержащих нечестные приемы
                    ведения дискуссий в виде «передергиваний» высказываний собеседников, провокации к нарушению
                    настоящего Пользовательского соглашения.</p>
                <p style={{paddingLeft: '30px'}}>3.3.14. Запрещается размещение комментариев, содержащих любые ссылки на
                    прочие интернет-ресурсы, кроме: согласованных с Администрацией (Модератором) либо являющихся
                    ответами на вопросы, заданные прочими участниками. Размещайте саму информацию, а не ссылку на
                    нее.</p>
                <p style={{paddingLeft: '30px'}}>3.3.15. Запрещается размещение комментариев, содержащих рекламу,
                    предложения покупки или продажи, оказания услуг, выполнения работ, другие сообщения коммерческого
                    характера.</p>
                <p style={{paddingLeft: '30px'}}>3.3.16. Запрещается размещение комментариев, содержащих грубые,
                    нецензурные выражения и оскорбления в любой форме — сообщения, грубые по тону, содержащие «наезды»
                    на личности, а также немотивированную, субъективную критику различных устройств и компаний.</p>
                <p style={{paddingLeft: '30px'}}>3.3.17. Запрещается размещение комментариев, не относящихся к обсуждаемой
                    теме, являющихся бессмысленными и (или) малоcодержательными и (или) провокационными, в том числе
                    сообщения в виде смайликов.</p>
                <p style={{paddingLeft: '30px'}}>3.3.18. Запрещается размещение одинаковых либо однотипных комментариев, в
                    том числе под несколькими статьями.</p>
                <p style={{paddingLeft: '30px'}}>3.3.19. Запрещается чрезмерное использование графических смайликов в
                    сообщении (более трех подряд). Допускается не более 5 смайлов в комментарии.</p>
                <p style={{paddingLeft: '30px'}}>3.3.20. Запрещается размещение комментариев, содержащих злоупотребление
                    элементами оформления (большими буквами и т.д.), затрудняющее визуальное восприятие
                    комментариев.</p>


                <p>3.4. ID пользователя</p>
                <p style={{paddingLeft: '30px'}}>3.4.1. Длина ID пользователя не должна быть более 30 символов, чтобы
                    обеспечить удобство использования.</p>
                <p style={{paddingLeft: '30px'}}>3.4.2. ID пользователя может содержать только текстовую и/или числовую
                    информацию.</p>
                <p style={{paddingLeft: '30px'}}>3.4.3. ID пользователя не должен содержать ссылок на любые
                    интернет-ресурсы</p>
                <p style={{paddingLeft: '30px'}}>3.4.4. ID пользователя не должны содержать рекламные материалы, включая
                    скрытую рекламу, наименования товарных знаков или иные средства индивидуализации участников
                    гражданского оборота, товаров, работ и услуг.</p>
                <p style={{paddingLeft: '30px'}}>3.4.5. Запрещено повторное использование ID пользователя, который был
                    отредактирован модератором или Администрацией сайта.</p>

                <p><b style={{fontSize: '120%'}}>4. Модерация</b></p>

                <p>4.1. За соблюдением настоящего Пользовательского соглашения следят Модераторы и Администрация.
                    Модераторы и Администрация могут делать Пользователям предупреждения, редактировать, удалять
                    комментарии Пользователей, удалять результаты любых тренировок, применять меры взыскания к
                    Пользователям.</p>
                <p>4.2. Комментарии пользователей на Сайте являются постмодерируемыми, то есть проверка Модераторами и
                    Администрацией комментариев на предмет их соответствия настоящему Пользовательскому соглашению
                    происходит после размещения таких сообщений. При этом Администрация не гарантирует проверку
                    абсолютно всех комментариев — ответственность за возможное нарушение Пользователем настоящего
                    Пользовательского соглашения, законодательства, в том числе законодательства какого-либо
                    государства, лежит исключительно на Пользователе.</p>
                <p>4.3. Если Пользователь считает, что какие-либо комментариев нарушают законодательство Республики
                    Беларусь или международные нормы, настоящее Пользовательское соглашение, содержат коммерческую тайню
                    или способны нанести ущерб физическим или юридическим лицам, Пользователь имеет право сообщить об в
                    службу поддержки.</p>
                <p>4.4. Если вы ошиблись, например продублировали комментарий, случайно отправили ответ не туда или
                    отправили пустое сообщение, то оставьте все как есть.Не стоит писать новые комментарии и
                    комментировать свои ошибки, оставлять комментарии с извинениями.</p>
                <p>4.5. Все предложения, замечания и отзывы по работе сайта следует отправлять в службу поддержки.</p>
                <p>4.6. Применяемые к нарушителям меры взыскания:</p>
                <p style={{paddingLeft: '30px'}}>4.6.1. Желтая карточка — замечание.</p>
                <p style={{paddingLeft: '30px'}}>4.6.2. Красная карточка — предупреждение. Две желтые карточки равноценны
                    красной.</p>
                <p style={{paddingLeft: '30px'}}>4.6.3. Черная карточка — запрет на написание сообщений на Сайте
                    (read-only) сроком на 5 суток. Две красные карточки равноценны черной.</p>
                <p style={{paddingLeft: '30px'}}>4.6.4. По прошествии месяца с момента выставления последней карточки
                    автоматически аннулируется по одной желтой карточке.</p>
                <p style={{paddingLeft: '30px'}}>4.6.5. Модерация комментариев в новостных разделах проводится на
                    основании настоящего Пользовательского соглашения и правил комментирования. В случае их нарушения
                    модератор может удалить комментарий или выставить предупреждение. За нарушение правил пользователю
                    может быть выставлен запрет писать на 1/7/30 дней либо возможность комментирования может быть
                    отключена полностью.</p>
                <p>4.7. Модератор имеет право изменить имя и фамилию пользователя, если они не соответствуют требованию
                    3.1.6. В таких случаях произойдет замена имени и/или фамилии на случайные, созданные нейросетью. При
                    повторном нарушении пункта 3.1.6, изменение имени и/или фамилии может быть ограничено временно или
                    бессрочно.</p>
                <p>4.8. Пользователь, систематически нарушающий Пользовательское соглашение, может быть лишен доступа к
                    Сайту либо сразу, либо путем постепенного накопления штрафных карточек. При попытках повторной
                    регистрации нарушитель блокируется без предупреждения.</p>
                <p>4.9. Применяемую меру взыскания определяет Модератор. Модератор не обязан придерживаться
                    последовательности и строгости мер, а вправе применить любую меру взыскания.</p>
                <p>4.10. Пользователь имеет право обжаловать действия Модератора только в отношении самого себя:</p>
                <p style={{paddingLeft: '30px'}}>4.10.1. Модераторы не отвечают на личные сообщения по поводу
                    предупреждений за комментарии в новостных разделах. В остальных случаях для обжалования наложенного
                    взыскания Пользователь должен написать личное сообщение Модератору, который наложил взыскание, с
                    обязательным указанием ссылки на сообщение, за которое было наложено взыскание, и всех причин для
                    его отмены.</p>
                <p style={{paddingLeft: '30px'}}>4.10.2. Модератор вправе прекратить переписку с пользователем в случае
                    написания более одного личного сообщения по поводу наложения одного взыскания.</p>
                <p style={{paddingLeft: '30px'}}>4.10.3. В случае несогласия с ответом Модератора либо отсутствия ответа в
                    течение 5 рабочих дней Пользователь вправе обжаловать данное решение Администратору. Для этого
                    Пользователь должен отправить письмо по адресу powermemory.io@gmail.com с обязательным указанием
                    ссылки на сообщение, за которое было наложено взыскание, и переписки с Модератором, наложившим
                    взыскание.</p>
                <p style={{paddingLeft: '30px'}}>4.10.4. Решения Администратора по всем вопросам являются окончательными и
                    обжалованию не подлежат.</p>

                <p><b style={{fontSize: '120%'}}>5. Ответственность</b></p>
                <p>5.1. Все комментарии отражают мнения их авторов, пользователи несут всю ответственность за
                    распространяемую в своих комментариях информацию.</p>
                <p>5.2. Администрация не обладает соответствующими знаниями и ресурсами (в том числе и людскими), чтобы
                    отследить все комментарии и проверить их на соответствие законодательству. Поэтому Администрация не
                    несет никакой ответственности за достоверность и законность комментариев, публикуемых пользователями
                    на Сайте.</p>
                <p>5.3. Администрация стремится содействовать соблюдению законодательства при размещении Пользователями
                    информации на Сайте, однако не подменяет собой государственные органы, которые призваны не допускать
                    нарушения законодательства и прав и законных интересов граждан и организации.</p>
                <p>5.4. Администрация оставляет за собой право не участвовать в спорах и дискуссиях, не давать оценку
                    представленным доказательствам и не удалять комментарии в случае отсутствия однозначной возможности
                    оценить соответствие сообщения законодательству и настоящему Пользовательскому соглашению.</p>
                <p>5.5. В соответствии с законодательством правоохранительные, налоговые и судебные органы Республики
                    Беларусь в пределах своей компетенции имеют право мотивированно обратиться к Администрации за
                    информацией о любом из Пользователей, разместивших информацию на Сайте, и Администрация обязана
                    предоставить им всю доступную информацию.</p>
                <p>5.6. Пользователи, размещающие информацию, должны быть готовы в случае необходимости самостоятельно
                    предоставить доказательства ее правомерности и законности.</p>
                <p>5.7. Пользователи, должны быть готовы в случае необходимости самостоятельно предоставить
                    доказательства достоверности результатов по любым тренировкам на Сайте.</p>
                <p>5.8. Администрация оставляет за собой право привлекать Пользователей в качестве свидетелей, третьих
                    лиц, соответчиков в суде и правоохранительных органах.</p>

                <div className="line-solid" style={{paddingBottom: '100px'}}></div>

        </div>
    </div>
}