import {observer} from "mobx-react";
import {CustomComponent} from "../../../../../helpers/CustomComponent";
import {RequiredRequiredTraining} from "../NewRankModel";
import {StandartsRequiredsViewRank} from "./StandartsRequiredsViewRank";

export interface StandartsRequiredsRankProps{
    numberPosition: number;
    trainings: RequiredRequiredTraining[];
}
@observer
export class StandartsRequiredsRank extends CustomComponent<StandartsRequiredsRankProps, {}> {

    render() {
        return (<>
            <div className="ex-title-name">Обязательные нормативы</div>
            {this.props.trainings.map((x) =>
                <StandartsRequiredsViewRank key={x.mode + "_" + x.speed + "_" + x.number}
                                            numberPosition={this.props.numberPosition} training={x} />)}
        </>);
    }
}