import {observer} from "mobx-react";
import {CustomComponent} from "../../helpers/CustomComponent";
import {MnemocardComponent} from "./MnemocardComponent";
import MnemocardStore from "./store/MnemocardStore";
import React from "react";
import Modal from "../modal/ReactModal";
import {Loading} from "../shared/Loading";

@observer
export class Mnemocard extends CustomComponent<{}, {}> {
    componentDidMount() {
        if (window.location.pathname.startsWith("/@")) {
            let link = window.location.pathname.replace("/@", "");
            MnemocardStore.setLink(link);
        }
    }

    render() {
        if (!MnemocardStore.modalStore.showModal)
            return null;
        if (MnemocardStore.modalStore.showModal && !MnemocardStore.mnemoCart.IsLoaded)//
            return <Loading/>;
        return <Modal classNameContent={"mnemocard-width"} store={MnemocardStore.modalStore}>
            <MnemocardComponent/>
        </Modal>;
    }
}