import React, {Component} from 'react'
import './trainingCodingResultComponent.scss'
import {observer} from "mobx-react";
import {CodingResultResponseViewModel} from "./model/CodingResultMember";
import TrainingSportResultStore from "./store/TrainingSportResultStore";

@observer
export class TrainingCodingResultComponent extends Component<{
    model: CodingResultResponseViewModel
}, {}> {
    store: TrainingSportResultStore;

    constructor(props: Readonly<{
        model: CodingResultResponseViewModel
    }>) {
        super(props);
        this.store = new TrainingSportResultStore(this.props.model);
    }


    render() {
        return (
            <div className="coding-result">
                <div id="res-contener">
                    <div className="res-userinfo-data">
                        <div id="res-userinfo">
                            {/*<Avatar*/}
                            {/*    fullname={this.store.model.Model!.username}*/}
                            {/*    img={this.store.model.Model!.imgSrc}*/}
                            {/*    link={this.store.model.Model!.link}*/}
                            {/*/>*/}
                            {/*    @if (!string.IsNullOrEmpty(training.ImgSrc))*/}
                            {/*    {*/}
                            {/*        <div id="res-ava" style="background-image:url(@training.ImgSrc);"></div>*/}
                            {/*    }*/}
                            {/*    else*/}
                            {/*    {*/}
                            {/*        <div id="res-ava" class="user-info-avatar" style="line-height: 55px;font-size: 25pt;font-family: HeliosCond;"*/}
                            {/*             onload="stringToColor('@training.Username', this)">*/}
                            {/*        </div>*/}
                            {/*    }*/}

                            {/*<div id="res-name" data-title="Премиум пользователь" className={this.store.model.Model!.isPremium? "res-name-premium rnp-title": ""}>*/}
                            {/*    /!*{this.store.model.Model!.username}*!/*/}
                            {/*</div>*/}
                            {/*<div id="res-rank">{this.store.model..rang}</div>*/}
                            {/*    @{*/}
                            {/*    if (training.BestTraining == BestTraining.BestUser)*/}
                            {/*{*/}

                            {/*{*/}
                            {/*    this.store.model.Model!.bestTraining === BestTrainingType.BestUser &&*/}
                            {/*    <div id="star-rs" data-title={"Личный рекорд [" + this.store.model.Model!.dIS + "]"}></div>*/}
                            {/*}*/}
                            {/*    <div id="star-pr" data-title="Личный рекорд [@training.DIS]"></div>*/}
                            {/*    <style>*/}
                            {/*    #res-name, #res-rank {*/}
                            {/*    top: 14px;*/}
                            {/*    left: 73px;*/}
                            {/*}*/}

                            {/*    #res-ava {*/}
                            {/*    border-color: #afafaf;*/}
                            {/*    border-style: solid;*/}
                            {/*    border-width: 4px;*/}
                            {/*    line-height: 50px;*/}
                            {/*}*/}
                            {/*</style>*/}
                            {/*}*/}
                            {/*if (training.BestTraining == BestTraining.BestIsTheBest)*/}
                            {/*{*/}
                            {/*<style>*/}
                            {/*    #res-name {*/}
                            {/*    top: 14px;*/}
                            {/*    left: 73px;*/}
                            {/*}*/}

                            {/*    #res-rank {*/}
                            {/*    top: 14px;*/}
                            {/*    left: 73px;*/}
                            {/*}*/}

                            {/*    #res-ava {*/}
                            {/*    border-color: #fbc826;*/}
                            {/*    border-style: solid;*/}
                            {/*    border-width: 4px;*/}
                            {/*    line-height: 50px;*/}
                            {/*}*/}
                            {/*</style>*/}
                            {/*}*/}
                            {/*}*/}
                        </div>

                        <div className="res-data-box">
                            <div id="res-date">
                                <span id="res-date-time">{this.store.timeGet}</span>
                                <span id="res-date-date">{this.store.dateGet}</span>
                            </div>

                            {/*<div id="res-repost" style="display:none;" data-title="Поделиться результатом"></div>*/}
                            {/*<input id="el-show" type="checkbox" onClick="scroll()"/>*/}
                        </div>
                    </div>

                    <div className="res-diagram-box">
                        <div id="res-diagram">
                            <span className="spincrement" id="err-diagram">{this.store.model.error}</span>
                            <span className="spincrement"
                                  id="right-diagram">{this.store.model.list.length - this.store.model.error}</span>
                        </div>
                        <div id="res-diagram2" className="ct-chart ct-perfect-fourth"></div>
                    </div>

                    <div className="res-parametrs-box">
                        <div id="sim2-name">{this.store.getName}</div>

                        <div id="sim2-name-dis" data-before={this.store.model.figurativeCodesText}>Количество кодов:</div>

                        <div id="sim2-dis" data-before={this.store.model.list.length}>Образные коды:</div>

                        <div id="res-time" data-title="Время кодирования [MM:СС:mmm]">{this.store.timeAll}</div>
                        <div className="res-speed-box">
                            <div id="res-speed" data-title="Темп кодирования [CС:ммм]">{this.store.speedMiddle}</div>
                            <div className="max-min-speed-box">
                                <div id="max-res-speed">{this.store.speedMax}</div>
                                <div id="min-res-speed">{this.store.speedMin}</div>
                            </div>
                        </div>

                    </div>
                </div>

                <div id="element-diagram2"></div>
                <div className="charts-res" id="locitrans-chart"></div>

                {/*<button id="repeat-tren" onClick="window.location='/Training';">Тренироваться</button>*/}
            </div>
        )
    }
}