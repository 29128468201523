import React, {Component} from 'react'
import './memberTraining.scss'
import {MemberTrainingBaseStore} from "../store/MemberTrainingBaseStore";
import {observer} from "mobx-react";
import Modal from "../../modal/ReactModal";
import StopwatchService from "../../../helpers/StopwatchService";
import {CustomButton} from "../../../helpers/CustomButton";
import {ReactModalStore} from "../../modal/ReactModalStore";
import KeyboardManager from "../../../core/keyboardManager/adapters/KeyboardManager";

@observer
export default class MemberTrainingComponent extends Component<{ store: MemberTrainingBaseStore }, {}> {
    private readonly reactModalStore: ReactModalStore = new ReactModalStore();

    constructor(props: Readonly<{ store: MemberTrainingBaseStore }>) {
        super(props);
        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
        this.repeat = this.repeat.bind(this);
        this.closeTraining = this.closeTraining.bind(this);
        this.openModal = this.openModal.bind(this);
        this.nextTraining = this.nextTraining.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }
    componentDidMount() {
        this.props.store.init();
        this.reactModalStore.init();
        KeyboardManager.add('ArrowLeft', this.back);
        KeyboardManager.add('ArrowRight', this.next);
        KeyboardManager.add('Space', this.next);
    }
    componentWillUnmount() {
        this.reactModalStore.unInit();
        this.props.store.unmount();
        KeyboardManager.remove('ArrowLeft', this.back);
        KeyboardManager.remove('ArrowRight', this.next);
        KeyboardManager.remove('Space', this.next);
    }

    next() {
        this.props.store.next();
    }

    nextTraining() {
        this.props.store.finish();
    }

    back() {
        this.props.store.back();
    }

    repeat() {
        this.props.store.repeat();
    }

    closeTraining() {
        window.location.reload();
    }

    closeModal() {
        this.reactModalStore.close();
    }

    openModal() {
        this.reactModalStore.open();
    }

    render() {
        return (
            <div className="training-member" tabIndex={0}>
                <Modal store={this.reactModalStore}>
                    <div id="dialog-form" title="Закрыть">
                        <p><span className="ui-icon ui-icon-alert"
                                 style={{float: 'left', margin: '12px 12px 20px 0'}}>
                        </span>Вы уверены что хотите завершить тренировку?</p>
                        <div className="dialog-form-block-buttons">
                            <CustomButton text="Нет" onclick={this.closeModal} />
                            <CustomButton text="Закрыть тренировку" onclick={this.closeTraining} />
                            {
                                ((this.props.store.service?.numberElNow ?? 0) >= 9) &&
                                <CustomButton text="Досрочный ввод ответов" onclick={this.nextTraining} />
                            }
                        </div>
                    </div>
                </Modal>

                <header id="header-training">
                    <div id="header-training-box">
                        <div id="show-me-the-code" title="Посмотреть образ"></div>
                        {
                            this.props.store.memberModel!.dis === 'A' &&
                            <>
                                <div className="substrate-timer"></div>
                                <div className="substrate-timer60"></div>
                            </>
                        }
                        {
                            this.props.store.memberModel!.dis === 'B' &&
                            <>
                                <div className="substrate-timer"></div>
                                <div className="substrate-timer300"></div>
                            </>
                        }
                        {
                            this.props.store.memberModel!.dis === 'C' &&
                            <>
                                <div className="substrate-timer"></div>
                                <div className="substrate-timer600"></div>
                            </>
                        }
                        {
                            this.props.store.memberModel!.dis === 'D' &&
                            <>
                                <div className="substrate-timer"></div>
                                <div className="substrate-timer1200"></div>
                            </>
                        }
                        <div id="timer-abcd">{StopwatchService.currentTimeStr}</div>
                        <div id="f-finish" title="Закрыть тренировку" onClick={this.openModal}>
                        </div>
                    </div>
                </header>

                <div id="display-rem">
                    {this.props.store.view}
                    <div className="nextback">
                        <input id="back" type="button" className="not-select-element" onClick={this.back}/>
                        <label className="back-label not-select-element" htmlFor="back"></label>

                        <div className="back-to-start not-select-element" id="repeat" onClick={this.repeat}></div>

                        <input id="next" type="button" className="not-select-element" onClick={this.next}/>
                        <label className="next-label not-select-element" htmlFor="next"></label>
                        <span id="position-info"
                              className="position-info not-select-element">{this.props.store.positionInfo}</span>
                    </div>
                </div>

                {/*<button id="finish">Закончить</button>*/}
                {/*<div>*/}
                {/*    <ul id="spisoc"></ul>*/}
                {/*</div>*/}
            </div>
        )
    }
}