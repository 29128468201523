import {makeAutoObservable} from "mobx";


class TimeSiteStore{
    currentTimeStr: string = '00:00';
    timer: NodeJS.Timer | undefined = undefined;

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true});
        this.updateCurrentTime();
    }

    start(){
        this.timer = setInterval(this.updateCurrentTime,
            1000);
    }

    finish(){
        if (this.timer)
            clearInterval(this.timer);
    }

    updateCurrentTime(){
        this.currentTimeStr = new Date().toLocaleString();
        // return "19:36:34 (GMT+3)"
    }
}
export default new TimeSiteStore();