import {makeAutoObservable} from "mobx";
import {LoadingModel} from "../../shared/LoadingModel";
import {GetRequest} from "../../../helpers/Request";
import {PackageRouteViewModel, RouteCodeViewModel, TrainingRoutePointViewModel} from "../model/TrainingRouteModel";
import {SelectorPros} from "../../shared/selector/CustomSelector";
import {RouteTrainingModelView} from "../model/TrainingModel";
import TrainingBaseStore from "./TrainingBaseStore";

export default class RouteTrainingStore {
    private countPoints: number | undefined;
    model: LoadingModel<PackageRouteViewModel> = LoadingModel.createLoaded();
    changeRoutes: TrainingRoutePointViewModel[] = [];
    trainingStore: TrainingBaseStore;

    init() {
        this.model.IsLoaded = false;
        GetRequest<PackageRouteViewModel>('/Training/GetRoutes')
            .then(x => this.setModel(x))
    }

    setModel(model: LoadingModel<PackageRouteViewModel>) {
        this.model = model;
        this.changeRoutes = this.model.Model?.savePoints ?? [];
    }

    constructor(trainingStore: TrainingBaseStore) {
        this.trainingStore = trainingStore;
        makeAutoObservable(this, {}, {autoBind: true});
        this.init();
    }

    get customRoutes(): TrainingRoutePointViewModel[] {
        if (this.changeRoutes.length === 0){
            this.changeRoutes.push({
                endPoint: '',
                startPoint: '',
                routeId: '',
            })
        }
        return this.changeRoutes;
    }

    createRoute(index: number) {
        this.changeRoutes.splice(index + 1, 0,{
            endPoint: "", routeId: "", startPoint: ""
        })
    }

    deleteRoute(index: number) {
        this.changeRoutes.splice(index , 1);
    }

    get isCustomRoutes(): boolean {
        return (this.changeRoutes?.length ?? 0) > 1;
    }

    selectRouteCustom(v: SelectorPros, index: number) {
        let route = this.model.Model!.routes
            .filter((y) => y.id === v.value)[0];
        if (v.value === '') {
            this.clearCustomRoute();
            return;
        }
        let currentRoute = this.changeRoutes[index];
        currentRoute.routeId = route.id;
        currentRoute.startPoint = route.points[0].id;
        currentRoute.endPoint = route.points[route.points.length-1].id;
        this.onChangeCount(1);
    }

    selectCustomStartPoint(v: SelectorPros, index: number) {
        let currentRoute = this.changeRoutes[index];
        currentRoute.startPoint = v.value;
    }

    selectCustomFinishPoint(v: SelectorPros, index: number) {
        let currentRoute = this.changeRoutes[index];
        currentRoute.endPoint = v.value;
    }

    selectRoute(v: SelectorPros) {
        let route = this.model.Model!.routes
            .filter((y) => y.id === v.value)[0];
        if (v.value === '') {
            this.clearCustomRoute();
            return;
        }
        this.changeRoutes = [{
            startPoint: route.points[0].id,
            routeId: route.id,
            endPoint: route.points[route.points.length - 1].id
        }]
        this.onChangeCount(1);
    }

    selectStartPoint(v: SelectorPros) {
        let currentRoute = this.currentOrFirstRoute;
        if (!currentRoute)
            return;
        this.changeRoutes[0].startPoint = v.value;
        this.changeRoutes[0].endPoint = currentRoute.points[currentRoute.points.length - 1].id;
    }

    get getDefaultRoute(): string {
        let currentRoute = this.changeRoutes[0];
        if (currentRoute)
            return currentRoute.routeId;
        return "";
    }

    get getDefaultPoint(): string {
        let currentRoute = this.changeRoutes[0];
        if (currentRoute)
            return currentRoute.startPoint;
        return "";
    }

    get currentOrFirstRoute(): RouteCodeViewModel | undefined {
        if (!this.model.Model) return undefined;
        if (!this.model.IsLoaded) return undefined;
        if ((this.changeRoutes?.length ?? 0) === 0) return undefined;
        let currentRoutes = this.model.Model.routes
            .filter(x => x.id === this.changeRoutes[0].routeId)
        if (currentRoutes.length > 0)
            return currentRoutes[0];
        return undefined;
    }

    customCurrentRoute(index: number): RouteCodeViewModel | undefined {
        if (!this.model.Model) return undefined;
        if (!this.model.IsLoaded) return undefined;
        if ((this.changeRoutes?.length ?? 0) === 0) return undefined;
        let currentRoutes = this.model.Model.routes
            .filter(x => x.id === this.changeRoutes[index].routeId)
        if (currentRoutes.length > 0)
            return currentRoutes[0];
        return undefined;
    }

    get routes(): SelectorPros[] {
        let routes = [{
            value: "",
            title: "Без учёта маршрута"
        }];
        if (this.model && this.model.IsLoaded && this.model.Model) {
            return routes.concat(this.model.Model.routes.map((x) => {
                return {
                    value: x.id,
                    title: (x.name + " (" + (x.points?.length ?? 0) + ")")
                }
            }));
        }
        return routes;
    }

    get points(): SelectorPros[] {
        let currentRoute = this.currentOrFirstRoute;
        if (currentRoute && currentRoute.points.length > 0) {
            return currentRoute.points.map((x, ind) => {
                return {
                    value: x.id,
                    title: (ind + 1) + ". " + x.name
                }
            })
        }
        return [{
            value: "",
            title: ""
        }];
    }

    pointsCustom(index: number): SelectorPros[] {
        let currentRoute = this.customCurrentRoute(index);
        if (currentRoute && currentRoute.points.length > 0) {
            return currentRoute.points.map((x, ind) => {
                return {
                    value: x.id,
                    title: (ind + 1) + ". " + x.name
                }
            })
        }
        return [{
            value: "",
            title: ""
        }];
    }

    clearCustomRoute() {
        this.changeRoutes = [];
        this.onChangeCount(undefined);
    }

    get isSelectedRoute(): boolean {
        return this.changeRoutes.length !== 0;
    }

    onChangeCount(countPoints: number | undefined) {
        this.countPoints = countPoints;
    }

    get getCountPoints() {
        if (this.trainingStore.currentStorage.type === 'cards') {
            if (this.isSelectedRoute) {
                if (this.trainingStore.currentStorage.model.complexity === "1")
                    return "3";
                if (this.trainingStore.currentStorage.model.complexity === "3")
                    return "2";
            }
        }
        return this.countPoints?.toString() ?? "";
    }

    routeIds(): RouteTrainingModelView[] {
        if ((this.changeRoutes?.length ?? 0) === 0)
            return [];
        if (!this.model.Model)
            return [];
        let pointIds: RouteTrainingModelView[] = [];
        for (const changeRoute of this.changeRoutes) {
            let points = this.model.Model!.routes.filter(y => y.id === changeRoute.routeId);
            if (points.length === 0)
                continue;
            let isStart: boolean = false;
            for (const point of points[0].points) {
                if (point.id === changeRoute.startPoint) {
                    isStart = true;
                }
                if (isStart === false)
                    continue;
                pointIds.push({
                    pointId: point.id,
                    routeId: changeRoute.routeId
                })
                if (point.id === changeRoute.endPoint)
                    break;
            }
        }
        return pointIds;
    }
}
