import {makeAutoObservable} from "mobx";


export class HomeWorkStore {
    constructor() {
        makeAutoObservable(this, {}, {autoBind: true})
    }

    init() {

    }

    unInit() {

    }
}