import {CustomComponent} from "../../../helpers/CustomComponent";
import React from "react";
import TrainingSportBaseStore from "../store/TrainingSportBaseStore";
import {observer} from "mobx-react";


interface Props{ store: TrainingSportBaseStore }

@observer
export class RangeTimeCodingComponent extends CustomComponent<Props, {}> {

    constructor(props: Readonly<Props>) {
        super(props);
        this.changeOnTime = this.changeOnTime.bind(this);
        this.changeOnDefferTime = this.changeOnDefferTime.bind(this);
        this.getCheckboxActivity = this.getCheckboxActivity.bind(this);
        this.clickCheckbox = this.clickCheckbox.bind(this);
        this.changeOnAntiStop = this.changeOnAntiStop.bind(this);
    }

    changeOnTime(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.store.onChangeTimeElement(e.currentTarget?.value);
    }

    changeOnDefferTime(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.store.onChangeDefferTime(e.currentTarget?.value);
    }

    changeOnAntiStop(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.store.onChangeAntiStop(e.currentTarget?.value);
    }

    getCheckboxActivity(isAction: boolean) {
        if (isAction)
            return "ch-checkbox checkboxActivity";
        return "ch-checkbox";
    }

    clickCheckbox(ev: React.MouseEvent<HTMLDivElement>){
        switch (ev.currentTarget.id) {
            case "notUsed-label":
                this.props.store.requestModel.notUsed = !this.props.store.requestModel.notUsed;
                break;
            case "weak-label":
                this.props.store.requestModel.weak = !this.props.store.requestModel.weak;
                break;
            case "middle-label":
                this.props.store.requestModel.middle = !this.props.store.requestModel.middle;
                break;
            case "power-label":
                this.props.store.requestModel.power = !this.props.store.requestModel.power;
                break;
            case "verypower-label":
                this.props.store.requestModel.veryPower = !this.props.store.requestModel.veryPower;
                break;
            case "reflecs-label":
                this.props.store.requestModel.reflecs = !this.props.store.requestModel.reflecs;
                break;
        }
    }

    render() {
        return <div>
            <label id="timer-label" htmlFor="timer">Время на элемент, с</label>
            <input id="timer" type="number" style={{width: '190px'}}
                   onChange={this.changeOnTime}
                   value={this.props.store.requestModel.timeElement}
                   name="timer" autoComplete="off" title="От 0.1 до 20 сек"/>

            <label id="counting-label" htmlFor="counting">Отложенный старт, с</label>
            <input id="counting" type="number" style={{width: '190px'}}
                   onChange={this.changeOnDefferTime}
                   value={this.props.store.requestModel.defferTime}
                   name="counting" autoComplete="off" title="От 5 до 60 сек"/>

            <label id="antistop-coding-label" htmlFor="antistop-coding">Антитормоз, с</label>
            <input id="antistop-coding" style={{width: '190px'}} type="number"
                   onChange={this.changeOnAntiStop}
                   value={this.props.store.requestModel.antiStop}
                   name="antistop-coding" autoComplete="off" title="От 1 до 60 сек"/>

            <div id="power-sinaps">
                <span id="power-sinaps-name">Сила связи (последние 10)</span>

                <div className={this.getCheckboxActivity(this.props.store.requestModel.reflecs)}
                     id="reflecs-label"
                     onClick={this.clickCheckbox}>
                    Рефлекс (≤0,5 сек) = <b id="reflecs-sin-count">{this.props.store.currentCoding.reflecsSin}</b>
                </div>

                <div className={this.getCheckboxActivity(this.props.store.requestModel.veryPower)}
                     onClick={this.clickCheckbox}
                     id="verypower-label">
                    Очень сильная (0,5-1 сек) = <b id="verypower-sin-count">{this.props.store.currentCoding.verypowerSin}</b>
                </div>

                <div className={this.getCheckboxActivity(this.props.store.requestModel.power)}
                     onClick={this.clickCheckbox}
                     id="power-label">
                    Сильная (1-1,5 сек) = <b id="power-sin-count">{this.props.store.currentCoding.powerSin}</b>
                </div>

                <div className={this.getCheckboxActivity(this.props.store.requestModel.middle)}
                     id="middle-label"
                     onClick={this.clickCheckbox}>
                    Средняя (1,5-2,5 сек) = <b id="middle-sin-count">{this.props.store.currentCoding.middleSin}</b>
                </div>

                <div className={this.getCheckboxActivity(this.props.store.requestModel.weak)}
                     id="weak-label"
                     onClick={this.clickCheckbox}>
                    Слабая (≥2,5 сек) = <b id="weak-sin-count">{this.props.store.currentCoding.weakSin}</b>
                </div>

                <div className={this.getCheckboxActivity(this.props.store.requestModel.notUsed)}
                     id="notUsed-label"
                     onClick={this.clickCheckbox}>
                    Не использовано = <b id="notUsed-sin-count">{this.props.store.currentCoding.notUsed}</b>
                </div>
            </div>
        </div>;
    }
}