import {CustomComponent} from "../../../../helpers/CustomComponent";
import {ArchiveResultsStore} from "./ArchiveResultsStore";
import {ArchiveResultColumns} from "./ArchiveResultColumns";
import {Loading} from "../../../shared/Loading";
import './archiveResults.scss';
import {observer} from "mobx-react";
import {reaction} from "mobx";
import {CheckScreen, TypeScreen} from "../../../../context/TypeScreen";
import React from "react";


@observer
export class ArchiveResults extends CustomComponent<{}, {}>{
    private store: ArchiveResultsStore = new ArchiveResultsStore();

    constructor(props: Readonly<{}> | {}) {
        super(props);
        this.clickModeNumber = this.clickModeNumber.bind(this);
        this.clickModeWord = this.clickModeWord.bind(this);
        this.clickModeCards = this.clickModeCards.bind(this);
        this.clickModeName = this.clickModeName.bind(this);
        this.clickModeBins = this.clickModeBins.bind(this);
        this.clickModeImage = this.clickModeImage.bind(this);

        this.clickTimeAllTime = this.clickTimeAllTime.bind(this);
        this.clickTimeMonth = this.clickTimeMonth.bind(this);
        this.clickTimeDay = this.clickTimeDay.bind(this);
    }

    componentDidMount() {
        this.store.loadingStore.fetchRequest();
        reaction(
            () => [this.store.mode, this.store.dis, this.store.typeTime],
            this.store.loadingStore.fetchRequest
        );
    }

    clickModeNumber(){
        this.store.mode = 'number';
    }

    clickModeCards(){
        this.store.mode = 'cards';
    }
    clickModeWord(){
        this.store.mode = 'word';
    }
    clickModeName(){
        this.store.mode = 'name';
    }
    clickModeBins(){
        this.store.mode = 'bins';
    }
    clickModeImage(){
        this.store.mode = 'image';
    }
    clickTimeAllTime(){
        this.store.typeTime = 'allTime';
    }
    clickTimeMonth(){
        this.store.typeTime = 'month';
    }
    clickTimeDay(){
        this.store.typeTime = 'day';
    }
    render() {
        if (!this.store.loadingStore.isLoaded)
            return <Loading />
        return <>

            <span className="sat-name">Архив результатов</span>

            {/*@*РЕЗУЛЬТАТЫ РЕЗУЛЬТАТЫ РЕЗУЛЬТАТЫ*@*/}
            <div className="recordsboard" id="recordsboard-result">
                <div className="records-tabs-result">
                    {/*@*ТАБЫ ДИСЦИПЛИН*@*/}
                    <div className="results-table-header" id="results-table-dis">
                        <div className={"r-tab results-number-label" + this.store.getClassMode("number")}
                             onClick={this.clickModeNumber}
                             // type="number"
                             title="Числа">Числа
                        </div>
                        <div className={"r-tab results-number-label r-tab-sign" + this.store.getClassMode("number")}
                             onClick={this.clickModeNumber}
                             // type="number"
                             title="Числа">Ч
                        </div>

                        <div className={"r-tab results-cards-label"+ this.store.getClassMode("cards")}
                             onClick={this.clickModeCards}
                             // type="cards"
                             title="Карты">Карты
                        </div>
                        <div className={"r-tab results-cards-label r-tab-sign" + this.store.getClassMode("cards")}
                             onClick={this.clickModeCards}
                             // type="cards"
                             title="Карты">К
                        </div>

                        <div className={"r-tab results-words-label" + this.store.getClassMode("word")}
                             onClick={this.clickModeWord}
                             // type="word"
                             title="Слова">Слова
                        </div>
                        <div className={"r-tab results-words-label r-tab-sign"+ this.store.getClassMode("word")}
                             onClick={this.clickModeWord}
                             // type="word"
                             title="Слова">C
                        </div>

                        <div className={"r-tab results-names-label"+ this.store.getClassMode("name")}
                             onClick={this.clickModeName}
                             // type="name"
                             title="Имена">Имена
                        </div>
                        <div className={"r-tab results-names-label r-tab-sign"+ this.store.getClassMode("name")}
                             onClick={this.clickModeName}
                             // type="name"
                             title="Имена">И
                        </div>

                        <div className={"r-tab results-binary-label"+ this.store.getClassMode("bins")}
                             onClick={this.clickModeBins}
                             // type="bins"
                             title="Бинарные">Бинарные
                        </div>
                        <div className={"r-tab results-binary-label-mob"+ this.store.getClassMode("bins")}
                             onClick={this.clickModeBins}
                             // type="bins"
                             title="Бинарные">Бинар.
                        </div>
                        <div className={"r-tab results-binary-label r-tab-sign" + this.store.getClassMode("bins")}
                             onClick={this.clickModeBins}
                             // type="bins"
                             title="Бинарные">Б
                        </div>

                        <div className={"r-tab results-images-label" + this.store.getClassMode("image")}
                             onClick={this.clickModeImage}
                             // type="image"
                             title="Изображения">Изображения
                        </div>
                        <div className={"r-tab results-images-label-mob"+ this.store.getClassMode("image")}
                             onClick={this.clickModeImage}
                             // type="image"
                             title="Изображения">Изобр.
                        </div>
                        <div className={"r-tab results-images-label r-tab-sign" + this.store.getClassMode("image")}
                             onClick={this.clickModeImage}
                             // type="image"
                             title="Изображения">И
                        </div>
                    </div>

                    {/*@* ТАБЫ ВРЕМЯ МЕСЯЦ ДЕНЬ - РЕЗУЛЬТАТЫ*@*/}
                    <div className="tabs-result">
                        <div className="records-tabs-2">
                            <div className={"r-tab alltime-label" + this.store.getClassTime("allTime")}
                                 onClick={this.clickTimeAllTime}
                                 // type="allTime"
                                 title="Всё время">Всё время
                            </div>
                            <div className={"r-tab month-label" + this.store.getClassTime("month")}
                                 onClick={this.clickTimeMonth}
                                 // type="month"
                                 title="Месяц">Месяц
                            </div>
                            <div className={"r-tab day-label" + this.store.getClassTime("day")}
                                 onClick={this.clickTimeDay}
                                 // type="day"
                                 title="День">День
                            </div>
                        </div>
                    </div>
                </div>

                {/*@*ТЕЛО ТАБЛИЦЫ РЕЗУЛЬТАТОВ*@*/}
                <section id="results-table">
                    <div className="results-table-header">
                        <div className="td-number">№</div>
                        <div className="td-del"></div>
                        <div className="td-name">Дата</div>

                        <select className="chosen-select td-index" defaultValue="0">
                            <option value="0">Индекс (BEST)</option>
                            <option value="A">"A" ≤1м</option>
                            <option value="B">"B" 5м</option>
                            <option value="C">"C" 10м</option>
                            <option value="D">"D" 20м</option>
                        </select>
                        <div className="td-allelements td-allelements-comp">Элементов</div>
                        <div className="td-allelements td-allelements-mob">Эл.</div>
                        <div className="td-error">Ошибки</div>
                        <div className="td-speed">Темп</div>
                        <div className="td-time">Время</div>
                        {
                            CheckScreen([TypeScreen.Desktop, TypeScreen.Tablet]) &&
                            <div className="td-more" id="td-more-desctop">Подробнее</div>
                        }
                        {
                            CheckScreen([TypeScreen.Mobile]) &&
                            <div className="td-more" id="td-more-mob">См.</div>
                        }
                    </div>
                    <section id="result-lines">
                        {!this.store.loadingStore.model.IsLoaded &&
                            <Loading />
                        }
                        {this.store.loadingStore.model.IsLoaded && this.store.loadingStore.model.Model &&
                            <ArchiveResultColumns model={this.store.loadingStore.model.Model} />
                        }
                    </section>
                </section>
            </div>
        </>;
    }
}