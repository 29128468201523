import {CustomComponent} from "../CustomComponent";
import './horizontalLineComponent.scss'

interface Props {
}

export class HorizontalLineComponent extends CustomComponent<Props, any> {
    render() {
        return <div className="horizontal-line-component">
        </div>;
    }
}