import IResultService from "./IResultService";
import {TrainingResultItemModel, TrainingResultModel} from "../model/TrainingResultModel";
import {route} from "../../../helpers/trainingHelp/TrainingRoute";


export default class CardsResultService implements IResultService {
    constructor() {
        this.getItem = this.getItem.bind(this);
        this.GetItems = this.GetItems.bind(this);
    }

    GetItems(training: TrainingResultModel): JSX.Element {
        const chunkSize = 52;
        const groups = training.items.map((e, i) => {
            return i % chunkSize === 0 ? training.items.slice(i, i + chunkSize) : null;
        }).filter(e => {
            return e;
        });
        return <>
            {groups.map((group, index) => {
                return <div key={index} className="ans-cards-res">
                    <div className="ans-block-num-desk">
                        <span className="num-desk">Колода {index + 1}</span>
                    </div>
                    {group!.map(this.getItem)}
                </div>
            })}
        </>;
    }

    getItem(item: TrainingResultItemModel | null, position: number): JSX.Element {
        if (item === null)
            return <></>
        if (item.evenly) {
            return <div className="empty-cards" key={position + "_" + item.dictionary}
                        style={{backgroundImage: 'url(https://powermemory.io' + item.dictionaryImage + ')'}}>
                <span className="num-cell num-cell-cards" data-title={route(item)}>{position + 1}</span>
            </div>
        }
        return <div className="empty-cards" key={position + "_" + item.dictionary}
                    style={{backgroundImage: 'url(https://powermemory.io' + item.dictionaryImage + ')'}}>
            <span className="num-cell num-cell-cards" data-title={route(item)}>{position + 1}</span>
            <div className="err-cards"
                 style={{backgroundImage: 'url(' + item.answerImage + ')'}}></div>
            <div className="err-overlay"></div>
        </div>
    }

}