import RouteTrainingStore from "../../store/RouteTrainingStore";
import {observer} from "mobx-react";
import React, {Component} from "react";
import {SelectorPros} from "../../../shared/selector/CustomSelector";
import {TrainingRoutePointViewModel} from "../../model/TrainingRouteModel";
import SelectorWithText from "../../../shared/selector/SelectorWithText";

interface Props {
    store: RouteTrainingStore,
    route: TrainingRoutePointViewModel,
    index: number
}

@observer
export class RouteCustomView extends Component<Props, {}> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.changeRoute = this.changeRoute.bind(this);
        this.changePointStart = this.changePointStart.bind(this);
        this.changePointFinish = this.changePointFinish.bind(this);
        this.deleteRoute = this.deleteRoute.bind(this);
        this.createRoute = this.createRoute.bind(this);
    }

    changeRoute(sel: SelectorPros | undefined) {
        if (!sel)
            return;
        this.props.store.selectRouteCustom(sel!, this.props.index)
    }

    changePointStart(sel: SelectorPros | undefined) {
        if (!sel)
            return;
        this.props.store.selectCustomStartPoint(sel!, this.props.index)

    }

    changePointFinish(sel: SelectorPros | undefined) {
        if (!sel)
            return;
        this.props.store.selectCustomFinishPoint(sel!, this.props.index)
    }

    deleteRoute() {
        this.props.store.deleteRoute(this.props.index)
    }

    createRoute() {
        this.props.store.createRoute(this.props.index)
    }

    get points() {
        return this.props.store.pointsCustom(this.props.index)
    }

    render() {
        return (<div className="custom-route ng-scope">
            <div className="cut-container-header-route">
                <span className="ng-binding">Отрезок {this.props.index + 1}</span>
                <div className="cut-route">
                    <div className="del-i" onClick={this.deleteRoute}
                         title="Удалить отрезок"></div>
                    <div className="add-cut" onClick={this.createRoute}
                         title="Добавить отрезок"
                         id="cut1"></div>
                </div>
            </div>

            <SelectorWithText value={this.props.route.routeId}
                              text="Маршрут"
                              values={this.props.store.routes}
                              width="285px"
                              onChange={this.changeRoute}/>

            <div className="container-route">
                <SelectorWithText value={this.props.route.startPoint}
                                  text="Начало маршрута"
                                  values={this.points}
                                  width="285px"
                                  onChange={this.changePointStart}/>

                <SelectorWithText value={this.props.route.endPoint}
                                  text="Конец маршрута"
                                  values={this.points}
                                  width="285px"
                                  onChange={this.changePointFinish}/>
            </div>
        </div>)
    }
}