import '../news/newsPostPage.scss'
import TitleStore from "../../components/authorization/store/TitleStore";

export function PrivacyPolicyPage() {
    TitleStore.setTitle("Политика конфиденциальности");

    return <div className="news-post-page">
        <div id="news-post">
            <hr className="news-page"/>
            <div className="news-theme"></div>

            <div className="news-stat">
                <div className="author-date author-date2">Версия 2.0 от 13.10.2023</div>
            </div>

            <h1 id="page-name">Политика конфиденциальности</h1>
            <div className="news-theme"></div>
            <div className="news-theme">Политика</div>

            <p>Настоящая Политика конфиденциальности персональных данных (далее – Политика конфиденциальности)
                действует в отношении всей информации, которую данный сайт, на котором размещен текст этой Политики
                конфиденциальности, может получить о Пользователе, а также любых программ и продуктов, размещенных
                на нем.</p>
            <p><b style={{fontSize: '120%'}}>1. Определение терминов</b></p>
            <p>1.1 В настоящей Политике конфиденциальности используются следующие термины:</p>
            <p>1.1.1. «Администрация сайта» – уполномоченные сотрудники на управления сайтом, действующие от его
                имени, которые организуют и (или) осуществляет обработку персональных данных, а также определяет
                цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия
                (операции), совершаемые с персональными данными.</p>
            <p>1.1.2. «Персональные данные» - любая информация, относящаяся к прямо или косвенно определенному или
                определяемому физическому лицу (субъекту персональных данных).</p>
            <p>1.1.3. «Обработка персональных данных» - любое действие (операция) или совокупность действий
                (операций), совершаемых с использованием средств автоматизации или без использования таких средств с
                персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение
                (обновление, изменение), извлечение, использование, передачу (распространение, предоставление,
                доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
            <p>1.1.4. «Конфиденциальность персональных данных» - обязательное для соблюдения Администрацией сайта
                требование не допускать их умышленного распространения без согласия субъекта персональных данных или
                наличия иного законного основания.</p>
            <p>1.1.5. «Пользователь сайта (далее Пользователь)» – лицо, имеющее доступ к сайту, посредством сети
                Интернет и использующее данный сайт для своих целей.</p>
            <p>1.1.6. «Cookies» — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере
                пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе
                при попытке открыть страницу соответствующего сайта.</p>
            <p>1.1.7. «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу
                IP.</p>
            <p><b style={{fontSize: '120%'}}>2. Общие положения</b></p>
            <p>2.1. Использование Пользователем сайта означает согласие с настоящей Политикой конфиденциальности и
                условиями обработки персональных данных Пользователя.</p>
            <p>2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить
                использование сайта.</p>
            <p>2.3.Настоящая Политика конфиденциальности применяется только к данному сайту. Администрация сайта не
                контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь может перейти
                по ссылкам, доступным на данном сайте.</p>
            <p>2.4. Администрация сайта не проверяет достоверность персональных данных, предоставляемых
                Пользователем сайта.</p>
            <p><b style={{fontSize: '120%'}}>3. Предмет политики конфиденциальности</b></p>
            <p>3.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации сайта по
                умышленному неразглашению персональных данных, которые Пользователь предоставляет по разнообразным
                запросам Администрации сайта (например, при регистрации на сайте, оформлении заказа, подписки на
                уведомления и т.п).</p>
            <p>3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности,
                предоставляются Пользователем путём заполнения специальных форм на Сайте и обычно включают в себя
                следующую информацию:</p>
            <p>3.2.1. фамилию, имя Пользователя;</p>
            <p>3.2.2. контактный телефон Пользователя;</p>
            <p>3.2.3. адрес электронной почты (e-mail);</p>
            <p>3.2.4. место жительство Пользователя и другие данные.</p>
            <p>3.3. Администрация сайта также принимает усилия по защите Персональных данных, которые автоматически
                передаются в процессе посещения страниц сайта:</p>
            <p style={{paddingLeft: '30px'}}>IP адрес;</p>
            <p style={{paddingLeft: '30px'}}>информация из cookies;</p>
            <p style={{paddingLeft: '30px'}}>информация о браузере (или иной программе, которая осуществляет доступ к
                сайту);</p>
            <p style={{paddingLeft: '30px'}}>время доступа;</p>
            <p style={{paddingLeft: '30px'}}>посещенные адреса страниц;</p>
            <p style={{paddingLeft: '30px'}}>реферер (адрес предыдущей страницы) и т.п.</p>
            <p>3.3.1. Отключение cookies может повлечь невозможность доступа к сайту.</p>
            <p>3.3.2. Сайт осуществляет сбор статистики об IP-адресах своих посетителей. Данная информация
                используется с целью выявления и решения технических проблем, для контроля корректности проводимых
                операций.</p>
            <p>3.4. Любая иная персональная информация неоговоренная выше (история покупок, используемые браузеры и
                операционные системы и т.д.) не подлежит умышленному разглашению, за исключением случаев,
                предусмотренных в п.п. 5.2. и 5.3. настоящей Политики конфиденциальности.</p>
            <p><b style={{fontSize: '120%'}}>4. Цели сбора персональной информации пользователя</b></p>
            <p>4.1. Персональные данные Пользователя Администрация сайта может использовать в целях:</p>
            <p style={{paddingLeft: '30px'}}>4.1.1. Идентификации Пользователя, зарегистрированного на сайте, для
                оформления заказа и (или) заключения Договора.</p>
            <p style={{paddingLeft: '30px'}}>4.1.2. Предоставления Пользователю доступа к персонализированным ресурсам
                сайта.</p>
            <p style={{paddingLeft: '30px'}}>4.1.3. Установления с Пользователем обратной связи, включая направление
                уведомлений, запросов, касающихся использования сайта, оказания услуг, обработка запросов и заявок
                от Пользователя.</p>
            <p style={{paddingLeft: '30px'}}>4.1.4. Определения места нахождения Пользователя для обеспечения
                безопасности, предотвращения мошенничества.</p>
            <p style={{paddingLeft: '30px'}}>4.1.5. Подтверждения достоверности и полноты персональных данных,
                предоставленных Пользователем.</p>
            <p style={{paddingLeft: '30px'}}>4.1.6. Создания учетной записи для совершения покупок, если Пользователь
                дал согласие на создание учетной записи.</p>
            <p style={{paddingLeft: '30px'}}>4.1.7. Уведомления Пользователя сайта о состоянии Заказа.</p>
            <p style={{paddingLeft: '30px'}}>4.1.8. Обработки и получения платежей, подтверждения налога или налоговых
                льгот, оспаривания платежа, определения права на получение кредитной линии Пользователем.</p>
            <p style={{paddingLeft: '30px'}}>4.1.9. Предоставления Пользователю эффективной клиентской и технической
                поддержки при возникновении проблем связанных с использованием сайта.</p>
            <p style={{paddingLeft: '30px'}}>4.1.10. Предоставления Пользователю с его согласия, обновлений продукции,
                специальных предложений, информации о ценах, новостной рассылки и иных сведений от имени сайта или
                от имени партнеров сайта.</p>
            <p style={{paddingLeft: '30px'}}>4.1.11. Осуществления рекламной деятельности.</p>
            <p style={{paddingLeft: '30px'}}>4.1.12. Предоставления доступа Пользователю на сторонние сайты или
                сервисы партнеров данного сайта с целью получения их предложений, обновлений или услуг.</p>
            <p><b style={{fontSize: '120%'}}>5. Способы и сроки обработки персональной информации</b></p>
            <p>5.1. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным
                способом, в том числе в информационных системах персональных данных с использованием средств
                автоматизации или без использования таких средств.</p>
            <p>5.2. Пользователь соглашается с тем, что Администрация сайта вправе передавать персональные данные
                третьим лицам, в частности, курьерским службам, организациями почтовой связи, операторам
                электросвязи, исключительно в целях выполнения заявок Пользователя, оформленных на сайте, в рамках
                Договора публичной оферты.</p>
            <p>5.3. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной
                власти только по основаниям и в порядке, установленным действующим законодательством.</p>
            <p><b style={{fontSize: '120%'}}>6. Обязательства сторон</b></p>
            <p>6.1. Пользователь обязуется:</p>
            <p style={{paddingLeft: '30px'}}>6.1.1. Предоставить корректную и правдивую информацию о персональных
                данных, необходимую для пользования сайтом.</p>
            <p style={{paddingLeft: '30px'}}>6.1.2. Обновить или дополнить предоставленную информацию о персональных
                данных в случае изменения данной информации.</p>
            <p style={{paddingLeft: '30px'}}>6.1.3. Принимать меры для защиты доступа к своим конфиденциальным данным,
                хранящимся на сайте.</p>
            <p>6.2. Администрация сайта обязуется:</p>
            <p style={{paddingLeft: '30px'}}>6.2.1. Использовать полученную информацию исключительно для целей,
                указанных в п. 4 настоящей Политики конфиденциальности.</p>
            <p style={{paddingLeft: '30px'}}>6.2.2. Не разглашать персональных данных Пользователя, за исключением
                п.п. 5.2. и 5.3. настоящей Политики Конфиденциальности.</p>
            <p style={{paddingLeft: '30px'}}>6.2.3. Осуществить блокирование персональных данных, относящихся к
                соответствующему Пользователю, с момента обращения или запроса Пользователя или его законного
                представителя либо уполномоченного органа по защите прав субъектов персональных данных на период
                проверки, в случае выявления недостоверных персональных данных или неправомерных действий.</p>
            <p><b style={{fontSize: '120%'}}>7. Ответственность сторон</b></p>
            <p>7.1. Администрация сайта несёт ответственность за умышленное разглашение Персональных данных
                Пользователя в соответствии с действующим законодательством, за исключением случаев, предусмотренных
                п.п. 5.2., 5.3. и 7.2. настоящей Политики Конфиденциальности.</p>
            <p>7.2. В случае утраты или разглашения Персональных данных Администрация сайта не несёт
                ответственность, если данная конфиденциальная информация:</p>
            <p style={{paddingLeft: '30px'}}>7.2.1. Стала публичным достоянием до её утраты или разглашения.</p>
            <p style={{paddingLeft: '30px'}}>7.2.2. Была получена от третьей стороны до момента её получения
                Администрацией сайта.</p>
            <p style={{paddingLeft: '30px'}}>7.2.3. Была получена третьими лицами путем несанкционированного доступа к
                файлам сайта.</p>
            <p style={{paddingLeft: '30px'}}>7.2.4. Была разглашена с согласия Пользователя.</p>
            <p>7.3. Пользователь несет ответственность за правомерность, корректность и правдивость предоставленной
                Персональных данных в соответствии с действующим законодательством.</p>
            <p><b style={{fontSize: '120%'}}>8. Разрешение споров</b></p>
            <p>8.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем сайта и
                Администрацией сайта, обязательным является предъявление претензии (письменного предложения о
                добровольном урегулировании спора).</p>
            <p>8.2. Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно
                уведомляет заявителя претензии о результатах рассмотрения претензии.</p>
            <p>8.3. При не достижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии
                с действующим законодательством.</p>
            <p>8.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией сайта
                применяется действующее законодательство.</p>
            <p><b style={{fontSize: '120%'}}>9. Дополнительные условия</b></p>
            <p>9.1. Администрация сайта вправе вносить изменения в настоящую Политику конфиденциальности без
                согласия Пользователя.</p>
            <p>9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте, если иное не
                предусмотрено новой редакцией Политики конфиденциальности.</p>
        </div>
        <div className="line-solid" style={{paddingBottom: '100px'}}></div>
    </div>
}