import {observer} from "mobx-react";
import {CustomComponent} from "../../../../../helpers/CustomComponent";
import {LanguageViewModel} from "../NewRankModel";

export interface LanguageRankProps{
    numberPosition: number;
    language: LanguageViewModel;
}
@observer
export class LanguageRank extends CustomComponent<LanguageRankProps, {}> {

    constructor(props: Readonly<LanguageRankProps>) {
        super(props);
    }

    render() {
        return (
            <>
                <div className="ex-title-name">Выучите иностранный язык</div>
                <div className="exercise-box">
                    <div className="ex-name">{this.props.numberPosition}. Подтвердите владение {this.props.language.countLanguage} иностранным
                        языком
                    </div>
                    <div className="ex-line-box">
                        <div className="res-language-empty">Не подтвержденно</div>
                    </div>
                    <div className="exsb-1">
                        <a href="https://t.me/alexgruk">
                            <div className="ex-bottom">Подтвердить</div>
                        </a>
                    </div>
                </div>
            </>
        );
    }
}