
export enum TypeScreen {
    Mobile = 1,
    Tablet = 2,
    Desktop = 3
}

export function UseScreenType(): TypeScreen {
    let width = window.innerWidth;

    if (width <= 900)
        return TypeScreen.Mobile;
    if (width <= 1680)
        return TypeScreen.Tablet;
    return TypeScreen.Desktop;
}


export function CheckScreen(screens: TypeScreen[]): boolean {
    if (screens.includes(UseScreenType()))
        return true;
    return false;
}