import {makeAutoObservable} from "mobx";
import KeyboardManager from "../../core/keyboardManager/adapters/KeyboardManager";

export class ReactModalStore {
    public showModal: boolean = false;
    public customClose?: () => void;
    constructor(customClose?: ()=>void) {
        makeAutoObservable(this, {}, {autoBind: true});
        this.customClose = customClose;
    }

    init(){
        KeyboardManager.add('Escape', this.openOrClose);
    }
    unInit(){
        KeyboardManager.remove('Escape', this.openOrClose);
    }

    close(){
        this.showModal = false;
        if (this.customClose)
            this.customClose();
    }

    open(){
        this.showModal = true;
    }

    openOrClose(){
        if (this.showModal){
            this.close()
        }else{
            this.open()
        }
    }
}