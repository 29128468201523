import {CustomComponent} from "../../../../../helpers/CustomComponent";
import {TrainingAnswerRequestItemDto} from "../../../model/TrainingAnswerRequestModel";
import React, {ChangeEvent} from "react";
import {InputMask} from '@react-input/mask';
import {AnsCellFocusController} from "../../AnsCellFocusController";
import {observer} from "mobx-react";

export interface AnsCellNumberProps {
    item: TrainingAnswerRequestItemDto,
    pos: number,
    value: string;
    complexity: string;
    deleteBlock: (pos: number) => void
    addBlock: (pos: number) => void
    changeName: (pos: number, text: string) => void
}

@observer
export class AnsCellBins extends CustomComponent<AnsCellNumberProps, any> {
    public controller: AnsCellFocusController = new AnsCellFocusController();

    constructor(props: Readonly<AnsCellNumberProps>) {
        super(props);
        this.deleteBlock = this.deleteBlock.bind(this);
        this.addBlock = this.addBlock.bind(this);
        this.changeName = this.changeName.bind(this);
    }

    deleteBlock() {
        this.props.deleteBlock(this.props.pos);
    }

    addBlock() {
        this.props.addBlock(this.props.pos);
    }

    changeName(e: ChangeEvent<HTMLInputElement>) {
        if (e.nativeEvent.data !== '0' && e.nativeEvent.data !== '1'){
            return;
        }
        let value = e.target.value.replace(" ", "").replace(" ", "");
        let countValue = value.length;
        if (countValue <= 3){
            this.props.changeName(this.props.pos, value);
            return;
        }
        if (countValue <= 6){
            this.props.changeName(this.props.pos, value.slice(0, 3) + " " + value.slice(3));
            return;
        }
        this.props.changeName(this.props.pos, value.slice(0, 3) + " " + value.slice(3, 6)+ " " + value.slice(6));
    }

    get complexity(){
        if (this.props.complexity === '1') {
            return  'xxx'
        }
        if (this.props.complexity === '2') {
            return  'xxx xxx'
        }
        return 'xxx xxx xxx';
    }

    get nameDiv(){
        if (this.props.complexity === '1') {
            return  'ans-cell-number ';
        }
        return 'ans-cell-binary69 ';
    }

    render() {
        let focusClass = this.controller.hasFocus ? " input-write-answer-focus" : "";
        return <div className={this.nameDiv + focusClass}>
            <span className="num-cell" data-title={this.props.item.routeName}>{this.props.pos + 1}</span>
            <div onClick={this.deleteBlock} className="add-cell" title="Удалить со сдвигом влево"></div>
            <div onClick={this.addBlock} className="del-cell" title="Добваить ячейку"></div>
            <InputMask
                autoFocus={this.props.pos === 0}
                type='tel'
                onFocus={this.controller.onFocus}
                onBlur={this.controller.onBLur}
                ref={this.controller.setHTMLInputElement}
                mask={this.complexity}
                replacement={{x: /[0-1]/}}
                onChange={this.changeName}
                value={this.props.item.answer}
                className={"ans ans-focus " + focusClass }
            />
        </div>;
    }
}