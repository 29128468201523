import {GetRequest} from "../../helpers/Request";
import {TrainingResultModel} from "./model/TrainingResultModel";
import {makeAutoObservable} from "mobx";
import {LoadingModel} from "../shared/LoadingModel";
import React from "react";
import IResultService from "./service/IResultService";
import NumberResultService from "./service/NumberResultService";
import BinsResultService from "./service/BinsResultService";
import WordResultService from "./service/WordResultService";
import NameResultService from "./service/NameResultService";
import ImageResultService from "./service/ImageResultService";
import CardsResultService from "./service/CardsResultService";


export default class TrainingResultStore {
    trainingId?: string;
    model: LoadingModel<TrainingResultModel>;

    constructor(trainingId?: string, model?: LoadingModel<TrainingResultModel>) {
        makeAutoObservable(this, {}, {autoBind: true})
        this.trainingId = trainingId;
        this.model = model ?? LoadingModel.createLoaded<TrainingResultModel>();
    }

    getService(): IResultService | undefined {
        if (!this.model.IsLoadedModel())
            return undefined;
        if (this.model.Model!.mode === 'number')
            return new NumberResultService();
        if (this.model.Model!.mode === 'bins')
            return new BinsResultService();
        if (this.model.Model!.mode === 'word')
            return new WordResultService();
        if (this.model.Model!.mode === 'name')
            return new NameResultService();
        if (this.model.Model!.mode === 'image')
            return new ImageResultService();
        if (this.model.Model!.mode === 'cards')
            return new CardsResultService();
        return undefined;
    }

    setModel(model: LoadingModel<TrainingResultModel>) {
        this.model = model;
    }

    request() {
        if (this.trainingId)
            GetRequest<TrainingResultModel>("/Training/Search?id=" + this.trainingId)
                .then(this.setModel)
    }

    get coefficient(): number {
        if (this.model.IsLoadedModel()) {
            if (this.model.Model!.mode === 'bins') {
                if (this.model.Model!.complexity === "1")
                    return 3;
                if (this.model.Model!.complexity === "2")
                    return 6;
                if (this.model.Model!.complexity === "3")
                    return 9;
            }
        }
        return 1;
    }

    get textRight(): string {
        if (this.model.IsLoadedModel() && this.model.Model) {
            let model = this.model.Model;
            switch (model.mode) {
                case "cards":
                    if (model.complexity === "3")
                        return "";
                    return "карты"
                case "word":
                    return "слов"
                case "image":
                    if (model.complexity === "1")
                        return "фракталов";
                    if (model.complexity === "2")
                        return "картинок";
                    return ""
                case "bins":
                    return "знаков"
                case "name":
                    return "имён"
                case "number":
                    return "цифр"
            }
        }
        return '';
    }

    getItems(): JSX.Element {
        if (this.model.IsLoadedModel()) {
            return (<div id="ans-box-res">
                <div className="ans-box-container-res">
                    {this.getService()?.GetItems(this.model.Model!)}
                </div>
            </div>)
        }
        return <></>
    }
}