import {makeAutoObservable} from "mobx";
import {IAnswerTrainingBaseService} from "../service/IMemberTrainingBaseService";
import {TrainingAnswerModel} from "../model/TrainingAnswerModel";
import {NumberTrainingAnswerBaseService} from "../service/number/NumberTrainingAnswerBaseService";
import {TrainingAnswerRequestItemDto, TrainingAnswerRequestItemModel} from "../model/TrainingAnswerRequestModel";
import {WordTrainingAnswerBaseService} from "../service/words/WordTrainingAnswerBaseService";
import {BinsTrainingAnswerBaseService} from "../service/bins/BinsTrainingAnswerBaseService";
import {NameTrainingAnswerBaseService} from "../service/name/NameTrainingAnswerBaseService";
import {ImageTrainingAnswerBaseService} from "../service/image/ImageTrainingAnswerBaseService";
import StopwatchService from "../../../helpers/StopwatchService";
import {CardsTrainingAnswerBaseService} from "../service/cards/CardsTrainingAnswerBaseService";
import AuthorizationStore from "../../authorization/store/AuthorizationStore";


export class AnswerTrainingBaseStore {
    model: TrainingAnswerModel;
    items: TrainingAnswerRequestItemDto[] = [];
    service: IAnswerTrainingBaseService;
    resultRequest: (items: TrainingAnswerRequestItemModel[], timerRec: number) => void;

    constructor(model: TrainingAnswerModel, resultRequest: (items: TrainingAnswerRequestItemModel[], timeRec: number) => void) {
        makeAutoObservable(this, {}, {autoBind: true})
        this.model = model;
        this.resultRequest = resultRequest;
        for (const item of this.model.items) {
            this.items.push({
                id: item.id,
                answerNumber: null,
                routeName: item.routeName,
                dictionaryImage: item.dictionaryImage,
                answer: '',
                answerImage: undefined,
                numberPosition: item.numberPosition,
                length: item.length,
                p: null,
                setAnswer: false
            })
        }
        this.service = this.getAnswerService();
    }

    init(){
        AuthorizationStore.setShowLayoutHead(false);
    }

    unInit(){
        AuthorizationStore.setShowLayoutHead(true);
    }

    getAnswerService():IAnswerTrainingBaseService{
        if (this.model.mode === 'number')
            return new NumberTrainingAnswerBaseService(this.model, this.items);

        if (this.model.mode === 'word')
            return new WordTrainingAnswerBaseService(this.model, this.items);

        if (this.model.mode === 'bins')
            return new BinsTrainingAnswerBaseService(this.model, this.items);

        if (this.model.mode === 'name')
            return new NameTrainingAnswerBaseService(this.model, this.items);

        if (this.model.mode === 'image')
            return new ImageTrainingAnswerBaseService(this.model, this.items);

        if (this.model.mode === 'cards')
            return new CardsTrainingAnswerBaseService(this.model, this.items);

        throw new Error('not found mode' + this.model.mode);
    }

    nextElement(){
        this.service.next();
    }

    prevElement(){
        this.service.prev()
    }

    onFinish() {
        let res = this.items
            .sort((x, y) => x.numberPosition - y.numberPosition)
            .map<TrainingAnswerRequestItemModel>((x, pos) => {
            return {
                answer: this.model.mode === 'image' ? x.answerImage!: x.answer,
                number: pos,
            }
        })
        this.resultRequest(res, StopwatchService.currentTime)
    }
}