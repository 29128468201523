import {makeAutoObservable} from "mobx";


export class AnsCellFocusController {
    htmlInputElement: HTMLInputElement | null = null;
    focus: boolean = false;

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true})
    }

    setHTMLInputElement(htmlInputElement: HTMLInputElement) {
        this.htmlInputElement = htmlInputElement;
    }

    onFocus() {
        this.htmlInputElement?.focus();
        this.focus = true;
        console.log('onFocus', this.focus);
    }

    onBLur() {
        this.focus = false;
    }

    get hasFocus(): boolean {
        return this.focus;
    }
}