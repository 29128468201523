import React from 'react'
import {Link} from 'react-router-dom'
import './navigatorFooter.scss'
import AuthorizationStore from "../authorization/store/AuthorizationStore";
import {observer} from "mobx-react";
import {CustomComponent} from "../../helpers/CustomComponent";
import {CheckScreen, TypeScreen} from "../../context/TypeScreen";
import {Avatar} from "../user/Avatar";
import {HProfileUserMenu} from "../authorization/HProfileUserMenu";


@observer
export class NavigationFooter extends CustomComponent<{}, {}> {
    constructor(props: Readonly<{}>) {
        super(props);
        this.openModal = this.openModal.bind(this);
        this.openModalBalls = this.openModalBalls.bind(this);
        this.showMenu = this.showMenu.bind(this);
        this.hideMenu = this.hideMenu.bind(this);
    }
    componentDidMount() {
    }

    openModal(){
        AuthorizationStore.setShowModalBalls(false);
        AuthorizationStore.openModalAuth();
    }
    openModalBalls(){
        AuthorizationStore.setShowModalBalls(!AuthorizationStore.showModalBalls);
    }

    showMenu() {
        if (AuthorizationStore.showMenu) {
            this.hideMenu();
        } else {
            AuthorizationStore.setShowMenu(true);
        }
    }

    hideMenu() {
        AuthorizationStore.setShowMenu(false);
    }

    render() {
        if (!AuthorizationStore.showLayoutHead)
            return <></>
        if (CheckScreen([TypeScreen.Desktop, TypeScreen.Tablet]))
            return <></>
        return (
            <div className="header-footer footer">
                <div className="hf-bottom" id="mob-top" onClick={this.openModalBalls}></div>
                {
                    AuthorizationStore.checkAuthMenu &&
                    <Link className="hf-bottom" id="mob-stat" to={"/Statistics"} />
                }
                {
                    !AuthorizationStore.checkAuthMenu &&
                    <div className="hf-bottom" id="mob-stat" onClick={this.openModal}></div>
                }
                <Link className="hf-bottom" id="mob-play" to={"/Training"} />
                <Link className="hf-bottom" id="mob-notes-off" to={"/"} />
                {
                    !AuthorizationStore.checkAuthMenu &&
                    <div className="hf-bottom" id="mob-profil" onClick={this.openModal}>
                        <img className="mobile-footer-login" src="https://powermemory.io/images/auth/login.svg"
                             alt="Войти"/>
                    </div>
                }
                {AuthorizationStore.checkAuthMenu &&
                    <div className="container-user-login hf-bottom" id="mob-profil" onMouseLeave={this.hideMenu}>
                        <div className="ava-header-label" onClick={this.showMenu}>
                            <Avatar fullname={AuthorizationStore.user.Model?.fullname ?? ""}
                                    img={AuthorizationStore.user.Model?.imgSrc ?? ""}
                                    link={AuthorizationStore.user.Model?.link ?? ""}
                                    canClickMnemocard={false}
                                    height='33px' width='33px'/>
                        </div>
                        {
                            AuthorizationStore.showMenu &&
                            <HProfileUserMenu />
                        }
                    </div>
                }
            </div>
        )
    }
}

// export function Navigation() {
//   return (
//     <nav className="h-[50px] flex justify-between px-5 bg-gray-500 items-center text-white">
//       <span className="font-bold">React 2022</span>
//
//       <span>
//         <Link to="/" className="mr-2">Products</Link>
//         <Link to="/about">About</Link>
//         <Link to="assets/html/course.html">Course</Link>
//       </span>
//     </nav>
//   )
// }