import React, {ChangeEvent, Component} from 'react'
import {observer} from "mobx-react";
import TrainingChangeBaseModel from "../store/TrainingChangeBaseModel";
import CustomSelector, {SelectorPros} from "../../shared/selector/CustomSelector";
import {CustomNumberInput} from "../../shared/CustomNumberInput";

// todo Подумать еще разбить на блоки. ЧТобы без этой хуйни, когда все в одном компоненте!

enum ErrorNum {
    successfully = 0,
    outRange = 1,
    largeNumberComesOutSmaller = 2
}

@observer
export class RangeTimesTrainingOneView extends Component<{ changeModel: TrainingChangeBaseModel; }, {}> {
    public languageWords: SelectorPros[] = [
        {value: "0", title: "Русский"},
        {value: "1", title: "Английский"},
    ];
    public cards: SelectorPros[] = [
        {value: "1", title: "Англо-Американский"},
    ];
    public names: SelectorPros[] = [
        {value: "1", title: "Мужской и женский"},
        {value: "2", title: "Мужской"},
        {value: "3", title: "Женский"},
    ];
    public bins: SelectorPros[] = [
        {value: "1", title: "Нули и единицы"},
    ];

    constructor(props: Readonly<{ changeModel: TrainingChangeBaseModel; }>) {
        super(props);
        this.onChangeWordLanguage = this.onChangeWordLanguage.bind(this);
        this.changeTimeOnElement = this.changeTimeOnElement.bind(this);
        this.changeDeferredTime = this.changeDeferredTime.bind(this);
        this.changeRangeFrom = this.changeRangeFrom.bind(this);
        this.changeRangeTo = this.changeRangeTo.bind(this);
        this.onChangeCards = this.onChangeCards.bind(this);
        this.onChangeSex = this.onChangeSex.bind(this);
        this.onChangeBin = this.onChangeBin.bind(this);
    }

    changeTimeOnElement(value: string) {
        if (value === '')
            this.props.changeModel.onChangeTimeOnElement(undefined);
        let valueFloat = parseFloat(value.replace('.', ','));// Добавить точку '.'
        if (isNaN(valueFloat))
            return "";
        this.props.changeModel.timeOnElementError = valueFloat < 0.1 || valueFloat > 20;
        this.props.changeModel.onChangeTimeOnElement(value);
    }

    changeDeferredTime(value: string) {
        if (value === '')
            this.props.changeModel.onChangeDeferredTime(undefined);
        let valueFloat = parseFloat(value.replace('.', ','));// Добавить точку '.'
        if (isNaN(valueFloat))
            return "";
        this.props.changeModel.deferredTimeError = valueFloat < 1 || valueFloat > 60;
        this.props.changeModel.onChangeDeferredTime(value);
    }

    changeRangeFrom(e: ChangeEvent<HTMLInputElement>) {
        this.props.changeModel.onChangeRangeFrom(parseInt(e.currentTarget.value));
    }

    changeRangeTo(e: ChangeEvent<HTMLInputElement>) {
        this.props.changeModel.onChangeRangeTo(parseInt(e.currentTarget.value));
    }

    get numberStatus(): ErrorNum {
        if (this.props.changeModel.type !== 'number')
            return ErrorNum.successfully;

        if (!this.props.changeModel.model.rangeFrom || !this.props.changeModel.model.rangeTo)
            return ErrorNum.successfully;

        if (this.props.changeModel.model.rangeFrom > this.props.changeModel.model.rangeTo)
            return ErrorNum.largeNumberComesOutSmaller;

        if (this.props.changeModel.model.complexity === '1' &&
            (this.props.changeModel.model.rangeFrom >= 9 || this.props.changeModel.model.rangeTo >= 9 ))
            return ErrorNum.outRange;
        if (this.props.changeModel.model.complexity === '2' &&
            (this.props.changeModel.model.rangeFrom >= 99 || this.props.changeModel.model.rangeTo >= 99))
            return ErrorNum.outRange;
        if (this.props.changeModel.model.complexity === '3' &&
            (this.props.changeModel.model.rangeFrom >= 999 || this.props.changeModel.model.rangeTo >= 999))
            return ErrorNum.outRange;

        // #d93243
        return ErrorNum.successfully;
    }

    onChangeWordLanguage(sel: SelectorPros | undefined) {
        this.props.changeModel.onChangeLanguage(sel?.value);
    }

    onChangeCards(sel: SelectorPros | undefined) {
        this.props.changeModel.onChangeCards(sel?.value);
    }

    onChangeSex(sel: SelectorPros | undefined) {
        this.props.changeModel.onChangeSex(sel?.value);
    }

    onChangeBin(sel: SelectorPros | undefined) {
        this.props.changeModel.onChangeBin(sel?.value);
    }

    render() {
        return (
            <div className="range-times">
                <div>
                    <label id="timer-label" htmlFor="timer">Время на элемент, с</label>
                    <CustomNumberInput id="timer"
                           style={{width: '190px', color: !this.props.changeModel.timeOnElementError ? '#000' : '#d93243'}}
                           value={this.props.changeModel.model.timeOnElement?.toString() ?? ""}
                           onChange={this.changeTimeOnElement}
                           name="timer"
                           title="От 0.1 до 20 сек"/>

                    <label id="counting-label" htmlFor="counting">Отложенный старт, с</label>
                    <CustomNumberInput id="counting"
                                       style={{width: '190px', color: !this.props.changeModel.deferredTimeError ? '#000' : '#d93243'}}
                                       value={this.props.changeModel.model.deferredTime?.toString() ?? ""}
                                       onChange={this.changeDeferredTime}
                                       name="counting"
                                       title="От 5 до 60 сек"/>
                </div>

                <div className="addition-info">
                    {/*{*/}
                    {/*    this.props.changeModel.type === 'number' &&*/}
                    {/*    <span className="addition-name">Диапазон чисел</span>*/}
                    {/*}*/}
                    {
                        this.props.changeModel.type === 'word' &&
                        <span className="addition-name">Язык слов</span>
                    }
                    {
                        this.props.changeModel.type === 'cards' &&
                        <span className="addition-name">Стиль колоды</span>
                    }
                    {
                        this.props.changeModel.type === 'name' &&
                        <span className="addition-name">Пол</span>
                    }
                    {
                        this.props.changeModel.type === 'bins' &&
                        <span className="addition-name">Формат отображения</span>
                    }
                </div>

                <div style={{display: 'flex'}}>
                    {/*{*/}
                    {/*    this.props.changeModel.type === 'number' &&*/}
                    {/*    <>*/}
                    {/*        <div style={{display: 'flex', width: '50%'}}>*/}
                    {/*            <label className="diapason-1-label label_text" htmlFor="diapason-1">От</label>*/}
                    {/*            <input id="diapason-1" type="number" className="addition"*/}
                    {/*                   onChange={this.changeRangeFrom}*/}
                    {/*                   value={this.props.changeModel?.model?.rangeFrom ?? ""}*/}
                    {/*                   name="diapason" autoComplete="off"/>*/}
                    {/*        </div>*/}
                    {/*        <div style={{display: 'flex', width: '50%'}}>*/}
                    {/*            <label className="diapason-2-label label_text" htmlFor="diapason-2">До</label>*/}
                    {/*            <input id="diapason-2" type="number" className="addition"*/}
                    {/*                   onChange={this.changeRangeTo}*/}
                    {/*                   value={this.props.changeModel?.model?.rangeTo ?? ""}*/}
                    {/*                   name="diapason" autoComplete="off"/>*/}
                    {/*        </div>*/}
                    {/*    </>*/}
                    {/*}*/}
                    {
                        this.props.changeModel.type === 'word' &&
                        <CustomSelector defaultValue={this.props.changeModel.model.language ?? '0'}
                                        onChange={this.onChangeWordLanguage}
                                        width='190px'
                                        values={this.languageWords}/>
                    }
                    {
                        this.props.changeModel.type === 'cards' &&
                        <CustomSelector defaultValue={this.props.changeModel.model.language ?? '0'}
                                        onChange={this.onChangeCards}
                                        width='190px'
                                        values={this.cards}/>
                    }
                    {
                        this.props.changeModel.type === 'name' &&
                        <CustomSelector defaultValue={this.props.changeModel.model.sex ?? '1'}
                                        onChange={this.onChangeSex}
                                        width='190px'
                                        values={this.names}/>
                    }
                    {
                        this.props.changeModel.type === 'bins' &&
                        <CustomSelector defaultValue={this.props.changeModel.model.sex ?? '1'}
                                        onChange={this.onChangeBin}
                                        width='190px'
                                        values={this.bins}/>
                    }
                </div>
                {
                    this.props.changeModel.type === 'number' && this.numberStatus === ErrorNum.outRange &&
                    this.props.changeModel.model.complexity === '1' &&
                    <span>Для однозначных чисел диапазон должен быть от 0 до 9.</span>
                }
                {
                    this.props.changeModel.type === 'number' && this.numberStatus === ErrorNum.outRange &&
                    this.props.changeModel.model.complexity === '2' &&
                    <span>Для двузначных чисел диапазон должен быть от 00 до 99.</span>
                }
                {
                    this.props.changeModel.type === 'number' && this.numberStatus === ErrorNum.outRange &&
                    this.props.changeModel.model.complexity === '2' &&
                    <span>Для трехзначных чисел диапазон должен быть от 000 до 999.</span>
                }
                {
                    this.props.changeModel.type === 'number' && this.numberStatus === ErrorNum.largeNumberComesOutSmaller &&
                    <span>Значение 'От' должно быть меньше значения 'До'.</span>
                }
                {
                    this.props.changeModel.type === 'number' && (!!this.props.changeModel.model.rangeTo ||
                        !!this.props.changeModel.model.rangeFrom) &&
                    <span id="number_no_result" className="no-result">
                        (результаты не учитываются)
                    </span>
                }
            </div>
        )
    }
}