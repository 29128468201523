import {Component} from "react";
import {observer} from "mobx-react";
import TransitionRateStore from "./TransitionRateStore";
import CustomSelector from "../../../shared/selector/CustomSelector";
import {Loading} from "../../../shared/Loading";


@observer
export class TransitionRate extends Component<{}, {}> {
    private store: TransitionRateStore = new TransitionRateStore();

    componentDidMount() {
        this.store.loadingStore.fetchRequest();
    }

    render() {
        if (!this.store.loadingStore.isLoaded)
            return <Loading />
        return (
            <>
                <span className="sat-name">Темп перехода</span>
                <span className="sat-name sat-name2">Количество локаций по темпу перехода</span>
                <div className="statchosen-2v">
                    <div className="select-style statchosen2">
                        <CustomSelector defaultValue={this.store.selectorRoute?.value ?? ""}
                                        onChange={this.store.setSelectorRoute}
                                        values={this.store.getRoutes}
                                        width="100%"/>
                    </div>

                    <div className="chdis" id="ch-regime2">
                        <div className="select-style-t2">
                            <CustomSelector defaultValue={this.store.selectorCH.value}
                                            value={this.store.selectorCH.value}
                                            values={this.store.selectorsCH}
                                            onChange={this.store.setSelectorCH}
                                            width="100%"/>
                        </div>
                    </div>
                </div>

                <div className="circle-tabl-box">
                    <div className="elpia-and-text">
                        <div className="pietxt" id="elpie3"></div>
                        <div className="mswey">
                            <span className="medium-el" id="middleSpeed">0,00</span>
                            <span className="txt-actstl">средний темп перехода за последние 10 результатов</span>
                        </div>
                        <span className="empty-circle2"><span className="circle0">Нет данных</span></span>
                    </div>

                    <table className="elpie-tabel">
                        <tbody>
                        <tr className="htr">
                            <td>Переход</td>
                            <td>Ср.темп, с</td>
                            <td>Кол-во</td>
                        </tr>
                        <tr>
                            <td className="htr-ref">Рефлекторный</td>
                            <td>≤0,5</td>
                            <td id="speed05">{this.store.current?.speed05 ?? "-"}</td>
                        </tr>
                        <tr>
                            <td className="htr-vm">Очень быстрый</td>
                            <td>0,5-1</td>
                            <td id="speed10">{this.store.current?.speed10 ?? "-"}</td>
                        </tr>
                        <tr>
                            <td className="htr-v">Быстрый</td>
                            <td>1-1,5</td>
                            <td id="speed15">{this.store.current?.speed15 ?? "-"}</td>
                        </tr>
                        <tr>
                            <td className="htr-d">Средний</td>
                            <td>1,5-2,5</td>
                            <td id="speed25">{this.store.current?.speed25 ?? "-"}</td>
                        </tr>
                        <tr>
                            <td className="htr-b">Медленный</td>
                            <td>≥2,5</td>
                            <td id="speed">{this.store.current?.speed250 ?? "-"}</td>
                        </tr>
                        </tbody>
                    </table>

                </div>


                <div className="substrate sub1l"></div>

                <span className="sat-name sat-name3">Тенденция темпа перехода</span>
                {/*<div className="charttxt2" id="recway-chart"*/}
                {/*     style="max-width: 900px; min-height: 400px; margin: 0px auto; overflow: hidden;"*/}
                {/*     data-highcharts-chart="6"></div>*/}
                <div className="dop-chart2" id="recway-dop">
                    <div id="recwaydop1">
                        <span className="amount-actstl" id="speedGrowl">-</span>
                        <span className="txt-actstl">средний прирост темпа</span>
                    </div>
                    <div id="recwaydop2">
                        <span className="amount-actstl" id="days">-</span>
                        <span className="txt-actstl">дней до достижения рефлекса</span>
                    </div>
                </div>

                <span className="empty-stat4">Нет данных для построения графика</span>

                <div className="substrate sub2l"></div>

                <span className="sat-name sat-name4">Детализация результатов темпа перехода за тренировочные дни</span>

                <div className="select-style statchosen3">
                    <CustomSelector defaultValue={this.store.selectorCH.value}
                                    value={this.store.selectorCH.value}
                                    values={this.store.selectorsCH}
                                    onChange={this.store.setSelectorCH}
                                    width="305px"/>
                </div>

                <div className="stat-table-wrapper">
                    <table className="stat-table" id="speedway-table">
                        <tbody id="table_body">
                        </tbody>
                    </table>

                    <div className="del" id="del-tb-speedway"
                         title="Отчистить данные графика"></div>
                </div>

                <input className="info-t" id="info-speedway" type="checkbox"/>
                <label className="info-train info-stat" htmlFor="info-speedway"></label>
                <div className="info" id="about-speedway">
                    Справка по темпу перехода
                    <div className="closer2w"></div>
                </div>
            </>
        );
    }
}