import {makeAutoObservable} from "mobx";
import {SelectorPros} from "../../../shared/selector/CustomSelector";
import LoadingStore from "../../../../helpers/LoadingStore";
import Highcharts from "highcharts";
import {LocationQualityModel, LocationQualityPointModel, LocationQualityPointTimeModel} from "./LocationQualityModel";


export class LocationQualityStore {
    public currentPos: string = "0";
    public loadingStore: LoadingStore<LocationQualityModel[]>;
    public optionsPie = {
        title: "",
        width: 373,
        height: 373,
        colors: ['#4fb260', '#a5bd43', '#d93243'],
        chartArea: {left: 0, top: 0, width: '100%', height: '80%', margin: '10px'},
        legend: 'none'
    };


    constructor() {
        makeAutoObservable(this, {}, {autoBind: true});
        this.loadingStore = new LoadingStore<LocationQualityModel[]>(this.getUrl)
    }

    fetchRequest() {
        this.loadingStore.fetchRequest();
    }

    get getSelector(): SelectorPros[] {
        if (!this.loadingStore.isLoaded)
            return [];
        return this.loadingStore.get!.map((x, index) => {
            return {
                value: index.toString(),
                title: x.name
            }
        });
    }

    get getDataPie() {
        return [
            ['Состояние', 'Кол-во опорных'],
            ['Надёжные', this.getCurrent?.good ?? 9],
            ['Хорошие', this.getCurrent?.normal ?? 0],
            ['Плохие', this.getCurrent?.bad ?? 0]
        ];
    }

    get getCurrent(): LocationQualityModel {
        if (!this.loadingStore.isLoaded)
            return {
                bad: 0, good: 0, name: "", normal: 0, points: []
            };
        return this.loadingStore.get![parseInt(this.currentPos)]!;
    }

    getUrl(): string {
        return '/api/Statistic/LocationQuality';
    }

    get getView(): JSX.Element {
        return (<>
            {
                this.getCurrent.points.map((x, index) =>
                    <div key={x.name + '_' + x.number} className="route-line">
                        <div className="route">
                            <span className="name-route">{index + 1}. {x.name}</span>
                        </div>
                        <div className="route-cell">
                            {this.getCorrectlies(x.correctlies, x, index)}
                        </div>
                    </div>)
            }
        </>)
    }

    data() {
        return [
            ['Состояние', 'Кол-во опорных'],
            ['Надёжные', this.getCurrent.good],
            ['Хорошие', this.getCurrent.good],
            ['Плохие', this.getCurrent.good]
        ];
    }

    getCorrectlies(correctlies: LocationQualityPointTimeModel[], model: LocationQualityPointModel, index: number): JSX.Element {
        let massive = [];
        for (let p = 0; p < correctlies.length || p < 10; p++) {
            if (p >= correctlies.length) {
                massive.push(<div key={model.name + '_' + index + '_' + p}
                                  className="cell cell_empty"></div>)
                continue;
            }
            if (correctlies[p].isCorrect) {
                massive.push(<div key={correctlies[p].number + '_' + model.name + '_' + index + '_' + p}
                                  className="cell cell_green"></div>)
                continue;
            }
            massive.push(<div key={correctlies[p].number + '_' + model.name + '_' + index + '_' + p}
                              className="cell cell_red"></div>)
        }
        return <>
            {massive}
        </>
    }


    get options(): Highcharts.Options {
        return {
            chart: {
                // width: 900,
                style: {
                    'float': 'right'
                }
            },
            rangeSelector: {
                selected: 0
            },
            title: {
                text: ''
            },
            series: [{
                name: 'Текущий темп',
                // data: this.loadingStore?.model.Model?.list
                //     .map((x) => [x.date, x.speed]) ?? [],
                type: 'area',
                threshold: null,
                tooltip: {
                    valueDecimals: 0
                },
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, '#4572A7'],
                        [1, 'white']
                    ]
                }
            },
                {
                    name: 'Лучший темп на объеме 20',
                    // data: this.loadingStore?.model.Model?.list
                    //     .map((x) => [x.date, this.loadingStore?.model.Model!.minValue]) ?? [],
                    type: 'area',
                    threshold: null,
                    tooltip: {
                        valueDecimals: 0
                    },
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1
                        },
                        stops: [
                            [0, '#4572A7'],
                            [1, 'white']
                        ]
                    }
                }]
        };
    }

    setSelector(sel: SelectorPros) {
        this.currentPos = sel.value;
    }
}