import React, {ChangeEvent, Component} from 'react'
import {observer} from "mobx-react";
import RouteTrainingStore from "../store/RouteTrainingStore";
import CustomSelector, {SelectorPros} from "../../shared/selector/CustomSelector";
import {RouteCustomView} from "./view/RouteCustomView";
import Modal from "../../modal/ReactModal";
import {ReactModalStore} from "../../modal/ReactModalStore";


interface Props {
    store: RouteTrainingStore,
}

@observer
export class RouteView extends Component<Props, {}> {
    private readonly reactModalStore: ReactModalStore = new ReactModalStore();

    constructor(props: Readonly<Props>) {
        super(props);
        this.changeRoute = this.changeRoute.bind(this);
        this.changePoint = this.changePoint.bind(this);
        this.onChangeCount = this.onChangeCount.bind(this);
    }

    changeRoute(sel: SelectorPros | undefined) {
        if (sel)
            this.props.store.selectRoute(sel);
    }

    changePoint(sel: SelectorPros | undefined) {
        if (sel)
            this.props.store.selectStartPoint(sel);
    }

    onChangeCount(e: ChangeEvent<HTMLInputElement>) {
        if (e.currentTarget.value === '') {
            this.props.store.onChangeCount(undefined)
        } else {
            this.props.store.onChangeCount(parseInt(e.currentTarget.value))
        }
    }

    render() {
        if (this.props.store.trainingStore?.currentStorage.type === 'name')
            return <div className="route-training"/>
        return (
            <>
                <Modal store={this.reactModalStore} autoSub={true}>
                    <div className="add-way-now ui-sortable" id="add-way-now">
                        <div className="make-way">
                            {
                                this.props.store.customRoutes.map((route, i) => {
                                    return <RouteCustomView key={i} route={route} store={this.props.store} index={i}/>
                                })
                            }
                        </div>
                    </div>
                </Modal>
                <div className="route-training">
                    {/*// <!-- Маршрут и настройки тренажёров 1-->*/}
                    <div className="route-name">
                        <input type="button" id="nws"/>
                        <label className="way-text way-text1-1" id="name-way" htmlFor="nws">Маршрут</label>
                        <div className="container-manager">
                            {
                                this.props.store.isSelectedRoute &&
                                <div className="del-i clear-custom-route"
                                     onClick={this.props.store.clearCustomRoute}
                                     title="Очистить кастомные маршруты">
                                </div>
                            }
                            <div className="add-way-label point" onClick={this.reactModalStore.open}>
                            </div>
                        </div>
                    </div>

                    <div className="select-style-route">
                        <CustomSelector value={this.props.store.getDefaultRoute}
                                        values={this.props.store.routes}
                                        width="285px"
                                        onChange={this.changeRoute}/>
                        {
                            this.props.store.isCustomRoutes &&
                            <div className="chosen-select-route chosen-custom-route">
                                Кастомный маршрут ({this.props.store.changeRoutes.length} отрез.)
                            </div>
                        }
                    </div>

                    <div className="way-text" id="name-loci">Начало маршрута</div>
                    <div className="select-style-route loci">
                        <CustomSelector defaultValue={this.props.store.getDefaultPoint}
                                        values={this.props.store.points}
                                        width="285px"
                                        onChange={this.changePoint}/>
                        {
                            this.props.store.isCustomRoutes &&
                            <div className="chosen-select-route chosen-custom-route">
                                Кастомный маршрут ({this.props.store.changeRoutes.length} отрез.)
                            </div>
                        }
                    </div>

                    <div style={{marginTop: '20px'}}>
                        <label className="way-text" htmlFor="amount-loci" id="amount-loci-label">
                            Количество образов на локацию</label>
                        <input className="way-text" id="amount-loci"
                               type="number"
                               value={this.props.store.getCountPoints}
                               onChange={this.onChangeCount}
                               autoComplete="off"
                               name="amount-loci"/>
                    </div>
                </div>
            </>
        )
    }
}