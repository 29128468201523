import {CustomComponent} from "../../../../helpers/CustomComponent";
import {PropsTrainingType} from "./PropsTrainingType";
import {TitleComponent, TypePositionText, TypeTitle} from "../../../../helpers/title/TitleComponent";
import {ImageComponent} from "../../../../helpers/img/ImageComponent";
import React from "react";
import {observer} from "mobx-react";
import {makeAutoObservable} from "mobx";
import HeaderTrainingLearnLanguageStore from "../HeaderTrainingLearnLanguageStore";
import {CheckTextComponent} from "../../../../helpers/checkText/CheckTextComponent";
import {CheckTextStatus} from "../../../../helpers/checkText/CheckTextStatus";
import {compareWordsCheck} from "../../../../helpers/HelperPoints";
import {CheckAdjectiveComponent} from "../../../../helpers/nextComponent/CheckAdjectiveComponent";
import {NextElementTimeout} from "../TrainingLearnLanguageConstants";


@observer
export class WriteEnglishAdjectiveComponent extends CustomComponent<PropsTrainingType, any> {
    private store: Store;

    constructor(props: Readonly<PropsTrainingType>) {
        super(props);
        this.store = new Store(this.props);
    }

    componentWillUnmount() {
        this.store.unInit();
    }

    render() {
        return <>
            <TitleComponent key={this.props.currentItem.nameRus}
                            text={this.props.currentItem.nameRus}
                            typePosition={TypePositionText.center}
                            typeTitle={TypeTitle.bigTitle}/>
            {
                this.props.currentItem.nuance &&
                <TitleComponent text={this.props.currentItem.nuance} typePosition={TypePositionText.center}
                                typeTitle={TypeTitle.bigTitle}/>
            }
            <ImageComponent key={this.props.currentItem.image}
                            src={this.props.currentItem.image}
                            alt={this.props.currentItem.nameRus}/>

            {
                this.store.showHint &&
                <>
                    <TitleComponent key={this.props.currentItem.nameEng}
                                    text={this.props.currentItem.nameEng}
                                    typePosition={TypePositionText.center}
                                    typeTitle={TypeTitle.description}/>
                    <TitleComponent key={this.props.currentItem.transcriptionAmerica}
                                    text={this.props.currentItem.transcriptionAmerica}
                                    typePosition={TypePositionText.center}
                                    typeTitle={TypeTitle.description}/>
                </>
            }
            <CheckTextComponent key={"CheckTextComponent" + this.props.currentItem.nameEng}
                                answer={this.props.item.answer}
                                changeText={this.store.setUserAnswer}
                                checkAnswer={this.store.checkAnswerWrote}
                                status={this.store.status}
            />
            {
                this.store.showHint &&
                <CheckAdjectiveComponent onClick={this.store.next} text={"Далее"}
                                         positionInfo={this.props.positionInfo}/>
            }
            {
                !this.store.showHint &&
                <CheckAdjectiveComponent onClick={this.store.checkAnswerWrote} text={"Проверить"}
                                         positionInfo={this.props.positionInfo}/>
            }
        </>
    }
}

class Store {
    props: PropsTrainingType;
    showHint: boolean = false;
    isClickAnswer: boolean = false;
    status: CheckTextStatus = CheckTextStatus.noCheck;
    timerAnswer: NodeJS.Timer | undefined = undefined;

    constructor(props: PropsTrainingType) {
        this.props = props;
        makeAutoObservable(this, {}, {autoBind: true})
        HeaderTrainingLearnLanguageStore.setHintTraining(undefined);
    }

    checkAnswerWrote(): void {
        let resultCompare: boolean = false;
        resultCompare = compareWordsCheck(this.props.item.answer, this.props.currentItem.nameEng);
        this.checkAnswerWords(
            resultCompare
        )
        this.status = resultCompare ? CheckTextStatus.valid : CheckTextStatus.noValid;
    }

    checkAnswerWords(isCorrect: boolean): void {
        if (!isCorrect) {
            this.props.item.tryCount++
            if (this.props.item.tryCount >= 2) {
                this.showHint = true;
            }
            return
        }
        this.isClickAnswer = true;
        this.timerAnswer = setInterval(this.next, NextElementTimeout);
    }

    setUserAnswer(val: string) {
        this.status = CheckTextStatus.noCheck;
        this.props.item.answer = val;
    }

    next() {
        this.unInit();
        this.showHint = false;
        this.props.next(this.props.item)
    }

    unInit() {
        if (this.timerAnswer)
            clearInterval(this.timerAnswer);
    }
}