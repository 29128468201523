import React from "react";
import {AnsCellName} from "./block/AnsCellName";
import {AnswerTrainingBaseService} from "../AnswerTrainingBaseService";
import {TrainingAnswerModel} from "../../model/TrainingAnswerModel";
import {TrainingAnswerRequestItemDto} from "../../model/TrainingAnswerRequestModel";

export class NameTrainingAnswerBaseService extends AnswerTrainingBaseService {
    constructor(model: TrainingAnswerModel, items: TrainingAnswerRequestItemDto[]) {
        super(model, items);
        this.changeNameTraining = this.changeNameTraining.bind(this);
    }

    changeNameTraining(id: string, text: string) {
        let item = this.items.filter(x => x.id === id)[0];
        item.answer = text;
    }

    get view(): JSX.Element {
        return <>
            {
                this.items.map((x, index) =>
                    <AnsCellName key={x.id}
                                 ref={(r) => x.p = r}
                                 value={x.answer}
                                 pos={index}
                                 item={x}
                                 addBlock={this.addBlock}
                                 deleteBlock={this.deleteBlock}
                                 changeName={this.changeNameTraining}/>
                )
            }
        </>;
    }
}