import {observer} from "mobx-react";
import {CustomComponent} from "../../../../../helpers/CustomComponent";
import {RequiredCodingTraining} from "../NewRankModel";
import {Link} from "react-router-dom";

export interface RouteRankProps{
    numberPosition: number;
    coding: RequiredCodingTraining;
}
@observer
export class CodingRank extends CustomComponent<RouteRankProps, {}> {

    constructor(props: Readonly<RouteRankProps>) {
        super(props);
        this.codingLink = this.codingLink.bind(this);
        this.getCodingResult = this.getCodingResult.bind(this);
    }

    codingLink():string{
        return "/Training?trainingMode=coding&trainingType=2";
    }

    getCodingResult(): JSX.Element{
        if (this.props.coding.isSuccess)
        {
            return (<div className="exercise-complicate">Выполнено! текущий рекорд {this.props.coding.bestSpeed} сек / {this.props.coding.code}</div>)
        }
        if (this.props.coding.isBestFound)
            return (<>
                <div className="ex-explanations">Текущий темп</div>
                <div className="res-speed-box">
                    <div id="res-speed-now" data-title="Темп запоминания [CС:ммм]">coding.betterSpeed+</div>
                </div>
                <Link to={this.codingLink()}>
                    <div className="ex-bottom">Пройти тест</div>
                </Link>
            </>)
        return (<>
            <div className="res-speed-box">
                <div className="res-coding-empty">{this.props.coding.betterSpeedNotFound}</div>
            </div>
            <Link to={this.codingLink()}>
                <div className="ex-bottom">Пройти тест</div>
            </Link>
        </>)
    }

    render() {
        return (
            <div className="exercise-box">
                <div className="ex-block-title">
                    <div className="ex-name">{this.props.numberPosition}. Добейтесь темпа кодирования {this.props.coding.speed/1000} сек
                        / {this.props.coding.code} число в режиме “выбор из двух”
                    </div>
                    <div className="ex-info-question"></div>
                </div>
                <div className="ex-info-box">
                    Результат необходимо получить без ошибок. Настройки для тренировки:
                    100 элементов в полном диапазоне, минимальная генерация, режим кодирования "Выбор из двух".
                </div>
                {this.getCodingResult()}
            </div>
        );
    }
}