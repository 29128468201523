import {Component} from "react";
import {observer} from "mobx-react";
import CustomSelector from "../../../shared/selector/CustomSelector";
import {reaction} from "mobx";
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import {CodingSpeedStore} from "./CodingSpeedStore";
import {Loading} from "../../../shared/Loading";
import './codingSpeed.scss'


@observer
export class CodingSpeed extends Component<{}, {}> {
    private store: CodingSpeedStore = new CodingSpeedStore();

    componentDidMount() {
        this.store.loadingStore.fetchRequest();
        reaction(
            () => [this.store.selectorMode.value, this.store.selectorType.value],
            this.store.loadingStore.fetchRequest
        );
    }

    render() {
        if (!this.store.loadingStore.isLoaded)
            return <Loading/>
        return (
            <>
                <span className="sat-name">Темп кодирования</span>

                <span className="sat-name sat-name2">Количество кодов по силе связи</span>

                <div className="statchosen-2v">
                    <div className="select-style statchosen2">
                        <CustomSelector defaultValue={this.store.selectorMode.value}
                                        value={this.store.selectorMode.value}
                                        values={this.store.selectorModes}
                                        onChange={this.store.setSelectorMode}
                                        width="100%"/>
                    </div>

                    <div className="chdis" id="ch-regime">
                        <div className="select-style-t2">
                            <CustomSelector defaultValue={this.store.selectorType.value}
                                            value={this.store.selectorType.value}
                                            values={this.store.selectorTypes}
                                            onChange={this.store.setSelectorType}
                                            width="100%"/>
                        </div>
                    </div>
                </div>

                <div className="circle-tabl-box">

                    <div className="elpia-and-text">
                        {
                            this.store.loadingStore.isLoaded && this.store.loadingStore.get!.days?.length !== 0 &&
                            <HighchartsReact
                                styles={{width: '400px'}}
                                highcharts={Highcharts}
                                options={this.store.optionsPie}/>
                        }
                        {
                            (!this.store.loadingStore.isLoaded || this.store.loadingStore.get!.days?.length === 0) &&
                            <>
                                <div className="mswey">
                                    <span className="medium-el" id="speedCoding">0,00</span>
                                    <span className="txt-actstl">средний темп кодирования за последние 10 результатов</span>
                                </div>
                                <span className="empty-circle2"><span className="circle0">Нет данных</span></span>
                            </>
                        }

                    </div>

                    <table className="elpie-tabel">
                        <tbody>
                        <tr className="htr">
                            <td>Состояние</td>
                            <td>Ср.темп, с</td>
                            <td>Кол-во</td>
                        </tr>
                        <tr>
                            <td className="htr-ref">Рефлекс</td>
                            <td>≤0,5</td>
                            <td id="speed05">{this.store.loadingStore.get?.speed05 ?? '-'}</td>
                        </tr>
                        <tr>
                            <td className="htr-vm">Очень сильная</td>
                            <td>0,5-1</td>
                            <td id="speed10">{this.store.loadingStore.get?.speed10 ?? '-'}</td>
                        </tr>
                        <tr>
                            <td className="htr-v">Сильная</td>
                            <td>1-1,5</td>
                            <td id="speed15">{this.store.loadingStore.get?.speed15 ?? '-'}</td>
                        </tr>
                        <tr>
                            <td className="htr-d">Средняя</td>
                            <td>1,5-2,5</td>
                            <td id="speed25">{this.store.loadingStore.get?.speed25 ?? '-'}</td>
                        </tr>
                        <tr>
                            <td className="htr-b">Слабая</td>
                            <td>≥2,5</td>
                            <td id="speed">{this.store.loadingStore.get?.speed ?? '-'}</td>
                        </tr>
                        </tbody>
                    </table>

                </div>

                <div className="substrate sub1l"></div>

                <span className="sat-name sat-name3">Тенденция темпа чистого кодирования</span>
                {
                    this.store.loadingStore.isLoaded && this.store.loadingStore.get!.days?.length !== 0 &&
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={this.store.options}/>
                }

                <div className="dop-chart2" id="reccod-dop">
                    <div id="reccoddop1">
                        <span className="amount-actstl" id="speedGrowl">-</span>
                        <span className="txt-actstl">средний прирост темпа</span>
                    </div>
                    <div id="reccoddop2">
                        <span className="amount-actstl" id="days">-</span>
                        <span className="txt-actstl">дней до достижения рефлекса</span>
                    </div>
                </div>
                {
                    this.store.loadingStore.get!.days?.length === 0 &&
                    <span className="empty-stat4">Нет данных для построения графика</span>
                }

                <div className="substrate sub2l"></div>

                <span
                    className="sat-name sat-name4">Детализация результатов темпа кодирования за тренировочные дни</span>

                <div className="select-style statchosen3">
                    <CustomSelector defaultValue={this.store.selectorType.value}
                                    value={this.store.selectorType.value}
                                    values={this.store.selectorTypes}
                                    onChange={this.store.setSelectorType}
                                    width="305px"/>
                </div>

                <div className="stat-table-wrapper">
                    <table className="stat-table" id="speedcod-table">
                        <tbody>
                        <tr>
                            <th>День 1</th>
                            <th>День 2</th>
                            <th>День 3</th>
                            <th>День 4</th>
                            <th>День 5</th>
                            <th>День 6</th>
                            <th>День 7</th>
                            <th>День 8</th>
                            <th>День 9</th>
                            <th>День 10</th>
                            <th>День 11</th>
                            <th>День 12</th>
                            <th>День 13</th>
                            <th>День 14</th>
                            <th>День 15</th>
                        </tr>
                        </tbody>
                    </table>

                    {/*<div className="del" onClick="deleteResultAll()" id="del-tb-speedcod"*/}
                    {/*     title="Отчистить данные графика"></div>*/}
                </div>

                <input className="info-t" id="info-speedcod" type="checkbox"/>
                <label className="info-train info-stat" htmlFor="info-speedcod"></label>
                <div className="info" id="about-speedcod">
                    Справка по темпу кодирования
                    <div className="closer2w"></div>
                </div>
                {/*<div className="parent_popup" onClick="blackout()"></div>*/}

                {/*<div id="dialog-form" title="Закрыть">*/}
                {/*    <p><span className="ui-icon ui-icon-alert"*/}
                {/*             style="float:left; margin:12px 12px 20px 0;"></span><span id="delete-result-text">Вы уверены удалить результат?</span>*/}
                {/*    </p>*/}
                {/*</div>*/}
            </>
        );
    }
}