export interface SmallPostModel {
    id: number;
    title: string;
    link: string;
    image: string;
    summary: string;
    tags: string[];
    rubrics: string[];
    views: number;
    comm: number;
}

export enum StatusShowAllComment {
    none = 0,
    hide = 1,
    all = 2
}