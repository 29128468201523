import {Component} from "react";
import {observer} from "mobx-react";
import CustomSelector from "../../../shared/selector/CustomSelector";
import {ConnectionReliabilityStore} from "./ConnectionReliabilityStore";
import {reaction} from "mobx";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";


@observer
export class ConnectionReliability extends Component<{}, {}> {
    private store: ConnectionReliabilityStore = new ConnectionReliabilityStore();

    componentDidMount() {
        this.store.loadingStore.fetchRequest();
        reaction(
            () => [this.store.selectorMode.value, this.store.selectorDIS.value],
            this.store.loadingStore.fetchRequest
        );
    }

    render() {
        return (
            <>
                <span className="sat-name">Надежность запоминания</span>
                <span className="sat-name sat-name2">Тенденция надёжности за последние 30 дней</span>

                <div className="statchosen-2v">
                    <div className="select-style statchosen2">
                        <CustomSelector defaultValue={this.store.selectorMode.value} values={this.store.selectorsMode}
                                        onChange={this.store.setSelectorMode} width="100%"/>
                    </div>

                    <div className="chdis" id="ch-remdis">
                        <div className="select-style-t2">
                            <CustomSelector defaultValue={this.store.selectorDIS.value} values={this.store.selectorsDIS}
                                            onChange={this.store.setSelectorDis} width="100%"/>
                        </div>
                    </div>
                </div>

                {
                    this.store.loadingStore.isLoaded && this.store.loadingStore.get!.length === 0 &&
                    <span className="empty-stat2">Начните тренироваться, чтобы увидеть график надёжности вашего запоминания</span>
                }

                {/*<div className="charttxt" id="relstat-chart"*/}
                {/*     // style="max-width: 900px; min-height: 400px; margin: 0px auto; overflow: hidden;"*/}
                {/*     data-highcharts-chart="3"></div>*/}
                {
                    this.store.loadingStore.isLoaded && this.store.loadingStore.get!.length !== 0 &&
                    <HighchartsReact
                        // constructorType={'stockChart'}
                        highcharts={Highcharts}
                        options={this.store.options} />
                }
                <div className="dop-chart1" id="relstat-dop">
                    <div id="relstatdop1">
                        <span className="amount-actstl" id="speed-growth">-</span>
                        <span className="txt-actstl">средний прирост надёжности</span>
                    </div>
                    <div id="relstatdop2">
                        <span className="amount-actstl" id="days-left">-</span>
                        <span className="txt-actstl">дней до достижения надёжности 100%</span>
                    </div>
                </div>

                {/*// <!--Удалить данные по надёжности-->*/}
                <div className="del" id="del-tb-relstat" title="Отчистить данные графика"></div>

                {/*// <!--Справка по надёжности-->*/}
                {/*// <input className="info-t" id="info-relstat" type="checkbox"/>*/}
                {/*//     <label className="info-train info-stat" htmlFor="info-relstat"></label>*/}
                {/*//     <div className="info" id="about-relstat">*/}
                {/*//         Справка по надёжности*/}
                {/*//         <div className="closer2w"></div>*/}
                {/*//     </div>*/}
                {/*//     <div className="parent_popup" onClick="blackout()"></div>*/}
            </>
        );
    }
}