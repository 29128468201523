import {CustomComponent} from "../CustomComponent";
import './titleComponent.scss'

interface Props {
    typeTitle?: TypeTitle;
    typePosition?: TypePositionText;
    text?: string;
    children?: React.ReactNode;
}

export enum TypeTitle {
    h1 = 0,
    h2 = 1,
    h3 = 2,
    answerUser = 3, // Филоетовые ответы на вопросы
    universityQuestion = 4,
    taskTitle = 5,
    bigTitle = 6,
    description = 7,
    about = 8,
    universityModuleTitle = 9,
    homeWorkTitle = 10,
    homeTaskTitle = 11,
    homeTaskDescription = 12,
}

export enum TypePositionText {
    left = 0,
    center = 1,
    right = 2,
}

export class TitleComponent extends CustomComponent<Props, any> {
    get getClassName(): string {
        switch (this.props.typeTitle) {
            case TypeTitle.h1:
            case TypeTitle.h2:
            case TypeTitle.h3:
            case TypeTitle.answerUser:
                return "title-component-title"
            case TypeTitle.universityQuestion:
                return "title-component_university_question"
            case TypeTitle.taskTitle:
                return "title-component_task_title"
            case TypeTitle.bigTitle:
                return "title-component_task_big_title"
            case TypeTitle.description:
                return "title-component_task_description"
            case TypeTitle.about:
                return "title-component_task_about"
            case TypeTitle.universityModuleTitle:
                return "title-component_university_module_title"
            case TypeTitle.homeWorkTitle:
                return "title-component_home_work_title"
            case TypeTitle.homeTaskTitle:
                return "title-component_home_task_title"
            case TypeTitle.homeTaskDescription:
                return "title-component_home_task_description"
        }
        throw new Error("TitleComponent:getClassName: Not found type: " + this.props.typeTitle)
    }

    get getClassPosition(): string {
        switch (this.props.typePosition) {
            case TypePositionText.left:
                return "title-component_position_left";
            case TypePositionText.right:
                return "title-component_position_right";
            case TypePositionText.center:
                return "title-component_position_center";
        }
        return "";
    }

    render() {
        return <div className={"title-component"}>
            <div className={this.getClassName + " " + this.getClassPosition}>
                {this.props.text}
                {this.props.children}
            </div>
        </div>;
    }
}