import {ProductView} from "../model/ProductView";
import {LoadingModel} from "../../shared/LoadingModel";
import {IAdministrationStore} from "./IAdministrationStore";
import {AdministrationRepository} from "../repository/AdministrationRepository";
import {BaseAdministrationStore} from "./BaseAdministrationStore";

export class ProductStore implements IAdministrationStore<ProductView> {
    private repository = new AdministrationRepository();
    baseStore = new BaseAdministrationStore<ProductView>(this);

    constructor() {
    }

    requestLocal(): Promise<LoadingModel<ProductView[]>> {
        return this.repository.products();
    }
}