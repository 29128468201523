import {CustomComponent} from "../CustomComponent";
import './nextAndPrevAdjectiveComponent.scss';
import KeyboardManager from "../../core/keyboardManager/adapters/KeyboardManager";
import React from "react";
import {observer} from "mobx-react";

interface Props {
    next: () => void,
    back: () => void
    positionInfo: string
}
@observer
export class NextAndPrevAdjectiveComponent extends CustomComponent<Props, any> {

    componentDidMount() {
        KeyboardManager.add('ArrowLeft', this.props.back);
        KeyboardManager.add('ArrowRight', this.props.next);
    }

    componentWillUnmount() {
        KeyboardManager.remove('ArrowLeft', this.props.back);
        KeyboardManager.remove('ArrowRight', this.props.next);
    }

    render() {
        return <div className="next-and-prev-adjective-component">
            <input id="back" type="button"/>
            <label className="back-label" htmlFor="back" onClick={this.props.back}></label>
            <input id="next" type="button"/>
            <label className="next-label" htmlFor="next" onClick={this.props.next}></label>
            <span className="position-info coding-position-info">{this.props.positionInfo}</span>
        </div>;
    }
}