import {CustomComponent} from "../../../../helpers/CustomComponent";


export default class BinsMemberComplexityComponent extends CustomComponent<{countElementsOneTime: number, trainFormat: number, children: React.ReactNode}, {}> {
    render() {
        if (this.props.countElementsOneTime <= 3) {
            if (this.props.countElementsOneTime * this.props.trainFormat <= 9) {
                return <span id="binary-rem1">{this.props.children}</span>;
                // this.startText = '<span id="binary-rem1">';
                // this.endText = "</span>";
            } else if (this.props.countElementsOneTime * this.props.trainFormat <= 18) {
                return <span id="binary-rem2">{this.props.children}</span>;
                // this.startText = '<span id="binary-rem2">';
                // this.endText = "</span>";
            } else {
                return <span id="binary-rem3">{this.props.children}</span>;
                // this.startText = '<span id="binary-rem3">';
                // this.endText = "</span>";
            }
        } else if (this.props.countElementsOneTime === 4) {
            if (this.props.trainFormat === 3) {
                return <span id="binary-rem1-1">{this.props.children}</span>;
                // this.startText = '<span class="binary-rem1-1">';
                // this.endText = "</span>";
            } else if (this.props.trainFormat === 6) {
                return <span id="binary-rem2-1">{this.props.children}</span>;
                // this.startText = '<span class="binary-rem2-1">';
                // this.endText = "</span>";
            } else {
                return <span id="binary-rem3-1">{this.props.children}</span>;
                // this.startText = '<span class="binary-rem3-1">';
                // this.endText = "</span>";
            }
        } else if (this.props.countElementsOneTime === 5) {
            if (this.props.trainFormat === 3) {
                return <span id="binary-rem1-1">{this.props.children}</span>;
                // this.startText = '<span class="binary-rem1-1">';
                // this.endText = "</span>";
            } else if (this.props.trainFormat === 6) {
                return <span id="binary-rem2-1">{this.props.children}</span>;
                // this.startText = '<span class="binary-rem2-1">';
                // this.endText = "</span>";
            } else {
                return <span id="binary-rem3-2">{this.props.children}</span>;
                // this.startText = '<span class="binary-rem3-2">';
                // this.endText = "</span>";
            }

        } else {
            if (this.props.trainFormat === 3) {
                return <span id="binary-rem3-1">{this.props.children}</span>;
                // this.startText = '<span id="binary-rem3-1">';
                // this.endText = "</span>";
            } else if (this.props.trainFormat === 6) {
                return <span id="binary-rem2-23">{this.props.children}</span>;
                // this.startText = '<span id="binary-rem2-23">';
                // this.endText = "</span>";
            } else {
                return <span id="binary-rem3-3">{this.props.children}</span>;
                // this.startText = '<span id="binary-rem3-3">';
                // this.endText = "</span>";
            }
        }
    }
}