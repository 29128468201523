import {CustomComponent} from "../CustomComponent";
import ReactAudioPlayer from "react-audio-player";
import React from "react";
import {observer} from "mobx-react";

interface Props {
    src: string
}
@observer
export class AudioComponent extends CustomComponent<Props, any> {
    render() {
        return <div>
            <ReactAudioPlayer
                src={"https://powermemory.io" + this.props.src}
                autoPlay
                controls
            />
        </div>;
    }
}