import React from 'react'
import {CustomComponent} from "../../helpers/CustomComponent";
import {Avatar} from "./Avatar";
import './avatarWithMnemocard.scss';

interface Props {
    name: string;
    rank: string;
    image: string;
    link: string;
    isPremium: boolean;
    height?:string
    width?:string
}

export class AvatarWithMnemocard extends CustomComponent<Props, {}> {

    render() {
        return (
            <div className="avatar-with-name">
            {/**/}
                <Avatar fullname={this.props.name ?? ""}
                        img={this.props.image}
                        link={this.props.link}
                        canClickMnemocard={true}
                        height={this.props?.height ?? '33px'} width={this.props?.width ?? '33px'}/>
                <div className="results-table-username-rank">
                    <div className="results-table-username">
                        {this.props.name}
                        {
                            this.props.isPremium &&
                            <div className="premium-user"></div>
                        }
                    </div>
                    <div className="results-table-rank">{this.props.rank}</div>
                </div>
            </div>
        )
    }
}
