import {CustomComponent} from "../../../helpers/CustomComponent";
import {Link} from "react-router-dom";
import './shareComponent.scss'
import Modal from "../../modal/ReactModal";
import React from "react";
import {ReactModalStore} from "../../modal/ReactModalStore";


interface Props{
    id: string,
    store: ReactModalStore
}

export class SharedComponent extends CustomComponent<Props, {}>{
    render() {
        return <Modal store={this.props.store}>
            <Shared id={this.props.id} store={this.props.store}/>
        </Modal>
    }
}
class Shared extends CustomComponent<Props, {}>{
    constructor(props: Readonly<Props>) {
        super(props);
        this.copyLink = this.copyLink.bind(this);
    }
    componentDidMount() {
        this.props.store.init();
    }
    componentWillUnmount() {
        this.props.store.unInit();
    }

    copyLink(){
        let textField = document.createElement('textarea')
        textField.innerText = "https://powermemory.io/Training/Search?id=" + this.props.id
        document.body.appendChild(textField)
        textField.select();
        document.execCommand('copy')
        textField.remove();
    }

    render() {
        return <div className="repost">
            <div id="rep-icon"></div>
            <span className="report-title">Поделиться ссылкой на результат</span>
            <div className="repost-div">
                <Link to={"https://vk.com/share.php?url=https://powermemory.io/Training/Search?id=" + this.props.id}
                      rel="nofollow noopener" className="social__button" target="_blank">
                    <div className="rep-soc" id="rep-vk"></div>
                </Link>
                <Link
                    to={"https://telegram.me/share/url?url=https://powermemory.io/Training/Search?id=" + this.props.id}
                    rel="nofollow noopener" className="social__button" target="_blank">
                    <div className="rep-soc" id="rep-tg"></div>
                </Link>
                <Link
                    to={"https://twitter.com/share?url=https://powermemory.io/Training/Search?id=" + this.props.id}
                    rel="nofollow noopener" className="social__button" target="_blank">
                    <div className="rep-soc" id="rep-tw"></div>
                </Link>
                <div className="rep-soc" id="rep-copi" onClick={this.copyLink}></div>
            </div>
        </div>
    }
}