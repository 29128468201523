import React, {Component} from "react";
import {observer} from "mobx-react";
import './../lessons.scss'
import {TitleComponent, TypeTitle} from "../../../../helpers/title/TitleComponent";
import {BlockTextComponent} from "../../../../helpers/blockText/BlockTextComponent";
import {PointsComponent} from "../../../../helpers/points/PointsComponent";


@observer
export class Goals1 extends Component<{}, {}> {

    componentDidMount() {
    }

    render() {
        return (<div className="lesson-1 lesson">
            <TitleComponent typeTitle={TypeTitle.h1} text="Модуль 1"/>
            <div className="lesson-body">
                <TitleComponent typeTitle={TypeTitle.h2} text="Цели"/>
                <TitleComponent typeTitle={TypeTitle.h3} text="Цель №1"/>
                <BlockTextComponent>
                    Добиться безошибочного припоминания каждой последовательности слов по образам. При этом не важно с
                    какой
                    попытки вы это сделаете. Перечитайте и вникните в эту цель ещё раз.
                </BlockTextComponent>
                <TitleComponent typeTitle={TypeTitle.h3} text="Цель №2"/>
                <BlockTextComponent>
                    Начать закрывать оттоки энергии – стремиться к энергоэффективности в запоминании и припоминании
                    (критерий "Лёгкость").
                </BlockTextComponent>
                <TitleComponent typeTitle={TypeTitle.h3} text="Интенсивность тренировок"/>
                <PointsComponent title="План максимум">
                    Выполнить все упражнения для каждого тренировочного дня.
                </PointsComponent>
                <PointsComponent title="План минимум">
                    Создавать хотя бы 20 сильных связей каждый день из упражнений.
                </PointsComponent>
                <PointsComponent title="План микро">
                    Запоминать в день 10 картинок приёмом "Цепочка" на сайте powermemory.io
                </PointsComponent>
                <BlockTextComponent hasImage={true} hasBackground={true}>
                    Если так получилось, что весь день были заняты и не успели ничего сделать – ничего страшного. Задача
                    не
                    сделать всё по максимуму, задача сделать всё, что будет возможно для вас, в ваших условиях.

                    И помните. Если вы пропустили тренировку сегодня – это не повод пропустить вторую тренировку завтра.
                </BlockTextComponent>
            </div>
        </div>);
    }
}