import {UserAdminViewModel} from "../model/ProductView";
import {LoadingModel} from "../../shared/LoadingModel";
import {IAdministrationStore} from "./IAdministrationStore";
import {AdministrationRepository} from "../repository/AdministrationRepository";
import {BaseAdministrationStore} from "./BaseAdministrationStore";

export class UserAdminStore implements IAdministrationStore<UserAdminViewModel> {
    private repository = new AdministrationRepository();
    baseStore = new BaseAdministrationStore<UserAdminViewModel>(this);

    constructor() {
    }

    requestLocal(): Promise<LoadingModel<UserAdminViewModel[]>> {
        return this.repository.users();
    }
}