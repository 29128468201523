import React from 'react'
import {observer} from "mobx-react";
import TrainingMainBaseStore, {StateTrainingTrainBase} from "../../components/trainingBase/store/TrainingMainBaseStore";
import MemberTrainingComponent from "../../components/trainingBase/component/MemberTrainingComponent";
import AnswerTrainingComponent from "../../components/trainingBase/component/AnswerTrainingComponent";
import {TrainingResultPage} from "../trainingResult/TrainingResultPage";
import {Loading} from "../../components/shared/Loading";
import {TrainingBaseComponent} from "../../components/training/TrainingBaseComponent";
import {CustomComponent} from "../../helpers/CustomComponent";
import TitleStore from "../../components/authorization/store/TitleStore";
import {TrainingBaseBaseComponent} from "../../components/trainingBaseBase/TrainingBaseBaseComponent";


@observer
export class TrainingBasePage extends CustomComponent<{}, {}> {
    store: TrainingMainBaseStore;

    constructor(props: Readonly<{}>) {
        super(props);
        this.store = new TrainingMainBaseStore();
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        TitleStore.setTitle("Базовая тренировка");
    }

    render() {
        if (this.store.state === StateTrainingTrainBase.member && !this.store.storeMember)
            return <Loading/>
        if (this.store.state === StateTrainingTrainBase.answer && !this.store.storeAnswer)
            return <Loading/>
        if (this.store.state === StateTrainingTrainBase.result && !this.store.resultModel.IsLoadedModel())
            return <Loading/>
        return (
            <>
                {this.store.state === StateTrainingTrainBase.base &&
                    <TrainingBaseBaseComponent />}
                {/*{this.store.state === StateTrainingTrainBase.member &&*/}
                {/*    <MemberTrainingComponent store={this.store.storeMember!}/>}*/}
                {/*{this.store.state === StateTrainingTrainBase.answer &&*/}
                {/*    <AnswerTrainingComponent store={this.store.storeAnswer}/>}*/}
                {/*{this.store.state === StateTrainingTrainBase.result &&*/}
                {/*    <TrainingResultPage model={this.store.resultModel}/>}*/}
            </>
        )
    }
}
