import {makeAutoObservable} from "mobx";
import {LoadingModel} from "../../shared/LoadingModel";
import {SmallPostModel} from "../model/SmallPostModel";
import {GetRequest} from "../../../helpers/Request";

export class PostsStore {
    model: LoadingModel<SmallPostModel[]> = LoadingModel.createLoaded();
    posts: SmallPostModel[] = [];
    tag?: string | null;
    rubric?: string | null;


    constructor(tag?: string | null, rubric?: string | null) {
        makeAutoObservable(this, {}, {autoBind: true})
        this.tag = tag;
        this.rubric = rubric;
    }

    init(){
        this.fetchRequest()
    }

    somePosts() {
        this.fetchRequest();
    }

    setModel(model: LoadingModel<SmallPostModel[]>) {
        this.model.IsLoaded = true;
        this.model = model;
        if (model.Model) {
            for (let i = 0; i < model.Model.length; i++) {
                this.posts.push(model.Model[i]);
            }
        }
    }

    fetchRequest() {
        this.model.IsLoaded = false;
        GetRequest<SmallPostModel[]>('/Post/Posts?now=' + (this.posts.length ?? 0) +
            '&count=' + ((this.posts.length ?? 0) + 4) + (this.tag ? '&tag=' + this.tag : '') +
            (this.rubric ? '&rubric=' + this.rubric : ''))
            .then(x => this.setModel(x))
    }
}