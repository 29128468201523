import './navBurger.scss'
import React, {Component} from "react";
import {observer} from "mobx-react";
import {NavBurgerStore} from "./NavBurgerStore";
import {NavLink} from "react-router-dom";
import {NavBarCollectionModel} from "./NavBarModel";
import {CheckScreen, TypeScreen} from "../../context/TypeScreen";
import ReactModalRight from "../../components/modal/ReactModalRight";

interface Props {
    models: NavBarCollectionModel[];
}

@observer
export class NavBurger extends Component<Props, {}> {
    private store: NavBurgerStore = new NavBurgerStore();

    constructor(props: Readonly<Props>) {
        super(props);
        this.changeBurger = this.changeBurger.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    changeBurger() {
        this.store.setBurger = !this.store.burger;
    }

    onClick() {
        this.store.setBurger = false;
    }

    render() {
        return <div id="navsat">
            <div id="navburger" onClick={this.changeBurger}></div>
            {
                CheckScreen([TypeScreen.Mobile]) &&
                <ReactModalRight showModal={this.store.burger} onClose={this.changeBurger} isNotShowCloser={true}>
                    <div className="nav-stat-mobile">
                        {this.props.models.map((x, index) =>
                            <div key={x.name + "_" + index} className="nav-item">
                                <span className="chc-span">{x.name}</span>
                                {x.models.map(y =>
                                    <NavLink key={y.toLink} to={y.toLink}  onClick={this.onClick} className="sattxt">{y.name}</NavLink>
                                )}
                            </div>)
                        }
                    </div>
                </ReactModalRight>
            }
            {
                !this.store.isMobile &&
                this.props.models.map((x, index) =>
                    <div key={x.name + "_" + index} className="nav-item">
                        <span className="chc-span">{x.name}</span>
                        {x.models.map(y =>
                            <NavLink key={y.toLink} to={y.toLink} className="sattxt" id="ns-3label">{y.name}</NavLink>
                        )}
                    </div>)
            }
        </div>;
    }
}