import React from "react";
import {observer} from "mobx-react";
import {CommentResViewModel} from "./model/PostModel";
import {Avatar} from "../user/Avatar";
import {StatusShowAllComment} from "./model/SmallPostModel";
import {CustomComponent} from "../../helpers/CustomComponent";
import CommentStore from "./store/CommentStore";

export interface PropComment {
    comm: CommentResViewModel
    isAdminOrSameUser: boolean | null
    liked: boolean;

    clickLike(commentId: string): void;

    clickDelete(commentId: string): void;
}

@observer
export default class Comment extends CustomComponent<PropComment, {}> {
    store: CommentStore;

    constructor(props: Readonly<PropComment>) {
        super(props);
        this.store = new CommentStore(this.props.comm?.text ?? "");
        this.clickShowAll = this.clickShowAll.bind(this);
        this.clickDelete = this.clickDelete.bind(this);
        this.clickLike = this.clickLike.bind(this);
    }

    clickShowAll() {
        this.store.clickShowAll();
    }

    clickDelete() {
        this.props.clickDelete(this.props.comm.id)
    }

    clickLike() {
        this.props.clickLike(this.props.comm.id)
    }

    render() {
        return (
            <div className="comm-users">
                <div>
                    <Avatar fullname={this.props.comm.name} img={this.props.comm.image} link={this.props.comm.link}/>
                </div>
                <div className="user-name">
                    {this.props.comm.name}
                    {this.props.comm.isPremium &&
                        <div className="premium-user"></div>}
                </div>
                <div className="author-date author-date3">{this.props.comm.getDate2}</div>
                <div className="like" onClick={this.clickLike}>
                    <i className={"like-i " + ((this.props.liked ?? false) ? "liked-i" : "")}></i>
                    <span className="like-num">{this.props.comm.likesOfNameUser.length}</span>
                </div>

                <div className="comm-txt">
                    {
                        this.store.status === StatusShowAllComment.hide &&
                        this.props.comm.text.slice(0, this.store.lengthMax) + "..."
                    }
                    {
                        this.store.status !== StatusShowAllComment.hide &&
                        this.props.comm.text
                    }
                </div>
                {
                    this.store.status === StatusShowAllComment.hide &&
                    <div className='comment-show' onClick={this.clickShowAll}>Читать дальше</div>
                }
                <div className="comm-borders"></div>
                <div className="comm-answer">Ответить</div>
                {
                    this.props.isAdminOrSameUser &&
                    <div className="admindel" title="Удалить" onClick={this.clickDelete}></div>
                }
            </div>
        )
    }
}