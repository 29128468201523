import {CustomComponent} from "../../../helpers/CustomComponent";
import React from "react";
import {observer} from "mobx-react";

interface Props {
    text: string;
    type: string;
    click: (type: string) => void;
    currentSelect: string;
    left?: TypeTrainingCodingPropsModel
    center?: TypeTrainingCodingPropsModel;
    right?: TypeTrainingCodingPropsModel;
}

export interface TypeTrainingCodingPropsModel {
    text?: string;
    image?: string;
}

@observer
export class TypeTrainingCodingComponent extends CustomComponent<Props, {}> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.click = this.click.bind(this);
        this.getCodingTextClassName = this.getCodingTextClassName.bind(this);
        this.getCodingClassName = this.getCodingClassName.bind(this);
    }

    click() {
        this.props.click(this.props.type)
    }

    get selected(): boolean {
        return this.props.currentSelect === this.props.type;
    }

    getCodingClassName(classname: string): string {
        if (this.selected)
            return classname + " activityCoding";
        return classname;
    }

    getCodingTextClassName(classname: string): string {
        if (this.selected)
            return classname + " activityCodingText";
        return classname;
    }

    get codingClassName(): string {
        return this.getCodingClassName("handler-chosen");
    }

    get codingClassNameChosenName(): string {
        return this.getCodingTextClassName("chosen-name");
    }

    get codingClassNameQuestionC(): string {
        return this.getCodingTextClassName("question-c");
    }

// url("")
    render() {
        return <div onClick={this.click}
                    className={this.codingClassName}>
            <span className={this.codingClassNameChosenName}>{this.props.text}</span>
            <div className="container-coding">
                {
                    this.props.left?.image &&
                    <span className="chosen-ex" style={{backgroundImage: 'url(' + this.props.left.image + ')'}}></span>
                }
                {
                    this.props.left?.text &&
                    <span className={this.codingClassNameQuestionC}>{this.props.left?.text}</span>
                }
                {
                    this.props.center?.image &&
                    <span className="chosen-ex" style={{backgroundImage: 'url(' + this.props.center.image + ')'}}></span>
                }
                {
                    this.props.center?.text &&
                    <span className={this.codingClassNameQuestionC}>{this.props.center?.text}</span>
                }
                {
                    this.props.right?.image &&
                    <span className="chosen-ex" style={{backgroundImage: 'url(' + this.props.right.image + ')'}}></span>
                }
                {
                    this.props.right?.text &&
                    <span className={this.codingClassNameQuestionC}>{this.props.right?.text}</span>
                }
            </div>
        </div>;
    }
}