import React from "react";
import "./ReactModalRight.scss";
import {CustomComponent} from "../../helpers/CustomComponent";

type ModalProps = {
    showModal: boolean;
    onClose: () => void;
    children: React.ReactNode;
    isNotShowCloser?: boolean;
};
const ModalRight = (props: ModalProps) => {
    if (!props.showModal) {
        return null;
    }
    return <Modal {...props}/>
};

class Modal extends CustomComponent<ModalProps, {}> {
    constructor(props: Readonly<ModalProps>) {
        super(props);
    }

    componentDidMount() {
        document.body.style.overflow = 'hidden';
    }

    componentWillUnmount() {
        document.body.style.overflow = 'unset';
    }

    render() {
        return (
            <div className="modal-backdrop-right" onClick={this.props.onClose}>
                <div className="parent_popup"></div>
                <div className='modal-content' onClick={(e) => e.stopPropagation()}>
                    {
                        this.props.isNotShowCloser !== true &&
                        <div className="closer2w" onClick={this.props.onClose}></div>
                    }
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default ModalRight;