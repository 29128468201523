import {makeAutoObservable} from "mobx";
import {MatryoshkaModel, MatryoshkaState} from "./MatryoshkaModel";

export class MatryoshkaStore {
    state: MatryoshkaState;
    model: MatryoshkaModel;
    finish:(model: MatryoshkaModel) => void;

    constructor(model: MatryoshkaModel, finish:(model: MatryoshkaModel) => void) {
        makeAutoObservable(this, {}, {autoBind: true})
        this.model = model;
        this.state = MatryoshkaState.remember;
        this.finish = finish;
    }

    onChange(val: string, position: number) {
        this.model.items[position].answerUser = val;
    }

    clickHint(position: number) {
        this.model.items[position].isHint = true;
    }

    nextStep() {
        if (this.state === MatryoshkaState.remember) {
            this.state = MatryoshkaState.writeAnswer;
            return;
        }
        if (this.state === MatryoshkaState.writeAnswer) {
            this.state = MatryoshkaState.checkAnswer;
            for (const item of this.model.items) {
                item.isCorrect = item.answerUser === item.word;
            }
            return;
        }
        if (this.state === MatryoshkaState.checkAnswer) {
            if (this.model.items.filter(x => x.word !== x.answerUser).length > 0) {
                this.state = MatryoshkaState.writeAnswer;
                this.model.countRetries++;
                for (const item of this.model.items) {
                    item.answerUser = undefined;
                    item.isHint = undefined;
                    item.isCorrect = undefined;
                }
            } else {
                this.finish();
            }
            return;
        }
    }
}