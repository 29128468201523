import {CustomComponent} from "../../../helpers/CustomComponent";
import {Link} from "react-router-dom";
import React from "react";
import {ProductDto} from "../models/ProductDto";


interface Props {
    dto: ProductDto,
    index: number,
    currentValueCurrency: number,
    currentCurrency: string,
}

export class ProductItemComponent extends CustomComponent<Props, {}> {
    constructor(props: Readonly<Props>) {
        super(props);
    }

    get getCostNow(){
        if (!this.props.dto.costNow)
            return  0;
        return (this.props.dto.costNow * this.props.currentValueCurrency).toFixed(2)
    }

    get getCostOld(){
        if (!this.props.dto.costOld)
            return  0;
        return (this.props.dto.costOld * this.props.currentValueCurrency).toFixed(2)
    }

    render() {
        return <Link key={this.props.dto.titleHtml + this.props.index} to={this.props.dto.link}
                     className={"block-administration " + this.props.dto.additionalClassName}>
            <div className="bs-picture" style={{backgroundImage: 'url(' + this.props.dto.image + ')'}}></div>
            <div className="bs-htxt">{this.props.dto.titleHtml}</div>
            <div className="bs-txt">{this.props.dto.description}</div>
            <div className="cost-prod">
                <div className="cost-prod-now"
                     id="full-course-group">{this.props.dto.costNow && this.getCostNow} {this.props.dto.costNow && this.props.currentCurrency} {this.props.dto.customCostNow}</div>
                <s className="cost-prod-old"
                   id="full-course-group-old">{this.props.dto.costOld && this.getCostOld} {this.props.dto.costOld && this.props.currentCurrency}</s>
                {
                    this.props.dto.customCostOld &&
                    <div className="cost-prod-dop">{this.props.dto.customCostOld}</div>
                }
                {
                    this.props.dto.customHtml &&
                    <div dangerouslySetInnerHTML={{ __html: this.props.dto.customHtml }}>
                    </div>
                }
            </div>
        </Link>;
    }
}