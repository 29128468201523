import {observer} from "mobx-react";
import {CustomComponent} from "../../helpers/CustomComponent";
import {ChangeEvent} from "react";
import AuthorizationStore from "./store/AuthorizationStore";
import KeyboardManager from "../../core/keyboardManager/adapters/KeyboardManager";

@observer
export class AuthorizationMenu extends CustomComponent<{}, {}> {
    constructor(props: Readonly<{}>) {
        super(props);
        this.changeLogin = this.changeLogin.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.changeRememberEmailTrue = this.changeRememberEmailTrue.bind(this);
        this.changeRememberEmailFalse = this.changeRememberEmailFalse.bind(this);
    }

    componentDidMount() {
        KeyboardManager.add('Enter', AuthorizationStore.logIn);
    }

    componentWillUnmount() {
        KeyboardManager.remove('Enter', AuthorizationStore.logIn);
    }

    changeLogin(e: ChangeEvent<HTMLInputElement>) {
        AuthorizationStore.setLogin(e.target.value);
    }

    changePassword(e: ChangeEvent<HTMLInputElement>) {
        AuthorizationStore.setPassword(e.target.value);
    }

    changeRememberEmailTrue() {
        AuthorizationStore.setChangeRememberEmail(true);
    }

    changeRememberEmailFalse() {
        AuthorizationStore.setChangeRememberEmail(false);
    }

    render() {
        return <div className="authorize-window">
            {
                AuthorizationStore.error &&
                <p id="error-auth">Неправильно введены e-mail или пароль</p>
            }
            <div className="a-date">
                <input id="a-email"
                       className="border-1px-solid"
                       value={AuthorizationStore.login}
                       onChange={this.changeLogin}
                       type="text"
                       placeholder="Email или логин"
                       name="email"
                       autoFocus/>
            </div>

            <div className="a-date">
                <input id="a-pass"
                       className="border-1px-solid"
                       value={AuthorizationStore.password}
                       onChange={this.changePassword}
                       type="password"
                       placeholder="Пароль"
                       name="password"/>

                {
                    !AuthorizationStore.turnRememberPassword &&
                    <div className="a-forget-text" onClick={this.changeRememberEmailTrue}>Забыли пароль</div>
                }

                {
                    AuthorizationStore.turnRememberPassword &&
                    <>
                        <div id="forget-cancel-label">Отмена</div>
                        <input id="a-email-forget" type="button" value="Отправить пароль на EMAIL"
                               onClick={AuthorizationStore.sendRememberEmail}/>
                    </>
                }

                {/*<input id="a-email-forget" type="button" value=""/>*/}
            </div>

            {/*<ul className="social-items">*/}
            {/*    <li className="social-item google_auth"><a href="#"></a></li>*/}
            {/*    <li className="social-item vk_auth"><a href="#"> </a></li>*/}
            {/*    <li className="social-item"><a href="#"> </a></li>*/}
            {/*</ul>*/}
            <div className="a-date2 border-1px-solid">
                {AuthorizationStore.jwtModel.IsLoadedModel() &&
                    <>
                        {!AuthorizationStore.turnRememberPassword &&
                            <div className="a-enter" onClick={AuthorizationStore.logIn}>ВОЙТИ</div>
                        }
                        {AuthorizationStore.turnRememberPassword &&
                            <div className="a-enter" onClick={this.changeRememberEmailFalse}>Отмена</div>
                        }
                    </>
                }

                <span className="a-remember">
                    <div className="a-rem-label">Запомнить</div>
                </span>
            </div>

            <div className="a-date a-social">
                <a id="a-reg" href="/User">Регистрация</a>
            </div>
        </div>
    }
}