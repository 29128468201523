import React, {Component} from 'react'
import './trainingBaseComponent.scss'
import TrainingBaseStore from "./store/TrainingBaseStore";
import {TrainingMode} from "../../helpers/trainingHelp/TrainingMode";
import {observer} from "mobx-react";
import {NumberView} from "./components/NumberView";
import {RouteView} from "./components/RouteView";
import {RangeTimesTrainingOneView} from "./components/RangeTimesTrainingOneView";
import {TrainingModelCreateTraining} from "./model/TrainingModel";
import {CustomButtonView} from "./components/CustomButtonView";
import {CardsView} from "./components/CardsView";
import {Link} from "react-router-dom";
import {WordView} from "./components/WordView";
import {NameView} from "./components/NameView";
import {BinsView} from "./components/BinsView";
import {ImageView} from "./components/ImageView";
import KeyboardManager from "../../core/keyboardManager/adapters/KeyboardManager";
import {ModeTrainingComponent} from "./components/menu/ModeTrainingComponent";


interface Props {
    memberRequest: (model: TrainingModelCreateTraining) => void
}

@observer
export class TrainingBaseComponent extends Component<Props, {}> {

    store: TrainingBaseStore;

    constructor(props: Readonly<Props>) {
        super(props);
        this.store = new TrainingBaseStore(this.props.memberRequest);
        this.clickMode = this.clickMode.bind(this);
        this.back = this.back.bind(this);
    }

    componentDidMount() {
        this.store.init();
        KeyboardManager.add('Enter', this.store.startTraining);
        KeyboardManager.add('Space', this.store.startTraining);
        KeyboardManager.add('Escape', this.back);
    }

    componentWillUnmount() {
        KeyboardManager.remove('Enter', this.store.startTraining);
        KeyboardManager.remove('Space', this.store.startTraining);
        KeyboardManager.remove('Escape', this.back);
    }

    back() {
        window.location.pathname = '/Training';
    }

    clickMode(mode: TrainingMode) {
        this.store.changeMode(mode);
    }

    render() {
        return (
            <div className="training-sport-base">
                <div className="mode-training">
                    {/*// <!--Панель выбора упражнений-->*/}
                    <div className="mode-training-main">
                        <Link to={"/Training"} id="next-simulators-label"/>

                        <div className="select-mode-training">
                            <ModeTrainingComponent
                                currentMode={this.store.mode}
                                mode={'number'}
                                clickMode={this.clickMode}/>
                            <ModeTrainingComponent
                                currentMode={this.store.mode}
                                mode={'cards'}
                                clickMode={this.clickMode}/>
                            <ModeTrainingComponent
                                currentMode={this.store.mode}
                                mode={'word'}
                                clickMode={this.clickMode}/>
                            <ModeTrainingComponent
                                currentMode={this.store.mode}
                                mode={'name'}
                                clickMode={this.clickMode}/>
                            <ModeTrainingComponent
                                currentMode={this.store.mode}
                                mode={'bins'}
                                clickMode={this.clickMode}/>
                            <ModeTrainingComponent
                                currentMode={this.store.mode}
                                mode={'image'}
                                clickMode={this.clickMode}/>
                        </div>
                    </div>
                </div>


                <div className="simulators">
                    <RouteView store={this.store.storeRoute}/>

                    <div className="modeTypeTraining">
                        {this.store.mode === 'number' &&
                            <NumberView changeModel={this.store.baseStores.get('number')!}
                            />}
                        {this.store.mode === 'cards' &&
                            <CardsView changeModel={this.store.baseStores.get('cards')!}
                            />}
                        {this.store.mode === 'word' &&
                            <WordView changeModel={this.store.baseStores.get('word')!}
                            />}
                        {this.store.mode === 'name' &&
                            <NameView changeModel={this.store.baseStores.get('name')!}
                            />}
                        {this.store.mode === 'bins' &&
                            <BinsView changeModel={this.store.baseStores.get('bins')!}
                            />}
                        {this.store.mode === 'image' &&
                            <ImageView changeModel={this.store.baseStores.get('image')!}
                            />}
                        <CustomButtonView onClick={this.store.startTraining}/>
                    </div>
                    <RangeTimesTrainingOneView
                        changeModel={this.store.currentStorage}/>
                </div>
            </div>
        )
    }
}