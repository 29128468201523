import {Component} from "react";
import {observer} from "mobx-react";
import './tempSpeedEngish.scss';
import CustomSelector from "../../../shared/selector/CustomSelector";
import {Loading} from "../../../shared/Loading";
import TempSpeedEnglishStore from "./TempSpeedEnglishStore";


@observer
export class TempSpeedEnglish extends Component<{}, {}> {
    private store: TempSpeedEnglishStore = new TempSpeedEnglishStore();

    componentDidMount() {
        this.store.loadingStore.fetchRequest();
    }

    render() {
        if (!this.store.loadingStore.isLoaded)
            return <Loading />
        return (
            <div className="tempSpeedEnglish">
                <span className="sat-name">Темп кодирования словообразы</span>
                <span className="sat-name sat-name2">
                    Качество связей между словом и словообразом с учётом среднего темпа
                    декодирования, кодирования, кодирования аудио за последние 10 тренировок
                </span>
                <div className="statchosen-2v">
                    <div className="select-style statchosen2">
                        <CustomSelector defaultValue={this.store.selectorRoute?.value ?? ""}
                                        onChange={this.store.setSelectorRoute}
                                        values={this.store.getRoutes}
                                        width="100%"/>
                    </div>

                    <div className="chdis" id="ch-regime2">
                        <div className="select-style-t2">
                            <CustomSelector defaultValue={this.store.selectorCH.value}
                                            value={this.store.selectorCH.value}
                                            values={this.store.selectorsCH}
                                            onChange={this.store.setSelectorCH}
                                            width="100%"/>
                        </div>
                    </div>
                </div>

                <div className="circle-tabl-box">
                    <div className="elpia-and-text">
                        <div className="pietxt" id="elpie3"></div>
                        <div className="mswey">
                            <span className="medium-el" id="middleSpeed">0,00</span>
                            <span className="txt-actstl">средний темп перехода за последние 10 результатов</span>
                        </div>
                        <span className="empty-circle2"><span className="circle0">Нет данных</span></span>
                    </div>

                    <table className="elpie-tabel">
                        <tbody>
                        <tr className="htr">
                            <td>Состояние</td>
                            <td>Ср.темп, с</td>
                            <td>Кол-во</td>
                        </tr>
                        <tr>
                            <td className="htr-ref">Рефлекторный</td>
                            <td>≤0,5</td>
                            <td id="speed05">{this.store.current?.speed05 ?? "-"}</td>
                        </tr>
                        <tr>
                            <td className="htr-vm">Очень быстрый</td>
                            <td>0,5-1</td>
                            <td id="speed10">{this.store.current?.speed10 ?? "-"}</td>
                        </tr>
                        <tr>
                            <td className="htr-v">Быстрый</td>
                            <td>1-1,5</td>
                            <td id="speed15">{this.store.current?.speed15 ?? "-"}</td>
                        </tr>
                        <tr>
                            <td className="htr-d">Средний</td>
                            <td>1,5-2,5</td>
                            <td id="speed25">{this.store.current?.speed25 ?? "-"}</td>
                        </tr>
                        <tr>
                            <td className="htr-b">Медленный</td>
                            <td>≥2,5</td>
                            <td id="speed">{this.store.current?.speed250 ?? "-"}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="substrate sub1l"></div>

                <span className="sat-name sat-name4">Цвето-цифровая таблица детализации темпа кодирования за последние 10 тренировок</span>

                <div className="select-style statchosen3">
                    <CustomSelector defaultValue={this.store.selectorCH.value}
                                    value={this.store.selectorCH.value}
                                    values={this.store.selectorsCH2}
                                    onChange={this.store.setSelectorCH}
                                    width="305px"/>
                </div>

                <div className="table-english-table">
                    <div className="row-table">
                        <div>Уверенный</div>
                        <div>3.768</div>
                        <div>3.768</div>
                        <div>3.768</div>
                        <div>3.768</div>
                        <div>3.768</div>
                        <div>3.768</div>
                        <div>3.768</div>
                        <div>3.768</div>
                        <div>3.768</div>
                    </div>
                </div>
            </div>
        );
    }
}