import React, {Component} from 'react'
import {observer} from "mobx-react";
import {TopUserModel} from "./TopUserModel";
import {AvatarWithMnemocard} from "../user/AvatarWithMnemocard";


@observer
export class TopUsersItem extends Component<{ item: TopUserModel, pos: number }, {}> {

    constructor(props: Readonly<{ item: TopUserModel, pos: number }>) {
        super(props);
        this.getValue = this.getValue.bind(this);
    }

    getValue(number: number) {
        if (number === 0)
            return '-';
        return String(number);
    }

    render() {
        return <div className="results-table-userinfo" key={this.props.item.idAccount + "_" + this.props.pos}>
            <div className="results-table-number td-number">{this.props.pos}</div>
            <AvatarWithMnemocard
                name={this.props.item.name}
                image={this.props.item.image}
                isPremium={this.props.item.isPremium}
                link={this.props.item.link}
                rank={this.props.item.rank} />
            <div className="td_point_all">{this.getValue(this.props.item.pointAll)}</div>
            <div className="td_point_month">{this.getValue(this.props.item.pointMonth)}</div>
            <div className="td_point_lastmonth">{this.getValue(this.props.item.pointPrevMonth)}
            </div>
            <div className="td_point_day">{this.getValue(this.props.item.pointDay)}</div>
            <div className="td_point_lastday">{this.getValue(this.props.item.pointPrevDay)}
            </div>
        </div>
    }
}
