import {CustomComponent} from "../../helpers/CustomComponent";
import React, {ChangeEvent, CSSProperties} from "react";
import {observer} from "mobx-react";

interface Props {
    id: string
    value: string;
    onChange: (val: string) => void;
    name: string;
    title: string;
    style?: CSSProperties;
}

@observer
export class CustomNumberInput extends CustomComponent<Props, {}> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(e: ChangeEvent<HTMLInputElement>){
        this.props.onChange(e.currentTarget.value)
    }

    render() {
        return <input id="timer"
                      style={this.props.style}
                      value={this.props.value}
                      onChange={this.onChange}
                      inputMode="numeric"
                      type="number"
                      step="0.01" min="0"
                      lang="en"
                      name="timer"
                      autoComplete="off"
                      title="От 0.1 до 20 сек"/>;
    }
}