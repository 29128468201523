import React, {Component} from 'react'
import './settingsPage.scss'
import SettingsStore, {SettingsType} from "../../components/settings/store/SettingsStore";
import MainSettingsComponent from "../../components/settings/MainSettingsComponent";
import {observer} from "mobx-react";
import SecuritySettingsComponent from "../../components/settings/SecuritySettingsComponent";
import Modal from "../../components/modal/ReactModal";
import UploadFileComponent from "../../helpers/UploadFileComponent";
import {ReactModalStore} from "../../components/modal/ReactModalStore";
import TitleStore from "../../components/authorization/store/TitleStore";


@observer
export class SettingsPage extends Component<{}, {}> {
    private readonly reactModalStore: ReactModalStore = new ReactModalStore();
    store: SettingsStore = new SettingsStore();

    constructor(props: Readonly<{}>) {
        super(props);
        this.getSelected = this.getSelected.bind(this);
        this.setTypeMain = this.setTypeMain.bind(this);
        this.setTypeSecurity = this.setTypeSecurity.bind(this);
    }
    componentDidMount() {
        TitleStore.setTitle("Настройки");
    }

    getSelected(type : SettingsType){
        return this.store.type === type ? 'setupLabelSelected' : '';
    }
    setTypeMain(){
        this.store.setType('main');
    }
    setTypeSecurity(){
        this.store.setType('security');
    }
    render() {
        return (
            <div id="contener-2" className="container-settings">
                <div id="setup-h">
                    <div className="setting-header">
                        <div className={"setup-label " + this.getSelected('main')} onClick={this.setTypeMain}>
                            <span>Основное</span>
                        </div>
                        <div className={"setup-label " + this.getSelected('security')} onClick={this.setTypeSecurity}>
                            <span>Безопасность</span>
                        </div>
                    </div>
                    {
                        this.store.model.IsLoaded && this.store.type === 'main' &&
                        <MainSettingsComponent store={this.store} />
                    }
                    {
                        this.store.model.IsLoaded && this.store.type === 'security' &&
                        <SecuritySettingsComponent store={this.store} />
                    }
                </div>
                <Modal store={this.reactModalStore}>
                    <div id="dialog-form" title="Загрузка аватарку" style={{width: '250px'}}>
                        <p className="validateTips" id="name-code">Загрузить аватарку</p>
                        <UploadFileComponent title={"Загрузите файл"} afterUpload={this.store.afterUpload} />
                    </div>
                </Modal>
            </div>
        )
    }
}