import {LoadingModel} from "../../shared/LoadingModel";
import {BallsResModel} from "../models/BallModel";
import {makeAutoObservable} from 'mobx';
import {PostRequest} from "../../../helpers/Request";
import {ResultModel} from "../models/ResultModel";
import {TrainingMode} from "../../../helpers/trainingHelp/TrainingMode";
import {TrainingDis} from "../../../helpers/trainingHelp/TrainingDis";


export enum TypeBallsResult {
    balls = 'balls',
    results = 'results',
}

export enum TypeBallsResultTime {
    day = 'day',
    month = 'month',
    all = 'all',
}

export default class BallResultStore {
    typeResult: TypeBallsResult = TypeBallsResult.balls;
    typeTime: TypeBallsResultTime = TypeBallsResultTime.month;
    modelBalls: LoadingModel<BallsResModel> = LoadingModel.createNotLoaded<BallsResModel>();
    modelResult: LoadingModel<ResultModel[]> = LoadingModel.createNotLoaded<ResultModel[]>();
    discipline: TrainingMode = 'number';
    dis: TrainingDis = '0';

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true})
    }

    changeTime(type: TypeBallsResultTime) {
        this.typeTime = type;
    }

    changeType(type: TypeBallsResult) {
        this.typeResult = type;
    }

    setBalls(res: LoadingModel<BallsResModel>) {
        this.modelBalls = res;
    }

    setResults(res: LoadingModel<ResultModel[]>) {
        this.modelResult = res;
    }

    setDiscipline(discipline: TrainingMode) {
        this.discipline = discipline;
    }

    setDis(dis: TrainingDis) {
        this.dis = dis;
    }

    fetchRequest() {
        if (this.typeResult === 'balls') {
            this.modelBalls = LoadingModel.createLoaded<BallsResModel>();
            PostRequest<BallsResModel, {}>('/Home/GetBalls?time=' + this.typeTime, {})
                .then(x => this.setBalls(x))
        } else {
            this.modelResult = LoadingModel.createLoaded<ResultModel[]>();
            PostRequest<ResultModel[], {}>('/Home/GetResult?time=' + this.typeTime + "&discipline=" + this.discipline + "&mode=" + this.dis, {})
                .then(x => this.setResults(x))
        }
    }
}