import React, {Component} from "react";
import {observer} from "mobx-react";
import {TitleComponent, TypeTitle} from "../../../../helpers/title/TitleComponent";
import {BlockTextComponent} from "../../../../helpers/blockText/BlockTextComponent";
import './../lessons.scss'
import {WriteAnswer} from "../../../../helpers/writeAnswer/WriteAnswer";
import {Accordion} from "../../../../helpers/accordion/Accordion";
import {ManyAnswer} from "../../../../helpers/manyAnswers/ManyAnswer";
import {
    BlockTextWithLeftBoardComponent
} from "../../../../helpers/blockTextWithLeftBoard/BlockTextWithLeftBoardComponent";

let question1 = [
    {title: "Мнемоника как драйвер", isSelected: false},
    {title: "Понятие", isSelected: false},
    {title: "Закон мнемоники", isSelected: false},
    {title: "Стимул -> Реакция", isSelected: false},
    {title: "Зачем?", isSelected: false},
    {title: "Почему?", isSelected: false},
    {title: "Цепочка", isSelected: false},
    {title: "Матрёшка", isSelected: false},
    {title: "Отличность", isSelected: false},
    {title: "Проникновение", isSelected: false},
    {title: "Мысленный кадр", isSelected: false},
    {title: "Мысленный экран", isSelected: false},
    {title: "Образ", isSelected: false},
    {title: "Образ мысленный", isSelected: false},
    {title: "Образ реальности", isSelected: false},
    {title: "Часть образа", isSelected: false},
    {title: "Энергоэффективность", isSelected: false},
    {title: "Быстрый подсмотр с усилением", isSelected: false},
    {title: "Нет лишних слов/звуков", isSelected: false},
    {title: "Не лишних движений", isSelected: false},
    {title: "Тело расслабленно", isSelected: false},
    {title: "Глазодвигательный паттерн «Фокус-Расфокус»", isSelected: false},
    {title: "Хладнокровие", isSelected: false},
    {title: "Доверие к памяти", isSelected: false},
    {title: "Нет логической ошибки обобщения", isSelected: false},
    {title: "Отношение к связи как к первой", isSelected: false},
    {title: "Нет лишних мысленных операций", isSelected: false},
    {title: "Цикл усиления связей", isSelected: false},
    {title: "Связь не создана", isSelected: false},
    {title: "Связь слабая", isSelected: false},
    {title: "Связь сильная", isSelected: false},
    {title: "Связь рефлекторная", isSelected: false},
]

@observer
export class LessonDay1 extends Component<{}, {}> {

    render() {
        return (<div className="lesson_day_1 lesson">
            <TitleComponent typeTitle={TypeTitle.h2} text="День №1"/>
            <div className="lesson-body">
                <BlockTextComponent hasPadding={false}>
                    Если вы открыли эту тренировку, то сегодняшний день станет для вас особенным. Ведь именно с него
                    начинается ваш путь к полному контролю вашей памяти – эффективному запоминанию любой нужной вам
                    информации (быстро, надёжно, легко).<br/><br/>

                    Всю жизнь вы пользовались памятью случайно – запомнили / не запомнили – как повезёт. Сегодня вам
                    предстоит начать привыкать к осознанному созданию нейронных связей. Никаких случайностей больше не
                    будет. Вы будете запоминать с гарантией дальнейшего припоминания.<br/><br/>

                    Запоминание, припоминание, забывание, ошибочное припоминание – всему этому ВСЕГДА есть причина! Во
                    время
                    тренировки старайтесь понимать причины не только слабых и не созданных связей, но также и сильных
                    связей.<br/>
                </BlockTextComponent>
                <TitleComponent typeTitle={TypeTitle.answerUser} text="Вопросы к заданию №1"/>
                <WriteAnswer question="1. По какому закону работает память?"/>
                <WriteAnswer question="2. Как правильно представлять образы?"/>
                <WriteAnswer question="3. Как правильно соединять образы?"/>
                <WriteAnswer question="4. Какие основные этапы выделяют в процессе запоминания?"/>
                <WriteAnswer question="5. Какие условия нужно соблюсти для гарантированного создания связи?"/>
                <WriteAnswer
                    question="6. Почему создавать связи между образами, используя эмоциональное нагнетание, истории, рационализацию не правильно?"/>
                <WriteAnswer
                    question="7. Чем отличаются приёмы запоминания последовательности «Цепочка» и «Матрёшка»?"/>
                <WriteAnswer question="8. Какие виды связей по силе создания вы знаете?"/>
                <WriteAnswer question="9. Какие виды связей по силе создания вы знаете?"/>
                <WriteAnswer
                    question="10. Какие цели должны быть достигнуты для того, чтобы сказать 'навык эффективного запоминания' встроен?"/>

                <TitleComponent typeTitle={TypeTitle.answerUser} text="Ключевые слова к занятию №1"/>
                <BlockTextComponent hasPadding={false}>
                    Прочитайте каждое ключевое слово к занятию и сделайте следующее:<br/>
                    1. Ответьте на вопрос: "Понимаете ли вы его?".<br/>
                    2. Попробуйте устно дать определение каждому ключевому слову.<br/>
                    3. Если пункты 1 и 2 смогли выполнить – поставьте напротив слова галочку.<br/>
                </BlockTextComponent>
                <Accordion title="Ключевые слова к занятию №1">
                    <ManyAnswer models={question1}/>
                </Accordion>
                <BlockTextComponent hasBackground={true} hasImage={true}>
                    <strong>Следите за энергоэффективностью во время запоминания и припоминания:</strong><br/>
                    - Быстро подсматривайте и усиливайте связь во время припоминания<br/>
                    - Нет лишних слов/звуков<br/>
                    - Нет лишних движений<br/>
                    - Тело расслаблено<br/>
                    - Хладнокровие<br/>
                </BlockTextComponent>
                <TitleComponent text="Упр. 1 Запомните последовательность слов приёмом 'Цепочка'"/>
                <BlockTextWithLeftBoardComponent>
                    Арбуз – Газета – Наволочка – Метла – Клык – Перстень – Трамвай – Тюльпан – Умывальник – Автомобиль –
                    Шампанское – Мяч – Бантик – Ружьё – Скелет – Апельсин – Флаг – Рыба – Гиря – Телевизор – Муравей
                </BlockTextWithLeftBoardComponent>
                <Accordion title="Темп запоминания (20 связей)">
                    <BlockTextComponent>
                        Чтобы узнать свой темп поделите время запоминания (без учёта усиления связей) на кол-во связей.
                        Например, вы создали 20 связей за 120 секунд. Ваш темп запоминания будет 120 секунд / 20 связей
                        = 6 сек на связь.
                    </BlockTextComponent>
                </Accordion>
                <Accordion title="Количество циклов усиления связей">
                    <BlockTextComponent>
                        Во время первого припоминания, когда вы обнаруживаете что Образ(S) не даёт вам Образ(R), нужно
                        быстро подсмотреть и усилить связь. Так необходимо сделать везде где реакция отсутствует. Итого,
                        на первом припоминании вы сделаете 1-ый цикл усиления связей.
                        <br/><br/>
                        Начните припоминание второй раз. Слабых и не созданных связей будет уже меньше. Сделайте 2-ой
                        цикл усиления связей. Начните припоминание третий раз.
                        <br/><br/>
                        Если припоминая третий раз вы вспомнили всё – значит, для безошибочного припоминания
                        потребовалось 2 цикла усиления связей. Это и нужно указать.
                    </BlockTextComponent>
                    <BlockTextComponent hasBackground={true} hasPadding={true} hasImage={true}>
                        Огорчаться и винить себя за то, что нет реакции - грубая ошибка. Ваша задача сломать связь,
                        которую навязала школа "Не вспомнил – виноват, огорчайся", на связь "Не вспомнил – подсмотрел и
                        усилил связь".
                    </BlockTextComponent>
                </Accordion>
                <Accordion title="Описание соединений 'Скелет – Апельсин – Флаг – Рыба'">
                    <BlockTextComponent>
                        Тренеру необходимо убедиться, что вы создаёте связи правильно. Для этого вам необходимо описать
                        словами предложенные соединения. По вашему описанию будет заметно, если вы что-то делаете не так
                        или вы что-то не поняли.
                    </BlockTextComponent>
                </Accordion>
                <TitleComponent typeTitle={TypeTitle.answerUser}
                                text="Упр. 2 Запомните последовательность слов приёмом 'Цепочка'"/>
                <BlockTextWithLeftBoardComponent>
                    Корень – Санки – Мячик – Слонёнок – Аквариум – Пушок – Деньга – Пески – Облако – Самовар – Шар –
                    Доски – Мелок – Травинка – Макулатура – Патроны – Батарейка
                </BlockTextWithLeftBoardComponent>
                <Accordion title="Темп запоминания (15 связей)">
                    <BlockTextComponent>
                        Чтобы узнать свой темп поделите время запоминания (без учёта усиления связей) на кол-во связей.
                        Например, вы создали 20 связей за 120 секунд. Ваш темп запоминания будет 120 секунд / 20 связей
                        = 6 сек на связь.
                    </BlockTextComponent>
                </Accordion>
                <Accordion title="Количество циклов усиления связей">
                    <BlockTextComponent>
                        Во время первого припоминания, когда вы обнаруживаете что Образ(S) не даёт вам Образ(R), нужно
                        быстро подсмотреть и усилить связь. Так необходимо сделать везде где реакция отсутствует. Итого,
                        на первом припоминании вы сделаете 1-ый цикл усиления связей.
                        <br/>
                        <br/>
                        Начните припоминание второй раз. Слабых и не созданных связей будет уже меньше. Сделайте 2-ой
                        цикл усиления связей. Начните припоминание третий раз.
                        <br/>
                        <br/>
                        Если припоминая третий раз вы вспомнили всё – значит, для безошибочного припоминания
                        потребовалось 2 цикла усиления связей. Это и нужно указать.
                    </BlockTextComponent>
                    <BlockTextComponent hasBackground={true} hasImage={true}>
                        Огорчаться и винить себя за то, что нет реакции - грубая ошибка. Ваша задача сломать связь,
                        которую навязала школа "Не вспомнил – виноват, огорчайся", на связь "Не вспомнил – подсмотрел и
                        усилил связь".
                    </BlockTextComponent>
                </Accordion>
                <TitleComponent typeTitle={TypeTitle.answerUser}
                                text="Упр. 3 Запомните последовательность слов приёмом 'Цепочка'"/>
                <BlockTextWithLeftBoardComponent>
                    Человек – Рогатка – Ножницы – Улитка – Антенна – Открытка – Кресло – Акула – Сердце – Жёлудь –
                    Конфета
                </BlockTextWithLeftBoardComponent>
                <Accordion title="Темп запоминания (15 связей)">
                    <BlockTextComponent>
                        Чтобы узнать свой темп поделите время запоминания (без учёта усиления связей) на кол-во связей.
                        Например, вы создали 20 связей за 120 секунд. Ваш темп запоминания будет 120 секунд / 20 связей
                        = 6 сек на связь.
                    </BlockTextComponent>
                </Accordion>
                <Accordion title="Количество циклов усиления связей">
                    <BlockTextComponent>
                        Во время первого припоминания, когда вы обнаруживаете что Образ(S) не даёт вам Образ(R), нужно
                        быстро подсмотреть и усилить связь. Так необходимо сделать везде где реакция отсутствует. Итого,
                        на первом припоминании вы сделаете 1-ый цикл усиления связей.
                        <br/>
                        <br/>
                        Начните припоминание второй раз. Слабых и не созданных связей будет уже меньше. Сделайте 2-ой
                        цикл усиления связей. Начните припоминание третий раз.
                        <br/>
                        <br/>
                        Если припоминая третий раз вы вспомнили всё – значит, для безошибочного припоминания
                        потребовалось 2 цикла усиления связей. Это и нужно указать.
                    </BlockTextComponent>
                    <BlockTextComponent hasBackground={true} hasImage={true}>
                        Огорчаться и винить себя за то, что нет реакции - грубая ошибка. Ваша задача сломать связь,
                        которую навязала школа "Не вспомнил – виноват, огорчайся", на связь "Не вспомнил – подсмотрел и
                        усилил связь".
                    </BlockTextComponent>
                </Accordion>
                <TitleComponent typeTitle={TypeTitle.answerUser} text="Дополнительно"/>
                <BlockTextComponent hasPadding={false}>
                    Начните соревноваться с другими мнемонистами.
                    <br/>
                    В свободное время в течение дня, запоминайте приёмом "Цепочка" по 10 картинок на сайте
                    powermemory.io, а также простые слова по 10 штук. Добиваться безошибочного припоминания при этом не
                    нужно.
                </BlockTextComponent>
                <TitleComponent typeTitle={TypeTitle.answerUser} text="Самоанализ"/>
                <BlockTextComponent hasPadding={false}>
                    Для повышения и удержания вашей осознанности на высоком уровне самоанализ обязателен. Просто ответьте честно на предложенные вопросы.
                </BlockTextComponent>
                <WriteAnswer question="1. Субъективно оцените свою тренировку по шкале 10 баллов" placeholder={"Оценка:"}/>
                <WriteAnswer question="2. Что в течение тренировки получилось лучше?" placeholder={"Оценка:"}/>
                <WriteAnswer question="3. Что получилось хуже?" placeholder={"Оценка:"}/>
                <WriteAnswer question="3. Что осталось непонятным?" placeholder={"Оценка:"}/>
            </div>
        </div>);
    }
}