import React, {ChangeEvent, useRef, useState} from "react";
import {CustomButton} from "./CustomButton";
import {UploadImage} from "./Request";
import {LoadingUrlModel} from "./models/LoadingUrlModel";


interface Props {
    title: string;
    afterUpload: (l: LoadingUrlModel | null) => void;
}

// export default class UploadFileComponent extends CustomComponent<Props, {}> {
//     constructor(props: Readonly<Props>) {
//         super(props)
//         this.uploadFile = this.uploadFile.bind(this);
//     }
//
//     uploadFile(event: ChangeEvent<HTMLInputElement>) {
//         let files = event.target.files;
//         console.log(files);
//
//         if (files && files.length === 1) {
//             let data = new FormData();
//             data.append('file', files[0]);
//         }
//     }
//
//     render() {
//         return <span>
//             <img />
//             <input type="file"
//                    id="fileCode"
//                    accept=".png, .jpg, .jpeg"
//                    max-file-size="1"
//                    onChange={this.uploadFile}/>
//       </span>
//     }
// }

export default function UploadFileComponent(props: Props) {
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const filePicekerRef = useRef<HTMLInputElement>(null);
    const [files, setFiles] = useState<File | null>();

    function previewFile(e: ChangeEvent<HTMLInputElement>) {
        const reader = new FileReader();
        let files = e.target.files;
        if (files && files.length === 1) {
            const selectedFile = files[0];
            setFiles(selectedFile);
            if (selectedFile) {
                reader.readAsDataURL(selectedFile);
            }
            reader.onload = (readerEvent) => {
                if (selectedFile.type.includes("image")) {
                    setImagePreview(readerEvent.target!.result as string);
                }
            };
        }
    }

    function clearFiles() {
        setImagePreview(null);
    }

    function onclick() {
        filePicekerRef.current!.click()
    }

    function loadImg() {
        UploadImage(files!).then(props.afterUpload);
    }

    return (
        <div>
            <div className="btn-container">
                <input
                    ref={filePicekerRef}
                    accept=".png, .jpg, .jpeg"
                    max-file-size="1"
                    onChange={previewFile}
                    type="file"
                    hidden
                />
                <CustomButton text={props.title} onclick={onclick}/>
            </div>

            <div className="preview" style={{margin: '10px'}}>
                {imagePreview != null && <img style={{width: '250px', height: '150px'}} src={imagePreview} alt=""/>}
            </div>
            {
                imagePreview != null &&
                <div style={{display: 'flex', marginTop: '10px', justifyContent: 'space-between'}}>
                    <CustomButton text="Отменить" onclick={clearFiles}/>
                    <CustomButton text="Загрузить" onclick={loadImg}/>
                </div>
            }
        </div>
    );
}