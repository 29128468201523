import IResultService from "./IResultService";
import {TrainingResultItemModel, TrainingResultModel} from "../model/TrainingResultModel";


export default class NameResultService implements IResultService {
    constructor() {
        this.getItem = this.getItem.bind(this);
        this.GetItems = this.GetItems.bind(this);
    }

    GetItems(training: TrainingResultModel): JSX.Element {
        return <>
            {training.items.map(this.getItem)}
        </>;
    }

    getItem(item: TrainingResultItemModel, position: number): JSX.Element {
        if (item.evenly) {
            return <div className="names-rem3t" key={position + "_" + item.dictionaryImage}>
                <img alt="" src={"https://powermemory.io" + item.dictionaryImage}/>
                <input type="text" className="ans-text" value={item.dictionary}/>
            </div>
        }
        return <div className="names-rem3t" key={position + "_" + item.dictionaryImage}>
            <img alt="" className="names-img-err" src={'https://powermemory.io' + item.dictionaryImage}/>
            <input type="text" className="ans-text" value={item.dictionary}/>
            <span className="names-ans-err">{item.answer}</span>
        </div>
    }

}