import {CustomComponent} from "../../helpers/CustomComponent";
import SettingsStore from "./store/SettingsStore";
import React, {ChangeEvent} from "react";
import {observer} from "mobx-react";
import {CustomButtonView} from "../training/components/CustomButtonView";


@observer
export default class SecuritySettingsComponent extends CustomComponent<{ store: SettingsStore }, {}> {
    constructor(props: Readonly<{ store: SettingsStore }>) {
        super(props);

        this.changeOldPassword = this.changeOldPassword.bind(this);
        this.changeNewPassword = this.changeNewPassword.bind(this);
        this.changeEmail = this.changeEmail.bind(this);

        this.saveEmail = this.saveEmail.bind(this);
        this.savePassword = this.savePassword.bind(this);
        this.confirmEmail = this.confirmEmail.bind(this);
    }

    changeOldPassword(e: ChangeEvent<HTMLInputElement>) {
        this.props.store.changePassword.oldPassword = e.currentTarget.value;
    }

    changeNewPassword(e: ChangeEvent<HTMLInputElement>) {
        this.props.store.changePassword.newPassword = e.currentTarget.value;
    }

    changeEmail(e: ChangeEvent<HTMLInputElement>) {
        this.props.store.changeEmail.email = e.currentTarget.value;
    }

    saveEmail() {
        this.props.store.changeEmailRequest();
    }

    savePassword() {
        this.props.store.changePasswordRequest();
    }

    confirmEmail() {
        this.props.store.confirmEmail();
    }

    render() {
        return (
            <section id="setup-security">
                <div className="setting-security-box">

                    <div className="edit-s" id="auth-sn">
                        <span>Вход через социальные сети:</span>
                        {/*@* <input id="enter-google" type="button" ng-click="googleOauth()"> *@*/}
                        {/*@* <label htmlFor="enter-google"> *@*/}
                        {/*    @* Google *@*/}
                        {/*    @* <span ng-if="settings.isGoogleOauth === false"*/}
                        {/*             id="span-enter-google">@Localizer["Connection"]</span> *@*/}
                        {/*    @* <span ng-if="settings.isGoogleOauth === true"*/}
                        {/*             id="span-enter-google">@Localizer["Unconnection"]</span> *@*/}
                        {/*    @* </label> *@*/}
                        <input id="enter-vk"
                               style={{display: this.props.store.changeModel!.isVkOauth ? "display" : "none"}}
                               type="button"/>
                        <input id="enter-vk"
                               style={{display: this.props.store.changeModel!.isVkOauth ? "none" : "display"}}
                               type="button"
                        />
                        <label htmlFor="enter-vk">
                            VK
                            <span style={{display: this.props.store.changeModel!.isVkOauth ? "none" : "display"}}
                                  id="span-enter-vk">Подключить</span>
                            <span style={{display: this.props.store.changeModel!.isVkOauth ? "display" : "none"}}
                                  id="span-enter-vk">Отключить</span>
                        </label>
                    </div>

                    <div className="edit-s" id="email-setup">
                        <span>E-mail</span>
                        <input id="new-email" type="text" onChange={this.changeEmail}
                               value={this.props.store.changeEmail.email}/>
                        <CustomButtonView onClick={this.saveEmail}
                                          style={{width: "283px"}}
                                          readonly={this.props.store.sameEmail}
                                          text="Сохранить"/>
                        {
                            this.props.store.changeEmail.error &&
                            <div className="error-email">{this.props.store.changeEmail.error}</div>
                        }
                        <CustomButtonView onClick={this.confirmEmail}
                                          style={{width: "283px"}}
                                          readonly={this.props.store.model.Model?.emailConfirm ?? false}
                                          text="Подтвердить"/>
                    </div>

                    <div className="edit-s" id="edit-s-email">
                        <span>Сменить пароль</span>
                        <input name="OldPassword" id="old-password"
                               value={this.props.store.changePassword.oldPassword}
                               onChange={this.changeOldPassword}
                               type="password" placeholder="Старый пароль"/>
                        <input name="NewPassword" id="new-password"
                               value={this.props.store.changePassword.newPassword}
                               onChange={this.changeNewPassword}
                               type="password" placeholder="Новый пароль"/>
                        {
                            this.props.store.changePassword.error &&
                            <div className="error-password">{this.props.store.changePassword.error}</div>
                        }
                        <CustomButtonView onClick={this.savePassword}
                                          style={{width: "283px"}}
                                          readonly={this.props.store.savePassword}
                                          text="Сохранить"/>
                    </div>
                </div>
            </section>
        )
    }
}