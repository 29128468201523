import {makeAutoObservable} from "mobx";
import LoadingStore from "../../../../helpers/LoadingStore";
import NewRankModel from "./NewRankModel";
import {SelectorPros} from "../../../shared/selector/CustomSelector";
import {GetRequest} from "../../../../helpers/Request";


export default class NewRankStore{
    public loadingStore: LoadingStore<NewRankModel>;
    public options = {
        title: "",
        'width': 373,
        'height': 373,
        'colors': ['#6386b0', '#f39164', '#fcc826', '#b873b2', '#50b162', '#d93243'],
        'chartArea': { left: 0, top: 0, width: '100%', height: '80%', margin: '10px' },
        'legend': 'none'
    };
    public selector: SelectorPros;
    public selectors: SelectorPros[] = [
        {value:"rank-1",title:"lvl 1 Начинающий" },
        {value:"rank-2",title:"lvl 2 Новичок" },
        {value:"rank-3",title:"lvl 3 Ученик" },
        {value:"rank-4",title:"lvl 4 Опытный" },
        {value:"rank-5",title:"lvl 5 Знаток" },
        {value:"rank-6",title:"lvl 6 Специалист" },
        {value:"rank-7",title:"lvl 7 Профессионал" },
        {value:"rank-8",title:"lvl 8 Подмастерье" },
        {value:"rank-9",title:"lvl 9 Мастер памяти" },
        {value:"rank-10",title:"lvl 10 Гроссмейстер" },
        {value:"rank-11",title:"lvl 11 Гуру" },
        {value:"rank-12",title:"lvl 12 Виртуоз" },
        {value:"rank-13",title:"lvl 13 Легенда" },
        {value:"rank-14",title:"lvl 14 Оракул" },
        {value:"rank-15",title:"lvl 15 Архитектор памяти" },
    ];
    pos: number = 0;


    constructor() {
        makeAutoObservable(this, {}, {autoBind: true});
        this.selector = this.selectors.filter(x => x.value === 'rank-1')[0];
        this.loadingStore = new LoadingStore<NewRankModel>(this.getUrl)
    }

    initRank(){
        GetRequest<string>('/api/GetNewRank/nextRank')
            .then(m => {
                this.setSelector(this.selectors.filter(x => x.value === m.Model)[0]);
                this.fetchRequest();
            });
    }

    numberPosition():number{
        return 0;
    }

    fetchRequest(){
        this.pos = 0;
        this.loadingStore.fetchRequest();
    }

    getValueOrLoading(val: number | undefined){
        if (this.loadingStore.model.IsLoaded && val)
            return val.toString();
        return '-'
    }

    getUrl(): string {
        return '/api/GetNewRank/Rank?lvl=' + this.selector.value;
    }
    setSelector(sel: SelectorPros){
        this.selector = sel;
    }
}