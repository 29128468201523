import React, {useEffect, useState} from "react";
import {
    MantineReactTable,
    MRT_ColumnDef,
    MRT_ColumnFiltersState,
    MRT_PaginationState,
    MRT_SortingState
} from "mantine-react-table";
import {CustomHeader} from "./GetColumnsHelper";
import {MRT_Localization_RU} from "mantine-react-table/locales/ru";
import {PostRequest} from "../Request";
import {ListTable} from "../../components/administration/model/ProductView";

interface Props<T> {
    type: string
    url: string;
}

export const MantineReactCustomTableFunction = <T extends Record<string, any>>(props: Props<T>) => {
    const [data, setData] = useState<T[]>([]);
    const [count, setCount] = useState<number>(0);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);


    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });

    useEffect(() => {
        request()
    }, [])

    useEffect(() => {
        request()
    }, [
        columnFilters, //refetch when column filters change
        pagination.pageIndex, //refetch when page index changes
        pagination.pageSize, //refetch when page size changes
        sorting //refetch when sorting changes
    ]);
    const request = async () => {
        if (data.length === 0) {
            setIsLoading(true);
        }else{
            setIsRefetching(true);
        }
        let result = await PostRequest<ListTable<T>, any>(props.url, {
            filter: columnFilters,
            pagination: pagination,
            sorting: sorting
        });
        setIsLoading(false);
        setIsRefetching(false);
        if (result.Model) {
            setCount(result.Model.count);
            setData(result.Model.data);
        }
    }

    // // Доделать пагинацию с сортировкой и фильтрацией
    const getHeader = (): MRT_ColumnDef<T>[] => {
        return CustomHeader<T>(data, props.type);
    }
    return <MantineReactTable
        initialState={{density: 'xs', showColumnFilters: true}}
        getRowId={(row) => row.id}
        manualFiltering={true}
        manualPagination={true}
        manualSorting={true}
        onColumnFiltersChange={setColumnFilters}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        state={{
            columnFilters,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
        }}
        rowCount={count}
        columns={getHeader()}
        data={data}
        enableSorting={true}
        enableStickyHeader={true}
        enableTableHead={true}
        enableGlobalFilter={false}
        enableRowSelection={true}
        enableColumnResizing={true}
        enableColumnActions={false}
        enableRowActions={false}
        enableDensityToggle={true}
        enableFullScreenToggle={false}
        localization={MRT_Localization_RU}
        mantinePaginationProps={{
            rowsPerPageOptions: ['10', '20', '50', '100', '500'],
        }}
    />;
}