import React from 'react'
// import Page from './course.html';
// var htmlDoc = {__html: '<a class="menu__link" id="for-whom-menulink" href="#komu">ДЛЯ КОГО</a><a class="menu__link" id="best-menulink" href="#best">Лучший</a><a class="menu__link" id="program-menulink" href="#programma-div">Программа</a><a class="menu__link" id="terms-menulink" href="#best-mobil-us">Условия</a><a class="menu__link" id="author-menulink" href="#alex-div">Автор</a><a class="menu__link" id="comment-menulink" href="#result-div">ОТЗЫВЫ</a><a class="menu__link" id="cost-course-menulink" href="#format-course">Цена от 300$</a>'};
function iframe() {
  return {
      __html: '<iframe src="./assets/html/course.html" width="100%" height="100wh"></iframe>'
  }
}
export function AboutPage() {
  return (
    <div dangerouslySetInnerHTML={ iframe()} />
    // <div></div>
    )
}