import {CustomComponent} from "../../helpers/CustomComponent";
import React from "react";
import {PostsStore} from "./store/PostsStore";
import './posts.css'
import {SmallPosts} from "./SmallPosts";
import {observer} from "mobx-react";


@observer
export class Posts extends CustomComponent<{}, {}> {
    store: PostsStore;

    constructor(props: Readonly<{}>) {
        super(props);
        let params = new URLSearchParams(window.location.search);
        this.store = new PostsStore(params.get("tag"), (params.get("rubric")));
    }
    componentDidMount() {
        this.store.init();
    }

    render() {
        return (
            <section className="news-bords clear-fix">
                {
                    this.store.model.Model &&
                    this.store.posts.map((x) =>
                        <SmallPosts key={x.id} post={x}/>
                    )
                }
                {/*// todo Добавить скелетонс*/}
                {/*{*/}
                {/*    !this.store.model.IsLoaded && <Loading />*/}
                {/*}*/}

                <div className="more" onClick={this.store.somePosts}>ещё</div>
            </section>
        )
    }
}