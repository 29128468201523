import {SelectorPros} from "../../../shared/selector/CustomSelector";
import {makeAutoObservable, observable} from "mobx";
import {ActivityModel} from "./ActivityModel";
import LoadingStore from "../../../../helpers/LoadingStore";
import Highcharts from "highcharts";
import {getOpinion} from "../../../../helpers/HigchartsOptionsHelper";


export class ActivityStore {
    @observable
    public selector: SelectorPros;

    @observable
    public loadingStore: LoadingStore<ActivityModel>;

    public selectors: SelectorPros[] = [
        {value: "all", title: "Все виды информации"},
        {value: "number", title: "Числа"},
        {value: "cards", title: "Карты"},
        {value: "word", title: "Слова"},
        {value: "name", title: "Имена"},
        {value: "bins", title: "Бинарные"},
        {value: "image", title: "Картинки"}
    ];

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true});
        this.selector = this.selectors.filter(x => x.value === 'all')[0];
        this.loadingStore = new LoadingStore<ActivityModel>(this.getUrl)
    }

    get options(): Highcharts.Options {
        return getOpinion({
            nameSeries: 'Активность тренировок',
            valueDecimals: 0,
            date: this.loadingStore?.model.Model?.data?.map((x, y) => {
                return {date: x.date, value: x.count}
            }) ?? []
        })
    }

    getUrl(): string {
        return '/api/Statistic/Activity?mode=' + this.selector.value;
    }

    setSelector(sel: SelectorPros) {
        this.selector = sel;
    }
}