import {makeAutoObservable} from "mobx";
import {UniversityDayModel} from "../models/UniversityBlockTaskModel";
import {GetRequest} from "../../../helpers/Request";
import {LoadingModel} from "../../shared/LoadingModel";


export class UniversityModuleStore {
    model: LoadingModel<UniversityDayModel> = LoadingModel.createNotLoaded();

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true});
    }

    init() {
        this.request();
    }

    unInit() {

    }

    async request(){
        let data = await GetRequest<UniversityDayModel>("/api/University/DayTraining/1");
        this.model = data;
    }
}