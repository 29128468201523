import {CustomComponent} from "../../helpers/CustomComponent";
import SettingsStore from "./store/SettingsStore";
import React, {ChangeEvent} from "react";
import {observer} from "mobx-react";
import CustomSelector, {SelectorPros} from "../shared/selector/CustomSelector";
import {hoursSelectors, sexSelectors} from "../../helpers/SelectorPropsList";
import {CustomButtonView} from "../training/components/CustomButtonView";


@observer
export default class MainSettingsComponent extends CustomComponent<{ store: SettingsStore }, {}> {
    constructor(props: Readonly<{ store: SettingsStore }>) {
        super(props);
        this.changeFirstname = this.changeFirstname.bind(this);
        this.changeSurname = this.changeSurname.bind(this);
        this.changeLink = this.changeLink.bind(this);
        this.changeTimeZone = this.changeTimeZone.bind(this);
        this.changeSocialNetworks = this.changeSocialNetworks.bind(this);
        this.changeSex = this.changeSex.bind(this);
        this.changeCountry = this.changeCountry.bind(this);
        this.changeCity = this.changeCity.bind(this);

        this.saveModel = this.saveModel.bind(this);
        this.deleteImage = this.deleteImage.bind(this);
    }

    changeFirstname(e: ChangeEvent<HTMLInputElement>) {
        this.props.store.changeFirstname(e.target.value);
    }

    changeSurname(e: ChangeEvent<HTMLInputElement>) {
        this.props.store.changeSurname(e.target.value);
    }

    changeLink(e: ChangeEvent<HTMLInputElement>) {
        this.props.store.changeLink(e.target.value);
    }

    changeSocialNetworks(e: ChangeEvent<HTMLInputElement>) {
        this.props.store.changeSocialNetworks(e.target.value);
    }

    changeSex(sel: SelectorPros | undefined) {
        this.props.store.changeSex(sel?.value ?? "");
    }

    changeTimeZone(sel: SelectorPros | undefined) {
        this.props.store.changeTimeZone(parseInt(sel?.value ?? "3"))
    }

    changeCountry(e: ChangeEvent<HTMLSelectElement>) {
        this.props.store.changeCountry(e.target.value);
    }

    changeCity(e: ChangeEvent<HTMLInputElement>) {
        this.props.store.changeCity(e.target.value);
    }

    saveModel(){
        this.props.store.saveMainPage();
    }

    deleteImage(){
        this.props.store.deleteImage();
    }

    render() {
        return (
            <div id="setup-main">
                <div className="setting-box">
                    <div className="setting-main">
                        <div id="s-ava">
                            <img id="img-ava" src={this.props.store.changeModel!.image} alt="Моя невидимая аватарка"/>
                            <div className="closer" onClick={this.deleteImage}></div>
                            <div id="ava-change" onClick={this.props.store.openModalUploadFile}>
                                Загрузить фото
                            </div>
                        </div>
                    </div>

                    <div className="setting-main">
                        <div className="edit-s">
                            <span>Ваше имя</span>
                            <input name="fullname" id="firstname-s" value={this.props.store.changeModel!.firsname}
                                   type="text"
                                   onChange={this.changeFirstname}/>
                            <div className="error-text" id="error-firstname">{this.props.store.validName(this.props.store.changeModel?.firsname ?? "", 'имя')}</div>
                        </div>

                        <div className="edit-s">
                            <span>Ваша фамилия</span>
                            <input name="fullname" id="lastname-s" type="text"
                                   onChange={this.changeSurname} value={this.props.store.changeModel!.surname}/>
                            <div className="error-text" id="error-lastname">{this.props.store.validName(this.props.store.changeModel?.surname ?? "", 'фамилия')}</div>
                        </div>

                        <div className="edit-s">
                            <span>Дата рождения</span>
                            <div className="input-s" id="s-birthday-select">
                                <input type="date"
                                       min="1910-01-01" max="2018-12-31"/>
                            </div>
                        </div>

                        <div className="edit-s" id="edit-sex">
                            <span>Пол</span>
                            <CustomSelector defaultValue={this.props.store.changeModel!.sex}
                                            onChange={this.changeSex}
                                            width="261px"
                                            margin="0"
                                            values={sexSelectors}/>
                        </div>

                        <div className="edit-s edit-nm">
                            <span>Страна</span>
                            <select className="chosen-select select-style2" id="s-nation"
                                    defaultValue={this.props.store.changeModel!.country}>
                                <option>Планета Земля</option>
                                <optgroup label="Популярные">
                                    <option>Россия</option>
                                    <option>Беларусь</option>
                                    <option>Украина</option>
                                    <option>Казахстан</option>
                                    <option>США</option>
                                    <option>Германия</option>
                                    <option>Китай</option>
                                    <option>Монголия</option>
                                </optgroup>
                                <optgroup label="Все страны">
                                    <option>Абхазия</option>
                                    <option>Австралия</option>
                                    <option>Австрия</option>
                                    <option>Азербайджан</option>
                                    <option>Албания</option>
                                    <option>Алжир</option>
                                    <option>Ангола</option>
                                    <option>Андорра</option>
                                    <option>Антигуа и Барбуда</option>
                                    <option>Аргентина</option>
                                    <option>Армения</option>
                                    <option>Афганистан</option>
                                    <option>Багамские Острова</option>
                                    <option>Бангладеш</option>
                                    <option>Барбадос</option>
                                    <option>Бахрейн</option>
                                    <option>Белиз</option>
                                    <option>Беларусь</option>
                                    <option>Бельгия</option>
                                    <option>Бенин</option>
                                    <option>Болгария</option>
                                    <option>Боливия</option>
                                    <option>Босния и Герцеговина</option>
                                    <option>Ботсвана</option>
                                    <option>Бразилия</option>
                                    <option>Бруней</option>
                                    <option>Буркина-Фасо</option>
                                    <option>Бурунди</option>
                                    <option>Бутан</option>
                                    <option>Вануату</option>
                                    <option>Ватикан</option>
                                    <option>Великобритания</option>
                                    <option>Венгрия</option>
                                    <option>Венесуэла</option>
                                    <option>Восточный Тимор</option>
                                    <option>Вьетнам</option>
                                    <option>Габон</option>
                                    <option>Гаити</option>
                                    <option>Гайана</option>
                                    <option>Гамбия</option>
                                    <option>Гана</option>
                                    <option>Гватемала</option>
                                    <option>Гвинея</option>
                                    <option>Гвинея-Бисау</option>
                                    <option>Германия</option>
                                    <option>Гондурас</option>
                                    <option>Государство Палестина</option>
                                    <option>Гренада</option>
                                    <option>Греция</option>
                                    <option>Грузия</option>
                                    <option>Дания</option>
                                    <option>Джибути</option>
                                    <option>Доминика</option>
                                    <option>Доминиканская Республика</option>
                                    <option>ДР Конго</option>
                                    <option>Египет</option>
                                    <option>Замбия</option>
                                    <option>Зимбабве</option>
                                    <option>Израиль</option>
                                    <option>Индия</option>
                                    <option>Индонезия</option>
                                    <option>Иордания</option>
                                    <option>Ирак</option>
                                    <option>Иран</option>
                                    <option>Ирландия</option>
                                    <option>Исландия</option>
                                    <option>Испания</option>
                                    <option>Италия</option>
                                    <option>Йемен</option>
                                    <option>Кабо-Верде</option>
                                    <option>Казахстан</option>
                                    <option>Камбоджа</option>
                                    <option>Камерун</option>
                                    <option>Канада</option>
                                    <option>Катар</option>
                                    <option>Кения</option>
                                    <option>Кипр</option>
                                    <option>Киргизия</option>
                                    <option>Кирибати</option>
                                    <option>Китай</option>
                                    <option>КНДР</option>
                                    <option>Колумбия</option>
                                    <option>Коморские Острова</option>
                                    <option>Коста-Рика</option>
                                    <option>Кот-д'Ивуар</option>
                                    <option>Куба</option>
                                    <option>Кувейт</option>
                                    <option>Лаос</option>
                                    <option>Латвия</option>
                                    <option>Лесото</option>
                                    <option>Либерия</option>
                                    <option>Ливан</option>
                                    <option>Ливия</option>
                                    <option>Литва</option>
                                    <option>Лихтенштейн</option>
                                    <option>Люксембург</option>
                                    <option>Маврикий</option>
                                    <option>Мавритания</option>
                                    <option>Мадагаскар</option>
                                    <option>Малави</option>
                                    <option>Малайзия</option>
                                    <option>Мали</option>
                                    <option>Мальдивские Острова</option>
                                    <option>Мальта</option>
                                    <option>Марокко</option>
                                    <option>Маршалловы Острова</option>
                                    <option>Мексика</option>
                                    <option>Мозамбик</option>
                                    <option>Молдавия</option>
                                    <option>Монако</option>
                                    <option>Монголия</option>
                                    <option>Мьянма</option>
                                    <option>Намибия</option>
                                    <option>Науру</option>
                                    <option>Непал</option>
                                    <option>Нигер</option>
                                    <option>Нигерия</option>
                                    <option>Нидерланды</option>
                                    <option>Никарагуа</option>
                                    <option>Новая Зеландия</option>
                                    <option>Норвегия</option>
                                    <option>ОАЭ</option>
                                    <option>Оман</option>
                                    <option>Пакистан</option>
                                    <option>Палау</option>
                                    <option>Панама</option>
                                    <option>Папуа - Новая Гвинея</option>
                                    <option>Парагвай</option>
                                    <option>Перу</option>
                                    <option>Польша</option>
                                    <option>Португалия</option>
                                    <option>Республика Конго</option>
                                    <option>Республика Корея</option>
                                    <option>Россия</option>
                                    <option>Руанда</option>
                                    <option>Румыния</option>
                                    <option>Сальвадор</option>
                                    <option>Самоа</option>
                                    <option>Сан-Марино</option>
                                    <option>Сан-Томе и Принсипи</option>
                                    <option>Саудовская Аравия</option>
                                    <option>Северная Македония</option>
                                    <option>Сейшельские Острова</option>
                                    <option>Сенегал</option>
                                    <option>Сент-Винсент и Гренадины</option>
                                    <option>Сент-Китс и Невис</option>
                                    <option>Сент-Люсия</option>
                                    <option>Сербия</option>
                                    <option>Сингапур</option>
                                    <option>Сирия</option>
                                    <option>Словакия</option>
                                    <option>Словения</option>
                                    <option>Соломоновы Острова</option>
                                    <option>Сомали</option>
                                    <option>Судан</option>
                                    <option>Суринам</option>
                                    <option>США</option>
                                    <option>Сьерра-Леоне</option>
                                    <option>Таджикистан</option>
                                    <option>Таиланд</option>
                                    <option>Танзания</option>
                                    <option>Того</option>
                                    <option>Тонга</option>
                                    <option>Тринидад и Тобаго</option>
                                    <option>Тувалу</option>
                                    <option>Тунис</option>
                                    <option>Туркмения</option>
                                    <option>Турция</option>
                                    <option>Уганда</option>
                                    <option>Узбекистан</option>
                                    <option>Украина</option>
                                    <option>Уругвай</option>
                                    <option>Федеративные Штаты Микронезии</option>
                                    <option>Фиджи</option>
                                    <option>Филиппины</option>
                                    <option>Финляндия</option>
                                    <option>Франция</option>
                                    <option>Хорватия</option>
                                    <option>ЦАР</option>
                                    <option>Чад</option>
                                    <option>Черногория</option>
                                    <option>Чехия</option>
                                    <option>Чили</option>
                                    <option>Швейцария</option>
                                    <option>Швеция</option>
                                    <option>Шри-Ланка</option>
                                    <option>Эквадор</option>
                                    <option>Экваториальная Гвинея</option>
                                    <option>Эритрея</option>
                                    <option>Эсватини</option>
                                    <option>Эстония</option>
                                    <option>Эфиопия</option>
                                    <option>ЮАР</option>
                                    <option>Южная Осетия</option>
                                    <option>Южный Судан</option>
                                    <option>Ямайка</option>
                                    <option>Япония</option>
                                </optgroup>
                            </select>
                        </div>

                        <div className="edit-s">
                            <span>Город</span>
                            <input id="s-nation-city" type="text" onChange={this.changeCity}
                                   value={this.props.store.changeModel!.city}/>
                        </div>

                        <div className="edit-s">
                            <span>Часовой пояс</span>
                            <CustomSelector defaultValue={this.props.store.changeModel!.timeZone?.toString() ?? "+3"}
                                            onChange={this.changeSex}
                                            width="261px"
                                            margin="0"
                                            groups={hoursSelectors}/>
                        </div>

                        <div className="edit-s">
                            <span>Адрес страницы</span>
                            <div className="contact-wrapper">
                                <label id="idpage-text" htmlFor="idpage">powermemory.io/&#64;</label>
                                <input id="idpage" type="text" onChange={this.changeLink}
                                       value={this.props.store.changeModel!.link}/>
                            </div>
                        </div>
                        <div className="edit-s">
                            <span>Контакты</span>
                            <input id="socnet" type="text" onChange={this.changeSocialNetworks}
                                   value={this.props.store.changeModel!.socialNetworks}/>
                        </div>
                        <CustomButtonView onClick={this.saveModel}
                                          style={{width: "261px", marginLeft: 0}}
                                          text="Применить"/>
                    </div>

                </div>
            </div>
        )
    }
}