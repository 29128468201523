import {makeAutoObservable} from "mobx";

export enum UniversityState{
    menu = 0,

}

export class GlobalUniversityStore {
    state: UniversityState = UniversityState.menu;
    constructor() {
        makeAutoObservable(this, {}, {autoBind: true});
    }
    onchange = (state: UniversityState): void => {
        this.state = state;
    }
    init(){

    }
    uninit(){

    }
}