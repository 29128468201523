export function GetAllCards(): string[] {
    let massive = [];

    for (const strKey of ["h", "c", "d", "s"]) {
        for (const value of ["2", "3", "4", "5", "6", "7", "8", "9", "10", "j", "q", "k", "a"]) {
            massive.push(`https://powermemory.io/images/cards/default/${value}${strKey}.svg`);
        }
    }
    console.log('massive', massive)

    return massive;
}