import {makeAutoObservable} from "mobx";
import LoadingStore from "../../../../helpers/LoadingStore";
import {SelectorPros} from "../../../shared/selector/CustomSelector";
import {
    LocationQualityModel,
    LocationQualityPointModel,
    LocationQualityPointTimeModel
} from "../locationQuality/LocationQualityModel";
import {Loading} from "../../../shared/Loading";


export class CodeReliabilityStore {

    public selector: SelectorPros;
    public selectors: SelectorPros[] = [
        {value: "9", title: "0-9"},
        {value: "99", title: "00-99"},
        {value: "999", title: "000-999"},
    ];
    public loadingStore: LoadingStore<LocationQualityModel>;
    options = {
        title: "My Daily Activities",
        width: 373,
        height: 373,
        colors: ['#4fb260', '#a5bd43', '#d93243'],
        chartArea: { left: 0, top: 0, width: '100%', height: '80%', margin: '10px' },
        legend: 'none'
    };

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true});
        this.selector = this.selectors.filter((x) => x.value === "99")[0];
        this.loadingStore = new LoadingStore<LocationQualityModel>(this.getUrl)
    }

    getUrl(): string {
        return "/api/Statistic/CodeReliability?discipline=" + this.selector.value;
    }

    setSelector(sel: SelectorPros) {
        this.selector = sel;
    }

    get getDataPie(){
        return [
            ['Состояние', 'Кол-во опорных'],
            ['Надёжные', this.loadingStore?.get?.good ?? 0],
            ['Хорошие', this.loadingStore?.get?.normal ?? 0],
            ['Плохие', this.loadingStore?.get?.bad ?? 0]
        ];
    }

    get getView(): JSX.Element {
        if (!this.loadingStore.isLoaded)
            return <Loading/>
        return (<>
            {
                this.loadingStore.get?.points
                    .map((x, index) =>
                        <div key={x.name + '_' + x.number} className="route-line">
                            <div className="route">
                                <span className="name-route">{index + 1}. {x.name}</span>
                            </div>
                            <div className="route-cell">
                                {this.getCorrectlies(x.correctlies, x, index)}
                            </div>
                        </div>)
            }
        </>)
    }

    getCorrectlies(correctlies: LocationQualityPointTimeModel[], model: LocationQualityPointModel, index: number): JSX.Element {
        let massive = [];

        for (let p = 0; p < correctlies.length || p < 10; p++) {
            if (p >= correctlies.length) {
                massive.push(<div key={model.name + '_' + index + '_' + p}
                                  className="cell cell_empty"></div>)
                continue;
            }
            if (correctlies[p].isCorrect) {
                massive.push(<div key={correctlies[p].number + '_' + model.name + '_' + index + '_' + p}
                                  className="cell cell_green"></div>)
                continue;
            }
            massive.push(<div key={correctlies[p].number + '_' + model.name + '_' + index + '_' + p}
                              className="cell cell_red"></div>)
        }
        return <>
            {massive}
        </>
    }
}