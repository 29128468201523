import React, {Component, Suspense} from 'react'
import './administrationPage.scss'
import './containersAdministration.scss';
import {observer} from "mobx-react";
import {NavLink, Route, Routes} from "react-router-dom";
import {Loading} from "../../components/shared/Loading";
import TitleStore from "../../components/authorization/store/TitleStore";
import {AdministrationStore} from "../../components/administration/store/AdministrationStore";
import {SubscriptionComponent} from "../../components/administration/component/SubscriptionComponent";
import {UserSubscriptionComponent} from "../../components/administration/component/UserSubscriptionComponent";
import {ProductComponent} from "../../components/administration/component/ProductComponent";
import {UsersAdminComponent} from "../../components/administration/component/UsersAdminComponent";
import {WordsComponent} from "../../components/administration/component/WordsComponent";
import {ImagesComponent} from "../../components/administration/component/ImagesComponent";

interface Props {

}

@observer
export class AdministrationPage extends Component<Props, {}> {
    private store: AdministrationStore = new AdministrationStore();

    componentDidMount() {
        TitleStore.setTitle("Админка");
    }

    componentWillUnmount() {
        this.store.unInit();
    }

    render() {
        return (<div className="administration">
                <nav className="navsat">
                    {
                        <>
                            <div className="nav-item" id="nav-stat">
                                <span className="chc-span">Покупки пользователей</span>
                                <div className="nav-items">
                                    <NavLink to={"Users"} className="sattxt" id="ns-3label">Пользователи</NavLink>
                                    <NavLink to={"UserPurchases"} className="sattxt" id="ns-3label">Покупки
                                        пользователя</NavLink>
                                    <NavLink to={"UserSubscriptions"} className="sattxt" id="ns-3label">Подписки
                                        пользователя</NavLink>
                                </div>
                            </div>

                            <div className="nav-item" id="nav-an">
                                <span className="chc-span">Продукты и подписки</span>
                                <div className="nav-items">
                                    <NavLink to={"Products"} className="sattxt" id="ns-3label">Продукты</NavLink>
                                    <NavLink to={"Subscriptions"} className="sattxt" id="ns-3label">Подписки</NavLink>
                                </div>
                            </div>

                            <div className="nav-item" id="nav-an">
                                <span className="chc-span">Тренировка</span>
                                <div className="nav-items">
                                    <NavLink to={"Words"} className="sattxt" id="ns-3label">Слова</NavLink>
                                    <NavLink to={"Images"} className="sattxt" id="ns-3label">Картинки</NavLink>
                                </div>
                            </div>
                        </>
                    }
                </nav>
                <div className="containers-administration">
                    <Routes>
                        <Route path="/" element={<Suspense fallback={<Loading/>}>
                            <UsersAdminComponent store={this.store.usersStore}/>
                        </Suspense>}/>
                        <Route path="Users" element={<Suspense fallback={<Loading/>}>
                            <UsersAdminComponent store={this.store.usersStore}/>
                        </Suspense>}/>
                        <Route path="UserSubscriptions" element={<Suspense fallback={<Loading/>}>
                            <UserSubscriptionComponent store={this.store.userSubsStore}/>
                        </Suspense>}/>
                        <Route path="Products" element={<Suspense fallback={<Loading/>}>
                            <ProductComponent store={this.store.productsStore}/>
                        </Suspense>}/>
                        <Route path="Subscriptions" element={<Suspense fallback={<Loading/>}>
                            <SubscriptionComponent store={this.store.subsStore}/>
                        </Suspense>}/>
                        <Route path="Words" element={<Suspense fallback={<Loading/>}>
                            <WordsComponent store={this.store.wordsStore}/>
                        </Suspense>}/>
                        <Route path="Images" element={<Suspense fallback={<Loading/>}>
                            <ImagesComponent store={this.store.imagesStore}/>
                        </Suspense>}/>
                    </Routes>
                </div>
            </div>
        )
    }
}