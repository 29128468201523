import IResultService from "./IResultService";
import {TrainingResultItemModel, TrainingResultModel} from "../model/TrainingResultModel";
import {route} from "../../../helpers/trainingHelp/TrainingRoute";


export default class ImageResultService implements IResultService {
    constructor() {
        this.getItem = this.getItem.bind(this);
        this.GetItems = this.GetItems.bind(this);
    }

    GetItems(training: TrainingResultModel): JSX.Element {
        return <>
            {training.items.map(this.getItem)}
        </>;
    }

    getItem(item: TrainingResultItemModel, position: number): JSX.Element {
        if (item.evenly) {
            return <div className="ans-cell-images res-images" key={position + "_" + item.dictionaryImage}>
                <span className="num-cell" data-title={route(item)}>{position + 1}</span>
                <div className="ans" style={{backgroundImage: 'url(https://powermemory.io' + item.dictionaryImage + ')'}}></div>
                <div className="ans" style={{backgroundImage: 'url(https://powermemory.io' + item.answerImage + ')'}}></div>
            </div>
        }
        return <div className="ans-cell-images res-images" key={position + "_" + item.dictionaryImage}>
            <span className="num-cell num-img-err tooltip" data-title={item.pointName}>{position + 1}</span>
            <div className="ans" style={{backgroundImage: 'url(https://powermemory.io' + item.dictionaryImage + ')'}}></div>
            <div className="ans img-ans" style={{backgroundImage: 'url(https://powermemory.io' + item.answerImage + ')'}}></div>
        </div>
    }

}