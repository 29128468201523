import {CustomComponent} from "../../../helpers/CustomComponent";
import './matryoshkaCheckItemComponent.scss'
import {MatryoshkaItemModel} from "./MatryoshkaStore";
import {observer} from "mobx-react";

interface Props {
    model: MatryoshkaItemModel;
    position: number
}

@observer
export class MatryoshkaCheckItemComponent extends CustomComponent<Props, any> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.getErrorClass = this.getErrorClass.bind(this);
    }

    getErrorClass(errorClass: string): string {
        if (this.props.model.isCorrect === true)
            return "";
        return errorClass;
    }

    render() {
        return <div className={"matryoshka_check_item_component " +
            this.getErrorClass("matryoshka_check_item_component_error")}>
            <div className={"position-cell " +
                this.getErrorClass("position-cell-error")}>{this.props.position + 1}</div>

            <input readOnly={true} className={"ans-input " +
                this.getErrorClass("background-error")}
                   value={this.props.model.word}/>
            {
                this.props.model.isCorrect === false &&
                <input readOnly={true} className={"ans-input " +
                    this.getErrorClass("ans-input-error")}
                       value={this.props.model.answerUser}/>
            }
        </div>;
    }
}