import React, {Component} from 'react'
import './userName.scss'
import AuthorizationStore from "../authorization/store/AuthorizationStore";

interface Props {
    link: string;
    username: string,
    fullname: string,
    rank: string,
    isPremium: boolean
}

export class UserName extends Component<Props, {}> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.opernMnemocard = this.opernMnemocard.bind(this);
    }
    opernMnemocard(){
        AuthorizationStore.openMnemocard(this.props.link)
    }
    render() {
        return (
            <div className="user-name-and-rank" onClick={this.opernMnemocard}>
                <div className="user-name">
                    {this.props.fullname}
                    {this.props.isPremium && <div className="premium-user" data-title="Премиум пользователь"></div>}
                </div>
                <div className="rank">{this.props.rank}</div>
            </div>
        )
    }
}
