import {IMemberTrainingBaseService} from "../../components/trainingBase/service/IMemberTrainingBaseService";
import {NumberMemberTrainingBaseService} from "../../components/trainingBase/service/number/NumberTrainingBaseService";
import {TrainingMemberModel} from "../../components/trainingBase/model/TrainingMemberModel";
import {TrainingMemberRequestItemModel} from "../../components/trainingBase/model/TrainingMemberRequestModel";
import {WordMemberTrainingBaseService} from "../../components/trainingBase/service/words/WordTrainingBaseService";
import {ImageMemberTrainingBaseService} from "../../components/trainingBase/service/image/ImageTrainingBaseService";
import {BinsMemberTrainingBaseService} from "../../components/trainingBase/service/bins/BinsTrainingBaseService";
import {NameMemberTrainingBaseService} from "../../components/trainingBase/service/name/NameTrainingBaseService";
import {CardsMemberTrainingBaseService} from "../../components/trainingBase/service/cards/CardsTrainingBaseService";

export type TrainingMode = 'number' | 'cards' | 'word' | 'image' | 'bins' | 'name';


export function nameMode(mode: TrainingMode){
    switch (mode){
        case "number":
            return "Числа"
        case "cards":
            return "Карты"
        case "word":
            return "Слова"
        case "image":
            return "Картинки"
        case "bins":
            return "Бинарные"
        case "name":
            return "Имена"
    }
}
// ХЗ в чем разница какая лучше...
export function nameMode2(mode: TrainingMode){
    switch (mode){
        case "number":
            return "Числа"
        case "cards":
            return "Карты"
        case "word":
            return "Слова"
        case "image":
            return "Изображения"
        case "bins":
            return "Бинарные"
        case "name":
            return "Имена"
    }
}
export function MemberMode(mode: TrainingMode, model: TrainingMemberModel, answerRequest: (items: TrainingMemberRequestItemModel[]) => void) : IMemberTrainingBaseService{
    switch (mode){
        case "number":
            return new NumberMemberTrainingBaseService(model, answerRequest)
        case "cards":
            return new CardsMemberTrainingBaseService(model, answerRequest)
        case "word":
            return new WordMemberTrainingBaseService(model, answerRequest)
        case "image":
            return new ImageMemberTrainingBaseService(model, answerRequest)
        case "bins":
            return new BinsMemberTrainingBaseService(model, answerRequest)
        case "name":
            return new NameMemberTrainingBaseService(model, answerRequest)
    }
    throw new Error("Member mode")
}