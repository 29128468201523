import React from 'react'
import './topUsersPage.scss'
import {observer} from "mobx-react";
import {Loading} from "../../components/shared/Loading";
import {TopUsersSorting, TopUsersStore} from "../../components/topUsers/TopUsersStore";
import {TopUsersItem} from "../../components/topUsers/TopUsersItem";
import {CustomComponent} from "../../helpers/CustomComponent";
import {TopUserHead} from "../../components/topUsers/TopUserHead";
import TitleStore from "../../components/authorization/store/TitleStore";
import {InputSearchCustom} from "../../components/shared/input/InputSearchCustom";

interface Props {

}

@observer
export class TopUsersPage extends CustomComponent<Props, {}> {
    store: TopUsersStore;

    constructor(props: Readonly<Props>) {
        super(props);
        this.store = new TopUsersStore();
        this.clickHead = this.clickHead.bind(this);
    }

    componentDidMount() {
        TitleStore.setTitle("Баллы всех пользователей");
        this.store.request()
    }

    clickHead(attr: string) {
        this.store.changeSorting(attr as TopUsersSorting);
    }

    render() {
        return <div className="top-users-page">
            <InputSearchCustom changeAndRequest={this.store.changeNameAndRequest}/>

            <div className="results-table-header">
                <div className="td-number">№</div>
                <div className="td-name">Имя</div>
                <TopUserHead title={"Быллы за всё время"}
                             text={"Всё"}
                             className={"td_point_all"}
                             onclick={this.clickHead}
                             customAttr={TopUsersSorting.all}
                             status={this.store.sort === TopUsersSorting.all}/>

                <TopUserHead title={"Быллы за месяц"}
                             text={"Месяц"}
                             className={"td_point_month"}
                             onclick={this.clickHead}
                             customAttr={TopUsersSorting.month}
                             status={this.store.sort === TopUsersSorting.month}/>

                <TopUserHead title={"Быллы за прошлый месяц"}
                             text={"Пр. месяц"}
                             className={"td_point_lastmonth"}
                             onclick={this.clickHead}
                             customAttr={TopUsersSorting.prevMonth}
                             status={this.store.sort === TopUsersSorting.prevMonth}/>

                <TopUserHead title={"День"}
                             text={"День"}
                             className={"td_point_day"}
                             onclick={this.clickHead}
                             customAttr={TopUsersSorting.day}
                             status={this.store.sort === TopUsersSorting.day}/>

                <TopUserHead title={"Пр. день"}
                             text={"Пр. день"}
                             className={"td_point_lastday"}
                             onclick={this.clickHead}
                             customAttr={TopUsersSorting.prevDay}
                             status={this.store.sort === TopUsersSorting.prevDay}/>
            </div>

            <section id="result-lines">
                {
                    !this.store.model.IsLoadedModel() &&
                    <Loading/>
                }
                {
                    this.store.model.Model?.map((x, y) =>
                        <TopUsersItem key={x.idAccount} item={x} pos={y + 1}/>)
                }
            </section>
        </div>
    }
}
