import {makeAutoObservable} from "mobx";
import {SelectorPros} from "../../../shared/selector/CustomSelector";
import LoadingStore from "../../../../helpers/LoadingStore";
import Highcharts from "highcharts";
import {TransitionRateModel, TransitionViewViewModel} from "./TempSpeedEnglishModel";


export default class TempSpeedEnglishStore {
    public selectorCH: SelectorPros;
    public selectorRoute: SelectorPros | undefined;

    public loadingStore: LoadingStore<TransitionRateModel[]>;

    public selectorsCH: SelectorPros[] = [
        {value: "all-ch", title: "Все колоды"},
    ];

    public selectorsCH2: SelectorPros[] = [
        {value: "all-ch", title: "Декодирование в словообразы"},
    ];

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true});
        this.selectorCH = this.selectorsCH.filter(x => x.value === 'all-ch')[0];
        this.loadingStore = new LoadingStore<TransitionRateModel[]>(this.getUrl)
    }

    getUrl(): string {
        return '/api/Statistic/TransitionRate';
    }

    get options(): Highcharts.Options {
        return {
            chart: {
                type: 'area',
                style: {
                    'float': 'right'
                },
            },
            rangeSelector: {
                selected: 0
            },
            title: {
                text: ''
            },
            series: [{
                name: 'Запоминание (мин)',
                type: 'area',
                threshold: null,
                tooltip: {
                    valueDecimals: 0
                },
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, '#4572A7'],
                        [1, 'white']
                    ]
                }
            }],
        };
    }

    setSelectorCH(sel: SelectorPros) {
        this.selectorCH = sel;
    }

    setSelectorRoute(sel: SelectorPros) {
        this.selectorRoute = sel;
    }

    get currentRoute(): TransitionRateModel | undefined {
        if (!this.loadingStore.isLoaded)
            return undefined
        if (this.selectorRoute === undefined)
            return this.loadingStore.get![0];
        return this.loadingStore.get!.filter(x => x.id === this.selectorRoute!.value)[0];
    }

    get current(): TransitionViewViewModel | undefined {
        if (!this.loadingStore.isLoaded && !this.currentRoute)
            return undefined;
        if (this.selectorCH.value === "all-ch")
            return this.currentRoute!.all;
        if (this.selectorCH.value === "kod-ch")
            return this.currentRoute!.withoutTimer;
        return this.currentRoute!.withTimer;
    }

    get getRoutes(): SelectorPros[] {
        return [{
            title: "Глаголы",
            value: ""
        }]
        // if (!this.loadingStore.isLoaded)
        //     return [];
        // return this.loadingStore.get!.map((x) => {
        //     return {
        //         value: x.id,
        //         title: x.name,
        //     }
        // })
    }
}