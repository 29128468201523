import React from 'react'
import './ball.scss'
import {CustomComponent} from "../../helpers/CustomComponent";
import {BallModel} from "./models/BallModel";
import {UserName} from "../user/UserName";
import {Avatar} from "../user/Avatar";
import {pointToString} from "../../helpers/HelperPoints";


export class Ball extends CustomComponent<{ball: BallModel, pos?: number | null}, {}>{
    // constructor(props: Readonly<{ball: BallModel, pos: number}>) {
    //     super(props);
    // }

    render() {
        return (
            <div className="p-a-user">
                <div className="ava-and-position">
                    <Avatar
                        fullname={this.props.ball.fullName}
                        img={this.props.ball.image}
                        link={this.props.ball.link} />
                    {
                        this.props.pos &&
                        <span className="p-a-user__position position">{this.props.pos}</span>
                    }
                </div>
                <UserName link={this.props.ball.link}
                          username={this.props.ball.username}
                          fullname={this.props.ball.fullName}
                          rank={this.props.ball.rank}
                          isPremium={this.props.ball.isPremium} />
                <div className="p-a-points count-balls">{pointToString(this.props.ball.point)}</div>
                {/*model="@item.Point"*/}
            </div>
        )
    }
}
