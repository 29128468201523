import {makeAutoObservable} from "mobx";
import LoadingStore from "../../helpers/LoadingStore";
import {SettingsCodingItemModel, SettingsCodingModel} from "./model/SettingsCodingModel";
import {SetURLSearchParams} from "react-router-dom";
import {GetRequest, GetRequestWithoutResponseBody, PostRequestWithoutResponseBody} from "../../helpers/Request";
import {LoadingUrlModel} from "../../helpers/models/LoadingUrlModel";


export class SettingsCodingItemsStore {
    loadingStore: LoadingStore<SettingsCodingModel>;
    isMK: boolean = true;
    isChange: boolean = true;
    isShowModalChangeImage: boolean = false;
    mode: string;
    type?: string;

    changeImageId?: string;

    models: SettingsCodingItemModel[] | undefined;

    search: URLSearchParams;
    setSearch: SetURLSearchParams;

    getUrl(): string {
        return "/api/SettingsCoding/GetLinking" + this.userId() + "&mode=" + this.mode + "&isMk=" + this.isMK + "&rate=" + this.type;
    }
    setModels(models: SettingsCodingItemModel[] | undefined) {
        this.models = models;
    }

    constructor(mode: string, search: URLSearchParams, setSearch: SetURLSearchParams, type?: string) {
        makeAutoObservable(this, {}, {autoBind: true})
        this.loadingStore = new LoadingStore<SettingsCodingModel>(this.getUrl)
        this.mode = mode;
        this.type = "";
        if (type !== undefined)
            this.type = type;
        this.search = search;
        this.setSearch = setSearch;
        this.setMK(search.get("isMK") === 'true')
    }

    afterUpload(file: LoadingUrlModel | null) {
        if (!file) {
            console.log('ошибка загрузки файла')
        }
        if (!this.changeImageId) {
            console.log('Отсутствует идентификатор')
        }
        PostRequestWithoutResponseBody<ChangeImageCoding>("/api/SettingsCoding/SaveImg", {
            id: this.changeImageId!,
            userId: this.getUserId(),
            img: file!.files[0]!,
        }).then(x => {
            if (x.ok){
                let codings = this.models!.filter(x => x.id === this.changeImageId!);
                if (codings?.length === 1) {
                    codings[0].image = file!.files[0]!;
                }
                this.onChangeImageClosed();
            }
        })
    }

    setMK(isMK: boolean) {
        this.isMK = isMK;
        this.setSearch({'isMK': String(isMK)});
    }

    userId(): string {
        if (this.search.has('userId'))
            return "?userId=" + this.getUserId() + "&";
        return '?';
    }

    getUserId(): string | null {
        if (this.search.has('userId'))
            return this.search.get('userId')!;
        return null;
    }

    setChange(isChange: boolean) {
        this.isChange = isChange;
    }

    fetchRequest() {
        this.loadingStore.fetchRequest()
            .then(x => this.setModels(x.Model?.list));
    }


    restoreAll() {
        GetRequestWithoutResponseBody("/api/SettingsCoding/RestoreAll" + this.userId() + "mode=" + this.mode + "&rate=" + this.type)
            .then(x => {
                this.fetchRequest();
            });
    }

    changeNameSettingsCoding(id: string, name: string) {
        let codings = this.models!.filter(x => x.id === id);
        if (codings?.length === 1) {
            codings[0].name = name;
        }
    }

    clickRestore(id: string) {
        GetRequest<RestoreCoding>("/api/SettingsCoding/restore" + this.userId() + "id=" + id)
            .then(x => {
                this.fetchRequest();
            })
            .then(x => console.log(x))
    }

    get hasRowMK(): boolean{
        if (this.mode === "9"  || this.mode === "99"  || this.mode === "999")
            return true;
        return false;
    }

    onChangeImage(id: string) {
        this.isShowModalChangeImage = true;
        this.changeImageId = id;
    }

    onChangeImageClosed() {
        this.isShowModalChangeImage = false;
        this.changeImageId = undefined;
    }

    getNameChangeImage() {
        let model = this.models?.filter(x => x.id === this.changeImageId);
        if (model && model.length === 1)
            return model[0].name;
        return "";
    }
}

interface RestoreCoding {
    id: string;
    userId: string | null;
}

interface RestoreCoding {
    id: string;
    userId: string | null;
}

interface ChangeImageCoding {
    id: string;
    img: string;
    userId: string | null;
}