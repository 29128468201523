import React, {Component} from 'react'
import TrainingChangeBaseModel from "../store/TrainingChangeBaseModel";
import {observer} from "mobx-react";
import CustomSelector, {SelectorGroupPros, SelectorPros} from "../../shared/selector/CustomSelector";
import {CountElementsView} from "./CountElementsView";
import {DisHelperView} from "./DisHelperView";

export interface Props {
    changeModel: TrainingChangeBaseModel;
}

@observer
export class NumberView extends Component<Props, {}> {
    private selectGroups: SelectorGroupPros[] = [
        {
            title: 'Кратная двум', selectors: [
                {value: '8', title: 'ХХ XX ХХ XX(8)'},
                {value: '9', title: 'ХХ XX ХХ XX XX(10)'},
                {value: '10', title: 'ХХXX(4)'},
                {value: '11', title: 'ХХXXXX(6)'},
                {value: '12', title: 'ХХXXXXXX(8)'},
                {value: '13', title: 'ХХXXXXXXXX(10)'},
                {value: '14', title: 'ХХXX XX(6)'},
                {value: '15', title: 'ХХ XXXX(6)'},
            ],
        },
        {
            title: 'Кратная трём', selectors: [
                {value: '16', title: 'XXXХXX(6)'},
                {value: '17', title: 'ХХXXXXXXX(9)'},
            ],
        },
        {
            title: 'Строка', selectors: [
                {value: '18', title: 'XХХХХХ...(39)'},
                {value: '19', title: 'XХХ ХХХ...(39)'},
                {value: '20', title: 'ХХХХХХ...(40)'},
                {value: '21', title: 'ХХ ХХ ХХ...(40)'},
                {value: '22', title: 'ХХХХ ХХХХ...(40)'},
            ],
        }
    ]
    constructor(props: Readonly<Props>) {
        super(props);
        this.setComplexity = this.setComplexity.bind(this);
        this.setElementAtGo = this.setElementAtGo.bind(this);
        this.changeSelector = this.changeSelector.bind(this);
        this.changeGroupAndElements = this.changeGroupAndElements.bind(this);
    }

    setComplexity(e: React.MouseEvent<HTMLDivElement>) {
        this.props.changeModel.changeComplexity(e.currentTarget.getAttribute("custom") ?? "2");
        if (this.props.changeModel.model.complexity === '1' && this.props.changeModel.hasGroupsElementsStart()){
            this.props.changeModel.changeElementAtGo('1');
        }
    }

    setElementAtGo(e: React.MouseEvent<HTMLDivElement>) {
        this.props.changeModel.changeElementAtGo(e.currentTarget.getAttribute("custom") ?? "2");
    }

    changeGroupAndElements(){
        if (this.props.changeModel.hasGroupsElementsStart()){
            this.props.changeModel.changeElementAtGo("4");
        }else{
            this.props.changeModel.changeElementAtGo("2");
        }
    }

    changeSelector(sel: SelectorPros | undefined) {
        this.props.changeModel.changeElementAtGo(sel?.value ?? "2");
    }

    getComplexityClassName(complexity: string): string {
        if (this.props.changeModel.model.complexity === complexity) {
            return "elements element1 point complexityModeActivity"
        }
        return "elements element1 point"
    }

    getElementAtGoClassName(elementOfTime: number): string {
        if (this.props.changeModel.model.elementAtGo === elementOfTime) {
            return "block-number point complexityModeActivity"
        }
        return "block-number point"
    }

    render() {
        return (
            <div className="number-training-default">
                <div className="complexity-training-default">
                    <div custom="1" className={this.getComplexityClassName("1")} onClick={this.setComplexity}>
                        <span>0-9</span>
                    </div>
                    <div custom="2" className={this.getComplexityClassName("2")} onClick={this.setComplexity}>
                        <span>00-99</span>
                    </div>
                    <div custom="3" className={this.getComplexityClassName("3")} onClick={this.setComplexity}>
                        <span>000-999</span>
                    </div>
                </div>

                <div id="number-view">
                    <input id="atonce" type="radio" name="view-elements" value="2"/>
                    <div className="change-group-number" onClick={this.changeGroupAndElements}>
                        {
                            this.props.changeModel.hasGroupsElementsStart() &&
                            <label id="atonce-label" className="point">Показ
                                элементов за раз</label>
                        }
                        {
                            !this.props.changeModel.hasGroupsElementsStart() &&
                            <label id="grouping-number-label" className="point">Группировка
                                цифр</label>
                        }
                        <label id="view-1-label"></label>
                    </div>
                    {
                        this.props.changeModel.hasGroupsElementsStart() &&
                        <div className="count-element-time">
                            <div id="number-view1-label"
                                 onClick={this.setElementAtGo}
                                 custom='1'
                                 className={this.getElementAtGoClassName(1)}>1
                            </div>
                            {
                                this.props.changeModel.model.complexity !== '1' &&
                                <>
                                    <div id="number-view2-label"
                                         onClick={this.setElementAtGo}
                                         custom='2'
                                         className={this.getElementAtGoClassName(2)}>2
                                    </div>
                                    <div id="number-view3-label"
                                         onClick={this.setElementAtGo}
                                         custom='3'
                                         className={this.getElementAtGoClassName(3)}>3
                                    </div>
                                </>
                            }

                        </div>
                    }
                    {
                        !this.props.changeModel.hasGroupsElementsStart() &&
                        <CustomSelector defaultValue={this.props.changeModel.model.elementAtGo?.toString() ?? '2'}
                                        groups={this.selectGroups}
                                        width="190px"
                                        onChange={this.changeSelector}/>
                    }
                    <CountElementsView changeModel={this.props.changeModel} />
                    <DisHelperView changeModel={this.props.changeModel} />
                </div>
            </div>
        )
    }
}