import {CustomComponent} from "../CustomComponent";
import React from "react";
import './bottomBlockComponent.scss';


interface Props{
    children: React.ReactNode;
    className?: string;
}
export class BottomBlockComponent extends CustomComponent<Props, any>
{
    render() {
        return <div className={"bottomBlockComponent " + this.props.className}>
            {this.props.children}
        </div>;
    }
}