import {TrainingAnswerModel} from "../../model/TrainingAnswerModel";
import {TrainingAnswerRequestItemDto} from "../../model/TrainingAnswerRequestModel";
import React from "react";
import {makeAutoObservable} from "mobx";
import {IAnswerTrainingBaseService} from "../IMemberTrainingBaseService";

export class ImageTrainingAnswerBaseService implements IAnswerTrainingBaseService {
    private chunkSize = 30;
    model: TrainingAnswerModel;
    items: TrainingAnswerRequestItemDto[] = [];
    lastMouseItem: TrainingAnswerRequestItemDto;

    currentNumber: number = 0;
    currentPageNumber: number = 0;
    maxPageNumber: number = 0;

    constructor(model: TrainingAnswerModel, items: TrainingAnswerRequestItemDto[]) {
        makeAutoObservable(this, {}, {autoBind: true})

        this.model = model;
        this.updateChunk(items);
        this.lastMouseItem = items[0];
        this.currentPageNumber = 0;
        this.maxPageNumber = Math.ceil((items.length - 1) / this.chunkSize) - 1;
        this.initPage();
    }
    func() {
        return 0.5 - Math.random();
    }
    updateChunk(items: TrainingAnswerRequestItemDto[]) {
        for (let i = 0; i < items.length; i += this.chunkSize) {
            const chunk = items.slice(i, i + this.chunkSize);
            chunk.sort(this.func);
            this.items.push(...chunk)
        }
        console.log(this.items);
    }

    addBlock(pos: number) {
        for (let x = this.items.length - 1; x > pos; x--) {
            if (x - 1 === pos) {
                this.items[x].answer = "";
                this.items[x].answerImage = "";
                continue;
            }
            if (x === this.items.length - 1) {
                this.returnAnswer(this.items[x].id);
            }
            this.items[x].answer = this.items[x - 1].answer;
            this.items[x].answerImage = this.items[x - 1].answerImage;
        }
    }

    deleteBlock(pos: number) {
        for (let x = pos; x < this.items.length; x++) {
            if (x === pos) {
                this.returnAnswer(this.items[x].id);
            }
            if (x === this.items.length - 1) {
                this.items[x].answer = "";
                this.items[x].answerImage = "";
                return;
            }
            this.items[x].answer = this.items[x + 1].answer;
            this.items[x].answerImage = this.items[x + 1].answerImage;
        }
    }

    get view(): JSX.Element {
        return <></>
    }

    get currentItemsPage() {
        return this.items.slice(this.currentPageNumber * this.chunkSize, (this.currentPageNumber + 1) * this.chunkSize);
    }

    get current() {
        return this.currentNumber + this.currentPageNumber * this.chunkSize;
    }

    initPage() {
        this.lastMouseItem = this.currentItemsPage[0];
        this.currentNumber = -1;
        this.nextCurrentNumber();
    }

    next() {
        this.nextCurrentNumber();
    };

    prev() {
        this.prevCurrentNumber();
    };

    setLastMouseItem(id: string) {
        this.lastMouseItem = this.items.filter(x => x.id === id)[0]
    }

    clickAnswer(id: string) {
        let currentAnswerImage = this.items.filter(x => x.id === id)[0];
        if (currentAnswerImage.setAnswer)
            return;
        if (this.items[this.current].answerImage){
            this.returnAnswer(this.items[this.current].id);
        }
        this.items[this.current].answerImage = currentAnswerImage.dictionaryImage;
        currentAnswerImage.setAnswer = true;
        this.nextCurrentNumber();
    }

    returnAnswer(id: string) {
        let item = this.items.filter(x => x.id === id)[0];
        let dicts = this.items.filter(x => x.dictionaryImage === item.answerImage);
        if (dicts.length !== 1)
            return;
        dicts[0].setAnswer = false;
        item.answerImage = undefined;
    }

    nextCurrentNumber() {
        if (this.currentItemsPage.filter(x => x.answerImage === undefined).length === 0)
            return;
        this.currentNumber++;
        if (this.currentNumber >= this.currentItemsPage.length) {
            this.currentNumber = -1;
            this.nextCurrentNumber();
        } else {
            if (this.currentItemsPage[this.currentNumber].answerImage)
                this.nextCurrentNumber();
        }
    }

    prevCurrentNumber() {
        if (this.currentItemsPage.filter(x => x.answerImage === undefined).length === 0)
            return;
        this.currentNumber--;
        if (this.currentNumber < 0) {
            this.currentNumber = this.currentItemsPage.length;
            this.prevCurrentNumber();
        } else {
            if (this.currentItemsPage[this.currentNumber].answerImage)
                this.prevCurrentNumber();
        }
    }

    nextPage() {
        if (this.currentPageNumber < this.maxPageNumber) {
            this.currentPageNumber++;
            this.initPage();
        }
    }

    prevPage() {
        if (this.currentPageNumber >= 0) {
            this.currentPageNumber--;
            this.initPage();
        }
    }

    setCurrentNumber(setCurrentNumber: number) {
        this.currentNumber = setCurrentNumber;
    }

    get viewCustom(): JSX.Element | undefined {
        return <div className="ans_area" id="ans_area_images">
            <div className="ans_area__pages">
                <div className="ans_area__page">
                    <div className="ans_area__base_entry base_entry">
                        <div className="ans_area__row clearfix">
                            <div className="ans_area__answers clearfix">
                                {
                                    this.currentItemsPage.map((x, index) => (
                                        <div key={x.id} className="ans_cell__container cell__container">
                                            {/*ans_cell-select*/}
                                            <div
                                                className={"cell ans_cell cell-empty " + (this.currentNumber === index ? "ans_cell-select" : "")}
                                                data-index={index}>
                                                <div className="ans_cell__title">
                                                    <div onClick={() => this.deleteBlock(index)}
                                                         className="ans_cell__control_button ans_cell__del_cell"
                                                         title="Удалить со сдвигом влево">-
                                                    </div>
                                                    <span className="ans_cell__index"
                                                          data-title={x.routeName ?? "Нет данных о локации"}>{index + 1}</span>
                                                    <div onClick={() => this.addBlock(index)}
                                                         className="ans_cell__control_button ans_cell__add_cell"
                                                         title="Добваить ячейку">
                                                    </div>
                                                </div>
                                                <div className="ans_cell__card cell__card" onClick={() => {
                                                    this.returnAnswer(x.id);
                                                    this.setCurrentNumber(index);
                                                }}>
                                                    {
                                                        x.answerImage &&
                                                        <div className="card"
                                                             onMouseEnter={() => this.setLastMouseItem(x.id)}
                                                             data-ans={x.answerImage}
                                                             style={{backgroundImage: 'url(https://powermemory.io/' + x.answerImage + ')'}}>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            {/*// <!-- Viewer -->*/}
                            <div className="ans_area__viewer">
                                <div className="viewer"
                                     style={{backgroundImage: 'url(https://powermemory.io/' + this.lastMouseItem.dictionaryImage + ')'}}></div>
                            </div>
                        </div>
                        <div className="ans_area__row clearfix">
                            {/*// <!-- Нижнее поле -->*/}
                            <div className="ans_area__storage">
                                {
                                    this.currentItemsPage.map((x, index) => (
                                        <div key={x.id} className="storage_cell__container cell__container"
                                             onClick={() => this.clickAnswer(x.id)}>
                                            <div className="cell storage_cell" data-index={index}>
                                                <div className="storage_cell__card cell__card">
                                                    {
                                                        !x.setAnswer &&
                                                        <div className="card"
                                                             onMouseEnter={() => this.setLastMouseItem(x.id)}
                                                             data-ans={x.dictionaryImage}
                                                             style={{backgroundImage: 'url(https://powermemory.io' + x.dictionaryImage + ')'}}>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            {/*// <!-- Контролы -->*/}
                            <div className="ans_area__controls controls">
                                <div className="controls__component">
                                    <div className="switch_page">
                                        <div onClick={this.prevPage}
                                             className="switch_page__button switch_page__prev">Предыдущая страница
                                        </div>
                                        <div
                                            className="switch_page__info">{this.currentPageNumber + 1} / {this.maxPageNumber + 1}</div>
                                        <div onClick={this.nextPage}
                                             className="switch_page__button switch_page__next">Следующая страница
                                        </div>
                                    </div>
                                </div>
                                <div className="controls__component">
                                    <button onClick={this.nextCurrentNumber}
                                            className="select_next controls__button">Следующая ячейка
                                    </button>
                                </div>
                                <div className="controls__component">
                                    <button onClick={this.prevCurrentNumber}
                                            className="select_prev controls__button">Предыдущая ячейка
                                    </button>
                                </div>
                                {/*<div className="controls__component">*/}
                                {/*    <button className="to_quick_entry controls__button">Быстрый ввод</button>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                    {/*// <!-- Быстый ввод -->*/}
                    <div className="ans_area__quick_entry quick_entry" style={{display: 'none'}}>
                        <div className="quick_entry__row quick_entry__first_row clearfix ">
                            <div className="quick_entry__cells">
                                <div className="quick_entry__cell_container">
                                    <div className="quick_entry__cell" data-index="0"></div>
                                </div>
                                <div className="quick_entry__cell_container">
                                    <div className="quick_entry__cell" data-index="1"></div>
                                </div>
                                <div className="quick_entry__cell_container">
                                    <div className="quick_entry__cell" data-index="2"></div>
                                </div>
                                <div className="quick_entry__cell_container">
                                    <div className="quick_entry__cell" data-index="3"></div>
                                </div>
                                <div className="quick_entry__cell_container">
                                    <div className="quick_entry__cell" data-index="4"></div>
                                </div>
                                <div className="quick_entry__cell_container">
                                    <div className="quick_entry__cell" data-index="5"></div>
                                </div>
                                <div className="quick_entry__cell_container">
                                    <div className="quick_entry__cell" data-index="6"></div>
                                </div>
                                <div className="quick_entry__cell_container">
                                    <div className="quick_entry__cell" data-index="7"></div>
                                </div>
                                <div className="quick_entry__cell_container">
                                    <div className="quick_entry__cell" data-index="8"></div>
                                </div>
                                <div className="quick_entry__cell_container">
                                    <div className="quick_entry__cell" data-index="9"></div>
                                </div>
                                <div className="quick_entry__cell_container">
                                    <div className="quick_entry__cell" data-index="10"></div>
                                </div>
                                <div className="quick_entry__cell_container">
                                    <div className="quick_entry__cell" data-index="11"></div>
                                </div>
                                <div className="quick_entry__cell_container">
                                    <div className="quick_entry__cell" data-index="12"></div>
                                </div>
                                <div className="quick_entry__cell_container">
                                    <div className="quick_entry__cell" data-index="13"></div>
                                </div>
                                <div className="quick_entry__cell_container">
                                    <div className="quick_entry__cell" data-index="14"></div>
                                </div>
                                <div className="quick_entry__cell_container">
                                    <div className="quick_entry__cell" data-index="15"></div>
                                </div>
                            </div>
                        </div>
                        <div className="quick_entry__row quick_entry__last_row clearfix">
                            <div className="quick_entry__cells">
                                <div className="quick_entry__cell_container">
                                    <div className="quick_entry__cell" data-index="16"></div>
                                </div>
                                <div className="quick_entry__cell_container">
                                    <div className="quick_entry__cell" data-index="17"></div>
                                </div>
                                <div className="quick_entry__cell_container">
                                    <div className="quick_entry__cell" data-index="18"></div>
                                </div>
                                <div className="quick_entry__cell_container">
                                    <div className="quick_entry__cell" data-index="19"></div>
                                </div>
                                <div className="quick_entry__cell_container">
                                    <div className="quick_entry__cell" data-index="20"></div>
                                </div>
                                <div className="quick_entry__cell_container">
                                    <div className="quick_entry__cell" data-index="21"></div>
                                </div>
                                <div className="quick_entry__cell_container">
                                    <div className="quick_entry__cell" data-index="22"></div>
                                </div>
                                <div className="quick_entry__cell_container">
                                    <div className="quick_entry__cell" data-index="23"></div>
                                </div>
                                <div className="quick_entry__cell_container">
                                    <div className="quick_entry__cell" data-index="24"></div>
                                </div>
                                <div className="quick_entry__cell_container">
                                    <div className="quick_entry__cell" data-index="25"></div>
                                </div>
                                <div className="quick_entry__cell_container">
                                    <div className="quick_entry__cell" data-index="26"></div>
                                </div>
                                <div className="quick_entry__cell_container">
                                    <div className="quick_entry__cell" data-index="27"></div>
                                </div>
                                <div className="quick_entry__cell_container">
                                    <div className="quick_entry__cell" data-index="28"></div>
                                </div>
                                <div className="quick_entry__cell_container">
                                    <div className="quick_entry__cell" data-index="29"></div>
                                </div>
                            </div>
                            <div className="quick_entry__controls controls">
                                <div className="controls__component switch_page__contaner">
                                    <div className="switch_page">
                                        <button className="switch_page__button switch_page__prev">Предыдущая страница
                                        </button>
                                        <div className="switch_page__info">1 / 1</div>
                                        <button className="switch_page__button switch_page__next">Следующая страница
                                        </button>
                                    </div>
                                </div>
                                <div className="controls__component ans_preview__container">
                                    <div className="ans_preview">
                                        <div className="ans_preview__title">
                                            <button
                                                className="ans_preview__control_button quick_entry__select_prev">Предыдущая
                                                ячейка
                                            </button>
                                            <span className="ans_preview__index">1</span>
                                            <button
                                                className="ans_preview__control_button quick_entry__select_next">Следующая
                                                ячейка
                                            </button>
                                        </div>
                                        <div className="ans_preview__card ans_preview__card"></div>
                                    </div>
                                </div>
                                <div className="controls__component to_base_entry__container">
                                    <button className="to_base_entry controls__button">Назад</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}