import {CustomComponent} from "../../helpers/CustomComponent";
import React, {ChangeEvent} from "react";
import {PointViewModel} from "./model/RouteModel";
import {DraggableProvided} from "react-beautiful-dnd";

export interface PropsRoute{
    point: PointViewModel;
    pos: number;
    provided: DraggableProvided;
    changeNamePoint: (pos: number, val: string) => void;
    createPoint: (pos: number) => void;
    deletePoint: (pos: number) => void;
}
export class RouteItem extends CustomComponent<PropsRoute, {}> {
    constructor(props: Readonly<PropsRoute>) {
        super(props);
        this.changeName = this.changeName.bind(this);
        this.createPoint = this.createPoint.bind(this);
        this.deletePoint = this.deletePoint.bind(this);
    }

    changeName(ex: ChangeEvent<HTMLInputElement>){
        this.props.changeNamePoint(this.props.pos, ex.target.value)
    }
    createPoint(){
        this.props.createPoint(this.props.pos + 1)
    }
    deletePoint(){
        this.props.deletePoint(this.props.pos)
    }

    render() {
        return <div className="sortable route-item"
                   ref={this.props.provided.innerRef}
                   {...this.props.provided.draggableProps}
                   {...this.props.provided.dragHandleProps}>
            <div className="point_pos point_item"><span className="first-point">{this.props.pos + 1}</span></div>
            <div className="point_description point_item">
                <input className="point" type="text"
                       defaultValue={this.props.point.name} onChange={this.changeName}/>
                <span className="idpoint">{this.props.pos}</span>
                <div className="action-route">
                    <div className="add-loci-mk add-loc"
                         onClick={this.createPoint}></div>
                    <div className="del-i-route rem-loc"
                         onClick={this.deletePoint}></div>
                </div>
            </div>
        </div>
    }
}