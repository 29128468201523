import {SelectorPros} from "../shared/selector/CustomSelector";
import {ProductDto} from "./models/ProductDto";


export const coursesStore: SelectorPros[] = [{
    title: "USD (доллар США)",
    value: "usd",
}, {
    title: "RUB (российский рубль)",
    value: "rub",
}, {
    title: "BYN (беларусский рубль)",
    value: "byn",
}, {
    title: "KZT (казахский тенге)",
    value: "kzt",
}, {
    title: "UAH (украинская гривна)",
    value: "uah",
}, {
    title: "EUR (евро)",
    value: "eur",
}, {
    title: "AED (дирхам ОАЭ)",
    value: "aed",
}];


export const products: ProductDto[] = [
    {
        id: 1,
        titleHtml: 'Полный курс мнемотехники \n Групповое обучение',
        description: 'Встраиваем навык запоминания, для того чтобы ты смог' +
            'запоминать любую информацию максимально быстро, надежно и легко.',
        costNow: 150,
        costOld: 200,
        link: '/Course',
        image: 'https://powermemory.io/images/marketing/shop/1.jpeg',
        // image: '',
    },
    {
        id: 2,
        titleHtml: 'Полный курс мнемотехники \nИндвидуальное обучение',
        description: 'Сверхпрограмма. Работа до результата. Один на один.\n' +
            'Гибкий график. Особый подход.\n' +
            'Решение твоих задач запоминания.',
        costNow: 450,
        costOld: 500,
        link: '/Course',
        additionalClassName: 'block-administration-gold',
        image: 'https://powermemory.io/images/marketing/shop/2.jpeg',
    },
    {
        id: 3,
        titleHtml: 'Индивидуальный курс \nдля школьников',
        description: 'Курс адаптируется конкретно под вашего ребенка и его школьную программу.\n' +
            'Качественное, экологичное и комфортное обучение. 8+',
        costNow: 540,
        costOld: undefined,
        link: '/Course',
        image: 'https://powermemory.io/images/marketing/shop/3.jpg',
    },
    {
        id: 4,
        titleHtml: 'Экспресс-обучение \n"Как это запомнить?"',
        description: 'Завтра экзамен? Пройти полный курс мнемотехники уже не успеешь.\n' +
            'Решение: я описываю все мысленные операции\n' +
            'и ты запоминаешь то, что нужно.',
        costNow: 30,
        customCostNow: ' / ЧАС',
        costOld: undefined,
        link: '/Course',
        image: 'https://powermemory.io/images/marketing/shop/4.jpeg',
    },
    {
        id: 5,
        titleHtml: 'Корпоративный курс для продвинутых компаний',
        description: 'Тратите деньги на курсы по саморазвитию для своих работников,\n' +
            'а вместо развития они получают сертификаты?\n' +
            'Обучите их мнемотехнике,\n' +
            'чтобы любой курс усваивался на 100%',
        customCostNow: 'Стоимость договорная',
        link: '/Course',
        image: 'https://powermemory.io/images/marketing/shop/5.jpg',
    },
    {
        id: 6,
        titleHtml: 'Открытый урок "Мнемотехника. \nБыстрый старт"',
        description: 'Ты уже способен запоминать в 10 - 100 раз быстрее!\n' +
            'Твоя память - суперкар. Начни учиться управлению на\n' +
            'бесплатном открытом уроке от Алекса Грука.',
        customCostNow: 'СКОРО (ЗАПИШИСЬ)',
        link: 'https://t.me/PowerMemoryIO_bot?start=64a3fb9cebcbea947407df17',
        image: 'https://powermemory.io/images/marketing/shop/6.jpg',
    }
]

// Повышение качества тренировок на сайте
export const improvingQuality: ProductDto[] = [
    {
        id: 7,
        titleHtml: 'Premium подписка',
        description: 'Тренируйся без рекламы, с полной статистикой,\n' +
            'дополнительными тренажёрами и персональными настройками',
        costNow: 24.95,
        costOld: 60,
        customCostNow: ' / ГОД',
        link: '/premiumbuy',
        image: 'https://powermemory.io/images/marketing/shop/7.jpg',
    },
    {
        id: 8,
        titleHtml: 'Образные коды 000-999',
        description: 'За 8 часов тренировки на специальном тренажере ты загрузишь в память\n' +
            'лучшие образные коды на трёхзначные числа и сможешь ими пользоваться на практике',
        costNow: 49.95,
        costOld: 100,
        link: '/purchases/BuyCodes',
        image: 'https://powermemory.io/images/marketing/shop/8.jpg',
    },
]
export const additionalCourse: ProductDto[] = [
    {
        id: 9,
        titleHtml: 'Система супер продуктивности [предзапись]',
        description: 'Тебя никто не сможет\n' +
            'остановить на пути к твоим амбициозным целям. С этой системой ты гарантированно дойдёшь до конца! 16+',
        link: 'https://t.me/alexgruk',
        image: 'https://powermemory.io/images/marketing/shop/9.jpg',
        customHtml: '<div class="cost-prod-now cpn-multi"><b>150$</b> - первые 10<br><b>200$</b> - вторые 10<br><b>300$</b> - другие</div>'
    },
    {
        id: 10,
        titleHtml: 'Модель эффективной коммуникации',
        description: 'Как с гарантией достигать любых целей при общении с людьми и строить выгодные взаимоотношения.\n' +
            '\n - ораторское искусство' +
            '\n - эриксоновский гипноз' +
            '\n - нейролингвистическое программирование 18+',
        costNow: 990,
        customCostOld: '(для мнемонистов)',
        link: 'https://t.me/alexgruk',
        image: 'https://powermemory.io/images/marketing/shop/10.jpg',
    },
]