import {CustomComponent} from "../../../../helpers/CustomComponent";
import React from "react";
import {nameMode2, TrainingMode} from "../../../../helpers/trainingHelp/TrainingMode";

interface Props {
    mode: TrainingMode;
    currentMode: TrainingMode;
    clickMode: (mode: TrainingMode) => void;
}

export class ModeTrainingComponent extends CustomComponent<Props, {}> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.clickMode = this.clickMode.bind(this);
    }

    clickMode() {
        this.props.clickMode(this.props.mode);
    }

    get getClassNameMode(): string {
        if (this.props.mode === this.props.currentMode) {
            return 'regime-select complexityRegimeActivity';
        }
        return 'regime-select'
    }

    render() {
        return <div className={this.getClassNameMode} onClick={this.clickMode}>
            <div className={'sim-label results-' + this.props.mode + '-label'}>
                {nameMode2(this.props.mode)}
            </div>
        </div>;
    }
}