import {observer} from "mobx-react";
import {CustomComponent} from "../../helpers/CustomComponent";
import React from "react";
import {PackageLanguageViewModel} from "./model/PackageTrainingCodingViewModel";

interface Props {
    model: PackageLanguageViewModel,
    onClick: (id: bigint) => void;
}

@observer
export class ContainerStageMain extends CustomComponent<Props, any> {

    constructor(props: Readonly<Props>) {
        super(props);
        this.click = this.click.bind(this);
    }

    click() {
        this.props.onClick(this.props.model.id);
    }

    render() {
        return <div className="container-step" key={this.props.model.id} onClick={this.click}>
            <div className="container-step-title">{this.props.model.title}</div>
            <div className="container-step-description">{this.props.model.description}</div>
            <img src={this.props.model.image} alt={this.props.model.title}/>
            <div>{20}</div>
        </div>;
    }
}