import {makeAutoObservable} from "mobx";
import {GetRequest, PostRequest} from "../../helpers/Request";
import {LoadingModel} from "../shared/LoadingModel";
import {UniversityFinishModel, UniversityTrainingModel} from "./models/UniversityTrainingModel";
import {MatryoshkaModel} from "../university/matryoshka/MatryoshkaModel";


export class UniversityTrainingStore {
    private id: string | undefined;

    model: LoadingModel<UniversityTrainingModel> = LoadingModel.createNotLoaded();
    finishModel: LoadingModel<UniversityFinishModel> = LoadingModel.createNotLoaded();
    state: UniversityTrainingState = UniversityTrainingState.recommendations;

    private matryoshkaModel: MatryoshkaModel | undefined;

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true})
    }

    getOrCreateMatryoshka(): MatryoshkaModel {
        if (this.matryoshkaModel !== undefined)
            return this.matryoshkaModel;

        this.matryoshkaModel = {
            id: this.model.Model!.id!,
            countRetries: 0,
            items: this.model?.Model?.sequenceWords.map((x) => {
                return {
                    id: x.id,
                    word: x.word,
                    hjighlight: x.hjighlight
                }
            }) ?? []
        }
        return this.matryoshkaModel;
    }

    init(id: string) {
        this.id = id;
        this.request();
    }

    unInit() {

    }

    async request() {
        this.model = await GetRequest<UniversityTrainingModel>(`/api/UniversityTraining/Training/${this.id}`);
    }

    async finishRequest(requestModel: MatryoshkaModel) {
        this.finishModel = await PostRequest<UniversityTrainingModel, MatryoshkaModel>(`/api/UniversityTraining/Finish`,
            requestModel);
    }

    finishRecommendation(){
        this.state = UniversityTrainingState.training;
    }

    finishTraining(model: MatryoshkaModel){
        this.state = UniversityTrainingState.finish;
        this.finishRequest(model);
    }
}

export enum UniversityTrainingState {
    recommendations = 1,
    training = 2,
    finish = 3,
}