import {CustomComponent} from "../CustomComponent";
import './taskComponent.scss'
import {observer} from "mobx-react";
import {TitleComponent, TypePositionText, TypeTitle} from "../title/TitleComponent";
import {CustomButton} from "../CustomButton";
import {UniversityTaskModel} from "../../components/university/models/UniversityTaskModel";
import {Link} from "react-router-dom";
import {UniversityStateTask} from "../../components/university/models/UniversityStateTask";
import {UniversityTypeTask} from "../../components/university/models/UniversityTypeTask";
import {ImageComponent} from "../img/ImageComponent";

interface Props {
    task: UniversityTaskModel
}

@observer
export class TaskComponent extends CustomComponent<Props, any> {
    render() {
        return <Link to={"/universityTraining/" + this.props.task.id} className="task-component">
            <div className="task-component_block_complexity">
                {
                    // this.props.hasComplexity === true &&
                    // <>
                    //     <div className="task-component_point"></div>
                    //     <div className="task-component_complexity">{this.props.complexity?.toLowerCase()}</div>
                    // </>
                }
            </div>
            <TitleComponent text={this.props.task.name}
                            typeTitle={TypeTitle.taskTitle}
                            typePosition={TypePositionText.center}/>
            <ImageComponent src={this.props.task.image} alt="какакая-та картирк"/>

            {
                this.props.task.state === UniversityStateTask.success &&
                <>
                    <div className="task-component_state task-component_success">
                        Выполнено
                    </div>
                    <CustomButton text={"Повторить"} className="task-component_button"/>
                </>
            }
            {
                this.props.task.state === UniversityStateTask.wait && this.props.task.type === UniversityTypeTask.task &&
                <>
                    <div className="task-component_state">
                        Не выполнено
                    </div>
                    <CustomButton text={"Начать"} className="task-component_button task-component_button_open"/>
                </>
            }
            {
                this.props.task.state === UniversityStateTask.wait && this.props.task.type === UniversityTypeTask.read &&
                <>
                    <div className="task-component_state">
                        Не выполнено
                    </div>
                    <CustomButton text={"Читать"} className="task-component_button task-component_button_open"/>
                </>
            }
        </Link>;
    }
}