import {UserSubscriptionView} from "../model/ProductView";
import {LoadingModel} from "../../shared/LoadingModel";
import {IAdministrationStore} from "./IAdministrationStore";
import {AdministrationRepository} from "../repository/AdministrationRepository";
import {BaseAdministrationStore} from "./BaseAdministrationStore";

export class UserSubscriptionStore implements IAdministrationStore<UserSubscriptionView> {
    private repository = new AdministrationRepository();
    baseStore = new BaseAdministrationStore<UserSubscriptionView>(this);

    constructor() {
    }

    requestLocal(): Promise<LoadingModel<UserSubscriptionView[]>> {
        return this.repository.userSubscriptions();
    }
}