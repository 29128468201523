import {CustomComponent} from "../../../helpers/CustomComponent";
import {CustomButton} from "../../../helpers/CustomButton";
import React from "react";
import {observer} from "mobx-react";
import {observable} from "mobx";

interface Props {
    answer: string;
    checkAnswer: boolean | undefined;
    click: (answer: string) => boolean | undefined;
    disabled?: boolean;
}

@observer
export class ChooseAnswerTrainingLearnLanguage extends CustomComponent<Props, any> {
    @observable
    checkAnswer: boolean | undefined;
    @observable
    classAnswer: string = "";

    constructor(props: Readonly<Props>) {
        super(props);
        this.click = this.click.bind(this);
    }

    click() {
        this.checkAnswer = this.props.click(this.props.answer);
        this.classAnswer = this.validAnswer;
        this.render();
    }

    get validAnswer() {
        if (this.checkAnswer === true) {
            return "correct-answer"
        }
        if (this.checkAnswer === false) {
            return "bad-answer"
        }
        return "";
    }

    get disableAnswer() {
        if (this.props.disabled === true) {
            return "disabled"
        }
        return "";
    }

    render() {
        return <CustomButton className={"choose-answer " + this.classAnswer + " " + this.disableAnswer}
                             onclick={this.click}
                             text={this.props.answer}/>;
    }
}