import {makeAutoObservable} from "mobx";
import {LoadingModel} from "../shared/LoadingModel";
import {PointViewModel, RouteViewModel} from "./model/RouteModel";
import {GetRequest, PostRequest} from "../../helpers/Request";
import KeyboardManager from "../../core/keyboardManager/adapters/KeyboardManager";
import AuthorizationStore from "../authorization/store/AuthorizationStore";
import {ReactModalStore} from "../modal/ReactModalStore";


export class RouteStore {
    reactModalDeleteStore: ReactModalStore = new ReactModalStore();
    reactModalLoadingFileStore: ReactModalStore = new ReactModalStore();

    model: LoadingModel<RouteViewModel[]> = LoadingModel.createLoaded();
    currentModel: RouteViewModel | undefined;
    currentRoutePosition: number = 0;
    valueLoadingFile: string = "";

    getEmptyModel(number: number): PointViewModel {
        return {
            id: "",
            routeId: this.currentModel?.id ?? "",
            number: number,
            name: "",
        };
    }

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true});
    }

    init(){
        KeyboardManager.add('ArrowLeft', this.prevRoute);
        KeyboardManager.add('ArrowRight', this.nextRoute);
    }
    uninit(){
        KeyboardManager.remove('ArrowLeft', this.prevRoute);
        KeyboardManager.remove('ArrowRight', this.nextRoute);
    }

    newCreateRoute() {
        let massive = this.valueLoadingFile.split("\n");
        this.onLoadingFile(massive.filter(x => x !== "" && x !== null));
    }

    changeValueLoadingFile(name: string) {
        this.valueLoadingFile = name;
    }

    nextRoute() {
        if (this.isNextRoute)
            this.currentRoutePosition++;
        this.updateCurrentRoute();
    }

    prevRoute() {
        if (this.isPrevRoute)
            this.currentRoutePosition--;
        this.updateCurrentRoute();
    }

    get isNextRoute(): boolean {
        return this.currentRoutePosition < ((this.model.Model?.length ?? 1) - 1);
    }

    get isPrevRoute(): boolean {
        return this.currentRoutePosition > 0;
    }

    updateCurrentRoute() {
        this.currentModel = this.model.Model![this.currentRoutePosition];
    }

    get getPoints(): PointViewModel[] {
        if (!this.currentModel)
            return [];
        let points = this.currentModel.points;
        points.sort((a, b) => a.number - b.number)
        if (points.length >= 10){
            if (this.currentModel.points[points.length - 1].name !== ""){
                points.push(this.getEmptyModel(points.length));
            }
            return points;
        }
        let countPointsNeed = 10 - this.currentModel.points.length;
        for (let i = 0; i < countPointsNeed; i++) {
            points.push(this.getEmptyModel(points.length));
        }
        return points;
    }

    onDeleteRoute() {
        this.reactModalDeleteStore.close();
        if (this.currentModel) {
            return GetRequest<RouteViewModel[]>("/api/Route3/DeleteRoute?id=" + this.currentModel.id)
                .then(x => {
                    this.reactModalDeleteStore.close();
                    this.prevRoute();
                    this.setModel(x);
                })
        }
    }

    onLoadingFile(massive: string[]) {
        if (this.currentModel) {
            return PostRequest<RouteViewModel[], string[]>("/api/Route3/CreateRouteFromFile", massive)
                .then(x => {
                    this.reactModalLoadingFileStore.close();
                    this.setModel(x);
                    this.currentRoutePosition = (this.model.Model?.length ?? 0) - 1;
                    this.updateCurrentRoute();
                })
        }
    }

    onBeforeRoute(massive: string[]) {
        // if (this.currentModel) {
        //     PostRequest<RouteViewModel[], string[]>("/api/Route3/AddEndFromFile", massive)
        //         .then(x => {
        //             this.closeModalDelete();
        //             this.prevRoute();
        //             this.setModel(x);
        //         })
        // }
    }

    onAfterFile(massive: string[]) {
        // if (this.currentModel) {
        //     PostRequest<RouteViewModel[], string[]>("/api/Route3/AddBeginFromFile", massive)
        //         .then(x => {
        //             this.closeModalDelete();
        //             this.prevRoute();
        //             this.setModel(x);
        //         })
        // }
    }

    get getName(): string {
        if (!this.currentModel || !this.currentModel.name)
            return 'Маршрут №' + (this.currentRoutePosition + 1);
        return this.currentModel.name;
    }

    changeName(name: string) {
        this.currentModel!.name = name;
    }

    fetchRequest() {
        this.model.IsLoaded = false;
        GetRequest<RouteViewModel[]>('/api/Route3/get')
            .then(x => this.setModel(x))
    }

    updateRouteRequest() {
        this.model.IsLoaded = false;
        PostRequest<RouteViewModel[], RouteViewModel>('/api/Route3/UpgradePoints', this.currentModel!)
            .then(x => this.setModel(x))
    }

    createRouteRequest() {
        this.model.IsLoaded = false;
        let newRoute = {id: '', name: '', createTime: 0, points: []};
        PostRequest<RouteViewModel[], RouteViewModel>('/api/Route3/CreateRoute', newRoute)
            .then(x => {
                this.currentRoutePosition = x.Model!.length - 1;
                this.setModel(x)
            })
    }

    setModel(model: LoadingModel<RouteViewModel[]>) {
        this.model.IsLoaded = true;
        this.model = model;
        this.updateCurrentRoute();
    }

    createPoint(number: number) {
        this.currentModel!.points.splice(number, 0, this.getEmptyModel(number));
        this.updateNumberPoints();
    }

    deletePoint(number: number) {
        this.currentModel!.points.splice(number, 1);
        this.updateNumberPoints();
    }

    updatePoint(pos: number, s: string) {
        this.currentModel!.points[pos].name = s;
    }

    updatePoints(startIndex: number, endIndex: number) {
        const result = Array.from(this.currentModel!.points);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        this.currentModel!.points = result;
        this.updateNumberPoints();
    }

    // Обновить положение points
    updateNumberPoints() {
        for (let i = 0; i < this.currentModel!.points.length; i++) {
            this.currentModel!.points[i].number = i;
        }
        // this.updateRouteRequest();
    }
}