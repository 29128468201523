import {makeAutoObservable} from "mobx";
import {PostRequest} from "../../../helpers/Request";
import {LoadingModel} from "../../shared/LoadingModel";
import {
    TrainingMemoryRussianCreateViewModel,
    TrainingMemoryRussianMemberViewModel,
    TrainingMemoryRussianResultViewModel, TrainingMemoryRussianWriteFinishViewModel,
    TrainingMemoryRussianWriteRequestViewModel
} from "../model/LearnLanguageMemberModel";
import {StateTrainingLearnLanguage} from "../../../pages/EnglishLanguage/LearnLanguagePage";


export default class TrainingLearnLanguageStore {
    state: StateTrainingLearnLanguage;
    modelMember: LoadingModel<TrainingMemoryRussianMemberViewModel> = LoadingModel.createNotLoaded<TrainingMemoryRussianMemberViewModel>();
    modelResult: LoadingModel<TrainingMemoryRussianResultViewModel> = LoadingModel.createNotLoaded<TrainingMemoryRussianResultViewModel>();

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true});
        this.state = StateTrainingLearnLanguage.base;
    }

    startTrainingRequest(model: TrainingMemoryRussianCreateViewModel): void {
        this.state = StateTrainingLearnLanguage.description;
        PostRequest<TrainingMemoryRussianMemberViewModel, object | undefined>("/api/TrainingMemoryRussian/Create", model)
            .then(x => this.setModelMember(x));
    }

    backBase(): void {
        this.state = StateTrainingLearnLanguage.base;
    }

    private setModelMember(model: LoadingModel<TrainingMemoryRussianMemberViewModel>) {
        this.modelMember = model;
    }

    finishDescription(): void {
        this.state = StateTrainingLearnLanguage.member;
    }

    resultTrainingRequest(model: TrainingMemoryRussianWriteRequestViewModel): void {
        this.state = StateTrainingLearnLanguage.result;
        PostRequest<TrainingMemoryRussianResultViewModel, TrainingMemoryRussianWriteRequestViewModel>(
            "/api/TrainingMemoryRussian/Result", model)
            .then(x => this.setModelResult(x));
    }

    continueTrainingRequest(model: TrainingMemoryRussianWriteRequestViewModel): Promise<LoadingModel<TrainingMemoryRussianMemberViewModel>> {
        return PostRequest<TrainingMemoryRussianMemberViewModel, TrainingMemoryRussianWriteRequestViewModel>(
            "/api/TrainingMemoryRussian/Continue", model);
    }

    finishTrainingRequest(model: TrainingMemoryRussianWriteFinishViewModel): void {
        this.state = StateTrainingLearnLanguage.result;
        PostRequest<TrainingMemoryRussianResultViewModel, TrainingMemoryRussianWriteFinishViewModel>(
            "/api/TrainingMemoryRussian/finish", model)
            .then(x => this.setModelResult(x));
    }

    private setModelResult(model: LoadingModel<TrainingMemoryRussianResultViewModel>) {
        this.modelResult = model;
    }
}