import {CustomComponent} from "../../../helpers/CustomComponent";
import {Avatar} from "../../user/Avatar";
import {BestTrainingType} from "../model/TrainingResultModel";
import './userInfoComponent.scss';
import React from "react";
import {ResAndShareBox} from "./ResAndShareBox";
import {CheckScreen, TypeScreen} from "../../../context/TypeScreen";


interface Props {
    fullname: string
    imgSrc: string
    link: string
    rang: string
    isPremium: boolean;
    bestTraining: BestTrainingType;
    dis: string;
    id: string;

    timeGet: string;
    dateGet: string;
    openCloseDiagram: () => void;
    sharedOpen: () => void;
}

export class UserInfoComponent extends CustomComponent<Props, {}> {
    componentDidMount() {
    }

    componentWillUnmount() {
    }

    get addClassAvatar() {
        if (this.props.bestTraining === BestTrainingType.BestUser) {
            return "best-user";
        }
        if (this.props.bestTraining === BestTrainingType.BestIsTheBest) {
            return "best-site";
        }
        return "";
    }

    render() {
        return <div className="res-userinfo-data">
            <div id="res-userinfo">
                <Avatar
                    fullname={this.props.fullname}
                    img={this.props.imgSrc}
                    link={this.props.link}
                    addClass={this.addClassAvatar}
                />
                <div className="res-container">
                    <div id="res-name" data-title="Премиум пользователь"
                         className={this.props!.isPremium ? "res-name-premium rnp-title" : ""}>
                        {this.props!.fullname}
                    </div>
                    <div id="res-rank">{this.props.rang}</div>
                </div>
                {
                    this.props.bestTraining === BestTrainingType.BestUser &&
                    <div id="star-pr"
                         data-title={"Личный рекорд [" + this.props.dis + "]"}></div>
                }
                {
                    this.props.bestTraining === BestTrainingType.BestIsTheBest &&
                    <div id="star-rs"
                         data-title={"Рекорд сайта [" + this.props.dis + "]"}></div>
                }
            </div>
            {
                CheckScreen([TypeScreen.Desktop, TypeScreen.Tablet]) &&
                <ResAndShareBox {...this.props} toShare={this.props.sharedOpen}/>
            }
        </div>;
    }
}