import {CustomComponent} from "../../../helpers/CustomComponent";
import {ProductStore} from "../store/ProductStore";
import {observer} from "mobx-react";
import {TopUserHead} from "../../topUsers/TopUserHead";
import {TopUsersSorting} from "../../topUsers/TopUsersStore";
import React from "react";

interface Props {
    store: ProductStore;
}

@observer
export class ProductComponent extends CustomComponent<Props, {}> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.clickHead = this.clickHead.bind(this)
    }

    clickHead(customAttr: string){

    }

    render() {
        return <>
            {/*<InputSearchCustom changeAndRequest={this.store.changeNameAndRequest}/>*/}

            <div className="results-table-header">
                <div className="td-number">№</div>
                <div className="td-name">Имя</div>
                <TopUserHead title={"Быллы за всё время"}
                             text={"Всё"}
                             className={"td_point_all"}
                             onclick={this.clickHead}
                             customAttr={TopUsersSorting.all}
                             status={false}/>

                <TopUserHead title={"Быллы за месяц"}
                             text={"Месяц"}
                             className={"td_point_month"}
                             onclick={this.clickHead}
                             customAttr={TopUsersSorting.month}
                             status={false}/>

                <TopUserHead title={"Быллы за прошлый месяц"}
                             text={"Пр. месяц"}
                             className={"td_point_lastmonth"}
                             onclick={this.clickHead}
                             customAttr={TopUsersSorting.prevMonth}
                             status={false}/>

                <TopUserHead title={"День"}
                             text={"День"}
                             className={"td_point_day"}
                             onclick={this.clickHead}
                             customAttr={TopUsersSorting.day}
                             status={false}/>

                <TopUserHead title={"Пр. день"}
                             text={"Пр. день"}
                             className={"td_point_lastday"}
                             onclick={this.clickHead}
                             customAttr={TopUsersSorting.prevDay}
                             status={false}/>
            </div>

            <section id="result-lines">
                {/*{*/}
                {/*    !this.store.model.IsLoadedModel() &&*/}
                {/*    <Loading/>*/}
                {/*}*/}
                {/*{*/}
                {/*    this.store.model.Model?.map((x, y) =>*/}
                {/*        <TopUsersItem key={x.idAccount} item={x} pos={y + 1}/>)*/}
                {/*}*/}
            </section>
        </>;
    }
}