import {Component} from "react";
import {observer} from "mobx-react";
import CustomSelector from "../../../shared/selector/CustomSelector";
import NewRankStore from "./NewRankStore";
import {reaction} from "mobx";
import {BallsRank} from "./components/BallsRank";
import {RouteRank} from "./components/RouteRank";
import {CodingRank} from "./components/CodingRank";
import {StandartsRequiredsRank} from "./components/StandartsRequiredsRank";
import {LanguageRank} from "./components/LanguageRank";
import {StandartsRank} from "./components/StandartsRank";
import {Loading} from "../../../shared/Loading";


@observer
export class NewRank extends Component<{}, {}> {
    private store: NewRankStore = new NewRankStore();

    constructor(props: Readonly<{}>) {
        super(props);
    }
    componentDidMount() {
        this.store.initRank()
        reaction(
            () => [this.store.selector.value],
            this.store.fetchRequest
        );
    }

    render() {
        if (!this.store.loadingStore.isLoaded)
            return <Loading />
        return (<>
            <span className="sat-name">Задания для получения нового ранга</span>
            <div className="select-style statchosen">
                <CustomSelector values={this.store.selectors}
                                defaultValue={this.store.selector.value}
                                value={this.store.selector.value}
                                onChange={this.store.setSelector} />
                <button id="next-rank" className="button-next-rank">+</button>
            </div>
            {
                this.store.loadingStore.isLoaded &&
                this.store.loadingStore.model.Model &&
                <>
                    <BallsRank ballsNow={this.store.loadingStore.model.Model.countBallsNow}
                               ballsRequired={this.store.loadingStore.model.Model.countBallsRequired}
                               numberPosition={this.store.numberPosition()}/>
                    {
                        this.store.loadingStore.model.Model.routeCount &&
                        <RouteRank numberPosition={this.store.numberPosition()}
                                   count={this.store.loadingStore.model.Model.routeCount.count}
                                   needCount={this.store.loadingStore.model.Model.routeCount.needCount} />
                    }
                    {
                        this.store.loadingStore.model.Model.codingTraining &&
                        <CodingRank numberPosition={this.store.numberPosition()}
                                    coding={this.store.loadingStore.model.Model.codingTraining} />
                    }
                    {
                        this.store.loadingStore.model.Model.defaultTrainingsRequired.length !== 0 &&
                        <StandartsRequiredsRank numberPosition={this.store.numberPosition()}
                                    trainings={this.store.loadingStore.model.Model.defaultTrainingsRequired} />
                    }
                    <StandartsRank numberPosition={this.store.numberPosition()}
                                   countRequired={this.store.loadingStore.model.Model.countRequired}
                                   trainings={this.store.loadingStore.model.Model.defaultTrainings} />
                    {
                        this.store.loadingStore.model.Model.language &&
                        <LanguageRank numberPosition={this.store.numberPosition()}
                                    language={this.store.loadingStore.model.Model.language} />
                    }
                </>
            }

        </>);
    }
}