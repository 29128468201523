import {makeAutoObservable} from "mobx";

class StopwatchService {
    currentTimeStr: string = '00:00';
    startTime: number | undefined;
    timer: NodeJS.Timer | undefined = undefined;

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true})
    }

    start() {
        this.timer = setInterval(this.setTimeStr, 1000);
        this.startTime = Date.now();
    }

    get currentTime(): number {
        if (this.startTime === undefined)
            return 0;
        return Date.now() - this.startTime;
    }

    setTimeStr() {
        let current_date = this.currentTime;
        if (current_date === 0) {
            this.currentTimeStr = '00:00'
            return;
        }
        let sec = Math.abs(Math.floor(current_date / 1000) % 60); //sek
        let min = Math.abs(Math.floor(current_date / 1000 / 60));  //min
        this.currentTimeStr = (min <= 9 ? '0' + min : min.toString()) + ':' + (sec <= 9 ? '0' + sec : sec.toString());
    }

    clearTimer() {
        clearInterval(this.timer);
    }
}
export default new StopwatchService();