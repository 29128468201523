import {CustomComponent} from "../CustomComponent";
import './imageComponent.scss'
import {observer} from "mobx-react";
import {ReactSVG} from "react-svg";
import React from "react";


interface Props{
    src: string;
    alt: string
}
@observer
export class ImageComponent extends CustomComponent<Props, any>{

    render() {
        return <>
            {
                this.props.src.endsWith(".svg") &&
                <ReactSVG className="image-component"
                          src={this.props.src} />
            }
            {
                !this.props.src.endsWith(".svg") &&
                <img className="image-component" src={"https://powermemory.io" + this.props.src} alt={this.props.alt}/>
            }
        </>;
    }
}