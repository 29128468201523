import {makeAutoObservable, observable} from "mobx";
import {LoadingModel} from "../components/shared/LoadingModel";
import {GetRequest} from "./Request";


export default class LoadingStore<T> {
    @observable
    public model: LoadingModel<T> = LoadingModel.createLoaded<T>();
    public getUrl: () => string;

    constructor(getUrl: () => string) {
        makeAutoObservable(this, {}, {autoBind: true});
        this.getUrl = getUrl;
    }

    setModel(model: LoadingModel<T>): LoadingModel<T> {
        this.model = model;
        return model;
    }

    get get(): T | null {
        if (this.isLoaded)
            return this.model.Model;
        return null;
    }

    get isLoaded(): boolean {
        return this.model && this.model.Model !== null && this.model.IsLoaded;
    }


    fetchRequest() {
        this.model.IsLoaded = false;
        return GetRequest<T>(this.getUrl())
            .then(x => this.setModel(x));
    }
}