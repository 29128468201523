import React, {Component} from 'react'
import './homePage.scss'
import {Link} from "react-router-dom";
import {Posts} from "../../components/post/Posts";
import {BallsResults} from "../../components/ball/MobileBallsResultsModel";
import {observer} from "mobx-react";
import Footer from "../../components/shared/Footer";
import TitleStore from "../../components/authorization/store/TitleStore";
import BallResultStore, {TypeBallsResult} from "../../components/ball/store/BallResultStore";
import {RecordsboardResultModel} from "../../components/ball/RecordsboardResultModel";

@observer
export class HomePage extends Component<{}, {}> {
    ballResultStore = new BallResultStore();
    componentDidMount() {
        TitleStore.setTitle("PowerMemory");
    }

    render() {
        return (<>
                <div id="contener-2" className="two-one-cell container-silver">
                    {/*@*СЛАЙДЕР И СПИСОК СТАТЕЙ*@*/}
                    <main className="content clear-fix">
                        <section className="sliderbox">
                            <input type="radio" name="slides" id="slide1"/>
                            <input type="radio" name="slides" id="slide2"/>
                            <input type="radio" name="slides" id="slide3"/>
                            <input type="radio" name="slides" id="slide4"/>
                            <input type="radio" name="slides" id="slide5"/>
                            <input type="radio" name="slides" id="slide6" defaultChecked={true}/>

                            <div className="slider">
                                <div className="s_img">
                                    <div className="txt-slides-block">
                                        <span className="slides-htxt">PowerMemory.io – проект о том, как управлять своей памятью, чтобы управлять жизнью</span>
                                        <span className="slides-txt">
                            На этом сайте ты сможешь качественно тренировать свою память,
                            для совершенствования скорости, надежности и легкости в запоминании
                            полезной информации.
                        </span>
                                        <span className="slides-txt">Этот сайт – твой лучший тренировочный зал для мозга. Начнём?</span>

                                        <div id="slider-bottom">
                                            <div className="sb-1">
                                                <div>
                                                    <Link to={'/Course'} className='more'>Платное обучение</Link>
                                                </div>
                                            </div>
                                            <div className="sb-1">
                                                <Link to={'/Training'} className='more start-slides'>Начать
                                                    тренировку</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Posts/>
                    </main>
                    <BallsResults isHomePage={true} store={this.ballResultStore}/>
                    {
                        this.ballResultStore.typeResult === TypeBallsResult.results &&
                        <RecordsboardResultModel store={this.ballResultStore} />
                    }
                </div>
                <Footer/>
            </>
        )
    }
}