import {makeAutoObservable} from "mobx";
import LoadingStore from "../../helpers/LoadingStore";
import {SetStoneResultModel, StoneResultModel} from "./models/StoneModel";
import {PostRequestWithoutResponseBody} from "../../helpers/Request";
import AuthorizationStore from "../authorization/store/AuthorizationStore";

export enum StoneStatus {
    main = 'main',
    choose = 'choose'
}

export enum StoneSelector {
    stone7 = 'stone-1',
    stone14 = 'stone-2',
    stone21 = 'stone-3',
    stone30 = 'stone-4',
    stone60 = 'stone-5',
}

export class StoneStore {
    stoneStatus: StoneStatus = StoneStatus.main
    stoneSelector: StoneSelector = StoneSelector.stone7;
    showModal: boolean = false;

    public loadingStore: LoadingStore<StoneResultModel>;

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true})
        this.loadingStore = new LoadingStore<StoneResultModel>(this.getUrl)
    }

    getUrl(): string {
        return "/api/stone/get";
    }

    request() {
        this.loadingStore.fetchRequest()
            .then(x => {
                if (x.Model === null) {
                    AuthorizationStore.logOut();
                }
                if (x.Model!.statusStone === 1) {
                    this.selectStatus(StoneStatus.choose);
                } else {
                    this.selectStatus(StoneStatus.main);
                }
            }).catch(() => {
                console.log('catch');
        });
    }

    setStone() {
        if (this.loadingStore.get?.stoneSelectedId !== this.stoneSelector.toString()) {
            PostRequestWithoutResponseBody<SetStoneResultModel>('/api/stone/setStone',
                {stoneId: this.stoneSelector.toString()})
                .then(this.request)
        }
        this.selectStatus(StoneStatus.main);
    }

    closedShowModal() {
        this.showModal = false;
    }

    openShowModal() {
        this.showModal = true;
    }

    changeStatus() {
        if (this.stoneStatus === StoneStatus.main) {
            this.selectStatus(StoneStatus.choose);
        } else {
            this.selectStatus(StoneStatus.main);
        }
    }

    selectStatus(status: StoneStatus) {
        this.stoneStatus = status;
    }

    selectStone(stoneSelector: StoneSelector) {
        this.stoneSelector = stoneSelector;
    }

    checkStone(stoneSelector: StoneSelector) {
        return this.stoneSelector === stoneSelector;
    }

    calendar() {
        let massive = [];
        for (let i = 0; i < 7; i++) {
            massive.push({day: i + 1, isGreen: true})
        }

        return massive;
    }
}