import {CustomComponent} from "../../../helpers/CustomComponent";
import {StoneSelector} from "../StoneStore";


interface Props {
    countBalls: string,
    daysText: string,
    stone: string,
    stoneSelector: StoneSelector;
    isSelected: boolean;
    click: (selector: StoneSelector) => void;
}

export class StoneSelectorTable extends CustomComponent<Props, {}> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.click = this.click.bind(this);
    }

    click() {
        this.props.click(this.props.stoneSelector);
    }

    render() {
        return <div className={"trs-h seria-stones " + (this.props.isSelected ? "seria-on" : "")}
                    id="seria-stone-1"
                    onClick={this.click}>
            <div className="tds-1">{this.props.countBalls}</div>
            <div className="tds-1">{this.props.daysText}</div>
            <div className="tds-2">{this.props.stone}</div>
        </div>;
    }
}