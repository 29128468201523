import {SelectorPros} from "../../shared/selector/CustomSelector";


export function range9(): SelectorPros[] {
    return [{
        value: 'all',
        title: 'Все элементы'
    }]
}

export function range99(): SelectorPros[] {
    return [{value: 'all', title: 'Все элементы'},
        {value: '00-09', title: '00-09'},
        {value: '10-19', title: '10-19'},
        {value: '20-29', title: '20-29'},
        {value: '30-39', title: '30-39'},
        {value: '40-49', title: '40-49'},
        {value: '50-59', title: '50-59'},
        {value: '60-69', title: '60-69'},
        {value: '70-79', title: '70-79'},
        {value: '80-89', title: '80-89'},
        {value: '90-99', title: '90-99'},
        {value: 'km00', title: 'KM 0 (00, 10, 20 ... 90)'},
        {value: 'km01', title: 'KM 1 (01, 11, 21 ... 91)'},
        {value: 'km02', title: 'KM 2 (02, 12, 22 ... 92)'},
        {value: 'km03', title: 'KM 3 (03, 13, 23 ... 93)'},
        {value: 'km04', title: 'KM 4 (04, 14, 24 ... 94)'},
        {value: 'km05', title: 'KM 5 (05, 15, 25 ... 95)'},
        {value: 'km06', title: 'KM 6 (06, 16, 26 ... 96)'},
        {value: 'km07', title: 'KM 7 (07, 17, 27 ... 97)'},
        {value: 'km08', title: 'KM 8 (08, 18, 28 ... 98)'},
        {value: 'km09', title: 'KM 9 (09, 19, 29 ... 99)'}
    ]
}

export function range999(): SelectorPros[] {
    return [{value: 'all', title: 'Все элементы'},
        {value: '000-099', title: '000-099'},
        {value: '100-199', title: '100-199'},
        {value: '200-299', title: '200-299'},
        {value: '300-399', title: '300-399'},
        {value: '400-499', title: '400-499'},
        {value: '500-599', title: '500-599'},
        {value: '600-699', title: '600-699'},
        {value: '700-799', title: '700-799'},
        {value: '800-899', title: '800-899'},
        {value: '900-999', title: '900-999'},
        {value: 'km-pack-0', title: 'KM 000-009'},
        {value: 'km-pack-1', title: 'KM 010-019'},
        {value: 'km-pack-2', title: 'KM 020-029'},
        {value: 'km-pack-3', title: 'KM 030-039'},
        {value: 'km-pack-4', title: 'KM 040-049'},
        {value: 'km-pack-5', title: 'KM 050-059'},
        {value: 'km-pack-6', title: 'KM 060-069'},
        {value: 'km-pack-7', title: 'KM 070-079'},
        {value: 'km-pack-8', title: 'KM 080-089'},
        {value: 'km-pack-9', title: 'KM 090-099'},
        {value: 'km000', title: 'KM 0 (000, 100, 200 ... 900)'},
        {value: 'km001', title: 'KM 1 (001, 101, 201 ... 901)'},
        {value: 'km002', title: 'KM 2 (002, 102, 202 ... 902)'},
        {value: 'km003', title: 'KM 3 (003, 103, 203 ... 903)'},
        {value: 'km004', title: 'KM 4 (004, 104, 204 ... 904)'},
        {value: 'km005', title: 'KM 5 (005, 105, 205 ... 905)'},
        {value: 'km006', title: 'KM 6 (006, 106, 206 ... 906)'},
        {value: 'km007', title: 'KM 7 (007, 107, 207 ... 907)'},
        {value: 'km008', title: 'KM 8 (008, 108, 208 ... 908)'},
        {value: 'km009', title: 'KM 9 (009, 109, 209 ... 909)'},
        {value: 'km010', title: 'KM 10 (010, 110, 210 ... 910)'},
        {value: 'km011', title: 'KM 11 (011, 111, 211 ... 911)'},
        {value: 'km012', title: 'KM 12 (012, 112, 212 ... 912)'},
        {value: 'km013', title: 'KM 13 (013, 113, 213 ... 913)'},
        {value: 'km014', title: 'KM 14 (014, 114, 214 ... 914)'},
        {value: 'km015', title: 'KM 15 (015, 115, 215 ... 915)'},
        {value: 'km016', title: 'KM 16 (016, 116, 216 ... 916)'},
        {value: 'km017', title: 'KM 17 (017, 117, 217 ... 917)'},
        {value: 'km018', title: 'KM 18 (018, 118, 218 ... 918)'},
        {value: 'km019', title: 'KM 19 (019, 119, 219 ... 919)'},
        {value: 'km020', title: 'KM 20 (020, 120, 220 ... 920)'},
        {value: 'km021', title: 'KM 21 (021, 121, 221 ... 921)'},
        {value: 'km022', title: 'KM 22 (022, 122, 222 ... 922)'},
        {value: 'km023', title: 'KM 23 (023, 123, 223 ... 923)'},
        {value: 'km024', title: 'KM 24 (024, 124, 224 ... 924)'},
        {value: 'km025', title: 'KM 25 (025, 125, 225 ... 925)'},
        {value: 'km026', title: 'KM 26 (026, 126, 226 ... 926)'},
        {value: 'km027', title: 'KM 27 (027, 127, 227 ... 927)'},
        {value: 'km028', title: 'KM 28 (028, 128, 228 ... 928)'},
        {value: 'km029', title: 'KM 29 (029, 129, 229 ... 929)'},
        {value: 'km030', title: 'KM 30 (030, 130, 230 ... 930)'},
        {value: 'km031', title: 'KM 31 (031, 131, 231 ... 931)'},
        {value: 'km032', title: 'KM 32 (032, 132, 232 ... 932)'},
        {value: 'km033', title: 'KM 33 (033, 133, 233 ... 933)'},
        {value: 'km034', title: 'KM 34 (034, 134, 234 ... 934)'},
        {value: 'km035', title: 'KM 35 (035, 135, 235 ... 935)'},
        {value: 'km036', title: 'KM 36 (036, 136, 236 ... 936)'},
        {value: 'km037', title: 'KM 37 (037, 137, 237 ... 937)'},
        {value: 'km038', title: 'KM 38 (038, 138, 238 ... 938)'},
        {value: 'km039', title: 'KM 39 (039, 139, 239 ... 939)'},
        {value: 'km040', title: 'KM 40 (040, 140, 240 ... 940)'},
        {value: 'km041', title: 'KM 41 (041, 141, 241 ... 941)'},
        {value: 'km042', title: 'KM 42 (042, 142, 242 ... 942)'},
        {value: 'km043', title: 'KM 43 (043, 143, 243 ... 943)'},
        {value: 'km044', title: 'KM 44 (044, 144, 244 ... 944)'},
        {value: 'km045', title: 'KM 45 (045, 145, 245 ... 945)'},
        {value: 'km046', title: 'KM 46 (046, 146, 246 ... 946)'},
        {value: 'km047', title: 'KM 47 (047, 147, 247 ... 947)'},
        {value: 'km048', title: 'KM 48 (048, 148, 248 ... 948)'},
        {value: 'km049', title: 'KM 49 (049, 149, 249 ... 949)'},
        {value: 'km050', title: 'KM 50 (050, 150, 250 ... 950)'},
        {value: 'km051', title: 'KM 51 (051, 151, 251 ... 951)'},
        {value: 'km052', title: 'KM 52 (052, 152, 252 ... 952)'},
        {value: 'km053', title: 'KM 53 (053, 153, 253 ... 953)'},
        {value: 'km054', title: 'KM 54 (054, 154, 254 ... 954)'},
        {value: 'km055', title: 'KM 55 (055, 155, 255 ... 955)'},
        {value: 'km056', title: 'KM 56 (056, 156, 256 ... 956)'},
        {value: 'km057', title: 'KM 57 (057, 157, 257 ... 957)'},
        {value: 'km058', title: 'KM 58 (058, 158, 258 ... 958)'},
        {value: 'km059', title: 'KM 59 (059, 159, 259 ... 959)'},
        {value: 'km060', title: 'KM 60 (060, 160, 260 ... 960)'},
        {value: 'km061', title: 'KM 61 (061, 161, 261 ... 961)'},
        {value: 'km062', title: 'KM 62 (062, 162, 262 ... 962)'},
        {value: 'km063', title: 'KM 63 (063, 163, 263 ... 963)'},
        {value: 'km064', title: 'KM 64 (064, 164, 264 ... 964)'},
        {value: 'km065', title: 'KM 65 (065, 165, 265 ... 965)'},
        {value: 'km066', title: 'KM 66 (066, 166, 266 ... 966)'},
        {value: 'km067', title: 'KM 67 (067, 167, 267 ... 967)'},
        {value: 'km068', title: 'KM 68 (068, 168, 268 ... 968)'},
        {value: 'km069', title: 'KM 69 (069, 169, 269 ... 969)'},
        {value: 'km070', title: 'KM 70 (070, 170, 270 ... 970)'},
        {value: 'km071', title: 'KM 71 (071, 171, 271 ... 971)'},
        {value: 'km072', title: 'KM 72 (072, 172, 272 ... 972)'},
        {value: 'km073', title: 'KM 73 (073, 173, 273 ... 973)'},
        {value: 'km074', title: 'KM 74 (074, 174, 274 ... 974)'},
        {value: 'km075', title: 'KM 75 (075, 175, 275 ... 975)'},
        {value: 'km076', title: 'KM 76 (076, 176, 276 ... 976)'},
        {value: 'km077', title: 'KM 77 (077, 177, 277 ... 977)'},
        {value: 'km078', title: 'KM 78 (078, 178, 278 ... 978)'},
        {value: 'km079', title: 'KM 79 (079, 179, 279 ... 979)'},
        {value: 'km080', title: 'KM 80 (080, 180, 280 ... 980)'},
        {value: 'km081', title: 'KM 81 (081, 181, 281 ... 981)'},
        {value: 'km082', title: 'KM 82 (082, 182, 282 ... 982)'},
        {value: 'km083', title: 'KM 83 (083, 183, 283 ... 983)'},
        {value: 'km084', title: 'KM 84 (084, 184, 284 ... 984)'},
        {value: 'km085', title: 'KM 85 (085, 185, 285 ... 985)'},
        {value: 'km086', title: 'KM 86 (086, 186, 286 ... 986)'},
        {value: 'km087', title: 'KM 87 (087, 187, 287 ... 987)'},
        {value: 'km088', title: 'KM 88 (088, 188, 288 ... 988)'},
        {value: 'km089', title: 'KM 89 (089, 189, 289 ... 989)'},
        {value: 'km090', title: 'KM 90 (090, 190, 290 ... 990)'},
        {value: 'km091', title: 'KM 91 (091, 191, 291 ... 991)'},
        {value: 'km092', title: 'KM 92 (092, 192, 292 ... 992)'},
        {value: 'km093', title: 'KM 93 (093, 193, 293 ... 993)'},
        {value: 'km094', title: 'KM 94 (094, 194, 294 ... 994)'},
        {value: 'km095', title: 'KM 95 (095, 195, 295 ... 995)'},
        {value: 'km096', title: 'KM 96 (096, 196, 296 ... 996)'},
        {value: 'km097', title: 'KM 97 (097, 197, 297 ... 997)'},
        {value: 'km098', title: 'KM 98 (098, 198, 298 ... 998)'},
        {value: 'km099', title: 'KM 99 (099, 199, 299 ... 999)'}
    ]
}

export function rangeEng(): SelectorPros[] {
    return [{value: 'phonemaEng', title: 'Фонемные коды'},
        {value: 'symbolPhonemaEng', title: 'Букво-фонемные коды'},
        {value: 'syffixCodeEng', title: 'Суффиксные коды'},
        {value: 'prefixCodeEng', title: 'Префиксные коды'},
        {value: 'rootCodeEng', title: 'Корневые коды'},
        {value: 'codeValueCodeEng', title: 'Коды-значения'},
        {value: 'diffCombinationCodeEng', title: 'Разные комбинации 1'},
        {value: 'diffCombination2CodeEng', title: 'Разные комбинации 2'}
    ]
}

export function rangeEngIng(): SelectorPros[] {
    return [{value: 'all', title: 'Все коды (A-Z) (450)'},
        {value: 'phonema', title: 'Фонемные коды (13)'},
        {value: 'letterPhonemicCodes', title: 'Букво-фонемные коды (34)'},
        {value: 'superFrequency', title: 'Сверхчастотные (108)'},
        {value: 'veryFrequent', title: 'Очень частотные (110)'},
        {value: 'frequency', title: 'Частотные (133)'},
        {value: 'lessFrequent', title: 'Среднечастотные (25)'},
        {value: 'lowFrequency', title: 'Малочастотные (27)'}
    ]
}

export function rangeFigurativeCodes(): SelectorPros[] {
    return [{value: 'adjectivesFigurativeСodes', title: 'Прилагательные'}
    ]
}