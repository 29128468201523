import './mnemocardComponent.scss'
import {observer} from "mobx-react";
import MnemocardStore from "./store/MnemocardStore";
import {CustomComponent} from "../../helpers/CustomComponent";


@observer
export class MnemocardRewardsComponent extends CustomComponent<{}, {}> {

    render() {
        return (
            <div className="tab-mnemocard" id="m-rewards">

                <div className="reward">
                    <span>Камни за серию</span>
                    {
                        MnemocardStore.mnemoCart.Model!.stoneUserProgresses2.length === 0 &&
                        <div className="no-reward">Награда отсутствует</div>
                    }
                    {
                        MnemocardStore.mnemoCart.Model!.stoneUserProgresses2.length > 0 &&
                        <div className="rewards seria-rew">
                            {MnemocardStore.mnemoCart.Model!.stoneUserProgresses2.map((x, index) =>
                                <div className="rew-img" key={index}
                                     data-title={x.name + ' за серию ' + x.countDays + ' дней по ' + x.countBalls + ' эл.'}>
                                    <img src={"https://powermemory.io/" + x.image} alt={x.name} />
                                    <div className="rew-amount" id="sim3-amount">x {x.stonesCount}</div>
                                </div>
                            )}
                        </div>
                    }
                </div>

                <div className="reward">
                    <span>Звезда Симонида</span>

                    {
                        MnemocardStore.mnemoCart.Model!.cimonidaStars2.length === 0 &&
                        <div className="no-reward">Награда отсутствует</div>
                    }
                    {
                        MnemocardStore.mnemoCart.Model!.cimonidaStars2.length > 0 &&
                        <div className="rewards simonid">
                            {MnemocardStore.mnemoCart.Model!.cimonidaStars2.map((x, index) =>
                                <div className="rew-img" key={index} data-title={x.name + ' набрано за день'}>
                                    <img src={"https://powermemory.io/" + x.image} alt={x.name}/>
                                    <div className="rew-amount">x {x.comonidaCount}</div>
                                </div>
                            )}
                        </div>
                    }
                </div>

                <div className="reward">
                    <span>Уровень совершенства</span>

                    {
                        MnemocardStore.mnemoCart.Model!.perfectLevel.length === 0 &&
                        <div className="no-reward">Награда отсутствует</div>
                    }
                    {
                        MnemocardStore.mnemoCart.Model!.perfectLevel.length > 0 &&
                        <div className="rewards perfection">
                            {MnemocardStore.mnemoCart.Model!.perfectLevel.map((x, index) =>
                                <div className="rew-img perfection-level" key={index} data-title="10 результатов без ошибок подряд">
                                    <div className="perfection_num">{x.countElements / 10} A</div>
                                </div>
                            )}
                        </div>
                    }
                </div>

                <div className="reward">
                    <span>Пирамида Мнемозины</span>
                    {
                        MnemocardStore.mnemoCart.Model!.mnemosinsPyramids2.length === 0 &&
                        <div className="no-reward">Награда отсутствует</div>
                    }
                    {
                        MnemocardStore.mnemoCart.Model!.mnemosinsPyramids2.length > 0 &&
                        <div className="rewards mnemosina">
                            {MnemocardStore.mnemoCart.Model!.mnemosinsPyramids2.map((x, index) =>
                                <div className="rew-img" key={index} data-title={'Баллов набрано за месяц'}>
                                    <img src={"https://powermemory.io/" + x.image} alt={x.name}/>
                                    <div className="rew-amount">x {x.count}</div>
                                </div>
                            )}
                        </div>
                    }
                </div>
            </div>
        )
    }
}