import {Component} from "react";
import {observer} from "mobx-react";
import VolumeRememberedStore from "./VolumeRememberedStore";
import {Chart} from "react-google-charts";
import {Loading} from "../../../shared/Loading";


@observer
export class VolumeRemembered extends Component<{}, {}> {
    private store: VolumeRememberedStore = new VolumeRememberedStore();

    componentDidMount() {
        this.store.loadingStore.fetchRequest();
    //     reaction(
    //         () => [this.store.selector.value],
    //         this.store.loadingStore.fetchRequest
    //     );
    }

    render() {
        if (!this.store.loadingStore.isLoaded)
            return <Loading />
        return (<>
            <span className="sat-name">Объeм запомненой информации</span>
            <span className="sat-name sat-name2">Объeм в элементах и единицах информации</span>

            <div className="circle-tabl-box">
                <div className="pietxt" id="elpie4"></div>
                {this.store.loadingStore.isLoaded &&
                    <Chart
                        chartType="PieChart"
                        data={this.store.data}
                        options={this.store.options}
                        width={"100%"}
                        height={"400px"}
                    />
                }
                {!this.store.loadingStore.isLoaded &&
                    <span className="empty-circle">
                        <span className="empty-stat3">Нет данных</span>
                    </span>
                }
                <table className="elpie-tabel" id="elpie-tabel-vr">
                    <tbody>
                    <tr className="htr">
                        <td>Информация</td>
                        <td>Элементы</td>
                        <td>Единицы</td>
                    </tr>
                    <tr>
                        <td className="htr-num">Числа</td>
                        <td id="number-el">{this.store.getValueOrLoading(this.store.loadingStore.model.Model?.number.el)}</td>
                        <td id="number-count">{this.store.getValueOrLoading(this.store.loadingStore.model.Model?.number.count)}</td>
                    </tr>
                    <tr>
                        <td className="htr-cards">Карты</td>
                        <td id="cards-el">{this.store.getValueOrLoading(this.store.loadingStore.model.Model?.cards.el)}</td>
                        <td id="cards-count">{this.store.getValueOrLoading(this.store.loadingStore.model.Model?.cards.el)}</td>
                    </tr>
                    <tr>
                        <td className="htr-words">Слова</td>
                        <td id="word-el">{this.store.getValueOrLoading(this.store.loadingStore.model.Model?.word.el)}</td>
                        <td id="word-count">{this.store.getValueOrLoading(this.store.loadingStore.model.Model?.word.el)}</td>
                    </tr>
                    <tr>
                        <td className="htr-names">Имена</td>
                        <td id="name-el">{this.store.getValueOrLoading(this.store.loadingStore.model.Model?.name.el)}</td>
                        <td id="name-count">{this.store.getValueOrLoading(this.store.loadingStore.model.Model?.name.el)}</td>
                    </tr>
                    <tr>
                        <td className="htr-bin">Бинарные</td>
                        <td id="bins-el">{this.store.getValueOrLoading(this.store.loadingStore.model.Model?.bins.el)}</td>
                        <td id="bins-count">{this.store.getValueOrLoading(this.store.loadingStore.model.Model?.bins.el)}</td>
                    </tr>
                    <tr>
                        <td className="htr-img">Изображения</td>
                        <td id="image-el">{this.store.getValueOrLoading(this.store.loadingStore.model.Model?.image.el)}</td>
                        <td id="image-count">{this.store.getValueOrLoading(this.store.loadingStore.model.Model?.image.el)}</td>
                    </tr>
                    </tbody>
                </table>

            </div>


            {/*// <!--Удаление "Объём запомненной информации"-->*/}
            <div className="del" id="del-elvol"></div>
        </>);
    }
}