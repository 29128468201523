import {makeAutoObservable} from "mobx";
import {
    PackageLanguageViewModel,
    TrainingLearnLanguageVocabularyViewModel
} from "../model/PackageTrainingCodingViewModel";
import {SelectorPros} from "../../shared/selector/CustomSelector";
import AuthorizationStore from "../../authorization/store/AuthorizationStore";
import {
    StepTrainingRussianLanguage,
    TrainingMemoryRussianCreateViewModel,
    TrainingMemoryRussianStage
} from "../model/LearnLanguageMemberModel";
import KeyboardManager from "../../../core/keyboardManager/adapters/KeyboardManager";
import {GetRequest} from "../../../helpers/Request";

export enum TrainingLearnLanguageMenuType {
    stage = 0,
    step = 1
}

export default class TrainingLearnLanguageBaseStore {
    state: TrainingLearnLanguageMenuType;
    requestModel: TrainingMemoryRussianCreateViewModel;
    startTrainingCoding: (model: TrainingMemoryRussianCreateViewModel) => void;

    packages: PackageLanguageViewModel[] = [];
    currentPackage: PackageLanguageViewModel | undefined;

    vocabularies: TrainingLearnLanguageVocabularyViewModel[] = [];
    vocabulary: TrainingLearnLanguageVocabularyViewModel[] = [];

    get dictionaries(): SelectorPros[] {
        return this.vocabularies.map(x => {
            return {
                title: x.typeName,
                value: x.adjectiveObjectEngType.toString(),
            }
        })
    }

    get editorial(): SelectorPros[] {
        return [{
            title: "PowerMemory",
            value: "PowerMemory"
        },{
            title: "Свои",
            value: "self"
        }]
    }

    get packageWords(): SelectorPros[] {
        return this.vocabularies
            ?.filter(x => x.adjectiveObjectEngType === this.requestModel.languageVocabularyType)[0]
            ?.packages
            ?.map(x => {
                return {
                    title: x.name,
                    value: x.packageId.toString(),
                }
            })
    }

    constructor(startTraining: (model: TrainingMemoryRussianCreateViewModel) => void) {
        makeAutoObservable(this, {}, {autoBind: true});
        this.state = TrainingLearnLanguageMenuType.stage;
        this.startTrainingCoding = startTraining;
        // this.requestSend = false;
        this.requestModel = {
            group: "",
            languageVocabularyType: 1,
            audioRoleId: 1,
            packageId: -1,
            stage: TrainingMemoryRussianStage.FormationLibrary,
            step: StepTrainingRussianLanguage.remembersWords,
            systemCoding: 'PowerMemory',
            rateCoding: 'phonema',
        }
    }

    init() {
        this.request();
        KeyboardManager.add('Escape', this.back);
    }

    unInit() {
        KeyboardManager.remove('Escape', this.back);
    }

    back() {
        this.seStatus(TrainingLearnLanguageMenuType.stage)
    }

    startTraining() {
        if (!AuthorizationStore.checkAuthMenu) {
            AuthorizationStore.openModalAuth();
            return;
        }
        if (this.requestModel.stage === TrainingMemoryRussianStage.IncodeCoding){
            this.requestModel.packageId = undefined;
            this.requestModel.group = '';
        }
        if (this.requestModel.stage === TrainingMemoryRussianStage.FormationLibrary){
            this.requestModel.systemCoding = '';
            this.requestModel.rateCoding = '';
        }
        this.startTrainingCoding(this.requestModel);
    }

    setStages(model: PackageLanguageViewModel[]) {
        this.packages = model;
    }

    setVocabularies(model: TrainingLearnLanguageVocabularyViewModel[]) {
        this.vocabularies = model;
    }

    request() {
        if (AuthorizationStore.isAuthJwt()) {
            GetRequest<PackageLanguageViewModel[]>('/api/LearnLanguage/GetMenu')
                .then(x => {
                    if (x.Model === null)
                        return;
                    this.setStages(x.Model);
                });
            GetRequest<TrainingLearnLanguageVocabularyViewModel[]>('/api/LearnLanguage/GetVocabulary')
                .then(x => {
                    if (x.Model === null)
                        return;
                    this.setVocabularies(x.Model);
                });
        }
    }

    setStage(id: bigint) {
        let stage = this.packages.filter(x => x.id === id)[0];
        this.currentPackage = stage;
        this.requestModel.stage = stage.stage;
        this.state = TrainingLearnLanguageMenuType.step;
    }

    seStatus(base: TrainingLearnLanguageMenuType) {
        this.state = base;
    }

    setStep(stepStr: string) {
        let step = this.currentPackage?.steps.filter(x => x.step?.toString() === stepStr)[0];
        this.requestModel.step = step!.step;
    }
}