import {CustomComponent} from "../CustomComponent";
import React, {ChangeEvent} from "react";
import {observer} from "mobx-react";
import './checkTextComponent.scss'
import KeyboardManager from "../../core/keyboardManager/adapters/KeyboardManager";
import {CheckTextStatus} from "./CheckTextStatus";
import {ReactSVG} from "react-svg";


interface Props {
    changeText: (val: string) => void;
    answer: string;
    checkAnswer: () => void;
    status: CheckTextStatus;
}

@observer
export class CheckTextComponent extends CustomComponent<Props, any> {

    constructor(props: Readonly<Props>) {
        super(props);
        this.changeAnswer = this.changeAnswer.bind(this);
    }

    componentDidMount() {
        KeyboardManager.add('Enter', this.props.checkAnswer);
    }

    componentWillUnmount() {
        KeyboardManager.remove('Enter', this.props.checkAnswer);
    }

    changeAnswer(e: ChangeEvent<HTMLInputElement>) {
        this.props.changeText(e.currentTarget.value)
    }

    get classStatus(): string {
        if (this.props.status === CheckTextStatus.noValid)
            return "incorrect_answer";
        if (this.props.status === CheckTextStatus.valid)
            return "correct_answer";
        return "";
    }

    render() {
        return <div className="check-answer-component">
            <div className={"check-answer_input_block " + this.classStatus}>
                <input autoFocus={true} className="check-answer_input" type="text" value={this.props.answer}
                       onChange={this.changeAnswer}/>
                {
                    this.props.status === CheckTextStatus.noCheck &&
                    <ReactSVG className="check-answer_img"
                              src="/assets/english/check-true-silver.svg"
                              onClick={this.props.checkAnswer}/>
                }
                {
                    this.props.status === CheckTextStatus.noValid &&
                    <ReactSVG className="check-answer_img"
                              src="/assets/english/check-wrong-red.svg"
                              onClick={this.props.checkAnswer}/>
                }
                {
                    this.props.status === CheckTextStatus.valid &&
                    <ReactSVG className="check-answer_img"
                              src="/assets/english/check-true-green.svg"
                              onClick={this.props.checkAnswer}/>
                }
            </div>
        </div>;
    }
}

