import {Component} from "react";
import {observer} from "mobx-react";
import {VolumeEnduranceStore} from "./VolumeEnduranceStore";
import {reaction} from "mobx";
import CustomSelector from "../../../shared/selector/CustomSelector";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {Loading} from "../../../shared/Loading";


@observer
export class VolumeEndurance extends Component<{}, {}> {
    private store: VolumeEnduranceStore = new VolumeEnduranceStore();
    componentDidMount() {
        this.store.loadingStore.fetchRequest();
        reaction(
            () => [this.store.selector.value],
            this.store.loadingStore.fetchRequest
        );
    }

    render() {
        if (!this.store.loadingStore.isLoaded)
            return <Loading />
        return (
            <>
                <span className="sat-name">Выносливость по объёму</span>
                <span className="sat-name sat-name2">Тенденции выносливости</span>

                <CustomSelector
                    defaultValue={this.store.selector.value}
                    onChange={this.store.setSelector}
                    values={this.store.selectors}/>


                {
                    (!this.store.loadingStore.isLoaded || this.store.loadingStore.get?.list.length === 0) &&
                    <span id="empty-text" className="empty-stat2">Для построения графика необходимо запомнить <br/> 30 чисел и выбранный объём</span>
                }
                {
                    this.store.loadingStore.isLoaded &&
                    <HighchartsReact
                        // constructorType={'stockChart'}
                        highcharts={Highcharts}
                        options={this.store.options} />
                }

                <div className="dop-chart1" id="recend-dop">
                    <div id="recenddop1">
                        <span className="amount-actstl" id="amount-percent">-</span>
                        <span className="txt-actstl">средний прирост темпа</span>
                    </div>
                    <div id="recenddop2">
                        <span className="amount-actstl" id="amount-days">-</span>
                        <span className="txt-actstl">дней до достижения выносливости 100%</span>
                    </div>
                </div>

                {/*// <!--Удалить данные "Тенденции выносливости"-->*/}
                <div className="del" id="del-tb-recend" title="Отчистить данные графика"></div>
            </>
        );
    }
}