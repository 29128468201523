import React from "react";
import AuthorizationStore from "./store/AuthorizationStore";
import './authorization.scss'
import {observer} from "mobx-react";
import {Avatar} from "../user/Avatar";
import {CustomComponent} from "../../helpers/CustomComponent";
import {HProfileUserMenu} from "./HProfileUserMenu";
import {StoneComponent} from "../stone/StoneComponent";
import {AuthorizationModal} from "./AuthorizationModal";
import {TypeScreen, UseScreenType} from "../../context/TypeScreen";


@observer
export class Authorization extends CustomComponent<{}, {}> {

    constructor(props: Readonly<{}>) {
        super(props);
        this.showMenu = this.showMenu.bind(this);
        this.hideMenu = this.hideMenu.bind(this);
    }

    showMenu() {
        if (AuthorizationStore.showMenu) {
            this.hideMenu();
        } else {
            AuthorizationStore.setShowMenu(true);
        }
    }

    hideMenu() {
        AuthorizationStore.setShowMenu(false);
    }

    render() {
        if (!AuthorizationStore.checkAuthMenu) {
            return <AuthorizationModal />
        }
        if (UseScreenType() === TypeScreen.Mobile)
            return <></>
        return <div className="authorization-block">
            {AuthorizationStore.checkAuthMenu &&
                <div className="container-user-login" onMouseLeave={this.hideMenu}>
                    <StoneComponent />
                    <div className="ava-header-label" onClick={this.showMenu}>
                        <Avatar fullname={AuthorizationStore.user.Model?.fullname ?? ""}
                                img={AuthorizationStore.user.Model!.imgSrc}
                                link={AuthorizationStore.user.Model!.link}
                                canClickMnemocard={false}
                                height='33px' width='33px'/>
                    </div>
                    {
                        AuthorizationStore.showMenu &&
                        <HProfileUserMenu />
                    }
                </div>
            }
        </div>;
    }
}