import {CustomComponent} from "../CustomComponent";
import './resultTrainingComponent.scss';
import {UserInfoComponent} from "../../components/trainingResult/view/UserInfoComponent";
import {Chart} from "react-google-charts";
import {CheckScreen, TypeScreen} from "../../context/TypeScreen";
import {ResAndShareBox} from "../../components/trainingResult/view/ResAndShareBox";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React from "react";
import ConstantsColor from "../ConstantsColor";
import {observer} from "mobx-react";
import {BestTrainingType} from "../../components/trainingResult/model/TrainingResultModel";
import {ReactModalStore} from "../../components/modal/ReactModalStore";
import {getDateRes, getIndexResult, getTimeRes, msToTime, msToTimeSecond} from "../numberHelp/numberHelp";
import {SharedComponent} from "../../components/trainingResult/view/SharedComponent";

interface Props {
    fullname: string,
    imgSrc: string,
    rang: string,
    isPremium: boolean,
    link: string,
    bestTraining: BestTrainingType,
    dis: string,
    date: string,
    id: string,
    speed: number
    countRightImages?: number
    timeAll: number
    textRight: string;
    coefficient: number;

    error: number
    index: number

    items: { dictionary: string, answer: string, timeOnElement: number, evenly: boolean }[]
}

@observer
export class ResultTrainingComponent extends CustomComponent<Props, any> {
    private store: ResultTrainingStore;

    constructor(props: Readonly<Props>) {
        super(props);
        this.store = new ResultTrainingStore(this.props);
    }

    get dataPie() {
        return [
            ['Состояние', 'Кол-во'],
            ['Правильные', (this.props.items.length ?? 0) - (this.props.error ?? 0)],
            ['Ошибки', (this.props.error ?? 0)]
        ];
    }

    get opinionHighcharts(): Highcharts.Options {
        // todo fix it
        // return getOpinion({
        //     nameSeries: 'Активность тренировок',
        //     valueDecimals: 0,
        //     date: this.loadingStore?.model.Model?.data?.map((x, y) => {
        //         return {date: x.date, value: x.count}
        //     }) ?? []
        // })
        return {
            chart: {
                type: 'arearange',
                zooming: {
                    type: 'x'
                },
            },
            rangeSelector: {
                verticalAlign: 'top',
                allButtonsEnabled: true,
                selected: 2,
                floating: true,
                // y: 250,
                inputPosition: {
                    align: 'left',
                    x: 0,
                    y: 0
                },
                buttonPosition: {
                    align: 'right',
                    x: 0,
                    y: 0
                },
            },
            tooltip: {
                formatter: function () {
                    let s = `<br />Номер: ${this.x}`;
                    s += `<br />Время: ${this.y}c.`;
                    s += `<br />Значение: ${this.point.custom.dictionary}`;
                    s += `<br />Ответ: ${this.point.custom.answer}`;
                    return s;
                },
                shared: true,
            },
            title: {
                text: 'Темп запоминания'
            },
            xAxis: {
                allowDecimals: false,
                min: 1,
                minPadding: 0,
                maxPadding: 0,
                plotLines: [{
                    color: '#888',
                    value: 0,
                    width: 1,
                    label: {
                        rotation: 90
                    }
                }],
            },
            yAxis: [{
                lineWidth: 1,
                gridLineWidth: 1,
                title: {
                    text: ''
                },
                tickWidth: 1,
                tickLength: 5,
                labels: {
                    align: 'left',
                    x: 8
                }
            }],
            plotOptions: {
                series: {
                    stacking: 'normal'
                },
                area: {
                    fillOpacity: 1,
                    lineWidth: 1,
                    step: 'center'
                }
            },
            series: [{
                name: '',
                data: this.props.items.map((x, index) => {
                    return {
                        y: x.timeOnElement / 1000,
                        x: index,
                        color: x.evenly ? ConstantsColor.Green : ConstantsColor.Red,
                        custom: {
                            time: x.timeOnElement,
                            dictionary: x.dictionary,
                            evenly: x.evenly,
                            answer: x.answer,
                        }
                    }
                }) ?? [],
                type: 'column',
                borderColor: "#ffffff",
                showInLegend: false,
                tooltip:
                    {
                        valueDecimals: 1
                    }
            }]
        };
    }


    render() {
        return <>
            <SharedComponent id={this.props!.id} store={this.store.sharedStore}/>
            <div className="result-training-component">
                <UserInfoComponent
                    fullname={this.props.fullname}
                    imgSrc={this.props.imgSrc}
                    rang={this.props.rang}
                    isPremium={this.props.isPremium}
                    link={this.props.link}
                    bestTraining={this.props.bestTraining}
                    dis={this.props.dis}
                    timeGet={this.store.timeGet}
                    dateGet={this.store.dateGet}
                    openCloseDiagram={this.store.openCloseDiagram}
                    id={this.props.id}
                    sharedOpen={() => {
                    }}
                />

                {/*@*Круговая диаграмма*@*/}
                <div className="res-diagram-box">
                    <Chart
                        chartType="PieChart"
                        data={this.dataPie}
                        options={optionsPie}
                        width={'210px'}
                        height={'210px'}
                    />

                    <div id="res-diagram">
                        {
                            this.store.error !== '0' &&
                            <span className="spincrement" id="err-diagram">{this.props.error}</span>
                        }
                        <span className="spincrement" id="right-diagram">{this.store.rightDiagram}</span>
                        <span className="spincrement" id="right-number">{this.store.right} {this.props.textRight}</span>
                    </div>
                </div>

                {/*@*Время запоминания и индекс*@*/}
                <div className="res-parametrs-box">
                    <div id="res-index" data-title="Индекс запоминания">
                        <div id="index">{this.store.index}</div>
                    </div>
                    <div id="res-time" data-title="Время запоминания [MM:СС:mmm]">{this.store.timeAll}</div>
                    <div className="res-speed-box">
                        <div id="res-speed" data-title="Темп запоминания [CС:ммм]">{this.store.speedMiddle}</div>
                        <div className="max-min-speed-box">
                            <div id="max-res-speed">{this.store.speedMax}</div>
                            <div id="min-res-speed">{this.store.speedMin}</div>
                        </div>
                    </div>
                </div>
                {
                    CheckScreen([TypeScreen.Mobile]) &&
                    <ResAndShareBox timeGet={this.store.timeGet}
                                    dateGet={this.store.dateGet}
                                    toShare={this.store.sharedOpen}
                                    openCloseDiagram={this.store.openCloseDiagram}/>
                }
            </div>
            {
                this.store.showDiagram &&
                <div id="element-diagram">
                    <HighchartsReact
                        containerProps={{
                            style: {
                                width: '100%',
                                height: '500px',
                                display: 'flex',
                                justifyContent: 'center'
                            }
                        }}
                        highcharts={Highcharts}
                        options={this.opinionHighcharts}/>
                </div>
            }
        </>
    }
}

class ResultTrainingStore {
    props: Props;
    showDiagram: boolean = false;
    sharedStore = new ReactModalStore();

    constructor(props: Props) {
        this.props = props;
    }

    sharedOpen() {
        this.sharedStore.open();
    }

    openCloseDiagram() {
        this.showDiagram = !this.showDiagram;
    }

    get speedMiddle(): string {
        return msToTimeSecond(this.props.speed * 1000);
    }

    get rightDiagram(): string {
        return ((this.props.items.length - this.props.error) * this.props.coefficient).toString();
    }

    get right(): string {
        return this.props.countRightImages?.toString() ?? "";
    }

    get speedMax(): string {
        return msToTimeSecond(Math.min.apply(Math, this.props.items
            .map(function (val) {
                return val.timeOnElement;
            })));
    }

    get error(): string {
        return (this.props.error * this.props.coefficient).toString();

    }

    get speedMin(): string {
        return msToTimeSecond(Math.max.apply(Math, this.props.items
            .map(function (val) {
                return val.timeOnElement;
            })));
    }

    get timeGet(): string {
        return getTimeRes(this.props.date);

    }

    get dateGet(): string {
        return getDateRes(this.props.date);
    }

    get index(): string {
        return getIndexResult(this.props.index);
    }

    get timeAll(): string {
        return msToTime(this.props.timeAll);
    }
}

let optionsPie = {
    title: "",
    pieHole: 0.8,
    colors: [ConstantsColor.Green, ConstantsColor.Red],
    chartArea: {left: 0, top: 0, width: '100%', height: '100%'},
    legend: 'none',
    pieSliceText: "none",
};