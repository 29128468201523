import React from 'react'
import './mnemocardComponent.scss'
import {observer} from "mobx-react";
import {MnemocardUserInfoComponent} from "./MnemocardUserInfoComponent";
import {MnemocardRecordsComponent} from "./MnemocardRecordsComponent";
import {MnemoCartInfoComponent} from "./MnemoCartInfoComponent";
import {MnemocardRewardsComponent} from "./MnemocardRewardsComponent";
import {CustomComponent} from "../../helpers/CustomComponent";
import MnemocardStore from "./store/MnemocardStore";


@observer
export class MnemocardComponent extends CustomComponent<{}, {}> {

    constructor(props: Readonly<{}>) {
        super(props);
        this.clickAwards = this.clickAwards.bind(this);
        this.clickInformation = this.clickInformation.bind(this);
        this.clickRecords = this.clickRecords.bind(this);
    }

    componentDidMount() {
        MnemocardStore.init();
    }

    componentWillUnmount() {
        MnemocardStore.unInit();
    }

    clickAwards() {
        MnemocardStore.setType('awards');
    }

    clickInformation() {
        MnemocardStore.setType('information');
    }

    clickRecords() {
        MnemocardStore.setType('records');
    }

    render() {
        if (!MnemocardStore.mnemoCart.IsLoaded || !MnemocardStore.mnemoCart.Model)
            return null;
        return <div className="mnemocard-block">
            <MnemocardUserInfoComponent/>
            <div className="mnemocard-tabs">
                <div className={"mnemocard-tab" + MnemocardStore.getActiveTabClass('records')}
                     onClick={this.clickRecords}>Рекорды
                </div>
                <div className={"mnemocard-tab" + MnemocardStore.getActiveTabClass('awards')}
                     onClick={this.clickAwards}>Награды
                </div>
                <div className={"mnemocard-tab" + MnemocardStore.getActiveTabClass('information')}
                     onClick={this.clickInformation}>Информация
                </div>
            </div>
            {
                MnemocardStore.mnemoCartType === 'records' &&
                <MnemocardRecordsComponent results={MnemocardStore.mnemoCart.Model.results}/>
            }
            {
                MnemocardStore.mnemoCartType === 'awards' &&
                <MnemocardRewardsComponent/>
            }
            {
                MnemocardStore.mnemoCartType === 'information' &&
                <MnemoCartInfoComponent/>
            }
        </div>
    }
}