import {CustomComponent} from "../CustomComponent";
import './textAreaComponent.scss'
import {observer} from "mobx-react";
import {ChangeEvent} from "react";

interface Props {
    onchange?: (val: string) => void,
    value?: string,
}

@observer
export class TextAreaComponent extends CustomComponent<Props, any> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(ex: ChangeEvent<HTMLTextAreaElement>) {
        if (this.props.onchange)
            this.props.onchange(ex.target.value);
    }

    render() {
        return <div className="text-area-component">
            <textarea className="text-area-input"
                      value={this.props.value}
                      onChange={this.onChange}/>
        </div>;
    }
}