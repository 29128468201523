import React from 'react'
import './settingsCodingItemsPage.scss'
import {observer} from "mobx-react";
import Modal from "../../components/modal/ReactModal";
import {CustomComponent} from "../../helpers/CustomComponent";
import {SettingsCodingItemsStore} from "../../components/settingsCoding/SettingsCodingItemsStore";
import {Loading} from "../../components/shared/Loading";
import {SettingsCodingItem} from "../../components/statistics/component/SettingsCodingItem/SettingsCodingItem";
import {chunk, chunkSize} from "../../helpers/numberHelp/numberHelp";
import {Link, SetURLSearchParams, useParams, useSearchParams} from "react-router-dom";
import UploadFileComponent from "../../helpers/UploadFileComponent";
import {ReactModalStore} from "../../components/modal/ReactModalStore";
import TitleStore from "../../components/authorization/store/TitleStore";

interface Props {
    mode: string,
    type?: string,
    search: URLSearchParams,
    setSearch: SetURLSearchParams
}

@observer
class SettingsCodingItemsPage extends CustomComponent<Props, {}> {
    private readonly reactModalStore: ReactModalStore = new ReactModalStore();
    store: SettingsCodingItemsStore;

    componentDidMount() {
        TitleStore.setTitle("Настройка кодирования");
        this.store.fetchRequest();
    }

    constructor(props: Readonly<Props>) {
        super(props);
        this.clickMK = this.clickMK.bind(this);
        this.clickChange = this.clickChange.bind(this);
        this.restoreAll = this.restoreAll.bind(this);

        this.store = new SettingsCodingItemsStore(this.props.mode, this.props.search, this.props.setSearch, this.props.type)
    }

    clickMK() {
        this.store.setMK(!this.store.isMK);
    }

    clickChange() {
        this.store.setChange(!this.store.isChange);
    }

    restoreAll() {
        this.store.restoreAll()
    }

    get textMK() {
        if (this.store.isMK)
            return "Отключить КМ";
        return "Порядок КМ";
    }

    get textChange() {
        if (this.store.isChange)
            return "Замена / восст. ВЫКЛ";
        return "Замена / восст. ВКЛ";
    }


    render() {
        return (
            <div className="settings-coding-items">
                <section className="premium-user-bd">
                    <div>
                        <div id="panel-bd">
                            <Link to={"/SettingsCoding"} className="bottom-pbd" id="pbd-back"/>
                            {
                                this.store.hasRowMK &&
                                <div className="bottom-pbd" id="km-list" onClick={this.clickMK}>{this.textMK}</div>
                            }
                            <div className="bottom-pbd" id="replacement-all" onClick={this.restoreAll}>Восстановить
                                всё
                            </div>
                            <div className="bottom-pbd" id="off-change"
                                 onClick={this.clickChange}>{this.textChange}</div>
                        </div>
                        <div className="settings-coding-body">
                            {
                                !this.store.loadingStore.isLoaded &&
                                <Loading key="loading"/>
                            }
                            {
                                this.store.loadingStore.isLoaded && this.store.models &&
                                <>
                                    {
                                        !this.store.isMK &&
                                        chunk(this.store.models, 10).map((x, i) =>
                                            <div className="view-cods-box clearfix" key={"chunk_not_isMK_" + i}>
                                                <span className="row-cod-name">KM {i}</span>
                                                {x.map((y, _) =>
                                                    <SettingsCodingItem isChange={this.store.isChange}
                                                                        item={y}
                                                                        clickRestore={this.store.clickRestore}
                                                                        clickChange={this.store.onChangeImage}
                                                                        changeNameSettingsCoding={this.store.changeNameSettingsCoding}
                                                                        key={y.id}/>
                                                )}
                                            </div>
                                        )
                                    }
                                    {
                                        this.store.isMK &&
                                        chunkSize(this.store.models, 10).map((x, i) =>
                                            <div className="view-cods-box clearfix" key={"chunk_isMK_" + i}>
                                                <span className="row-cod-name">KM {i}</span>
                                                {x.map((y, _) =>
                                                    <SettingsCodingItem isChange={this.store.isChange}
                                                                        item={y}
                                                                        clickRestore={this.store.clickRestore}
                                                                        clickChange={this.store.onChangeImage}
                                                                        changeNameSettingsCoding={this.store.changeNameSettingsCoding}
                                                                        key={y.id}/>
                                                )}
                                            </div>
                                        )
                                    }
                                </>
                            }
                        </div>
                    </div>
                </section>
                <Modal store={this.reactModalStore}>
                    <div id="dialog-form" title="Загрузка кодов" style={{width: '250px'}}>
                        <p className="validateTips" id="name-code">Загрузить
                            код: {this.store.getNameChangeImage()}</p>
                        <UploadFileComponent title={"Загрузите файл"} afterUpload={this.store.afterUpload} />
                    </div>
                </Modal>
            </div>
        )
    }
}

function SettingsCodingItemsRoutePage() {
    const [search, setSearch] = useSearchParams();
    const param = useParams();
    return (
        <SettingsCodingItemsPage mode={param['mode']!} type={param['type']} search={search} setSearch={setSearch}/>
    );
}

export default SettingsCodingItemsRoutePage;