import {CustomComponent} from "../CustomComponent";
import {WriteAnswerStore} from "./WriteAnswerStore";
import {ChangeEvent} from "react";
import {observer} from "mobx-react";
import './writeAnswer.scss'

interface Props {
    question?: string;
    placeholder?: string;
}

@observer
export class WriteAnswer extends CustomComponent<Props, any> {
    private store = new WriteAnswerStore();

    constructor(props: Readonly<Props>) {
        super(props);
        this.changeAnswer = this.changeAnswer.bind(this);
    }

    changeAnswer(e: ChangeEvent<HTMLInputElement>) {
        this.store.changeAnswer(e.currentTarget.value)
    }

    render() {
        return <div className="write-answer">
            <div className="write-answer_qustion">{this.props.question}</div>
            <div className="write-answer_answer">
                <input className="write-answer_input" placeholder={this.props?.placeholder ?? "Ваш ответ"} type="text"
                       onChange={this.changeAnswer}/>
            </div>
        </div>;
    }
}