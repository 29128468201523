import {WordAdminView} from "../model/ProductView";
import {LoadingModel} from "../../shared/LoadingModel";
import {IAdministrationStore} from "./IAdministrationStore";
import {AdministrationRepository} from "../repository/AdministrationRepository";
import {BaseAdministrationStore} from "./BaseAdministrationStore";

export class WordsStore implements IAdministrationStore<WordAdminView> {
    private repository = new AdministrationRepository();
    baseStore = new BaseAdministrationStore<WordAdminView>(this);

    constructor() {
    }

    requestLocal(): Promise<LoadingModel<WordAdminView[]>> {
        return this.repository.words();
    }
}