import {makeAutoObservable} from "mobx";
import {CodingResultResponseViewModel} from "../model/CodingResultMember";
import {getDateRes, getTimeRes, msToTime, msToTimeSecond} from "../../../helpers/numberHelp/numberHelp";


export default class TrainingSportResultStore {
    model: CodingResultResponseViewModel

    constructor(model: CodingResultResponseViewModel) {
        makeAutoObservable(this, {}, {autoBind: true});
        this.model = model;
    }

    get speedMiddle(): string {
        return msToTimeSecond(this.model.speed);
    }

    get speedMax(): string {
        return msToTimeSecond(Math.min.apply(Math, this.model.list
            .map(function (val) {
                return val.timeOfElement;
            })));
    }

    get speedMin(): string {
        return msToTimeSecond(Math.max.apply(Math, this.model.list
            .map(function (val) {
                return val.timeOfElement;
            })));
    }

    get getName(): string {
        if (this.model.styleCoding === "XClean")
            return "Кодирование (Чистое)"
        if (this.model.styleCoding === "imageClean")
            return "Декодирование (Чистое)"
        if (this.model.styleCoding === "X2")
            return "Кодирование (Выбор)"
        return "Декодирование (Выбор)"
    }

    get timeGet(): string {
        return getTimeRes(this.model.dateTime);
    }

    get dateGet(): string {
        return getDateRes(this.model.dateTime);
    }

    get timeAll(): string {
        return msToTime(this.model.timeAll);
    }
}