import {observer} from "mobx-react";
import {CustomComponent} from "../../../../../helpers/CustomComponent";
import {RequiredRequiredTraining} from "../NewRankModel";
import {StandartsRequiredsViewRank} from "./StandartsRequiredsViewRank";

export interface StandartsRankProps{
    numberPosition: number;
    countRequired: number;
    trainings: RequiredRequiredTraining[];
}
@observer
export class StandartsRank extends CustomComponent<StandartsRankProps, {}> {

    get countRequired(): number{
        return this.props.trainings.filter(x => x.isFound).length
    }

    render() {
        return (<>
            {
                this.countRequired < this.props.countRequired &&
                <div className="ex-title-name">Нормативы на выбор: {this.countRequired} из {this.props.countRequired}</div>
            }
            {
                this.countRequired >= this.props.countRequired &&
                <div className="ex-title-name ex-title-success">Нормативы на выбор выполнены ({this.countRequired} из {this.props.countRequired})</div>
            }
            <div className="ex-standard-main-box">
                {this.props.trainings.map((x) =>
                    <StandartsRequiredsViewRank key={x.mode + "_" + x.speed + "_" + x.number}
                                                numberPosition={this.props.numberPosition} training={x} />)}
            </div>
        </>);
    }
}