import React from 'react'
import './trainingLearnLanguageMemberWriteComponent.scss'
import {observer} from "mobx-react";
import TrainingLearnLanguageMemberStore from "./store/TrainingLearnLanguageMemberStore";
import StopwatchService from "../../helpers/StopwatchService";
import AuthorizationStore from "../authorization/store/AuthorizationStore";
import {
    TrainingMemoryRussianMemberViewModel
} from "./model/LearnLanguageMemberModel";
import {CustomComponent} from "../../helpers/CustomComponent";
import {LanguageMemberTransportFunctionModel} from "./model/LanguageMemberTransportFunctionModel";
import {HeaderTrainingLearnLanguageComponent} from "./components/HeaderTrainingLearnLanguageComponent";


interface Props {
    transportModel: LanguageMemberTransportFunctionModel,
    model: TrainingMemoryRussianMemberViewModel
}
@observer
export class TrainingLearnLanguageMemberComponent extends CustomComponent<Props, {}> {
    store: TrainingLearnLanguageMemberStore;

    constructor(props: Readonly<Props>) {
        super(props);
        this.store = new TrainingLearnLanguageMemberStore(this.props.transportModel,
            this.props.model);
    }

    componentDidMount() {
        AuthorizationStore.setShowLayoutHead(false);
        StopwatchService.start();

        this.store.init();
    }
    componentWillUnmount() {
        this.store.unmount();

        StopwatchService.clearTimer()
        AuthorizationStore.setShowLayoutHead(true);
    }

    render() {
        return (
            <div className="training-learn-language">
                <HeaderTrainingLearnLanguageComponent
                    finishTraining={this.store.finishTrainingClick} />
                <div className="language-block">
                    {this.store.view}
                </div>
            </div>
        )
    }
}