import React, {Component, Suspense} from 'react'
import './universityPage.scss'
import {observer} from "mobx-react";
import TitleStore from "../../components/authorization/store/TitleStore";
import {Route, Routes} from "react-router-dom";
import {Loading} from "../../components/shared/Loading";
import {BlockTextComponent} from "../../helpers/blockText/BlockTextComponent";
import {TextAreaComponent} from "../../helpers/textArea/TextAreaComponent";
import {ManyAnswer} from "../../helpers/manyAnswers/ManyAnswer";
import {MiddleTextComponent} from "../../helpers/middleText/MiddleTextComponent";
import {UniversityModuleComponent} from "../../components/university/UniversityModuleComponent";
import {HomeWorkComponent} from "../../components/university/HomeWorkComponent";
import {GlobalUniversityStore} from "../../components/university/store/GlobalUniversityStore";
import {MatryoshkaComponent} from "../../components/university/matryoshka/MatryoshkaComponent";
import {MatryoshkaModel} from "../../components/university/matryoshka/MatryoshkaModel";

let question1 = [
    {title: "Мнемоника как драйвер", isSelected: false},
    {title: "Понятие", isSelected: false},
    {title: "Закон мнемоники", isSelected: false},
    {title: "Стимул -> Реакция", isSelected: false},
    {title: "Зачем?", isSelected: false},
    {title: "Почему?", isSelected: false},
    {title: "Цепочка", isSelected: false},
    {title: "Матрёшка", isSelected: false},
    {title: "Отличность", isSelected: false},
    {title: "Проникновение", isSelected: false},
    {title: "Мысленный кадр", isSelected: false},
    {title: "Мысленный экран", isSelected: false},
    {title: "Образ", isSelected: false},
    {title: "Образ мысленный", isSelected: false},
    {title: "Образ реальности", isSelected: false},
    {title: "Часть образа", isSelected: false},
    {title: "Энергоэффективность", isSelected: false},
    {title: "Быстрый подсмотр с усилением", isSelected: false},
    {title: "Нет лишних слов/звуков", isSelected: false},
    {title: "Не лишних движений", isSelected: false},
    {title: "Тело расслабленно", isSelected: false},
    {title: "Глазодвигательный паттерн «Фокус-Расфокус»", isSelected: false},
    {title: "Хладнокровие", isSelected: false},
    {title: "Доверие к памяти", isSelected: false},
    {title: "Нет логической ошибки обобщения", isSelected: false},
    {title: "Отношение к связи как к первой", isSelected: false},
    {title: "Нет лишних мысленных операций", isSelected: false},
    {title: "Цикл усиления связей", isSelected: false},
    {title: "Связь не создана", isSelected: false},
    {title: "Связь слабая", isSelected: false},
    {title: "Связь сильная", isSelected: false},
    {title: "Связь рефлекторная", isSelected: false},
]

let matryoshka: MatryoshkaModel = {
    id: BigInt(1),
    countRetries: 0,
    items: [{
        id: BigInt(0),
        word: "бокал",
    }, {
        id: BigInt(1),
        word: "иллюминатор",
    }, {
        id: BigInt(2),
        word: "тормоз",
    }, {
        id: BigInt(3),
        word: "казино",
    }, {
        id: BigInt(4),
        word: "забор",
    },]
}

@observer
export class UniversityPage extends Component<{}, {}> {
    private store: GlobalUniversityStore = new GlobalUniversityStore();

    componentDidMount() {
        TitleStore.setTitle("Университет");
        this.store.init()
    }

    componentWillUnmount() {
        this.store.uninit();
    }

    render() {
        return (<>
                <div className="container-university">
                    {/*<NavBurger models={this.navBugrers}/>*/}
                    <div className="stat-page" id="recordsboard">
                        <Routes>
                            <Route path="textArea" element={<Suspense fallback={<Loading/>}>
                                <BlockTextComponent hasBackground={true}>
                                    <TextAreaComponent/>
                                </BlockTextComponent>
                            </Suspense>}/>
                            <Route path="manyAnswers" element={<Suspense fallback={<Loading/>}>
                                <BlockTextComponent hasBackground={true}>
                                    <ManyAnswer models={question1}/>
                                </BlockTextComponent>
                            </Suspense>}/>
                            <Route path="middleText" element={<Suspense fallback={<Loading/>}>
                                <BlockTextComponent hasBackground={true}>
                                    <MiddleTextComponent/>
                                </BlockTextComponent>
                            </Suspense>}/>
                            <Route path="homeWork/*" element={<Suspense fallback={<Loading/>}>
                                <HomeWorkComponent/>
                            </Suspense>}/>
                            <Route path="matryoshka/*" element={<Suspense fallback={<Loading/>}>
                                <MatryoshkaComponent model={matryoshka} finish={() => {}}/>
                            </Suspense>}/>
                            <Route path="*" element={<Suspense fallback={<Loading/>}>
                                <UniversityModuleComponent/>
                            </Suspense>}/>
                        </Routes>
                    </div>
                </div>
            </>
        )
    }
}