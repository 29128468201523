import {makeAutoObservable} from "mobx";
import {LoadingModel} from "../../shared/LoadingModel";
import {GetRequest, PostRequest} from "../../../helpers/Request";
import {CommentResViewModel, CommentUserResViewModel, PostModel} from "../model/PostModel";
import TitleStore from "../../authorization/store/TitleStore";

export class PostStore {
    commentsRef?: HTMLDivElement | null;

    model: LoadingModel<PostModel> = LoadingModel.createLoaded<PostModel>();
    link: string;
    comms: CommentResViewModel[] | undefined;
    commentText: string = "";

    constructor(link: string) {
        makeAutoObservable(this, {}, {autoBind: true})
        this.link = link;
    }

    init() {
        this.commentsRef?.scrollIntoView()
    }

    get getLink() {
        return "https://powermemory.io/News/" + this.model.Model!.link
    }

    setModel(model: LoadingModel<PostModel>) {
        this.model = model;
        this.comms = model.Model?.commsUser.comms ?? [];
        TitleStore.setTitle("Новости: " + this.model.Model?.pageName);
        console.log('comments', this.commentsRef)
        this.commentsRef?.scrollIntoView()
    }

    setModelComment(model: LoadingModel<CommentUserResViewModel>) {
        this.model.Model!.commsUser.comms = model.Model!.comms;
        this.comms = model.Model!.comms ?? [];
    }

    changeTextComment(text: string) {
        this.commentText = text;
    }

    fetchRequest() {
        this.model.IsLoaded = false;
        GetRequest<PostModel>('/Post/GetPostJson?link=' + this.link)
            .then(x => this.setModel(x))
            .then(x => console.log(x))
    }

    clickLike(commentId: string) {
        PostRequest<CommentUserResViewModel, string>('/Post/ClickLike?id=' + this.model.Model!.id.toString() +
            '&idComm=' + commentId, "")
            .then(x => this.setModelComment(x));
    }

    clickDelete(commentId: string) {
        let res = {
            id: this.model.Model!.id,
            commentId: commentId
        };
        PostRequest<CommentUserResViewModel, object>('/Post/RemoveComment', res)
            .then(x => this.setModelComment(x))
    }

    createComment() {
        let res = {
            id: this.model.Model!.id,
            text: this.commentText
        };
        PostRequest<CommentUserResViewModel, object>('/Post/CreateComment', res)
            .then(x => this.setModelComment(x));
        this.commentText = "";
    }

    clickScrollComments() {
        console.log('clickScoll', this.commentsRef);
        this.commentsRef?.scrollIntoView();
    }
}