import {CustomComponent} from "../../../helpers/CustomComponent";
import {CustomButton} from "../../../helpers/CustomButton";
import Modal from "../../modal/ReactModal";
import React from "react";
import StopwatchService from "../../../helpers/StopwatchService";
import {ReactModalStore} from "../../modal/ReactModalStore";
import HeaderTrainingLearnLanguageStore from "../store/HeaderTrainingLearnLanguageStore";
import {observer} from "mobx-react";

interface Props {
    finishTraining : (() => void) | undefined
    hint? : (() => void) | undefined
}
@observer
export class HeaderTrainingLearnLanguageComponent extends CustomComponent<Props, any> {
    private readonly reactModalStore: ReactModalStore = new ReactModalStore();
    constructor(props: Readonly<Props>) {
        super(props);
        this.closeTraining = this.closeTraining.bind(this);
    }

    componentDidMount() {
        this.reactModalStore.init();
        HeaderTrainingLearnLanguageStore.setFinishTraining(this.props.finishTraining)
    }

    componentWillUnmount() {
        this.reactModalStore.unInit();
        HeaderTrainingLearnLanguageStore.clear();
    }

    closeTraining(){
        window.location.reload();
    }

    render() {
        return <>
            <Modal store={this.reactModalStore}>
                <div id="dialog-form" title="Закрыть">
                    <p><span className="ui-icon ui-icon-alert"
                             style={{float: 'left', margin: '12px 12px 20px 0'}}>
                        </span>Вы уверены что хотите завершить тренировку?</p>
                    <div className="dialog-form-block-buttons">
                        <CustomButton text="Нет" onclick={this.reactModalStore.close}/>
                        <CustomButton text="Закрыть тренировку" onclick={this.closeTraining}/>
                        {
                            ((HeaderTrainingLearnLanguageStore.numberElNow ?? 0) >= 10) &&
                            <CustomButton text="Показ результатов" onclick={HeaderTrainingLearnLanguageStore.finishClick}/>
                        }
                    </div>
                </div>
            </Modal>
            <header id="header-training">
                <div id="header-training-box">
                    {
                        !HeaderTrainingLearnLanguageStore.hasHint() &&
                        <div id="show-me-the-code" style={{backgroundImage: 'none'}}></div>
                    }
                    {
                        HeaderTrainingLearnLanguageStore.hasHint() &&
                        <div id="show-me-the-code" onClick={HeaderTrainingLearnLanguageStore.hintClick}
                             style={{backgroundImage: 'url(https://powermemory.io/images/icons/show-cod.svg)'}}></div>
                    }
                    <div id="timer-abcd">{StopwatchService.currentTimeStr}</div>
                    <div id="f-finish" onClick={this.reactModalStore.open} title="Закрыть тренировку"></div>
                </div>
            </header>
        </>;
    }
}