import React from 'react'
import './settingsCodingPage.scss'
import {Link, useSearchParams} from "react-router-dom";
import TitleStore from "../../components/authorization/store/TitleStore";


export function SettingsCodingPage() {
    TitleStore.setTitle("Настройки кодирования");
    const [search] = useSearchParams();
    const getUsersId = (): string => {
        if (search.has('userId')) {
            return "?userId=" + search.get('userId')
        }
        return "";
    }

    return <div className="settings-coding-page">
        <div className="main-select-ok">
            <label className="mso-title">Версия образов</label>
            <div className="select-style statchosen">
                <select className="chosen-select" id="discipline-type">
                    <option value="my-ok">Моя редакция</option>
                    <option value="powermemory-ok">PowerMemory</option>
                </select>
            </div>
        </div>

        <section id="site-bd">
            <div className="bd-block" id="bd-ok">
                <span>Цифры и числа</span>
                <table className="bd-table">
                    <tbody>
                    <tr>
                        <td>10</td>
                        <td>0-9</td>
                        <td><Link className="settings-coding-change-click" to={"/SettingsCoding/9" + getUsersId()}/>
                        </td>
                    </tr>
                    <tr>
                        <td>100</td>
                        <td>00-99</td>
                        <td><Link className="settings-coding-change-click" to={"/SettingsCoding/99" + getUsersId()}/>
                        </td>
                    </tr>
                    <tr>
                        <td>1000</td>
                        <td>000-999</td>
                        <td><Link className="settings-coding-change-click" to={"/SettingsCoding/999" + getUsersId()}/>
                        </td>
                    </tr>
                    </tbody>
                </table>
                {/*@if (response.IsShowEng)*/}
                {/*{*/}
                <span>English v.3.0 (06.2023)</span>
                <table className="bd-table">
                    <tbody>
                    <tr>
                        <td>12</td>
                        <td>Фонемные коды</td>
                        <td><Link className="settings-coding-change-click"
                                  to={"/SettingsCoding/eng/phonemaEng" + getUsersId()}/></td>
                    </tr>
                    <tr>
                        <td>33</td>
                        <td>Букво-фонемные коды</td>
                        <td><Link className="settings-coding-change-click"
                                  to={"/SettingsCoding/eng/symbolPhonemaEng" + getUsersId()}/></td>
                    </tr>
                    <tr>
                        <td>45</td>
                        <td>Суффиксные коды</td>
                        <td><Link className="settings-coding-change-click"
                                  to={"/SettingsCoding/eng/syffixCodeEng" + getUsersId()}/></td>
                    </tr>
                    <tr>
                        <td>33</td>
                        <td>Префиксные коды</td>
                        <td><Link className="settings-coding-change-click"
                                  to={"/SettingsCoding/eng/prefixCodeEng" + getUsersId()}/></td>
                    </tr>
                    <tr>
                        <td>20</td>
                        <td>Корневые коды</td>
                        <td><Link className="settings-coding-change-click"
                                  to={"/SettingsCoding/eng/rootCodeEng" + getUsersId()}/></td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>Коды-значения</td>
                        <td><Link className="settings-coding-change-click"
                                  to={"/SettingsCoding/eng/codeValueCodeEng" + getUsersId()}/></td>
                    </tr>
                    <tr>
                        <td>101</td>
                        <td>Разные комбинации 1</td>
                        <td><Link className="settings-coding-change-click"
                                  to={"/SettingsCoding/eng/diffCombinationCodeEng" + getUsersId()}/></td>
                    </tr>
                    <tr>
                        <td>75</td>
                        <td>Разные комбинации 2</td>
                        <td><Link className="settings-coding-change-click"
                                  to={"/SettingsCoding/eng/diffCombination2CodeEng" + getUsersId()}/></td>
                    </tr>
                    <tr className="tr-table-all">
                        <td>326</td>
                        <td>Все коды</td>
                        <td><Link className="settings-coding-change-click" to={"/SettingsCoding/eng" + getUsersId()}/>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <span>Образы значения</span>
                <table className="bd-table">
                    <tbody>
                    <tr>
                        <td>180</td>
                        <td>Прилагательные</td>
                        <td><Link className="settings-coding-change-click"
                                  to={"/SettingsCoding/figurativeСodes/adjectivesFigurativeСodes" + getUsersId()}/></td>
                    </tr>
                    <tr>
                        <td>0</td>
                        <td>Глаголы (скоро сдела)</td>
                        <td></td>
                    </tr>
                    </tbody>
                </table>
                {/*}*/}
            </div>
        </section>
    </div>

}