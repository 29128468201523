import {Component} from "react";
import {observer} from "mobx-react";
import {CodeReliabilityStore} from "./CodeReliabilityStore";
import {reaction} from "mobx";
import './codeReliability.scss';
import CustomSelector from "../../../shared/selector/CustomSelector";
import {Chart} from "react-google-charts";


@observer
export class CodeReliability extends Component<{}, {}> {
    private store: CodeReliabilityStore = new CodeReliabilityStore();

    componentDidMount() {
        this.store.loadingStore.fetchRequest();
        reaction(
            () => [this.store.selector.value],
            this.store.loadingStore.fetchRequest
        );
    }

    render() {
        return (
            <>
                <span className="sat-name">Качество образных кодов</span>
                <span className="sat-name sat-name2">Состояние образных кодов</span>
                <div className="select-style statchosen">
                    <CustomSelector defaultValue={this.store.selector.value} values={this.store.selectors}
                                    onChange={this.store.setSelector}/>
                </div>

                <div className="circle-tabl-box">
                    {this.store.loadingStore.isLoaded &&
                        <Chart
                            chartType="PieChart"
                            data={this.store.getDataPie}
                            options={this.store.options}
                            width={"100%"}
                            height={"400px"}
                        />
                    }
                    {!this.store.loadingStore.isLoaded &&
                        <span className="empty-circle">
                            <span className="empty-stat3">Нет данных</span>
                        </span>
                    }

                    <table className="elpie-tabel">
                        <tbody>
                        <tr className="htr">
                            <td className="htr1td">Состояние</td>
                            <td>Ошибки</td>
                            <td>Кол-во</td>
                        </tr>
                        <tr>
                            <td className="htr-ref">Надёжные</td>
                            <td>≤1/10</td>
                            <td id="good-stat">{this.store.loadingStore?.get?.good ?? '-'}</td>
                        </tr>
                        <tr>
                            <td className="htr-vm">Хорошие</td>
                            <td>20%</td>
                            <td id="normal-stat">{this.store.loadingStore?.get?.normal ?? '-'}</td>
                        </tr>
                        <tr>
                            <td className="htr-b">Плохие</td>
                            <td>≥30%</td>
                            <td id="bad-stat">{this.store.loadingStore?.get?.bad ?? '-'}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className="substrate sub1l"></div>

                <span className="sat-name sat-name3">Соотношения правильных ответов и ошибок за 10 последних использований кодов</span>

                <div className="newstackedbar" id="lists" data-highcharts-chart="1">
                    {this.store.getView}
                </div>

                <div className="del" id="del-tb-analcod" title="Отчистить данные графика"></div>
            </>
        );
    }
}