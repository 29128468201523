import React from "react";
import {Link} from "react-router-dom";
import '../news/newsPostPage.scss'
import TitleStore from "../../components/authorization/store/TitleStore";

export function ManifestPage() {
    TitleStore.setTitle("Манифест");

    return <div className="news-post-page">
        <div id="news-post" style={{paddingBottom: '20px'}}>
            <hr className="news-page"/>
            <div className="news-theme"></div>

            <div className="news-stat">
                <div className="author-date author-date2">Версия 1.0 от 11.01.2023</div>
            </div>

            <h1 id="page-name">Манифест</h1>
            <div className="news-theme">Документ</div>

            <p style={{marginTop: '20px'}}>
                <b>Наша цель</b> – организация комплексного обучения мнемотехнике и предоставление пользователям
                уникального
                опыта по тренировке памяти на специально разработанных для этого тренажёрах.
            </p>

            <p>
                Мы активно работаем над тем,
                чтобы ваша тренировка была максимально комфортной и эффективной, а также увлекательной за счёт
                продуманной
                геймификации с общим принципом “Жизнь игра – значит играй” и прокачай своего реального персонажа –
                себя.
            </p>


            <p style={{marginTop: '20px'}}>
                <b>Наша идея</b> – помогать людям достигать амбициозных целей с помощью искусства запоминания –
                мнемотехники.
                Мы формулируем эту идею на основе статистических данных, полученных в процессе обучения людей <a
                style={{color: '#40a632'}} href="https://youtu.be/1Ry0qjssDNQ?si=ViX2ONUJeThwDMIv">продуктивности</a>,
                в частности обучения целеполаганию.
            </p>

            <p>
                Оказалось, что около 70% целей являются прямо достижимыми. Это означает,
                что между людьми и большим количеством целей нет существенных преград кроме действий по обучению,
                например:
            </p>

            <p style={{paddingLeft: '#20px'}}>
                ● Если ваша цель зарабатывать больше, а для этого нужно знать китайский язык, вас никто не может
                остановить
                начать изучение иероглифов уже сегодня.
            </p>
            <p style={{paddingLeft: '#20px'}}>
                ● Если ваша цель поступить в престижный ВУЗ, вас никто не может остановить начать интенсивную
                подготовку
                к экзамену уже сегодня.
            </p>
            <p style={{paddingLeft: '#20px'}}>
                ● Если ваша цель - зарабатывать больше, как IT специалист, вас никто не может остановить начать
                изучение
                архитектуры, паттернов и других областей программирования.
            </p>

            <p>
                Мы утверждаем, что одна из главных причин, почему люди не достигают своих целей, заключается в
                неумении учиться,
                в том числе запоминать и понимать. При этом, мы признаём, что люди могут обойтись без мнемотехники.
            </p>

            <p>
                Возможно просто
                вызубрить китайский, билеты к экзамену, язык программирования, но это будет равносильно движению к
                цели сквозь дремучие
                заросли, что мы расцениваем как издевательство и насилие над инстинктом познания, особенно когда
                рядом
                проложен автобан – мнемоническое запоминание.
            </p>

            <p style={{marginTop: '20px'}}>
                <b>Наша миссия</b> – раскрытие интеллектуального потенциала мозга заинтересованных групп из числа
                медиков, IT и других специалистов
                до уровня решения задач биогеронтологии и достижение целей трансгуманизма.
            </p>

            <p>
                Миссия основана на парадигме управления и понимании, что мозг является самым сложным объектом во
                Вселенной. Мы убеждены в том,
                что интеллектуальный потенциал человека безграничен и не требует улучшений, но требует раскрытия
                через повышения качества и
                скорости обучения.
            </p>

            <p>
                Мы объявляем о новом подходе в достижении целей трансгуманизма. Вместо того чтобы фокусироваться на
                создании технологий
                улучшающих интеллектуальный потенциал (ноотропы, НКИ), мы фокусируемся на раскрытии
                интеллектуального потенциала для создания
                технологий.
            </p>

            <p>Как мы это видим:</p>

            <p style={{paddingLeft: '#20px'}}>
                1. Мнемотехника ускоряет и повышает качество образования<br/>
                <span className="italic-arial-1">Пример: Русскоговорящий специалист выучил 10 популярных иностранных языков.</span>
            </p>
            <p style={{paddingLeft: '#20px'}}>
                2. Новые знания начинают раскрывать ИПМ<br/>
                <span className="italic-arial-1">Пример: Реализована природная возможность мозга обрабатывать информацию на разных языках.</span>
            </p>
            <p style={{paddingLeft: '#20px'}}>
                3. Раскрытие ИПМ приводит к созданию новых технологий<br/>
                <span className="italic-arial-1">
            Пример: Общение и чтение иностранной литературы способствует объединению усилий специалистов, что повышает вероятность
            на изобретение за счёт синергического эффекта.
        </span>
            </p>
            <p style={{paddingLeft: '#20px'}}>
                4. Новые технологии повышают качество жизни<br/>

                <span className="italic-arial-1">
            Пример: Новое изобретение снижает затраты на производство суперкомпьютеров, делая их доступными для широкого круга людей.
            Это позволяет повысить эффективность решения повседневных рабочих задач и освобождает дополнительное время для
            самообразования.
        </span>
            </p>

            <p>
                Данный цикл из четырёх этапов повторяется до вхождения человечества в эру биологического бессмертия
                – отключения
                процесса старения и фиксации максимального биологического возраста на уровне 25-30 лет.
            </p>


            <figure className="page-foto">
                <img alt="News" src="https://powermemory.io/images/news/pic-for-news/4c-manifest.png"/>
            </figure>

            <p>
                Таким образом, мы рассматриваем мнемотехнику в качестве основного катализатора человеческого
                прогресса.
            </p>
            <div className="line-q-l"></div>
            <div className="qdiv-next">
                <p className="q-next">
                    “За последние 100 лет, не умея запоминать, человечество достигло огромных высот, но
                    представьте, каких высот оно бы
                    достигло, если бы люди умели запоминать.”
                </p>
            </div>
            <div className="line-q-r"></div>

            <p>
                Интернет позволяет получить доступ к огромному пласту информации и заняться
                самообразованием. Но мы наблюдаем
                пробуксовку – общество тонет в знаниях. Информационная доступность вызывает лень и
                прокрастинацию. Мы считаем,
                что так организм пытается защититься не от информационной перегрузки, как считают многие, а
                от негативного,
                фрустрирующего опыта обучения:
            </p>


            <div className="qdiv-vertical">
                <p className="q-next" style={{textAlign: 'left'}}>
                    Учил долго.<br/>
                    Учил сложно.<br/>
                    Со временем забыл.<br/>
                    Зачем тогда учил?<br/>
                </p>
            </div>


            <p>
                Мы считаем, что временные стандарты современного образования давно заржавели и должны быть
                пересмотрены с учётом мнемонического знания. Недопустимо подготавливать особо значимые для
                общества
                высококвалифицированные кадры 20 - 25 лет:
            </p>

            <p>
                - 11 лет - школа;<br/>
                - 5 лет - бакалавриат;<br/>
                - 2 года - магистратура;<br/>
                - 3 года - аспирантура;<br/>
                - 4 года - докторантура.<br/>
            </p>

            <p>
                Проект PowerMemory делает всё, чтобы докторами наук становились молодые люди уже в 20 лет, а
                их
                деятельность вносила значительный вклад в общественное развитие, затрагивая косвенно или
                прямо
                жизнь каждого человека на Земле, в том числе длительность жизни с учётом нашей миссии.
            </p>

            @*[фото 20летних докторов наук]*@

            <p style={{marginTop: '20px'}}><b>Политика распространения знания</b></p>

            <p>
                Мы занимаемся популяризацией мнемотехники с осторожностью. Анализ исторического периода от
                раннего средневековья
                до сегодняшних дней позволил однозначно определить мнемотехнику знанием, которое вызывает у
                большинства людей
                мнемонический футурошок – защитную психологическую реакцию на радикальное перестроение
                мировоззренческих стандартов
                в части осознания реальных возможностей мозга, который может запоминать что угодно в разы
                быстрее и надежнее, чем
                все привыкли.
            </p>

            <p>
                Суть мнемотехники заключается в управлении образами через мысленный экран. Если в нужной
                последовательности не
                объяснить <a style={{color: '#40a632'}} href="https://www.youtube.com/watch?v=b8EcltL8cEs">
                основы мнемотехники
            </a> начинающему мнемонисту, то первая практика по запоминанию, будет равносильна внезапному
                надеванию на голову очков дополненной реальности, что заставит инстинкт познания спрятаться
                за инстинктом выживания.
            </p>

            <p>
                Мы <a style={{color: '#40a632'}} href="https://youtu.be/4O4w4fvwyHo?si=iQqmvFPgxrsow7V1">активно
                боремся</a> с невежеством в теме, которое дискредитирует данное знание. На просторах
                интернета распространены
                неэффективные и вредные подходы в управлении памятью. Мы призываем критически подходить к
                изучению данной темы вне
                стен нашего проекта. Любые сомнения в эффективности мнемотехники призываем считать
                вызванными недостоверной или неполной информации о теме.
            </p>

            <p>
                Мы принимаем меры по повышению темпов распространения правильной мнемотехники. В частности,
                предлагаем вступить в
                <a style={{color: '#40a632'}} href="https://t.me/alexgruk">партнерскую программу</a>, которая
                позволяет
                выпускникам зарабатывать деньги, помогая другим людям узнать о мнемотехнике и начать
                обучение.
            </p>

            <p style={{marginTop: '20px'}}><b>Политика в области образования</b></p>

            <p>
                Мы постоянно совершенствуем образовательную программу полного курса мнемотехники, чтобы
                сделать ее еще более
                эффективной и увлекательной. Мы открыты к самой жёсткой критике нашей методологии обучения,
                а также сами
                проводим постоянные краш-тесты мнемонических алгоритмов запоминания, для выявления слабых
                мест и изобретения
                более совершенных подходов.
            </p>

            <p>
                Именно поэтому мы уверены в том, что предоставляем нашим студентам самое лучшее образование
                по мнемотехнике,
                которое доступно на сегодняшний день.
            </p>

            <p>
                Также мы занимаемся разработкой дополнительных обучающих программ, имеющие цель обновить
                текущие знания
                наших выпускников.
            </p>

            <p>
                Единственным создателем и преподавателем курса мнемотехники является Алекс Грук. Мы не
                заинтересованы в привлечении
                сторонних преподавателей, поскольку никто, по нашему мнению, не подходит под стандарты
                обучения PowerMemory. При
                этом мы не будем организовывать подготовку педагогических кадров, несмотря на возрастающие
                нагрузки.
            </p>

            <p>
                Компенсация нагрузок уже происходит и будет совершенствоваться за счёт автоматизированной
                системы контроля
                памяти с кодовым названием “Мнемозина”.
            </p>

            <p>
                Эта система переносит в мир компьютерных технологий более 13 лет опыта практического
                применения мнемотехники
                Алексом и студентами курса. Система уникальна и не имеет аналогов в мире. Она предназначена
                для анализа и
                контроля всех переменных и параметров запоминания с целью выявления проблем и генерации их
                решений в
                режиме реального времени. Таким образом, АСКП “Мнемозина” сравнима с томографом, где тренер
                выступает
                в роле доктора. <br/>... <span className="italic-arial-1">будет дополнено</span>
            </p>
            <div className="head__logo" style={{display: 'flex', justifyContent: 'center'}}>
                <Link to="/">
                    <img alt="Powermemory" src="https://powermemory.io/images/footer/f_logo.png" style={{opacity: '.4'}}/>
                </Link>
            </div>

            <p style={{marginTop: '50px'}}>Подпись:</p>
            <p style={{marginTop: '0', paddingBottom: '50px'}}><b>Команда PowerMemory.io</b></p>

            <Link className="goToHomePage" to="/">На главную</Link>
        </div>
    </div>
}