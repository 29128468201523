import {CustomComponent} from "../../../helpers/CustomComponent";
import Modal from "../../modal/ReactModal";
import {ReactModalStore} from "../../modal/ReactModalStore";
import {TitleComponent, TypePositionText, TypeTitle} from "../../../helpers/title/TitleComponent";
import './learnLanguageSettingsModalComponent.scss';


interface Props{

}

export class LearnLanguageSettingsModalComponent extends CustomComponent<Props, any>{
    private readonly reactModalStore: ReactModalStore = new ReactModalStore();

    componentDidMount() {
    }

    render() {
        return <div className="learn-language-settings-component">
            <div onClick={this.reactModalStore.open} className="image-settings"></div>
            <Modal store={this.reactModalStore} >
                <TitleComponent text={"Настройка словарей"}
                                typePosition={TypePositionText.center}
                                typeTitle={TypeTitle.bigTitle}/>

            </Modal>
        </div>;
    }
}